import React, { useEffect, useState } from "react";
import _ from "lodash";
import Joi, { schema } from "joi-browser";

import { useDispatch, useSelector } from "react-redux";
import { patchProjectSummary } from "../../../store/PM-actions";
import ProjectSummaryComponent from "./ICCProjectSummaryComponent";
import Spinner from '../../../components/Spinner';


const ICCProjectSummary = ({ projectID }) => {
    const dispatch = useDispatch();
    const LKUProjectSummaryColors = useSelector((state) => state.generic.LKUs.LKUProjectSummaryColors);
    const LKUProjectCompletions = useSelector((state) => state.generic.LKUs.LKUProjectCompletions);
    const projectSummary = useSelector((state) => state.PM.projectSummary);

    const formInfo = [
        { type: 'inline-color-dropdown', id: "quality", label: "Quality", isMulti: false, options: LKUProjectSummaryColors },
        { type: 'inline-color-dropdown', id: "budget", label: "Budget", isMulti: false, options: LKUProjectSummaryColors },
        { type: 'inline-color-dropdown', id: "timeline", label: "Timeline", isMulti: false, options: LKUProjectSummaryColors },
        { type: 'inline-color-dropdown', id: "invoices", label: "Invoices", isMulti: false, options: LKUProjectSummaryColors },
        { type: 'inline-color-dropdown', id: "staffing", label: "Staffing", isMulti: false, options: LKUProjectSummaryColors },
        { type: 'inline-text', id: "comments", label: "Comments" },
        { type: 'inline-multiselect', id: "pillar_involved", label: "pillars", options: projectSummary.pillar_options },
        {
            type: 'text',
            label: 'Estimated Internal Completion (%)',
            id: 'estimated_internal_completion_percent',
        }, {
            type: 'text',
            label: 'Estimated Subcontract Completion (%)',
            id: 'estimated_subcontract_completion_percent',
        }, {
            label: "Most Recent Activity Completed",
            id: "completion",
            type: "multiselect",
            options: LKUProjectCompletions,
            isMulti: false,
        }, {
            label: "Estimated Completion Date",
            id: "estimated_completion_date",
            type: "date"
        },
    ]

    const schema = {
        [formInfo[0].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).label(formInfo[0].label),
        [formInfo[1].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).label(formInfo[1].label),
        [formInfo[2].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).label(formInfo[2].label),
        [formInfo[3].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).label(formInfo[3].label),
        [formInfo[4].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).label(formInfo[4].label),
        [formInfo[5].id]: Joi.string().required().label(formInfo[5].label),
        [formInfo[6].id]: Joi.array().min(1).label(formInfo[6].label),
        [formInfo[7].id]: Joi.number().required().label(formInfo[7].label),
        [formInfo[8].id]: Joi.number().required().label(formInfo[8].label),
        [formInfo[9].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).label(formInfo[9].label),
        [formInfo[10].id]: Joi.string().required().label(formInfo[10].label),
    }

    const initializedData = {
        [formInfo[0].id]: projectSummary[formInfo[0].id] || '',
        [formInfo[1].id]: projectSummary[formInfo[1].id] || '',
        [formInfo[2].id]: projectSummary[formInfo[2].id] || '',
        [formInfo[3].id]: projectSummary[formInfo[3].id] || '',
        [formInfo[4].id]: projectSummary[formInfo[4].id] || '',
        [formInfo[5].id]: projectSummary[formInfo[5].id] || '',
        [formInfo[6].id]: projectSummary[formInfo[6].id] || '',
        [formInfo[7].id]: Math.round(parseFloat(projectSummary[formInfo[7].id] * 100)) || 0,
        [formInfo[8].id]: Math.round(parseFloat(projectSummary[formInfo[8].id] * 100)) || 0,
        [formInfo[9].id]: projectSummary[formInfo[9].id] || '',
        [formInfo[10].id]: projectSummary[formInfo[10].id] || '',
    }

    const doSubmit = (data) => {
        console.log('ProjectSummary - doSubmit - data', data)
        const organizedModifiedProjectSummary = {
            ...data,
            estimated_internal_completion_percent: (parseInt(data.estimated_internal_completion_percent) / 100).toFixed(2),
            estimated_subcontract_completion_percent: (parseInt(data.estimated_subcontract_completion_percent) / 100).toFixed(2)
        };
        dispatch(patchProjectSummary(projectID, organizedModifiedProjectSummary));
    };

    const dataIsReady = !_.isEmpty(projectSummary) && projectSummary.project_id === projectID

    return (
        <>
            {dataIsReady ? <><h6>Project Summary :</h6>
                <p className="mt-4">Since some projects may include revenue for multiple pillars, PMs are
                    responsible to indicate which pillars should be tracking project status.</p>
                <div className="mt-5">
                    <ProjectSummaryComponent
                        formInfo={formInfo}
                        schema={schema}
                        initializedData={initializedData}
                        doSubmit={doSubmit} />
                </div></> : <Spinner />}
        </>
    )
}
export default ICCProjectSummary;