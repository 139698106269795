import _ from "lodash";

import { office } from "../services/constService";

{
  /*** convert object 
{
    "project_id": 27,
    "invoice_structure": {
        "id": 1,
        "title": "T&M"
    },
    "service_offering": [
        {
            "id": 1,
            "this": "Change Management"
        },
        {
            "id": 2,
            "this": "Inventory Optimization"
        }
    ]
} ---> {
    "project_id": 27,
    "invoice_structure": {
        "id": 1,
        "value": "T&M",
        "label": "T&M"
    },
    "service_offering": [
        {
            "id": 1,
            "this": "Change Management"
        },
        {
            "id": 2,
            "this": "Inventory Optimization"
        }
    ]
}
***/
}
export function processAPIData(initial, labelKeys) {
  const converted = {};
  for (const [key, value] of Object.entries(initial)) {
    if (typeof value === "object" && value !== null) {
      converted[key] = Array.isArray(value) ? [] : {};
      for (const [innerKey, innerValue] of Object.entries(value)) {
        if (typeof innerValue === "object" && innerValue !== null) {
          converted[key][innerKey] = processAPIData(innerValue, labelKeys); // Recursive call
        } else {
          if (labelKeys.includes(innerKey)) {
            converted[key].value = innerValue;
            converted[key].label = innerValue;
          } else if (innerKey === "id") {
            converted[key].id = innerValue;
          } else {
            converted[key][innerKey] = innerValue;
          }
        }
      }
    } else {
      converted[key] = value;
    }
  }
  return converted;
}

/**
 * Transforms the input data array into an array of unique options.
 * Each option object will have 'id', 'label', and 'value' properties.
 * Duplicate options are removed based on their 'label' property.

 const inputData = [
  { id: 1, title: 'invoice 1'},
  { id: 2, title: 'invoice 2' },
];

const outputData =
[
  { id: 1, label: 'invoice 1', value: 'value1' },
  { id: 2, label: 'invoice 2', value: 'value2' },
]
 **/

export function generateOptions(data, idKey, labelKey, valueKey) {
  // console.log("publicUtils - generateOptions - data", data);
  const seen = new Set();
  const options = data
    .map((item) => {
      const id = getNestedValue(item, idKey);
      const label = getNestedValue(item, labelKey);
      const value = getNestedValue(item, valueKey);

      return {
        id,
        label,
        value,
      };
    })
    .filter((item) => {
      const k = item.id;
      return seen.has(k) ? false : seen.add(k);
    });

  seen.clear();

  return options;
}

/**
 * Retrieves the value of a nested property from an object using a dot-separated key.
 * Make the nested data like project.project_header.id can be reached as nested data
 * rather than treat project.project_header.id as a whole name
 **/

function getNestedValue(obj, key) {
  return key.split(".").reduce((nestedObj, nestedKey) => {
    return nestedObj && nestedObj[nestedKey] !== undefined
      ? nestedObj[nestedKey]
      : null;
  }, obj);
}
/*
initialData = {
    "projects": [
        {
            "id": 663,
            "project_number": "067-23",
            "offer_number": "001-22",
            "name": "2023-07-31 test project name",
            "description": "description"
        },
        {
            "id": 664,
            "project_number": "068-23",
            "offer_number": "001-22",
            "name": "2023-07-31 test project name",
            "description": "description"
        }
    ],
    "resources": [
        {
            "id": 1,
            "title": "Ching Yip"
        },
        {
            "id": 4,
            "title": "Monique Vang"
        }
    ]
}
organizedData = {
    "projects": [
        {
            "id": 663,
            "label": "067-23 2023-07-31 test project name",
            "value": "067-23 2023-07-31 test project name"
        },
        {
            "id": 664,
            "label": "068-23 2023-07-31 test project name",
            "value": "068-23 2023-07-31 test project name"
        }
    ],
    "resources": [
        {
            "id": 1,
            "label": "Ching Yip",
            "value": "Ching Yip"
        },
        {
            "id": 4,
            "label": "Monique Vang",
            "value": "Monique Vang"
        }
    ]
}
*/
export function processAPIData2(initialData) {
  const organizedData = {};
  for (const key in initialData) {
    if (Object.hasOwnProperty.call(initialData, key)) {
      organizedData[key] = initialData[key].map(
        ({ id, name, project_number, title, pillar_item_id, pillar_title }) => {
          if (title)
            return {
              id,
              label: title,
              value: title,
            };
          if (project_number && name)
            return {
              id,
              label: project_number + " " + name,
              value: project_number + " " + name,
            };
          if (pillar_item_id && pillar_title)
            return {
              id: pillar_item_id,
              label: pillar_title,
              value: pillar_title,
            };
        }
      );
    }
  }
  return organizedData;
}

export function processUpdateInvoice(selectedRow) {
  const organizedData = {
    updatedInfo: selectedRow.updatedValue,
    originalRowInfo: {
      invoiceID: selectedRow.rowInfo.id,
      projectID: selectedRow.rowInfo.project.id,
    },
  };
  return organizedData;
}

export function processUpdateSubcontract(selectedRow) {
  const organizedData = {
    updatedInfo: selectedRow.updatedValue,
    originalRowInfo: {
      subcontractID: selectedRow.rowInfo.id,
      projectID: selectedRow.rowInfo.project.id,
    },
  };
  return organizedData;
}

export function multiSelectTransformFromObjectNestedInArray(arrayData) {
  // console.log("publicUtil - multiSelectTransformFromObjectNestedInArray - 0");
  return arrayData.map((obj) => {
    const transformedObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (value && typeof value === "object") {
          transformedObj[key] = {
            id: value.id,
            value: value.title,
            label: value.title,
          };
        } else {
          transformedObj[key] = value;
        }
      }
    }

    return transformedObj;
  });
}

export function mergeProjectMasterHeader(
  projectMasters,
  projectHeaders,
  projectDetails = null
) {
  // console.log("publicUtil - mergeProjectMasterHeader - 0");
  let mergedProjects = projectMasters.map((project) => {
    const header = projectHeaders.find(
      (header) => header.project == project.id
    );

    if (projectDetails !== null) {
      const detail = projectDetails.find(
        (detail) => detail.project_id == project.id
      );
      return {
        ...project,
        ...(header ? header : {}),
        ...(detail ? detail : {}),
      };
    }

    return {
      ...project,
      ...(header ? header : {}),
    };
  });

  mergedProjects = multiSelectTransformFromObjectNestedInArray(mergedProjects);

  return mergedProjects;
}

export function conditionalRenderCurrencyAmount(
  original_amount,
  user_office_id
) {
  if (original_amount != null) {
    let formattedAmount = parseFloat(original_amount).toFixed(2);
    var str = formattedAmount .toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
  } else {
    return null;
  }
}

export function processPatchData(rawUpdatedValue) {
  const key = Object.entries(rawUpdatedValue)[0][0];
  const value = Object.entries(rawUpdatedValue)[0][1];

  let organizedUpdatedData = rawUpdatedValue;
  if (typeof value == "object") organizedUpdatedData = { [key]: value.id };

  return organizedUpdatedData;
}


export function transformTitle (object){
  const processedObjects = [];
  object.forEach(object =>{
      const newFormatedObject = {
          id: object.id,
          label: object.title,
          value: object.title
      }
      processedObjects.push(newFormatedObject)
  })
  return processedObjects;
}
export function transformName (object){
  const processedObjects = [];
  object.forEach(object =>{
      const newFormatedObject = {
          id: object.id,
          label: object.name,
          value: object.name
      }
      processedObjects.push(newFormatedObject)
  })
  return processedObjects;
}