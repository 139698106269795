import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Form, Row, Col } from 'react-bootstrap';

import Spinner from '../Spinner';
import { FaMinus } from 'react-icons/fa';


const FileModal = ({ show, setShow, files, handlDownloadFile, handleDeleteFile, handleUploadFile, editableView = false, loading }) => {

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>View Files</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loading ?
                    <div>{
                        files.length !== 0 ? <>
                            {files.map(singleFile => {
                                return <Row key={singleFile.id}>
                                    <Col>
                                        <a href="#" onClick={() => handlDownloadFile(singleFile.id, singleFile.file_name)}>{singleFile.file_name}</a>
                                    </Col>
                                    {editableView && <Col>
                                        <Button variant='danger' className='m-1' onClick={() => handleDeleteFile(singleFile.id)} > <FaMinus /> </Button>
                                    </Col>}
                                </Row>
                            })
                            }</> : <h3>There is no any file.</h3>
                    }</div> : <div style={{ marginTop: '-100px', marginBottom: '100px' }}>
                        <Spinner />
                    </div>}
                {editableView && <Form onSubmit={(e) => handleUploadFile(e)}>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload File</Form.Label>
                        <Form.Control type="file" name="formFile" />
                        <Button type='submit' className="mt-3">Upload</Button>
                    </Form.Group>
                </Form>}
            </Modal.Body>
        </Modal>
    );
};

export default FileModal;