import { createSlice } from "@reduxjs/toolkit";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    projectMasters: [],
    projectHeaders: [],
    projectDetails: [],
    clients: [],
    subcontactors: [],
  },
  reducers: {

//*********************************Projects**************************************** */
    setProjectMasters(state, action) {
      state.projectMasters = action.payload.projectMasters;
    },
    modifyProjectMaster(state, action) {},
    setProjectHeaders(state, action) {
      state.projectHeaders = action.payload.projectHeaders;
    },
    setProjectDetails(state, action) {
      state.projectDetails = action.payload.projectDetails;
    },

//*********************************Client**************************************** */

    setClient(state, action){
      state.clients = action.payload.clients
    },
    addClient(state, action) {
      const newRow = {
        ...action.payload.newClient,
      };
      state.clients.push(newRow);
    },
    updateClient(state, action) {
      const updatedClientName = action.payload.response.data.name;
      const updatedClientID = action.payload.response.data.id;
      const updatedClient = action.payload.response.data;
      const updatedClientIndustryID = action.payload.response.data.industry;
      const updatedIndustyName = action.payload.response.data.industryName
      const updatedIndustry = {
        id: updatedClientIndustryID,
        title: updatedIndustyName
      }

    //  console.log("Client ID - " + client.id)
      const index = state.clients.findIndex(
        (client) => client.id === updatedClientID
      );
      if (index >= 0) {
        state.clients[index] = updatedClient;
        state.clients[index].name = updatedClientName;
        state.clients[index].industry_id = updatedClientIndustryID;
      }
    },

//*********************************Subcontractor**************************************** */


    setSubcontractor(state, action){
      state.subcontactors = action.payload.subcontactors
    },
    addsubcontractor(state, action) {
      const newRow = {
        ...action.payload.newSubcontractor,
      };
      state.subcontactors.push(newRow);
    },


    updateSubcontractor(state, action) {
      const updatedSubcontractName = action.payload.response.data.name;
      const updatedSubcontractID = action.payload.response.data.id;
      const updatedSubcontract = action.payload.response.data;
      const index = state.subcontactors.findIndex(
        (subcontractor) => subcontractor.id === updatedSubcontractID
      );
      if (index >= 0) {
        state.subcontactors[index] = updatedSubcontract;
        state.subcontactors[index].name = updatedSubcontractName;
      }
    },



  },
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice;
