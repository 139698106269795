import React, {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Button } from 'react-bootstrap';
import { patchProjectHeader } from '../../../../store/PM-actions';

/* Used in PMO - Closeout */
const ProjectDetailDisplayForm = ({ projectID }) => {
    const dispatch = useDispatch();
    const closeoutInfo = useSelector((state) => state.PM.closeoutInfo);

    const completedToClosed = () => {
        dispatch(patchProjectHeader(projectID, {
            "status": {
                "id": 3,
                "label": "Closed",
                "value": "Closed"
            }
        },
        true));
    };
    return (
        <>
            <div>
    {Array.isArray(closeoutInfo) ? (
        closeoutInfo.map((info, index) => (
            <div key={index}>
                <h5>Office: {info[1]}</h5>
                <h6>Local Project Status</h6>
                <div
                    style={{
                        width: '30%',
                        backgroundColor: '#DCDCDC',
                        color: 'black',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {info[0]}
                </div>
                <br />
                <br />
            </div>
        ))
    ) : (
        <div></div>
    )}
</div>
            <h5>Project Status: Completed to Closed</h5>
            <Button className="mt-3" variant="outline-primary" onClick={completedToClosed}>Close Project</Button>
        </>
    );
};

export default ProjectDetailDisplayForm;