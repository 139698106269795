import { toast } from "react-toastify";
import _ from "lodash";

import { genericActions } from "./generic-slice";
import http from "../services/httpService";
import {
  LKUsAPI,
  currentUserAPI,
  currentWeekAPI,
  allMangersAPI,
  allAdminsAPI,
  listAllOfficesAPI,
} from "../services/backendURLService";
import { appRole } from "../services/constService";
import { processAPIData2, generateOptions } from "../utils/publicUtil";

// ******************************************** LKUs ***************************************
export const getLKUs = () => {
  return async (dispatch) => {
    try {
      const { data: LKUs } = await http.get(`${LKUsAPI}`);
      // console.log("generic-actions - getLKUs - LKUs", LKUs);
      const organized_lkus = processAPIData2(LKUs);

      dispatch(
        genericActions.setLKUs({
          LKUs: organized_lkus,
        })
      );
    } catch (error) {
      toast.error("Getting LKUs has something wrong!");
    }
  };
};
export const getAllManagers = () => {
  return async (dispatch) => {
    try {
      const { data: all_managers } = await http.get(`${allMangersAPI}`);
      const organized_all_managers = generateOptions(
        all_managers,
        "id",
        "title",
        "title"
      );

      // console.log(
      //   "generic-actions - getAllManagers - all_managers",
      //   all_managers
      // );
      // console.log(
      //   "generic-actions - getAllManagers - organized_all_managers",
      //   organized_all_managers
      // );

      dispatch(
        genericActions.setAllManagers({
          allManagers: organized_all_managers,
        })
      );
    } catch (error) {
      toast.error("Getting getAllManagers has something wrong!");
    }
  };
};
export const getAllAdmins = () => {
  return async (dispatch) => {
    try {
      const { data: all_admins } = await http.get(`${allAdminsAPI}`);
      const organized_all_admins = generateOptions(
        all_admins,
        "id",
        "title",
        "title"
      );

      // console.log(
      //   "generic-actions - getAllManagers - all_managers",
      //   all_managers
      // );
      // console.log(
      //   "generic-actions - getAllManagers - organized_all_managers",
      //   organized_all_managers
      // );

      dispatch(
        genericActions.setAllAdmins({
          allAdmins: organized_all_admins,
        })
      );
    } catch (error) {
      toast.error("Getting getAllAdmins has something wrong!");
    }
  };
};
// ******************************************** current user ***********************************
export const getCurrentUser = () => {
  return async (dispatch) => {
    try {
      const { data: currentUser } = await http.get(currentUserAPI);
      console.log("currentUser:", currentUser);

      const accessPermissions = {
        generalStaff: true,
        projectManager:
          currentUser.app_roles.includes(appRole.PROJECT_MANAGER) ||
          currentUser.is_superuser == 1,
        admin:
          currentUser.app_roles.includes(appRole.ADMIN) ||
          currentUser.is_superuser == 1,
        closeoutTeam:
          currentUser.closeout_team_member !== null ||
          currentUser.is_superuser == 1,
        developTeam: currentUser.app_roles.includes(appRole.DEVELOPER),
        staffingManager:
          currentUser.app_roles.includes(appRole.STAFFING_MANAGER) ||
          currentUser.is_superuser == 1,
        ICC_USER:
          currentUser.app_roles.includes(appRole.ICC_USER),
        ICC_ADMIN:
          currentUser.app_roles.includes(appRole.ICC_ADMIN),
      };

      dispatch(
        genericActions.setCurrentUser({
          currentUser: currentUser || [],
        })
      );
      dispatch(
        genericActions.setAccessPermissions({
          accessPermissions: accessPermissions,
        })
      );
    } catch (error) {
      toast.error("Getting current user has error!");
    }
  };
};
// ******************************************** current week ***********************************
export const getCurrentWeek = () => {
  return async (dispatch) => {
    try {
      const { data: current_week } = await http.get(currentWeekAPI);

      // console.log("generic-actions - getCurrentWeek - current_week", current_week)

      dispatch(
        genericActions.setCurrentWeek({
          currentWeek: current_week,
        })
      );
    } catch (error) {
      toast.error("ERROR IN get current week ID!");
    }
  };
};
// ******************************************** all offices ***********************************
export const getAllOffices = () => {
  return async (dispatch) => {
    try {
      const { data: all_offices } = await http.get(listAllOfficesAPI);
      dispatch(
        genericActions.setAllOffices({
          allOffices: all_offices,
        })
      );
    } catch (error) {
      toast.error("ERROR in get all offices!");
      console.log("ERROR: " + error);
    }
  };
};

