import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { updateAdminSubcontract } from '../../../store/admin-actions'
import { generateOptions, processUpdateSubcontract } from "../../../utils/publicUtil";
import SubcontractFileModal from '../../../components/pages/SubcontractFileModal';
import CustomTable from "../../../components/custom/Table/CustomTable";

const AdminApproveSubcontracts = ({ approveSubcontracts }) => {
  const dispatch = useDispatch();
  const LKUPaymentTerms = useSelector((state) => state.generic.LKUs.LKUPaymentTerms);
  const subcontractCompanies = useSelector((state) => state.admin.subcontractors);
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedSubcontractID, setSelectedSubcontractID] = useState();
  const [selectedProjectID, setSelectedProjectID] = useState();

  const columns = [
    { path: "project.project_number", label: "Project Number" },
    { path: "project.name", label: "Project Name" },
    { path: "project.project_header.manager.value", label: "Project Manager" },
    { path: "project.project_header.status.value", label: "Project Status" },
    { path: "project.project_header.currency.value", label: "Project Currency" },
    {
      path: "invoicing_company", label: "Subcontractor",sortPath:"invoicing_company.value",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          type: 'multiselect',
          label: "Invoicing Company",
          id: "invoicing_company",
          options: subcontractCompanies,
          isMulti: false,
        },
        schema: {
          'invoicing_company': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Invoicing company')
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "invoice_number", label: "Subcontractor Invoice Number" },
    { path: "invoice_date", label: "Subcontractor Invoice Date" },
    { path: "concept", label: "Concept" },
    { path: "amount", label: "Amount", displayType: 'currency_amount', },
    { path: "due_date", label: "Due date" },
    {
      path: "payment_term", label: "Payment terms",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          type: 'multiselect',
          label: "payment_term",
          id: "payment_term",
          options: LKUPaymentTerms,
          isMulti: false,
        },
        schema: {
          'payment_term': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Payment term')
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "comment", label: "Comment",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "comment",
          id: "comment",
          type: "text",
        },
        schema: {
          comment: Joi.string().required().label("comment"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "status.value", label: "Status" },
  ];

  ////prepare filter options data end
  const projectNameOptions = generateOptions(
    approveSubcontracts,
    'project.id',
    'project.name',
    'project.project_number'
  );
  const projectNumberOptions = generateOptions(
    approveSubcontracts,
    'project.id',
    'project.project_number',
    'project.project_number'
  );
  const projectManagerOptions = generateOptions(
    approveSubcontracts,
    'project.project_header.manager.id',
    'project.project_header.manager.value',
    'project.project_number'
  );
  const projectStatusOptions = generateOptions(
    approveSubcontracts,
    'project.project_header.status.id',
    'project.project_header.status.value',
    'project.project_number'
  );
  const subcontractorCompanyOptions = generateOptions(
    approveSubcontracts,
    'invoicing_company.id',
    'invoicing_company.value',
    'invoicing_company'
  );

  ////prepare filter group data end
  const filterGroup = [
    {
      'id': 'Project Name',
      'path': 'project',
      "options": projectNameOptions,
      "label": "Project Name"
    },
    {
      'id': 'Project Number',
      'path': 'project',
      "options": projectNumberOptions,
      "label": "Project Number"
    },
    {
      'id': 'Project Manager',
      'path': 'project.project_header.manager',
      "options": projectManagerOptions,
      "label": "Project Manager"
    },
    {
      'id': 'Project Status',
      'path': 'project.project_header.status',
      "options": projectStatusOptions,
      "label": "Project Status"
    },
    {
      'id': 'Subcontractor Company',
      'path': 'invoicing_company',
      "options": subcontractorCompanyOptions,
      "label": "Subcontractor"
    },
  ]

  const buttonGroup = {
    upper_left: [
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Attach/View PDF files
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select subcontract!");
          } else {
            setSelectedSubcontractID(selectedRow.id)
            setSelectedProjectID(selectedRow.project.id)
            setShowFileModal(true)
          }
        },
        id: 0,
      },
    ],
  };

  return (
    <div className="table-container">
      <h2>PM Responsiblility - Approve that the subcontractor invoice belongs to the Client project, move to Admin to connect Client Invoice</h2>
      <CustomTable
        headerData={columns}
        bodyData={approveSubcontracts}
        filterGroup={filterGroup}
        buttonGroup={buttonGroup}
      />
      <SubcontractFileModal show={showFileModal} setShow={setShowFileModal} projectID={selectedProjectID} subcontractID={selectedSubcontractID} editableView />
    </div>
  );
};

export default AdminApproveSubcontracts;
