import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import _ from "lodash"
import { toast } from "react-toastify";
import { Col, Row, Modal } from 'react-bootstrap';
import { useSelector } from "react-redux";

import http from '../../../services/httpService'
import { listProjectMastersAPI } from '../../../services/backendURLService'
import NewProjectForm from "./NewProjectForm";
import Spinner from "../../../components/Spinner";

const NewProject = () => {
    const clients = useSelector((state) => state.admin.clients);
    const LKUCurrencies = useSelector((state) => state.generic.LKUs.LKUCurrencies);
    const prepareNewProject = useSelector((state) => state.admin.prepareNewProject);
    const [showSuccessCreated, setShowSuccessCreated] = useState(false)
    const [newProjectInfo, setNewProjectInfo] = useState({})

    const formInfo = [
        {
            label: 'Offer number (BD assigned number in CRM - starts with year-assigned number of prospect project)',
            id: 'offer_number',
            type: 'text',
        },
        {
            type: 'multiselect',
            label: 'Client',
            options: clients,
            id: 'client',
            isMulti: false,
        },
        {
            label: "Project Name (only the project name (30 character max), no client name, no number…, avoid abbreviation & symbols)",
            id: "description",
            type: "text",
        },
        {
            type: 'multiselect',
            label: 'Currency',
            options: LKUCurrencies,
            id: 'currency',
            isMulti: false,
        },
        {
            label: "Project Duration in Consulting days",
            id: "duration_in_days",
            type: "text",
        },
        {
            label: "Internal fees",
            id: "internal_fees",
            type: "text",
        },
        {
            label: "External fees (Subcontractor)(Enter 0 if none)",
            id: "external_fees_subcontract",
            type: "text",
        },
        {
            type: 'multiselect',
            label: 'Business units',
            options: prepareNewProject.business_units,
            id: 'business_units',
            isMulti: false
        },
        {
            type: 'multiselect',
            label: 'Pillar(s)',
            options: [],
            id: 'pillars',
            isMulti: true
        }
    ];

    const formStructure = {
        back_button: true,
        submit_button: { label: 'Save New Project' }
    };

    const schema = {
        //offer number
        [formInfo[0].id]: Joi.string().required().label(formInfo[0].label),
        //Client
        [formInfo[1].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[1].label),
        //Desc
        [formInfo[2].id]: Joi.string().allow('').label(formInfo[2].label),
        //Currency
        [formInfo[3].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[3].label),
        //Duration in days
        [formInfo[4].id]: Joi.number().required().label(formInfo[4].label),
        //Internal fees
        [formInfo[5].id]: Joi.number().required().label(formInfo[5].label),
        //External fees
        [formInfo[6].id]: Joi.number().required().label(formInfo[6].label),
        //Buiness Units
        [formInfo[7].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string(), business_unit_item_id: Joi.number() }).required().label(formInfo[7].label),
        //Pillars
        [formInfo[8].id]: Joi.array().min(1).required().label(formInfo[8].label),
    };

    const organizePostData = (data) => {
        return {
            offer_number: data.offer_number,
            client: data.client.id,
            description: data.description,
            currency: data.currency.id,
            duration_in_days: parseInt(data.duration_in_days),
            internal_fees: parseInt(data.internal_fees),
            external_fees_subcontract: parseInt(data.external_fees_subcontract),
            pillar_split: data.pillars.map((pillar) => ({
                pillar_item: pillar.id,
                percent: parseFloat(data[pillar.id.toString()])
                // parseFloat((data[pillar.id.toString()] / 100).toFixed(2))
            }))
        };
    };

    const doSubmit = async (data) => {
        const newProjectData = organizePostData(data)
        // console.log('NewProject copy - doSubmit - newProjectData', newProjectData)
        const pillarPercent = newProjectData.pillar_split.reduce((acc, cur) => acc + cur.percent, 0);
        if (pillarPercent === 1) {
            if (data.description.toLowerCase() === "dev test") {
                console.log(newProjectData)
                window.history.back();
                toast.success("Project Saved!")
            } else {
                try {
                    const { data: new_project } = await http.toastPost(
                        `${listProjectMastersAPI}`,
                        newProjectData
                    );
                    console.log('NewProject - doSubmit - new_project', new_project)
                    setNewProjectInfo(new_project)
                } catch (error) {
                    toast.error(error.response.data);
                } finally { setShowSuccessCreated(true) }
            }
        }
        else toast.error("Please Fill the pillar splits to add up to 1");
    };

    const dataIsReady = !_.isEmpty(prepareNewProject)

    const modalMessage = <>
        <h5 style={{ display: 'inline' }}>Project ID: </h5>{newProjectInfo.id} <br />
        <h5 style={{ display: 'inline' }}>Project Name: </h5>{newProjectInfo.name} <br />
        <h5 style={{ display: 'inline' }}>Offer Number: </h5>{newProjectInfo.offer_number} <br />
        <h5 style={{ display: 'inline' }}>Project Number: </h5>{newProjectInfo.project_number} <br />
        Tip: This information will be sent to your email again. When you close this message interface, you will automatically jump back to the admin view main page.
    </>

    const handleCloseModal = () => {
        setShowSuccessCreated(false)
        window.history.back();
    };


    return (
        <>
            {dataIsReady ?
                <Row>
                    <Col lg={3}></Col>
                    <Col lg={6}>
                        <h2 className="mb-5">New Project Entry Details</h2>
                        <NewProjectForm
                            formInfo={formInfo}
                            formStructure={formStructure}
                            doSubmit={doSubmit}
                            schema={schema}
                            pillars={prepareNewProject.pillars}
                        />
                        <Modal show={showSuccessCreated} onHide={handleCloseModal} centered >
                            <Modal.Header><h3>New Created Project Info</h3></Modal.Header>
                            <Modal.Body>{modalMessage}</Modal.Body>
                        </Modal>
                    </Col>
                    <Col lg={3}></Col>
                </Row>
                :
                <Spinner />}
        </>
    )
}
export default NewProject;