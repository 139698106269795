import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useHistory } from 'react-router-dom';

import { subcontractStatus } from "../../../../services/constService";
import AdminConfirmSubcontracts from "./ICCAdminConfirmSubcontracts";
import AdminConnectSubcontracts from "./ICCAdminConnectSubcontracts";
import AdminApproveSubcontracts from "./ICCICCAdminApproveSubcontracts";
import AdminPendingPaySubcontracts from "./ICCAdminPendingPaySubcontracts";
import AdminPaidSubcontracts from "./ICCAdminPaidSubcontracts";
import AdminCreateSubcontracts from "./ICCAdminCreateSubcontracts";
import AdminAllSubcontracts from "./ICCAdminAllSubcontracts";
import Spinner from "../../../../components/Spinner";

const ICCNavAdminSubcontract = () => {
  const Subcontracts = useSelector((state) => state.admin.AdminSubcontracts);
  const [createSubcontracts, setCreateSubcontracts] = useState([]);
  const [approveSubcontracts, setApproveSubcontracts] = useState([]);
  const [connectSubcontracts, setConnectSubcontracts] = useState([]);
  const [confirmSubcontracts, setConfirmSubcontracts] = useState([]);
  const [pendingPaySubcontracts, setPendingPaySubcontracts] = useState([]);
  const [paidSubcontracts, setPaidSubcontracts] = useState([]);
  // const history = useHistory();

  // useEffect(() => {
  //   if (Subcontracts == null) {
  //     console.log('redirect to another page')
  //     history.push('/admin');
  //   }
  // }, [Subcontracts]);

  useEffect(() => {
    if (Subcontracts && Subcontracts.length > 0) {
      setCreateSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.CREATE))
      setApproveSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.APPROVE))
      setConnectSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.CONNECT))
      setConfirmSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.CONFIRM))
      setPendingPaySubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.PENDING_PAY))
      setPaidSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.PAID))
    }
    if (Subcontracts && Subcontracts.length == 0) {
      setCreateSubcontracts([])
      setApproveSubcontracts([])
      setConnectSubcontracts([])
      setConfirmSubcontracts([])
      setPendingPaySubcontracts([])
      setPaidSubcontracts([])
    }
  }, [Subcontracts]
  );

  return (
    <>
      {Subcontracts === null ? (
        <div><Spinner /></div>
      ) : (
        <>
          <Row>
            <Col>
              <h1 className="mb-5">ICC Admin Subcontract View</h1>
              <br />
              <h3 className="mb-5">
                Welcome to the Miebach project subcontract management page!
              </h3>
            </Col>
          </Row>

          <Tabs>
            <TabList>
              <Tab>Subcontract Management</Tab>
              <Tab>All Subcontracts</Tab>
            </TabList>

            <TabPanel>
              <Row>
                <Col xs={12}>
                  <Tabs>
                    <TabList>
                      <Tab>Admin Workload</Tab>
                      <Tab>PM Workload</Tab>
                    </TabList>

                    <TabPanel>
                      <Row>
                        <Col xs={12}>
                          <Tabs>
                            <TabList>
                              <Tab>Add Subcontractor Invoice</Tab>
                              <Tab>Connect to Client Invoice</Tab>
                              <Tab>Confirm Payment Date</Tab>
                              <Tab>Subcontract Paid</Tab>
                            </TabList>

                            <TabPanel>
                              <AdminCreateSubcontracts createSubcontracts={createSubcontracts} />
                            </TabPanel>
                            <TabPanel>
                              <AdminConnectSubcontracts connectSubcontracts={connectSubcontracts} />
                            </TabPanel>
                            <TabPanel>
                              <AdminPendingPaySubcontracts pendingPaySubcontracts={pendingPaySubcontracts} />
                            </TabPanel>
                            <TabPanel>
                              <AdminPaidSubcontracts paidSubcontracts={paidSubcontracts} />
                            </TabPanel>
                          </Tabs>
                        </Col>
                      </Row>
                    </TabPanel>
                    <TabPanel>
                      <Row>
                        <Col xs={12}>
                          <Tabs>
                            <TabList>
                              <Tab>Approve Project</Tab>
                              <Tab>Approve to Pay Subcontractor</Tab>
                            </TabList>
                            <TabPanel>
                              <AdminApproveSubcontracts approveSubcontracts={approveSubcontracts} />
                            </TabPanel>
                            <TabPanel>
                              <AdminConfirmSubcontracts confirmSubcontracts={confirmSubcontracts} />
                            </TabPanel>
                          </Tabs>
                        </Col>
                      </Row>
                    </TabPanel>
                  </Tabs>
                </Col>
              </Row>
            </TabPanel>

            <TabPanel>
              <AdminAllSubcontracts Subcontracts={Subcontracts} />
            </TabPanel>
          </Tabs>
        </>
      )}
    </>
  );
};

export default ICCNavAdminSubcontract;
