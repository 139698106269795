import { toast } from "react-toastify";

import { COHActions } from "./Coh-slice";
import http from "../services/httpService";
import {
  listWeekSummariesAPI,
  updateWeekSummariesAPI,
  listCOHActiveWeekIDsAPI
} from "../services/backendURLService";

export const getWeekSummaries = () => {
  return async (dispatch) => {
    try {
      const { data: weekSummaries } = await http.get(`${listWeekSummariesAPI}`);

      dispatch(
        COHActions.setWeekSummaries({
          weekSummaries: weekSummaries,
        })
      );
    } catch (error) {
      toast.error("Getting week summary has something wrong!");
    }
  };
};
export const postWeekSummary = (weekID) => {
  return async (dispatch) => {
    try {
      console.log("C-actions - postWeekSummary - weekID", weekID);
      const { data: new_week_summary } = await http.post(
        `${listWeekSummariesAPI}`,
        { week_id: weekID }
      );

      const newWeekSummary = {
        week_id: new_week_summary.week,
        week_summary_id: new_week_summary.week_summary_id,
        week_name: new_week_summary.week_name,
        is_complete: new_week_summary.is_complete,
      };

      dispatch(
        COHActions.addWeekSummary({
          newWeekSummary: newWeekSummary,
        })
      );
    } catch (error) {
      toast.error("Posting week summary has something wrong!");
    }
  };
};
export const patchWeekSummary = (weekID, weekSummaryID) => {
  return async (dispatch) => {
    try {
      const { data: updated_week_summary } = await http.patch(
        `${updateWeekSummariesAPI(weekSummaryID)}`,
        { week_id: weekID }
      );

      const updatedWeekSummary = {
        week_id: updated_week_summary.week_id,
        week_summary_id: updated_week_summary.week_summary_id,
        week_name: updated_week_summary.week_name,
        is_complete: updated_week_summary.is_complete,
      };

      dispatch(
        COHActions.updateWeekSummary({
          updatedWeekSummary: updatedWeekSummary,
        })
      );
    } catch (error) {
      toast.error("Patching week summary has something wrong!");
    }
  };
};
export const getCOHActiveWeekIDs = () => {
  return async (dispatch) => {
    try {
      const { data: active_week_ids } = await http.get(`${listCOHActiveWeekIDsAPI}`);

      dispatch(
        COHActions.setActiveWeekIDs({
          activeWeekIDs: active_week_ids,
        })
      );
    } catch (error) {
      toast.error("Getting week summary has something wrong!");
    }
  };
};