import React from "react";
import _ from "lodash";

import FormTemplate from './FormTemplate'
import TableTemplate from './TableTemplate'

const PageTest = () => {
    return (
        <React.Fragment>
            <div className="container">
                <div className="custom-test">
                    <div className="title-center banner">
                        <h1 className="section-title">Custom Form</h1>
                    </div>
                    <FormTemplate />
                </div>

                <div className="custom-test">
                    <div className="title-center banner">
                        <h1 className="section-title">Custom Table</h1>
                    </div>
                    <TableTemplate />
                </div>
            </div>
            <div className="custom-test"></div>
        </React.Fragment>
    )
};

export default PageTest;