import React from 'react';

import { listInvoiceFilesAPI, downloadInvoiceFileAPI, deleteInvoiceFileAPI } from '../../services/backendURLService'
import FileModal from './FileModal'
import { useHandleFile } from '../../hooks/useHandleFile'

const InvoiceFileModal = ({ show, setShow, projectID, invoiceID, editableView = false }) => {
    const { files: invoiceFiles,
        loading,
        handlDownloadFile,
        handleUploadFile,
        handleDeleteFile } = useHandleFile({
            projectID,
            invoiceOrSubconID: invoiceID,
            filesAPI: listInvoiceFilesAPI,
            downloadFileAPI: downloadInvoiceFileAPI,
            deleteFileAPI: deleteInvoiceFileAPI
        })

    return (
        <FileModal
            show={show}
            setShow={setShow}
            files={invoiceFiles}
            handlDownloadFile={handlDownloadFile}
            handleDeleteFile={handleDeleteFile}
            handleUploadFile={handleUploadFile}
            editableView={editableView}
            loading={loading}
        />
    );
};

export default InvoiceFileModal;