import React, { useState, useEffect } from "react";
import Select from 'react-select'
import _ from 'lodash'

const ProjectsDropdownList = ({ allProjects, selectedProject, handleSelectProject }) => {
    const [dropdownList, setDropdownList] = useState([]);

    useEffect(() => {
        const project_dropdown_list = allProjects.map(element => {
            const displayContent = `${element.id}---${element.project_number}---${element.name}`
            return { value: displayContent, id: element.id, label: displayContent }
        });
        // console.log('ProjectsDropdownList - useEffect - project_dropdown_list', project_dropdown_list)
        setDropdownList(project_dropdown_list)
    }, [allProjects]);

    // console.log('ProjectsDropdownList -  - selectedProject', selectedProject)

    return (
        <>
            {allProjects.length !== 0 &&
                <Select
                    options={dropdownList}
                    value={Object.keys(selectedProject).length != 0 ? selectedProject : null}
                    onChange={(selectedValue) => handleSelectProject(selectedValue)}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            width: '800px',
                        }),
                    }}
                />
            }
        </>
    );
};
export default ProjectsDropdownList;