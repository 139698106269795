import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

import { updateAdminSubcontract, deleteAdminSubcontract } from '../../../store/admin-actions'
import { generateOptions, processUpdateSubcontract } from "../../../utils/publicUtil";
import { subcontractStatus } from "../../../services/constService";
import SubcontractFileModal from '../../../components/pages/SubcontractFileModal';
import AdminConnectInvoiceFrameModel from "./AdminConnectInvoiceFrameModel";
import CustomTable from "../../../components/custom/Table/CustomTable";
import { adminSubcontractConnectAPI } from '../../../services/backendURLService'
import http from "../../../services/httpService";

const AdminConnectSubcontracts = ({ connectSubcontracts }) => {
  const dispatch = useDispatch();
  const subcontractCompanies = useSelector((state) => state.admin.subcontractors);
  const LKUPaymentTerms = useSelector((state) => state.generic.LKUs.LKUPaymentTerms);
  const [show, setShow] = useState(false);
  const [selectedSubconInfo, setSelectedSubconInfo] = useState({});
  const [invoicesToConnect, setInvoicesToConnect] = useState([])
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedSubcontractID, setSelectedSubcontractID] = useState();
  const [selectedProjectID, setSelectedProjectID] = useState();

  const columns = [
    { path: "project.project_number", label: "Project Number" },
    { path: "project.name", label: "Project Name" },
    { path: "project.project_header.manager.value", label: "Project Manager" },
    { path: "project.project_header.status.value", label: "Project Status" },
    { path: "project.project_header.currency.value", label: "Project Currency" },
    { path: "project.project_detail.payment_term.value", label: "Project Payment term" },
    {
      path: "invoicing_company", label: "Subcontractor",sortPath:"invoicing_company.value",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          type: 'multiselect',
          label: "Invoicing Company",
          id: "invoicing_company",
          options: subcontractCompanies,
          isMulti: false,
        },
        schema: {
          'invoicing_company': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Invoicing company')
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "invoice_number", label: "Subcontractor Invoice Number", customContent: {
        type: "inputbox",
        formInfo: {
          label: "invoice_number",
          id: "invoice_number",
          type: "text",
        },
        schema: {
          invoice_number: Joi.string().required().label("Invoice ID"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "invoice_date", label: "Invoice Date" },
    { path: "concept", label: "Concept" },
    { path: "amount", label: "Amount", displayType: 'currency_amount', },
    { path: "due_date", label: "Due date" },
    {
      path: "payment_term", label: "Payment terms",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          type: 'multiselect',
          label: "payment_term",
          id: "payment_term",
          options: LKUPaymentTerms,
          isMulti: false,
        },
        schema: {
          'payment_term': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Payment term')
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "comment", label: "Comment",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "comment",
          id: "comment",
          type: "text",
        },
        schema: {
          comment: Joi.string().required().label("comment"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "status.value", label: "Status" },
    { path: "invoice.invoice_number", label: "Corresponding customer invoice number" },
    { path: "invoice.sent_date", label: "Customer date sent" },
    { path: "invoice.paid_date", label: "Customer invoice paid date" },
  ];

  const handleShowInvoiceFrame = async (selectedRow) => {

    if (!selectedRow) toast.warning("Please select subcontract!")
    else {

      // console.log('AdminConnectSubcontracts - handleShowInvoiceFrame - invoiceType', invoiceType)

      const projectID = selectedRow.project.id

      const { data: invoices } = await http.get(
        `${adminSubcontractConnectAPI(projectID)}`
      );

      const invoicesToConnect =
        invoices.length !== 0
          ? invoices
          : [{ project_id: projectID }];

      setInvoicesToConnect(invoicesToConnect)

      setSelectedSubconInfo(selectedRow);
      setShow(true);
    }
  };


  const buttonGroup = {
    upper_left: [
      {
        type: "danger",
        buttonText: (
          <Fragment>
            Delete
          </Fragment>
        ),
        handleClick: (value) => {
          if (!value) {
            toast.warning("Please select subcontract!");
          } else {
            dispatch(
              deleteAdminSubcontract({ subcontractID: value.id, projectID: value.project.id })
            );
          }
        },
        id: 0,
      },
      {
        type: "primary",
        buttonText: (
          <Fragment>
            <FaArrowDown style={{ marginRight: "5px" }} />
            Reject and Move it Back to PM
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract({
            originalRowInfo: {
              subcontractID: selectedRow.id,
              projectID: selectedRow.project.id,
            },
            updatedInfo: {
              id: selectedRow.id,
              status: subcontractStatus.APPROVE,
            }
          }))
        },
        id: 1,
      },
      {
        type: "primary",
        buttonText: (
          <Fragment>
            <FaArrowUp style={{ marginRight: "5px" }} />
            Approve and Move it to Confirm Subcontract Paid
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract({
            originalRowInfo: {
              subcontractID: selectedRow.id,
              projectID: selectedRow.project.id,
            },
            updatedInfo: {
              id: selectedRow.id,
              status: subcontractStatus.CONFIRM,
            }
          }))
        },
        id: 2,
      },
      {
        type: "primary",
        buttonText: "Connect service invoice to subcontract",
        handleClick: (selectedRow) => handleShowInvoiceFrame(selectedRow),
        id: 3
      },
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Attach/View PDF files
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select subcontract!");
          } else {
            setSelectedSubcontractID(selectedRow.id)
            setSelectedProjectID(selectedRow.project.id)
            setShowFileModal(true)
          }
        },
        id: 4,
      },
    ],
  };

  const projectNameOptions = generateOptions(
    connectSubcontracts,
    'project.id',
    'project.name',
    'project.project_number'
  );
  const projectNumberOptions = generateOptions(
    connectSubcontracts,
    'project.id',
    'project.project_number',
    'project.project_number'
  );
  const projectManagerOptions = generateOptions(
    connectSubcontracts,
    'project.project_header.manager.id',
    'project.project_header.manager.value',
    'project.project_number'
  );
  const projectStatusOptions = generateOptions(
    connectSubcontracts,
    'project.project_header.status.id',
    'project.project_header.status.value',
    'project.project_number'
  );
  const subcontractorCompanyOptions = generateOptions(
    connectSubcontracts,
    'invoicing_company.id',
    'invoicing_company.value',
    'invoicing_company'
  );

  ////prepare filter group data end
  const filterGroup = [
    {
      'id': 'Project Name',
      'path': 'project',
      "options": projectNameOptions,
      "label": "Project Name"
    },
    {
      'id': 'Project Number',
      'path': 'project',
      "options": projectNumberOptions,
      "label": "Project Number"
    },
    {
      'id': 'Project Manager',
      'path': 'project.project_header.manager',
      "options": projectManagerOptions,
      "label": "Project Manager"
    },
    {
      'id': 'Project Status',
      'path': 'project.project_header.status',
      "options": projectStatusOptions,
      "label": "Project Status"
    },
    {
      'id': 'Subcontractor Company',
      'path': 'invoicing_company',
      "options": subcontractorCompanyOptions,
      "label": "Subcontractor"
    },
  ]

  return (
    <div className="table-container">
      <h2>
      Admin Responsibility - Connect to Client Invoice, once paid, move to PM to approve to pay subcontractor
      </h2>
      {show && <AdminConnectInvoiceFrameModel selectedSubconInfo={selectedSubconInfo} invoicesToConnect={invoicesToConnect} show={show} setShow={setShow} />}

      <CustomTable
        headerData={columns}
        bodyData={connectSubcontracts}
        filterGroup={filterGroup}
        buttonGroup={buttonGroup}
      />
      <br />
      <br />
      <SubcontractFileModal show={showFileModal} setShow={setShowFileModal} projectID={selectedProjectID} subcontractID={selectedSubcontractID} editableView />
    </div>
  );
};

export default AdminConnectSubcontracts;
