import React, { useState, Fragment } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Joi from "joi-browser";
import { useDispatch } from "react-redux";

import { updateAdminSubcontract } from '../../../store/admin-actions'
import { generateOptions, processUpdateSubcontract } from "../../../utils/publicUtil";
import SubcontractFileModal from './../../../components/pages/SubcontractFileModal';
import CustomTable from "../../../components/custom/Table/CustomTable";

const AdminPaidSubcontracts = ({ paidSubcontracts }) => {
  const dispatch = useDispatch();
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedSubcontractID, setSelectedSubcontractID] = useState();
  const [selectedProjectID, setSelectedProjectID] = useState();

  const columns = [
    { path: "project.project_number", label: "Project Number" },
    { path: "project.name", label: "Project Name" },
    { path: "project.project_header.manager.value", label: "Project Manager" },
    { path: "project.project_header.status.value", label: "Project Status" },
    { path: "project.project_header.currency.value", label: "Project Currency" },
    { path: "project.project_detail.payment_term.value", label: "Project Payment term" },
    { path: "invoicing_company.value", label: "Subcontractor",sortPath:"invoicing_company.value", },
    {
      path: "invoice_number", label: "Subcontractor Invoice Number",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "Subcontractor Invoice Number",
          id: "invoice_number",
          type: "text",
        },
        schema: {
          invoice_number: Joi.string().required().label("Subcontractor Invoice Number"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "invoice_date", label: "Subcontractor Invoice Date" },
    { path: "concept", label: "Concept" },
    {
      path: "amount",
      label: "Amount Paid",
      displayType: 'currency_amount',
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "amount",
          id: "amount",
          type: "text",
        },
        schema: {
          amount: Joi.number().required().label("Amount"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      }
    },
    { path: "due_date", label: "Due date" },
    { path: "payment_term.value", label: "Payment terms" },
    {
      path: "paid_date", label: "Subcontractor paid date",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "paid_date",
          id: "paid_date",
          type: "date",
        },
        schema: {
          paid_date: Joi.string().required().label("Paid Date"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "comment", label: "Comment",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "comment",
          id: "comment",
          type: "text",
        },
        schema: {
          comment: Joi.string().required().label("comment"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "status.value", label: "Status" },
    { path: "invoice.invoice_number", label: "Corresponding customer invoice number" },
    { path: "invoice.sent_date", label: "Customer date sent" },
    { path: "invoice.paid_date", label: "Customer invoice paid date" },
    {
      path: "actual_amount", label: "Actual Paid Amount",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "actual_amount",
          id: "actual_amount",
          type: "text",
        },
        schema: {
          actual_amount: Joi.string().required().label("actual_amount"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "actual_date_paid", label: "Actual Paid Date",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "actual_date_paid",
          id: "actual_date_paid",
          type: "date",
        },
        schema: {
          actual_date_paid: Joi.string().required().label("actual_date_paid"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    }
    ];
  ////prepare filter options data end
  const projectNameOptions = generateOptions(
    paidSubcontracts,
    'project.id',
    'project.name',
    'project.project_number'
  );
  const projectNumberOptions = generateOptions(
    paidSubcontracts,
    'project.id',
    'project.project_number',
    'project.project_number'
  );
  const projectManagerOptions = generateOptions(
    paidSubcontracts,
    'project.project_header.manager.id',
    'project.project_header.manager.value',
    'project.project_number'
  );
  const projectStatusOptions = generateOptions(
    paidSubcontracts,
    'project.project_header.status.id',
    'project.project_header.status.value',
    'project.project_number'
  );
  const subcontractorCompanyOptions = generateOptions(
    paidSubcontracts,
    'invoicing_company.id',
    'invoicing_company.value',
    'invoicing_company'
  );

  ////prepare filter group data end
  const filterGroup = [
    {
      'id': 'Project Name',
      'path': 'project',
      "options": projectNameOptions,
      "label": "Project Name"
    },
    {
      'id': 'Project Number',
      'path': 'project',
      "options": projectNumberOptions,
      "label": "Project Number"
    },
    {
      'id': 'Project Manager',
      'path': 'project.project_header.manager',
      "options": projectManagerOptions,
      "label": "Project Manager"
    },
    {
      'id': 'Project Status',
      'path': 'project.project_header.status',
      "options": projectStatusOptions,
      "label": "Project Status"
    },
    {
      'id': 'Subcontractor Company',
      'path': 'invoicing_company',
      "options": subcontractorCompanyOptions,
      "label": "Subcontractor"
    },
  ]

  const buttonGroup = {
    upper_left: [
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Attach/View PDF files
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select subcontract!");
          } else {
            setSelectedSubcontractID(selectedRow.id)
            setSelectedProjectID(selectedRow.project.id)
            setShowFileModal(true)
          }
        },
        id: 0,
      },
    ],
  };

  console.log('AdminPaidSubcontracts - - paidSubcontracts', paidSubcontracts)

  return (
    <div className="table-container">
      <h2>Historical log of Subcontractor Invoices</h2>
      <CustomTable
        headerData={columns}
        bodyData={paidSubcontracts}
        filterGroup={filterGroup}
        buttonGroup={buttonGroup}
      />
      <br />
      <br />
      <SubcontractFileModal show={showFileModal} setShow={setShowFileModal} projectID={selectedProjectID} subcontractID={selectedSubcontractID} editableView />
    </div>
  );
};

export default AdminPaidSubcontracts;
