import { msalConfig } from "../authConfig";

/**
 *  This method stores the claim challenge to the localStorage in the browser to be used when acquiring a token
 * @param {String} claimsChallenge
 */
export const addClaimsToStorage = (claimsChallenge, claimsChallengeId) => {
  sessionStorage.setItem(claimsChallengeId, claimsChallenge);
};

export const getClaimsFromStorage = (claimsChallengeId) => {
  return sessionStorage.getItem(claimsChallengeId);
};

/**
 * This method clears localStorage of any claims challenge entry
 * @param {Object} account
 */
export const clearStorage = (account) => {
  for (var key in sessionStorage) {
    if (
      key.startsWith(
        `cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}`
      )
    )
      sessionStorage.removeItem(key);
  }
};
