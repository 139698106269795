import React from 'react';
import CustomTable from '../../../components/custom/Table/CustomTable'
import _ from 'lodash';
import { useSelector } from "react-redux";
import Joi from "joi-browser";
import http from '../../../services/httpService';
import { toast } from "react-toastify";
import { updateProjectHeaderAPI, updateProjectMasterAPI } from '../../../services/backendURLService';
import { mergeProjectMasterHeader } from '../../../utils/publicUtil'

const RentProspectProject = () => {
    //get project name, offer number from project masters, filter based on project number
    const prospectProjectMaster = useSelector((state) => state.Staffing.projectProspectMasters);
    //get manger_id, currency_id from project header
    const prospectProjectHeaders = useSelector((state) => state.Staffing.projectProspectHeaders)
    //get list of managers
    const allManagers = useSelector((state) => state.generic.allManagers);

    const handleUpdateMaster = async (selectedRow) => {
        try {
            // console.log('RentProspectProject - columns - selectedRow', selectedRow)
            const projectID = selectedRow.rowInfo.id
            await http.patch(
                `${updateProjectMasterAPI(projectID)}`, selectedRow.updatedValue
            );
        } catch (error) {
            console.log("ERROR: " + error)
            toast.error("Path Project Master in UpdateProjectInfo has something wrong!");
        }
    }

    const handleUpdateHeader = async (selectedRow) => {
        try {
            const projectID = selectedRow.rowInfo.id
            // console.log('RentProspectProject - columns - projectID', projectID)
            // console.log('RentProspectProject - columns - selectedRow.manager.id', selectedRow.manager.id)
            await http.patch(
                `${updateProjectHeaderAPI(projectID)}`, { manager: selectedRow.updatedValue.manager.id }
            );
        } catch (error) {
            console.log("ERROR: " + error)
            toast.error("Getting Project Header in UpdateProjectInfo has something wrong!");
        }
    }

    const columns = [
        { path: "id", label: "Project ID", },
        { path: "project_number", label: "Project Number", },
        {
            path: "name", label: "Project Name", customContent: {
                type: 'inputbox',
                formInfo: {
                    label: 'Project Name',
                    type: 'text',
                    id: 'name',
                },
                schema: { 'name': Joi.string().required().label('Project Name') },
                doSubmit: handleUpdateMaster
            }
        },
        {
            path: "description", label: "Rent Until(dd MM, e.g. 02 Nov)",
            customContent: {
                type: 'inputbox',
                formInfo: {
                    label: 'Rent Until(dd MM, e.g. 02 Nov)',
                    type: 'text',
                    id: 'description',
                },
                schema: { 'description': Joi.string().required().label('Rent Until(dd MM, e.g. 02 Nov)') },
                doSubmit: handleUpdateMaster
            }
        },
        {
            path: "manager", label: "Active PM",
            customContent: {
                type: "inputbox",
                formInfo:
                {
                    type: 'multiselect',
                    label: "Active PM",
                    id: "manager",
                    options: allManagers,
                    isMulti: false,
                },
                schema: { 'manager': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Active PM') },
                doSubmit: handleUpdateHeader
            },
        },
    ];


    const tableData = mergeProjectMasterHeader(prospectProjectMaster, prospectProjectHeaders)

    // console.log('RentProspectProject -  - projectMaster', prospectProjectMaster)
    // console.log('RentProspectProject -  - projectHeader', prospectProjectHeaders)
    // console.log('RentProspectProject -  - tableData', tableData)

    return (
        <>
            <h2>Rent Prospect.</h2>
            <h6>On this page, you can "rent out" a BD prospect to more accurately staff it
                and keep track of the prospects in Staffing.
                In order to rent out a prospect number:</h6>
            <ol>
                <li>Identify a prospect which is not currently utilized (it has either the default Prospect name, or the expiration date has passed). If the expiration date has passed, make sure to align with whoever has rented it out on whether or not you can adjust it.</li>
                <li>In the "Prospect Name" column, update the name. The format for the name should be:
                    "(PROS) OfferName - (Offer#)".Make sure to maintain the "(PROS)" so it is obvious in the staffing sheet that this is not yet a won project.</li>
                <li>Set a "Rent Until" date by which you expect this prospect will no longer be active (in format dd MM e.g. "02 Nov" - you can always come back and update later), and set yourself as the "Active Manager" for the prospect.</li>
            </ol>
            <h6>
                <CustomTable
                    headerData={columns}
                    bodyData={tableData}
                    pageSize={10}
                />
            </h6>
        </>
    );
};

export default RentProspectProject;