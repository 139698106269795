import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import ICCProjectSummary from "./ICCProjectSummary";
import ICCFeeSummary from "./ICCFeeSummary";
import ICCProjectHeaderDisplayForm from './ICCProjectHeaderDisplayForm';
import ICCHourlyReport from './ICCHourlyReport'
import ICCLatestHoursReport  from "./ICCLatestHoursReport";

const ICCNavProjectManagement = () => {
    const selectedProject = useSelector((state) => state.PM.selectedProject);
    const history = useHistory();

    useEffect(() => {
        if (Object.keys(selectedProject).length === 0) history.push('/icc/pmo');
    }, [selectedProject]);
    
    return (
        <div>
            <Row>
                <Col> <h3 className="mb-5">ICC Project Management</h3></Col>
                <Col><h3 className="mb-5">{selectedProject.value}</h3></Col>
            </Row>
            {selectedProject.length !== 0 &&
                <>
                    <Row>
                        <Col md={9}>
                            <Tabs>
                                <TabList>
                                    <Tab>Project Summary</Tab>
                                    <Tab>Fee Summary</Tab>
                                    <Tab>Hourly Report</Tab>
                                    <Tab>Latest Hours Report</Tab>
                                </TabList>


                                <TabPanel>
                                    <ICCProjectSummary projectID={selectedProject.id} />
                                </TabPanel>
                                <TabPanel>
                                    <ICCFeeSummary projectID={selectedProject.id} />
                                </TabPanel>
                                <TabPanel>
                                    <ICCHourlyReport projectID={selectedProject.id} />
                                </TabPanel>
                                <TabPanel>
                                    <ICCLatestHoursReport projectID={selectedProject.id} />
                                </TabPanel>
                            </Tabs>
                        </Col>

                        <Col>
                            <ICCProjectHeaderDisplayForm projectID={selectedProject.id} />
                        </Col>
                    </Row>

                </>
            }
        </div>
    );
};

export default ICCNavProjectManagement;