import React from "react";
import _ from 'lodash'
import ProjectsDropdownList from "../../../components/pages/ProjectsDropdownList";
import Spinner from "../../../components/Spinner";


const CloseoutProjectsDropdownList = ({ selectedProject, setSelectedProject, projects: allProjects }) => {

    const renderProjects = () => {
        if (!allProjects) return <Spinner />;

        if (allProjects.length !== 0) {
            return (
                <ProjectsDropdownList
                    allProjects={allProjects}
                    selectedProject={selectedProject}
                    handleSelectProject={setSelectedProject}
                />
            );
        }

        return <h3>There are currently no pending items</h3>;
    };

    return <>{renderProjects()}</>;
};

export default CloseoutProjectsDropdownList;