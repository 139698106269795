import React, { useRef, useEffect } from 'react';
import _ from 'lodash'
import { toast } from "react-toastify";

import http from "../../../services/httpService";
import { teamCloseoutTrackerAPI } from "../../../services/backendURLService";
import CloseoutProjectsDropdownList from './CloseoutProjectsDropdownList'
import Spinner from '../../../components/Spinner'
import CloseoutTeamTrackerCmpnnt from './CloseoutTeamTrackerCmpnnt'
import { useState } from 'react';

const CloseoutTeamView = ({ projects, teamID }) => {
    const [closeoutTeamTracker, setCloseoutTeamTracker] = useState({})
    const [selectedProject, setSelectedProject] = useState({})

    const prevSelectedProject = useRef(selectedProject);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedProject && !_.isEmpty(selectedProject) && selectedProject !== prevSelectedProject.current) {
                try {
                    const { data: closeout_team_tracker } = await http.get(
                        `${teamCloseoutTrackerAPI(selectedProject.id, teamID)}`
                    );
                    setCloseoutTeamTracker(closeout_team_tracker)
                } catch (error) {
                    toast.error("ERROR IN getting closeout team tracker!");
                }
            }
        }
        fetchData();
    }, [selectedProject]);

    const showCloseoutTracker = !_.isEmpty(selectedProject)
    const dataIsReady = !_.isEmpty(closeoutTeamTracker) && closeoutTeamTracker.project == selectedProject.id

    return (
        <div>
            <CloseoutProjectsDropdownList selectedProject={selectedProject} setSelectedProject={setSelectedProject} projects={projects} />
            <br />
            <br />
            {showCloseoutTracker ? <>
                {dataIsReady ? <CloseoutTeamTrackerCmpnnt closeoutTeamTracker={closeoutTeamTracker} key={closeoutTeamTracker.closeout_tracker_id} setCloseoutTeamTracker={setCloseoutTeamTracker} /> : <Spinner />}
            </> : null}

        </div>
    );
};

export default CloseoutTeamView;