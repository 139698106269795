import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useHistory } from 'react-router-dom';



import ICCAdminCreateInvoices from "./ICCAdminCreateInvoices";
import ICCAdminPendingSendInvoices from "./ICCAdminPendingSendInvoices";
import ICCAdminPaidInvoices from "./ICCAdminPaidInvoices";
import ICCAdminForecastedInvoices from "./ICCAdminForecastedInvoices";
import ICCAdminRequestInvoices from "./ICCAdminRequestInvoices";
import ICCAdminApproveInvoices from "./ICCAdminApproveInvoices";
import ICCAdminSentInvoices from "./ICCAdminSentInvoices";
import ICCAdminAllInvoices from "./ICCAdminAllInvoices";
import { invoiceStatus } from "../../../../services/constService";
import Spinner from "../../../../components/Spinner";

const ICCNavAdminInvoicing = () => {
  const [forecastedInvoices, setForecastedInvoices] = useState([]);
  const [requestInvoices, setRequestInvoices] = useState([]);
  const [createInvoices, setCreateInvoices] = useState([]);
  const [approveInvoices, setApproveInvoices] = useState([]);
  const [pendingSendInvoices, setPendingSendInvoices] = useState([]);
  const [sendInvoices, setSentInvoices] = useState([]);
  const [paidInvoices, setPaidInvoices] = useState([]);
  const AdminInvoices = useSelector((state) => state.admin.AdminInvoices);
  // const history = useHistory();

  // useEffect(() => {
  //   if (AdminInvoices == null) {
  //     console.log('redirect to another page')
  //     history.push('/admin');
  //   }
  // }, [AdminInvoices]);


  useEffect(() => {
    if (AdminInvoices) {
      setForecastedInvoices(AdminInvoices.filter(e => e.status.id == invoiceStatus.FORECASTED))
      setRequestInvoices(AdminInvoices.filter(e => e.status.id == invoiceStatus.REQUEST))
      setCreateInvoices(AdminInvoices.filter(e => e.status.id == invoiceStatus.CREATE))
      setApproveInvoices(AdminInvoices.filter(e => e.status.id == invoiceStatus.APPROVE))
      setPendingSendInvoices(AdminInvoices.filter(e => e.status.id == invoiceStatus.PENDING_SEND))
      setSentInvoices(AdminInvoices.filter(e => e.status.id == invoiceStatus.SENT))
      setPaidInvoices(AdminInvoices.filter(e => e.status.id == invoiceStatus.PAID))
    }
    if (AdminInvoices && AdminInvoices.length == 0) {
      setForecastedInvoices([])
      setRequestInvoices([])
      setCreateInvoices([])
      setApproveInvoices([])
      setPendingSendInvoices([])
      setSentInvoices([])
      setPaidInvoices([])
    }
  }, [AdminInvoices]);

  return (

    <>
      {AdminInvoices === null ? (
        <div><Spinner /></div>
      ) : (<>
        <Row>
          <Col>
            <h1 className="mb-5">ICC Admin Invoicing View</h1>
            <br />
            <h3 className="mb-5">
              Welcome to the Miebach project invoicing management page!
            </h3>
          </Col>
        </Row>

        <Tabs>
          <TabList>
            <Tab>Invoice Workload</Tab>
            <Tab>All Invoices</Tab>
          </TabList>

          <TabPanel>
            <Tabs>
              <TabList>
                <Tab>PM Workload</Tab>
                <Tab>Admin Workload</Tab>
              </TabList>
              <TabPanel>
                <Tabs>
                  <TabList>
                    <Tab>Forecasted Invoices</Tab>
                    <Tab>Request</Tab>
                    <Tab>Approve</Tab>
                  </TabList>

                  <TabPanel>
                    <ICCAdminForecastedInvoices forecastedInvoices={forecastedInvoices} />
                  </TabPanel>

                  <TabPanel>
                    <ICCAdminRequestInvoices requestInvoices={requestInvoices} />
                  </TabPanel>

                  <TabPanel>
                    <ICCAdminApproveInvoices approveInvoices={approveInvoices} />
                  </TabPanel>
                </Tabs>
              </TabPanel>

              <TabPanel>
                <Tabs>
                  <TabList>
                    <Tab>Create</Tab>
                    <Tab>Pending Send</Tab>
                    <Tab>Sent Invoices</Tab>
                    <Tab>Paid Invoices</Tab>
                  </TabList>

                  <TabPanel>
                    <ICCAdminCreateInvoices createInvoices={createInvoices} />
                  </TabPanel>

                  <TabPanel>
                    <ICCAdminPendingSendInvoices pendingSendInvoices={pendingSendInvoices} />
                  </TabPanel>

                  <TabPanel>
                    <ICCAdminSentInvoices sendInvoices={sendInvoices} />
                  </TabPanel>

                  <TabPanel>
                    <ICCAdminPaidInvoices paidInvoices={paidInvoices} />
                  </TabPanel>
                </Tabs>
              </TabPanel>
            </Tabs>
          </TabPanel>
          <TabPanel>
            <ICCAdminAllInvoices AdminInvoices={AdminInvoices} />
          </TabPanel>
        </Tabs>
      </>)}
    </>
  )
};

export default ICCNavAdminInvoicing;
