import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

const FrameModal = ({ modalTitle, setShow, show, children, fullscreen = false, size = '', buttonStructure = {} }) => {

    const titleLines = modalTitle.split('\n').map((line, index) => (
        <div key={index}>{line}</div>
    ));

    return (
        <div>
            <Modal style={{ marginTop: '100px' }} show={show} onHide={() => setShow(false)} fullscreen={fullscreen} size={size} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ fontWeight: 'bolder' }}>{titleLines}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{children}</Modal.Body>
                {/* <Modal.Footer style={{ justifyContent: 'flex-end', borderTop: 'none' }}>
                    {buttonStructure.submit_button &&
                        <Button className="Generate" onClick={() => setShow(false)} >
                            {buttonStructure.submit_button.label || 'Ok'}
                        </Button>}
                    {buttonStructure.cancel_button &&
                        <Button className="Cancel_btn" onClick={() => setShow(false)} >
                            {buttonStructure.cancel_button.label || 'Cancel'}
                        </Button>}
                </Modal.Footer> */}
            </Modal>
        </div>
    );
};

export default FrameModal;