import React from 'react';
import { DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";

const TableFilterDropdown = ({ label, dropdownList, selectedValue, setSelectedValue }) => {
    return (
        <>
            <label>{label}</label>{' '}
            <DropdownButton
                as={ButtonGroup}
                align={{ lg: 'end' }}
                variant="outline-primary"
                title={selectedValue || "Please select a value"}
            >
                {dropdownList.map((item) => (
                    <Dropdown.Item key={item.id} onClick={() => setSelectedValue(item.value)}>
                        {item.value}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        </>

    );
};

export default TableFilterDropdown;