import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";

import { updateAdminSubcontract, deleteAdminSubcontract } from '../../../store/admin-actions'
import { generateOptions, processUpdateSubcontract } from "../../../utils/publicUtil";
import CustomTable from "../../../components/custom/Table/CustomTable";

const AdminAllSubcontracts = ({ Subcontracts }) => {
  const dispatch = useDispatch();
  const subcontractCompanies = useSelector((state) => state.admin.subcontractors);
  const LKUSubcontractStatus = useSelector((state) => state.generic.LKUs.LKUSubcontractStatus);

  const columns = [
    { path: "project.project_number", label: "Project Number" },
    { path: "project.name", label: "Project Name" },
    { path: "project.project_header.manager.value", label: "Project Manager" },
    { path: "project.project_header.status.value", label: "Project Status" },
    { path: "project.project_header.currency.value", label: "Project Currency" },
    {
      path: "invoicing_company", label: "Subcontractor",sortPath:"invoicing_company.value",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          type: 'multiselect',
          label: "Invoicing Company",
          id: "invoicing_company",
          options: subcontractCompanies,
          isMulti: false,
        },
        schema: {
          'invoicing_company': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Invoicing company')
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "invoice_number", label: "Subcontractor Invoice Number",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "Subcontractor Invoice Number",
          id: "invoice_number",
          type: "text",
        },
        schema: {
          invoice_number: Joi.string().required().label("Subcontractor Invoice Number"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "invoice_date", label: "Subcontractor Invoice Date" },
    { path: "concept", label: "Concept" },
    { path: "amount", label: "Amount", displayType: 'currency_amount', },
    { path: "due_date", label: "Due date" },
    { path: "payment_term.value", label: "Payment terms" },
    {
      path: "comment", label: "Comment",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "comment",
          id: "comment",
          type: "text",
        },
        schema: {
          comment: Joi.string().required().label("comment"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "status", label: "Subcontract Status",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          type: 'multiselect',
          label: "Subcontract Status",
          id: "status",
          options: LKUSubcontractStatus,
          isMulti: false,
        },
        schema: {
          'status': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Subcontract Status')
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "invoice.invoice_number", label: "Corresponding customer invoice number" },
    { path: "invoice.sent_date", label: "Customer date sent" },
    { path: "invoice.paid_date", label: "Customer invoice paid date" },
    {
      path: "actual_amount", label: "Actual Paid Amount",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "actual_amount",
          id: "actual_amount",
          type: "text",
        },
        schema: {
          actual_amount: Joi.string().required().label("Actual Paid Amount"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "actual_date_paid", label: "Actual Paid Date",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "actual_date_paid",
          id: "actual_date_paid",
          type: "date",
        },
        schema: {
          actual_date_paid: Joi.string().required().label("Actual Paid Date"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    }
  ];
  ////prepare filter options data end
  const projectNameOptions = generateOptions(
    Subcontracts,
    'project.id',
    'project.name',
    'project.project_number'
  );
  const projectNumberOptions = generateOptions(
    Subcontracts,
    'project.id',
    'project.project_number',
    'project.project_number'
  );

  const projectManagerOptions = generateOptions(
    Subcontracts,
    'project.project_header.manager.id',
    'project.project_header.manager.value',
    'project.project_number'
  );
  const projectStatusOptions = generateOptions(
    Subcontracts,
    'status.id',
    'status.value',
    'status.value'
  );
  const subcontractorCompanyOptions = generateOptions(
    Subcontracts,
    'invoicing_company.id',
    'invoicing_company.value',
    'invoicing_company'
  );

  ////prepare filter group data end
  const filterGroup = [
    {
      'id': 'Project Name',
      'path': 'project',
      "options": projectNameOptions,
      "label": "Project Name"
    },
    {
      'id': 'Project Number',
      'path': 'project',
      "options": projectNumberOptions,
      "label": "Project Number"
    },
    {
      'id': 'Project Manager',
      'path': 'project.project_header.manager',
      "options": projectManagerOptions,
      "label": "Project Manager"
    },
    {
      'id': 'Status',
      'path': 'status',
      "options": projectStatusOptions,
      "label": "Status"
    },
    {
      'id': 'Subcontractor Company',
      'path': 'invoicing_company',
      "options": subcontractorCompanyOptions,
      "label": "Subcontractor"
    },
  ]

  const buttonGroup = {
    upper_left: [
      {
        type: "outline-danger",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faDeleteLeft} /> Delete
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(deleteAdminSubcontract({
            subcontractID: selectedRow.id,
            projectID: selectedRow.project.id,
          }))
        },
        id: 0,
      }
    ],
  };


  return (
    <div className="table-container">

      <h3>All Subcontracts</h3>
      <CustomTable
        headerData={columns}
        bodyData={Subcontracts}
        filterGroup={filterGroup}
        buttonGroup={buttonGroup}
      />
      <br />
      <br />
    </div>
  );
};

export default AdminAllSubcontracts;
