import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi-browser";
import _ from 'lodash';
import { Button, Col, Row } from 'react-bootstrap';

import { patchProjectRate, postProjectRate } from '../../../store/PM-actions'
import CustomTable from '../../../components/common/CustomTable';
import Spinner from '../../../components/Spinner';


const PillarButton = ({ pillars, postProjectRate }) => {
    return (<>{pillars.map((item, index) =>
        <Col lg={2} key={index}>
            <Button onClick={() => postProjectRate(item.pillar_item_id)}>
                <span className='h5'>+</span>&nbsp;Generate Default {item.pillar_title} Rate
            </Button>
        </Col>
    )}</>)
}

const ProjectRates = ({ projectID }) => {
    const dispatch = useDispatch();
    const projectRates = useSelector((state) => state.PM.projectRates);

    const gnrtPrjctRtsDpndOnSlctdPllr = (selectedPillarItemID) => {
        const postData = { "pillar_item_id": selectedPillarItemID }
        dispatch(postProjectRate(projectID, postData));
    };

    const modifyProjectRate = (data) => {
        // console.log('ProjectRates - doSubmit - data', data)
        // console.log('ProjectRates - doSubmit - projectRates', projectRates)
        dispatch(patchProjectRate(projectID, data.id, data));
    }

    const columns = [
        { path: "project_number", label: "Project Number" },
        { path: "project_name", label: "Project Name" },
        { path: "position", label: "Project Positon" },
        {
            path: "hourly_rate", label: "Hourly Rate", customContent: {
                type: 'inputbox',
                formInfo: [{
                    label: 'hourly_rate',
                    type: 'text',
                    id: 'hourly_rate',
                }],
                schema: { 'hourly_rate': Joi.number().precision(2).required().label('Hourly Rate') },
                doSubmit: modifyProjectRate
            }
        }
    ];

    const dataIsReady = !_.isEmpty(projectRates)

    return (
        <>{dataIsReady ? <>
        <div style={{color: 'red'}}><h2>Important!</h2></div>
        <h4>Ensure you review your project rates. By default, these rates are generated in USD and require manual conversion to match your project's currency. Since these rates play a crucial role in budget calculations, it's important to adjust them to prevent data skewing, especially if your currency differs from USD.</h4>
            <Row className='mt-4'>
                {projectRates.hasOwnProperty('pillars') ?
                    <PillarButton pillars={projectRates.pillars} postProjectRate={gnrtPrjctRtsDpndOnSlctdPllr} /> :
                    <CustomTable
                        headerData={columns}
                        bodyData={projectRates.rates}
                    />}
            </Row></> : <Spinner />}</>);
};

export default ProjectRates;