import React, { useState,useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import Joi from "joi-browser";
import Select, { components } from 'react-select'
import { useSelector } from "react-redux";

import FormUnitFrame from "../../../../components/common/FormUnitFrame";
import GoBackButton from '../../../../components/GoBackButton';
import NewLocalOfficeinNewProject from "./NewLocalOfficeinNewProject";

const MultiValueRemove = (props) => {
  if (props.data.isFixed) {
    return null;
  }
  return <components.MultiValueRemove {...props} />;
};

function NewICCProjectForm({
  formInfo,
  formStructure,
  schema,
  doSubmit,
  pillars
}) {
  const [data, setData] = useState({ localOffices: [], icc_internal_fees: 0, icc_external_fees: 0 });
  const [errors, setErrors] = useState({});

  const [contractedAmount, setContractedAmount] = useState(0);
  const [internalFees, setInternalFees] = useState(0);
  const [externalFees, setExternalFees] = useState(0);

  useEffect(() => {
      // Calculate ContractedAmount whenever internalFees or externalFees change
      const calculateContractedAmount = () => {
          const internal = parseInt(internalFees) || 0;
          const external = parseInt(externalFees) || 0;
          const contractedAmount = internal + external;
          setContractedAmount(contractedAmount);
      };
      
      // Update data with internalFees and externalFees
      if (!isNaN(internalFees) && !isNaN(externalFees)) {
      setData(prevData => ({
          ...prevData,
          icc_internal_fees: internalFees,
          icc_external_fees: externalFees
      }));
    }
      calculateContractedAmount();
  }, [internalFees, externalFees]);


  const validate = () => {
    const options = { abortEarly: false };
    const { localOffices, icc_internal_fees, icc_external_fees, ...dataWithoutLocalOffices } = data; // Excluding icc_internal_fees, icc_external_fees and localOffices. They do not fit the Joi.validate
    const { error } = Joi.validate(dataWithoutLocalOffices, schema, options);
    if (!error) return null;
    const newErrors = {};
    for (let item of error.details) newErrors[item.path[0]] = item.message;
    return newErrors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const propertySchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, propertySchema);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = event => {
    event.preventDefault();
    console.log('NewProjectForm - handleSubmit - data', data)

    const newErrors = validate();
    console.log('NewProjectForm - handleSubmit - newErrors', newErrors)
    setErrors(newErrors || {});

    if (newErrors) return;
    doSubmit(data, errors, setData, setErrors);
  };

  const handleChange = ({ currentTarget: input }) => {
    handleSetError(input.name, input.value)
    handleSetData({ ...data }, input.name, input.value)
  };

  const handleMultiSelectChange = (selectedList, input) => {
    const targetName = input.name;
    let previousData = { ...data };
    handleSetError(targetName, selectedList)
    handleSetData(previousData, targetName, selectedList)
  
  };


  const handleSetError = (targetName, targetValue) => {
    let previousErrors = { ...errors };
    const errorMessage = validateProperty({ name: targetName, value: targetValue });
    if (errorMessage) previousErrors[targetName] = errorMessage;
    else delete previousErrors[targetName];
    setErrors(previousErrors)
  };

  const handleSetData = (previousData, targetName, targetValue) => {
    previousData[targetName] = targetValue;
    setData(previousData)
  };


  //Add / Delete Particpating Office Component functions
  const handleClickAddButton = () => {
    const newLocalOffice = { 'office_id': null, 'budget': null , leadingOrExternal: null};
    const updatedLocalOffices = [...data.localOffices, newLocalOffice];
    //setlocalOffices(updatedLocalOffices);
    const newData = { ...data, localOffices: updatedLocalOffices };
    setData(newData) 
  };
  const handleEnterNewLocalOffice = ({ index, newLocalOffice }) => {
    // setlocalOffices(prevLocalOffice => {
    //   const newLocalOffices = [...prevLocalOffice];
    //   newLocalOffices[index] = newLocalOffice;
    //   return newLocalOffices;
    // });
    setData(prevData => {
      const newData = { ...prevData };
      const updatedLocalOffices = [...newData.localOffices];
      updatedLocalOffices[index] = newLocalOffice;
      newData.localOffices = updatedLocalOffices;
      return newData;
    });


  };
  const handleClickDeleteButton = (index) => {
    // const updatedLocalOffices = localOffices.filter((_, i) => i !== index);
    // setlocalOffices(updatedLocalOffices);
    setData(prevData => {
      const newData = { ...prevData };
      const updatedLocalOffices = newData.localOffices.filter((_, i) => i !== index);
      newData.localOffices = updatedLocalOffices;
      return newData;
    });
  };


  return (
    <Form>
      {formInfo.map((formInfo) => (
        <Form.Group
          className="mb-3"
          controlId={formInfo.id}
          key={formInfo.id}
        >
          {formInfo.type === "multiselect" && (
            <FormUnitFrame
              errors={errors[formInfo.id]}
              label={formInfo.label}
              formText={formInfo.formText} >
              <Select
                options={formInfo.options}
                defaultValue={formInfo.defaultValue}
                isMulti={formInfo.isMulti}
                isClearable={false}
                name={formInfo.id}
                onChange={handleMultiSelectChange}
                components={{ MultiValueRemove }}
                value={data[formInfo.id]}
              />
            </FormUnitFrame>
          )}
          {(formInfo.type === "text" || formInfo.type === "pillar_splite") && (
            <FormUnitFrame
              errors={errors[formInfo.id]}
              label={formInfo.label}
              formText={formInfo.formText} >
              <Form.Control
                onChange={handleChange}
                name={formInfo.id}
                value={data[formInfo.id]}
                type={formInfo.type}
                disabled={formInfo.disabled || false}
              />
            </FormUnitFrame>
          )}
          {(formInfo.type === "localOffices" ) && (
            <><h4>Offices that need local projects created</h4>
            <Button onClick={handleClickAddButton} className='m-3' variant='outline-primary'>&nbsp;&nbsp;&nbsp; + &nbsp;&nbsp;&nbsp;</Button>
            {data.localOffices && data.localOffices.map((singlePR, index) => <div key={index}>
                <NewLocalOfficeinNewProject index={index} doSubmit={handleEnterNewLocalOffice} removeOffice={handleClickDeleteButton} />
            </div>)}</>
          )}
          {(formInfo.type === "internal_fees" ) && (
            <div>
            <h5>Contracted Amount: ${contractedAmount}</h5>
            
            <FormUnitFrame
              errors={errors[formInfo.id]}
              label={formInfo.label}
              formText={formInfo.formText} >
              <Form.Control
                onChange={(e) => setInternalFees(e.target.value)}
                name={formInfo.id}
                value={internalFees}
                type={formInfo.type}
                disabled={formInfo.disabled || false}
              />
            </FormUnitFrame>
            </div>
          )}
          {(formInfo.type === "external_fees" ) && (
            <FormUnitFrame
              errors={errors[formInfo.id]}
              label={formInfo.label}
              formText={formInfo.formText} >
              <Form.Control
                onChange={(e) => setExternalFees(e.target.value)}
                name={formInfo.id}
                value={externalFees}
                type={formInfo.type}
                disabled={formInfo.disabled || false}
              />
            </FormUnitFrame>
          )}
        </Form.Group>
      ))}

      {formStructure.back_button && <GoBackButton />}

      {formStructure.submit_button &&
        <Button className="mt-3 pull-right" variant="outline-primary" onClick={handleSubmit}>
          {formStructure.submit_button.label || 'Submit'} 
        </Button>}
    </Form>
  );
}

export default NewICCProjectForm;



