import React from 'react';

const Welcome = () => {
    return (
        <div>
            <h2>Welcome to Global MATER!</h2>
            <h2>You have successfully logged in for the first time.</h2>
            <h2>Please close this page and wait patiently.</h2>
            <h2>When your personal data is ready, you will be notified to officially use Global MATER.</h2>
            <h2>Thank you!</h2>
        </div>
    );
};

export default Welcome;