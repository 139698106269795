import React from "react";
import _ from "lodash";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import http from '../../services/httpService'
import CustomTable from '../../components/custom/Table/CustomTable'
import CustomForm from "../../components/custom/Form/CustomForm";
import { listClientAPI } from '../../services/backendURLService'
import { office } from "../../services/constService";
import { getAllClients, updateClient } from "../../store/settings-actions";
import { multiSelectTransformFromObjectNestedInArray } from '../../utils/publicUtil'
import { transformName } from "../../utils/publicUtil";

// - Add table to edit current clients 
const NewClient = () => {
    const dispatch = useDispatch();
    const LKUIndustry = useSelector((state) => state.generic.LKUs.LKUIndustry);
    const user = useSelector((state) => state.generic.currentUser);
    const clients = useSelector((state) => state.settings.clients);


//***********************************New Client Forum**************************************** */

    const formInfo = [
        {
            label: "Client Name",
            id: "name",
            type: "text",
         }, 
         {
            type: 'multiselect',
            label: 'Industry',
            options: LKUIndustry,
            id: 'industry',
            isMulti: false,
        },
    ];
    const formStructure = {
        submit_button: 'Save new Client',
        back_button: true
    };
    const formSchema = {
        [formInfo[0].id]: Joi.string().required().label(formInfo[0].label),
        [formInfo[1].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[1].label)
        };

  

    const submitData = async (data, errors) => {
        const newClientName = String(data.name).toLowerCase().trim()
        let clientExists = false;
        clients.forEach(client =>{
            const fixedClientName = String(client.name).toLowerCase().trim()
            if(fixedClientName.includes(newClientName)){
                clientExists = true;
                return; 
            }
        })

        if (clientExists) {
            toast.warning("Client already exists");
            return; // Exit the entire function
        }

        const officeID = user.office_id
        try { 
            const newValues = {
                name: data.name,
                industry: data.industry.id,
                office: officeID,
              };
            await http.post(
                `${listClientAPI(officeID)}`, newValues
            );
            toast.success("Saved Successfully."); 
        }
        catch (error) { 
            console.log("Error: " + error); 
        }
        dispatch(getAllClients(officeID))
    };


//*********************************Existing Client Table**************************************** */

const handleUpdate = async (selectedRow) => {  
    const officeID = user.office_id
    const clientID = selectedRow.rowInfo.id

  
    const value = Object.entries(selectedRow.updatedValue)[0][1]
    const key = Object.entries(selectedRow.updatedValue)[0][0]


    let newData = selectedRow.updatedValue
    if (typeof value == 'object') newData = { [key]: value.id }
    //newData.industry_id = newData.industry
    //delete newData.industry;

    if (!selectedRow) {
        toast.warning("Please select a client!")
    }
    else{
        dispatch(updateClient(officeID, clientID ,newData))
    } 
}

const clientObjects = transformName(clients)

    const columns = [
        { path: "name", label: "Client Name",
            customContent: {
                type: 'inputbox',
                formInfo: {
                    type: 'text',
                    id: 'name',
                },
                schema: { 'name': Joi.string().required().label('Client Name') },
                doSubmit: handleUpdate
            }
        },
        { path: "industry", label: "Industry",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'multiselect',
                    id: "industry",
                    options: LKUIndustry,
                    isMulti: false,
                },
                schema: { 'industry': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string()}).required().label('industry') },
                doSubmit: handleUpdate
            },
        },
    ];
    const filterGroup = [
        {
            'id': 'Client Name',
            "options": clientObjects,
            "label": "Client Name",
            'path': 'lookup',
        },
        {
            'id': 'industry',
            "options": LKUIndustry,
            "label": "Industry",
            'path': 'industry',
        }
    ]

     //had to add 'transformedObj.lookup' to every client object so the filter 'Client name' works. not sure exactly why but it works.
    function transformedClient(arrayData) {
        return arrayData.map((obj) => {
            const transformedObj = {};
          
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                const value = obj[key];
          
                if (value && typeof value === "object") {
                  transformedObj[key] = {
                    id: value.id,
                    value: value.title,
                    label: value.title,
                  };
                } else {
                  transformedObj[key] = value;
                }
              }
            }
            transformedObj.lookup = {
                id: transformedObj.id,
                value: transformedObj.name,
                label: transformedObj.name
            }
            return transformedObj; 
        });
    }




//*********************************Rendering**************************************** */

    return (
        <div className="container">
                <div className="custom-test">
                    <div className="title-center banner">
                        <h1 className="section-title">New Client</h1>
                    </div>
                    <div>
                    <p>Please fill in the new client's name and what industry they</p>
                    </div>
            <CustomForm
                formInfo={formInfo}
                formStructure={formStructure}
                formSchema={formSchema}
                doSubmit={submitData}
            />
            </div>
            <div>
                    <div className="title-center banner">
                        <h1 className="section-title">Existing Clients</h1>
                    </div>
                    <div>
                        <p>Below is a list of exisiting clients in the database, here is where you can edit the name and industry of the client. Note: Clients belonging to only your working office will show up.</p>
                    </div>
            <h6>
                 <CustomTable
                    headerData={columns}
                    bodyData={transformedClient(clients)}
                    pageSize={15}
                    filterGroup={filterGroup}
                /> 
            </h6>
            </div>
        </div>
    );
};
export default NewClient;