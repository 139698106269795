import React from 'react';

import { listSubcontractFilesAPI, downloadSubcontractFileAPI, deleteSubcontractFileAPI } from '../../services/backendURLService'
import FileModal from './FileModal'
import { useHandleFile } from '../../hooks/useHandleFile'

const SubcontractFileModal = ({ show, setShow, projectID, subcontractID, editableView = false }) => {
    const { files: subcontractFiles,
        loading,
        handlDownloadFile,
        handleUploadFile,
        handleDeleteFile } = useHandleFile({
            projectID,
            invoiceOrSubconID: subcontractID,
            filesAPI: listSubcontractFilesAPI,
            downloadFileAPI: downloadSubcontractFileAPI,
            deleteFileAPI: deleteSubcontractFileAPI
        })

    return (
        <FileModal
            show={show}
            setShow={setShow}
            files={subcontractFiles}
            handlDownloadFile={handlDownloadFile}
            handleDeleteFile={handleDeleteFile}
            handleUploadFile={handleUploadFile}
            editableView={editableView}
            loading={loading}
        />
    );
};

export default SubcontractFileModal;