import React, { useState } from 'react';
import { Button, Form, Container } from "react-bootstrap";
import Joi from "joi-browser";
import _ from "lodash";
import Select, { components } from 'react-select'

import FormUnitFrame from "../../../components/common/FormUnitFrame";
import './ICCProjectSummary.css'


const MultiValueRemove = (props) => {
    if (props.data.isFixed) return null;
    return <components.MultiValueRemove {...props} />;
};

const ICCProjectSummaryTable = ({
    formInfo,
    schema,
    initializedData = {},
    doSubmit }) => {


    const [data, setData] = useState(initializedData);
    const [errors, setErrors] = useState({});


    const validate = () => {
        const options = { abortEarly: false };
        const { error } = Joi.validate(data, schema, options);
        if (!error) return null;
        const newErrors = {};
        for (let item of error.details) newErrors[item.path[0]] = item.message;
        return newErrors;
    };

    const validateProperty = ({ name, value, type, checked }) => {
        const obj = { [name]: value }
        const propertySchema = { [name]: schema[name] };
        const { error } = Joi.validate(obj, propertySchema);
        return error ? error.details[0].message : null;
    };

    const handleSubmit = event => {
        event.preventDefault();

        const newErrors = validate();
        setErrors(newErrors || {});
        if (newErrors) return;

        doSubmit(data, errors, setData, setErrors);
    };

    const handleChange = ({ currentTarget: input }) => {
        const previousErrors = { ...errors };
        const errorMessage = validateProperty(input);
        if (errorMessage) previousErrors[input.name] = errorMessage;
        else delete previousErrors[input.name];
        setErrors(previousErrors)

        const previousData = { ...data };
        previousData[input.id] = input.value
        setData(previousData)
    };

    const handleMultiSelectChange = (selectedList, input) => {
        const targetValue = selectedList;
        const targetName = input.name;

        const previousErrors = { ...errors };
        const errorMessage = validateProperty({ name: targetName, value: targetValue });
        if (errorMessage) previousErrors[targetName] = errorMessage;
        else delete previousErrors[targetName];
        setErrors(previousErrors)

        const previousData = { ...data };
        previousData[targetName] = targetValue;
        setData(previousData)
    };

    return (
        <Container>
            <>
                {formInfo.map((formInfo) => (<div key={formInfo.id} style={{ display: 'inline' }}>
                    {formInfo.type === "inline-color-dropdown" && (
                        <div className='summary-inline'>
                            <FormUnitFrame
                                errors={errors[formInfo.id]}
                                label={formInfo.label}
                                formText={formInfo.formText} >
                                <Select
                                    options={formInfo.options}
                                    defaultValue={data[formInfo.id]}
                                    isMulti={formInfo.isMulti}
                                    isClearable={false}
                                    name={formInfo.id}
                                    onChange={handleMultiSelectChange}
                                    components={{ MultiValueRemove }}
                                    styles={{
                                        control: (styles) => ({ ...styles, backgroundColor: data[formInfo.id].value })
                                    }}
                                />
                            </FormUnitFrame>
                        </div>
                    )}
                    {formInfo.type === "inline-text" && (
                        <div className='summary-inline comment'>
                            <FormUnitFrame
                                errors={errors[formInfo.id]}
                                label={formInfo.label}
                                formText={formInfo.formText} >
                                <Form.Control
                                    onChange={handleChange}
                                    id={formInfo.id}
                                    name={formInfo.id}
                                    value={data[formInfo.id]}
                                    type={formInfo.type}
                                    disabled={formInfo.disabled || false}
                                />
                            </FormUnitFrame>
                        </div>
                    )}
                    {formInfo.type === "inline-multiselect" && (
                        <div className='summary-inline summary-pillar'>
                            <FormUnitFrame
                                errors={errors[formInfo.id]}
                                label={formInfo.label}
                                formText={formInfo.formText} >
                                <Select
                                    options={formInfo.options}
                                    defaultValue={data[formInfo.id]}
                                    isMulti={true}
                                    isClearable={false}
                                    name={formInfo.id}
                                    onChange={handleMultiSelectChange}
                                    components={{ MultiValueRemove }}
                                />
                            </FormUnitFrame>
                        </div>
                    )}
                </div>
                ))}
            </>
            <Form>
                {formInfo.map((formInfo) => (
                    <Form.Group
                        className="mb-3"
                        controlId={formInfo.id}
                        key={formInfo.id}
                    >
                        {formInfo.type === "multiselect" && (
                            <FormUnitFrame
                                errors={errors[formInfo.id]}
                                label={formInfo.label}
                                formText={formInfo.formText} >
                                <Select
                                    options={formInfo.options}
                                    defaultValue={data[formInfo.id]}
                                    isMulti={formInfo.isMulti}
                                    isClearable={false}
                                    name={formInfo.id}
                                    onChange={handleMultiSelectChange}
                                    components={{ MultiValueRemove }}
                                />
                            </FormUnitFrame>
                        )}
                        {(formInfo.type === "text" || formInfo.type === 'date') && (
                            <FormUnitFrame
                                errors={errors[formInfo.id]}
                                label={formInfo.label}
                                formText={formInfo.formText} >
                                <Form.Control
                                    onChange={handleChange}
                                    name={formInfo.id}
                                    value={data[formInfo.id]}
                                    type={formInfo.type}
                                    disabled={formInfo.disabled || false}
                                />
                            </FormUnitFrame>
                        )}
                    </Form.Group>
                ))}
                <Button className="mt-3" variant="outline-primary" onClick={handleSubmit}>
                    {'Update Project Summary'}
                </Button>
            </Form>
        </Container>

    );
};

export default ICCProjectSummaryTable;