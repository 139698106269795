import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash'
import Joi from "joi-browser";

import Spinner from "../../../components/Spinner";
import WorkingDays from "./WorkingDays";
import { postAvailableDays, patchAvailableDays, deleteAvailableDays } from '../../../store/staffing-actions'
import { toast } from "react-toastify";
import { prepareDateHeader, removeFirstElement } from '../../../utils/staffingUtil'
import CustomTable from "../../../components/custom/Table/CustomTable";
import { postUtilization, patchUtilization, deleteUtilization } from '../../../store/staffing-actions'

const AvailableDays = () => {
    const dispatch = useDispatch();
    const availableDays = useSelector((state) => state.Staffing.availableDays);
    let allResourcesOptions = useSelector((state) => state.generic.LKUs.LKUResources)
    const selectedWeek = useSelector((state) => state.Staffing.selectedWeek);

    const handleSubmit = (data) => {
        // console.log('AvailableDays - doSubmit - data', data)
        // console.log('AvailableDays - doSubmit - availableDays', availableDays)

        const { updatedValue, rowInfo } = data
        const resourceID = rowInfo.resource.id
        const weekID = Object.keys(updatedValue)[0].split(".")[0]
        const originalAvailableData = rowInfo[weekID]
        const availableDaysID = originalAvailableData.id
        const updatedAvailableDays = Object.values(updatedValue)[0]
        const workingDaysInThisWeek = originalAvailableData.working_days
        const submitData = {
            'resource': resourceID,
            'available_days': updatedAvailableDays,
            'working_days': workingDaysInThisWeek
        }

        // console.log('AvailableDays - doSubmit - resourceID', resourceID)
        // console.log('AvailableDays - doSubmit - weekID', weekID)
        // console.log('AvailableDays - doSubmit - originalAvailableData', originalAvailableData)
        // console.log('AvailableDays - doSubmit - availableDaysID', availableDaysID)
        // console.log('AvailableDays - doSubmit - updatedAvailableDays', updatedAvailableDays)
        // console.log('AvailableDays - doSubmit - workingDaysInThisWeek', workingDaysInThisWeek)

        if (updatedAvailableDays == workingDaysInThisWeek) {
            if (availableDaysID !== null) dispatch(deleteAvailableDays(weekID, availableDaysID, submitData));
        } else if (updatedAvailableDays > workingDaysInThisWeek) toast.error('You cannot save an available day which is larger than the working day in this week!')
        else {
            if (availableDaysID == null) dispatch(postAvailableDays(weekID, submitData));
            else dispatch(patchAvailableDays(weekID, availableDaysID, submitData));
        }

        updateUtilization(weekID, resourceID, parseFloat(updatedAvailableDays), parseFloat(workingDaysInThisWeek))
    };

    const utilizations = useSelector((state) => state.Staffing.utilizations);
    const forecastedDays = useSelector((state) => state.Staffing.forecastedDays);

    // console.log('AvailableDays - - utilizations', utilizations)
    // console.log('AvailableDays - - forecastedDays', forecastedDays)


    const getForecastedDays = (weekID, resourceID) => {
        let sum_forecasted_days = 0;
        for (const item of forecastedDays) {
            if (item.resource && item.project && item.resource.id == resourceID) {
                if (weekID in item) {
                    sum_forecasted_days += parseFloat(item[weekID].value);
                }
            }
        }
        return sum_forecasted_days
    };

    const getUtilizationID = (weekID, resourceID) => {
        const index = utilizations.findIndex(
            (utilization) => utilization.hasOwnProperty('resource') && utilization.resource.id == resourceID
        );

        const utilizationID = utilizations[index][weekID].utilization_summary_id
        // console.log('AvailableDays - utilizationID ', utilizationID)
        return utilizationID
    }

    const updateUtilization = (weekID, resourceID, availableDays, workingDays) => {
        const forecastedDays = getForecastedDays(weekID, resourceID)
        const utilizationID = getUtilizationID(weekID, resourceID)

        const utilization = ((workingDays - availableDays + forecastedDays) / workingDays).toFixed(2)

        // console.log('AvailableDays - updateUtilization - workingDays', workingDays)
        // console.log('AvailableDays - updateUtilization - availableDays', availableDays)
        // console.log('AvailableDays - updateUtilization - forecastedDays', forecastedDays)
        // console.log('AvailableDays - updateUtilization - utilization', utilization)

        const data = {
            "resource": resourceID,
            "utilization": utilization
        }

        // if (utilization == 0 && utilizationID !== null) dispatch(deleteUtilization(weekID, utilizationID, data));
        // else if (utilization !== 0) {
        //     if (utilizationID == null) dispatch(postUtilization(weekID, data));
        //     else dispatch(patchUtilization(weekID, utilizationID, data));
        // }
        if (utilizationID == null) dispatch(postUtilization(weekID, data));
        else dispatch(patchUtilization(weekID, utilizationID, data));
    };

    let header_data = []
    let body_data = []
    let dataIsReady = false

    if (availableDays) {
        // -------------------------------------- prepare header data --------------------------------------
        const basic_header_data = prepareDateHeader(availableDays)

        const header_with_custom_content = [...basic_header_data].map(item => ({
            path: `${item.path}.value`,
            label: item.label,
            customContent: {
                type: 'inputbox',
                formInfo: {
                    id: `${item.path}.value`,
                    type: 'text',
                },
                schema: { [`${item.path}.value`]: Joi.number().required().label('Avalilable Days') },
                doSubmit: handleSubmit
            }
        }));

        header_data = [
            { path: 'resource.value', label: "Resource" },
            ...header_with_custom_content
        ];

        // -------------------------------------- prepare body data --------------------------------------
        /* Because available days contains two parts, the first part is weeks (week ids, week names)(this part is for make table header), and the second part is available days itself */

        body_data = removeFirstElement(availableDays)

        // -------------------------------------- prepare is ready ? --------------------------------------
        /* header data index 0 is 'resource', 1 is the selected week id */
        dataIsReady = basic_header_data[0].path == selectedWeek.id
    }

    const filterGroup = [
        {
            'id': 'resource',
            'path': 'resource',
            "options": allResourcesOptions,
            "label": "Resource"
        }
    ]

    //  console.log('AvailableDays -  - header_data', header_data)
    //  console.log('AvailableDays -  - body_data', body_data)
    //  console.log('AvailableDays -  - availableDays', availableDays)

    return (
        <>
            {dataIsReady ? <>
                <WorkingDays />
                <div style={{ marginTop: '80px' }}>
                    <CustomTable headerData={header_data} bodyData={body_data} filterGroup={filterGroup} tableTitle={'Available Days'} />
                </div>
            </> : <Spinner />}
        </>
    );
};

export default AvailableDays;