import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Joi from "joi-browser";
import Select, { components } from 'react-select'

import FormUnitFrame from "./FormUnitFrame";
import GoBackButton from '../GoBackButton';

const MultiValueRemove = (props) => {
  if (props.data.isFixed) return null;
  return <components.MultiValueRemove {...props} />;
};

function CustomForm({
  formInfo,
  formStructure,
  schema,
  initializedData = {},
  doSubmit,
}) {
  const [data, setData] = useState(initializedData);
  const [errors, setErrors] = useState({});


  const validate = () => {
    const options = {
      abortEarly: false,
      allowUnknown: true,
    };;
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;
    const newErrors = {};
    for (let item of error.details) newErrors[item.path[0]] = item.message;
    return newErrors;
  };

  const validateProperty = ({ name, value, type, checked }) => {
    const obj = type !== "checkbox" ? { [name]: value } : { [name]: checked };
    const propertySchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, propertySchema);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = event => {
    event.preventDefault();

    const newErrors = validate();
    setErrors(newErrors || {});

    if (newErrors) return;
    doSubmit(data, errors, setData, setErrors);
  };

  const handleChange = ({ currentTarget: input }) => {
    const previousErrors = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) previousErrors[input.name] = errorMessage;
    else delete previousErrors[input.name];
    setErrors(previousErrors)

    const previousData = { ...data };
    input.type !== "checkbox"
      ? (previousData[input.id] = input.value)
      : (previousData[input.id] = input.checked);
    setData(previousData)
  };

  const handleMultiSelectChange = (selectedList, input) => {

    const targetValue = selectedList;
    const targetName = input.name;

    const previousData = { ...data };
    previousData[targetName] = targetValue;

    setData(previousData)

    const previousErrors = { ...errors };
    const errorMessage = validateProperty({ name: targetName, value: targetValue });
    if (errorMessage) previousErrors[targetName] = errorMessage;
    else delete previousErrors[targetName];
    setErrors(previousErrors)
  };


  return (
    <Form>
      {formInfo.map((formInfo) => (
        <Form.Group
          className="mb-3"
          controlId={formInfo.id}
          key={formInfo.id}
        >
          {formInfo.type === "multiselect" && (
            <FormUnitFrame
              errors={errors[formInfo.id]}
              label={formInfo.label}
              formText={formInfo.formText} >
              <Select
                options={formInfo.options}
                defaultValue={formInfo.defaultValue}
                isMulti={formInfo.isMulti}
                isClearable={false}
                name={formInfo.id}
                onChange={handleMultiSelectChange}
                components={{ MultiValueRemove }}
              />
            </FormUnitFrame>
          )}
          {(formInfo.type === "text" || formInfo.type === "password" || formInfo.type === 'email' || formInfo.type === 'date') && (
            <FormUnitFrame
              errors={errors[formInfo.id]}
              label={formInfo.label}
              formText={formInfo.formText} >
              <Form.Control
                onChange={handleChange}
                name={formInfo.id}
                value={data[formInfo.id]}
                type={formInfo.type}
                disabled={formInfo.disabled || false}
              />
            </FormUnitFrame>
          )}
          {formInfo.type === "textarea" && (
            <FormUnitFrame
              errors={errors[formInfo.id]}
              label={formInfo.label}
              formText={formInfo.formText} >
              <Form.Control
                onChange={handleChange}
                name={formInfo.id}
                value={data[formInfo.id]}
                disabled={formInfo.disabled || false}
                as={formInfo.type}
                rows={5}
              />
            </FormUnitFrame>
          )}
          {formInfo.type === "checkbox" && (
            <FormUnitFrame
              errors={errors[formInfo.id]}
              label={formInfo.label}
              formText={formInfo.formText} >
              <Form.Check
                type="checkbox"
                label={formInfo.label}
                onChange={handleChange}
                name={formInfo.id}
                defaultChecked={data[formInfo.id]}
              />
            </FormUnitFrame>
          )}

        </Form.Group>
      ))}

      {formStructure.back_button && <GoBackButton />}

      {formStructure.submit_button &&
        <Button className="mt-3 pull-right" variant="outline-primary" onClick={handleSubmit}>
          {formStructure.submit_button.label || 'Submit'}
        </Button>}
    </Form>
  );
}

export default CustomForm;
