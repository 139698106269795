import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import { UnauthenticatedTemplate, useMsal, AuthenticatedTemplate } from '@azure/msal-react';
import { Container, Row, Col } from 'react-bootstrap'

import auth from "../../services/authService";
import http from '../../services/httpService'
import { loginRequest } from '../../authConfig';
import { checkUserExistInfoCompleteAPI, usersAPI } from '../../services/backendURLService'
import Spinner from '../../components/Spinner'
import './LoginWithAzure.css'

const LoginWithAzure = () => {
  const PASSWORD = 'q@$sIKjboVgb['

  const { instance } = useMsal();
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const loginWithAzure = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.log(error);
    }
  };

  const completeLoginProcess = async (activeAccount) => {
    const userInfo = await checkUserExistence(activeAccount.username)        //activeAccount.localAccountId

    if (!userInfo.existence) {                 // first time login: user is not exist, register new user in GM backend
      await registerNewUser(activeAccount)
      window.location = "/welcome";
    } else if (!userInfo.completeness) window.location = "/welcome";    // exist this user, the info is not complete
    else loginWithDjangoBackend({
      "username": activeAccount.username,
      "password": PASSWORD
    })
  };

  const checkUserExistence = async (azureEmail) => {
    const { data } = await http.get(checkUserExistInfoCompleteAPI(azureEmail));
    return data
  }

  const registerNewUser = async (activeAccount) => {
    // console.log('LoginWithAzure - useEffect - activeAccount', activeAccount)
    // console.log('LoginWithAzure - useEffect - firstName', activeAccount.name.split(', ')[1])
    // console.log('LoginWithAzure - useEffect - lastName', activeAccount.name.split(', ')[0])
    // console.log('LoginWithAzure - useEffect - email', activeAccount.username)
    // console.log('LoginWithAzure - useEffect - username', activeAccount.localAccountId)
    // console.log('LoginWithAzure - useEffect - password', PASSWORD)

    const firstName = activeAccount.name.split(', ')[1]
    const lastName = activeAccount.name.split(', ')[0]
    const email = activeAccount.username
    const userName = activeAccount.username
    const password = PASSWORD

    try {
      await http.toastPost(`${usersAPI}`, {
        username: userName,
        email: email,
        password: password,
        first_name: firstName,
        last_name: lastName,
        office: '',
      });
    } catch (error) {
      const res = error.response.data;
      if (res.hasOwnProperty("username")) toast.error(res.username[0]);
      if (res.hasOwnProperty("email")) toast.error(res.email[0]);
      if (res.hasOwnProperty("password")) toast.error(res.password[0]);
    }
  };

  const loginWithDjangoBackend = async (data) => {
    // console.log('LoginWithAzure - loginWithDjangoBackend - data', data)
    try {
      await auth.login(data);
      window.location = "/home";
      toast.success("Login Sucessfully!");
    } catch (error) {
      if (error.response.status == 401) toast.error(error.response.data.detail);
      console.log(error.response);
    }
  };

  useEffect(() => {
    if (activeAccount !== null && activeAccount.username.includes('@miebach.com')) {
      completeLoginProcess(activeAccount)
    }
  }, [activeAccount])

  // console.log('LoginWithAzure - - activeAccount', activeAccount)

  return (
    <>
      <UnauthenticatedTemplate>
        <Container style={{ paddingTop: "5%", paddingBottom: "5%" }}>
          <Row style={{ marginBottom: "30px" }}>
            <div className="log-in-center banner">
              <h1 style={{ fontWeight: "bold" }}>MATER</h1>
            </div>
          </Row>
          <Row>
            <Col>
              <Container>
                <Row>
                  <Col sm="3"></Col>
                  <Col>
                    <div className='login-button'>
                      <Button onClick={loginWithAzure} variant='outline-primary' size="lg" className='m-3' style={{ width: '100%' }}>Sign in By Azure Account</Button>
                      <Button variant='outline-primary' size="lg" className='m-3' disabled style={{ width: '100%' }}>Sign in By Email and Password</Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col sm="1">
              <div className="log-in-center">
                <div className="vertical-line"></div>
              </div>
              <div className="log-in-center">
                <div className="vertical-line"></div>
              </div>
            </Col>
            <Col>
              <Container>
                <Row>
                  <div id="contact">
                    <div className="vertical-heading">
                      <h2>
                        Get In <strong>Touch</strong>
                      </h2>
                    </div>
                  </div>
                </Row>
                <Row>
                  <p></p>
                  <div id="login-offices">
                    <h4>North America</h4>
                    <ul className="office-details">
                      <li>
                        <i className="fa fa-mobile"></i>
                        <span>
                          <a href="tel:+13174263126">+(1) 317 423-3126</a>
                        </span>
                      </li>
                      <br />
                      <li>
                        <i className="fa fa-envelope"></i>
                        <span>
                          <a href="mailto:indianapolis@miebach.com">
                            indianapolis@miebach.com
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </Row>
                <Row>
                  <div id="login-offices">
                    <ul className="office-details">
                      <li>
                        <i className="fa fa-map-marker"></i>
                        <span>
                        11100 USA Parkway Suite 3500 IN 46037<br />
                        Fishers, USA.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-map-marker"></i>
                        <span>
                          1000 Sherbrooke Ouest 1410 H3A 3G4
                          <br />
                          Montreal, Canada.
                        </span>
                      </li>
                    </ul>
                    <ul className="social-list">
                      <li>
                        <a href="#">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-google-plus"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </UnauthenticatedTemplate>
      {
        activeAccount !== null && <AuthenticatedTemplate>
          <Spinner />
        </AuthenticatedTemplate>
      }
    </>
  );
};

export default LoginWithAzure;