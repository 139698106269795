import React, { useState } from 'react';
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { Container, Row, Col } from 'react-bootstrap';


import http from "../../../services/httpService";
import { icclatestHoursReport,getAllLocalICCProjects } from "../../../services/backendURLService";
import CustomForm from '../../../components/common/CustomForm';
import CustomTable from '../../../components/common/CustomTable';
import Spinner from '../../../components/Spinner'

const ICCLatestHoursReport = ({ projectID }) => {
    const [LatestHoursReport, setLatestHoursReport] = useState(null);
    const [showLoader, setShowLoading] = useState(false)

    const formInfo = [{}];

    const formStructure = { submit_button: { label: 'Calculate Latest Hours Entry Dates'} };

    const schema = {};

    const doSubmit = async (data, errors, setData, setErrors) => {
        setShowLoading(true)
        try {
            //Get all ICC projects local connected projects
            const { data: listOflocalICCProjects } = await http.get(getAllLocalICCProjects(projectID));
            //loop through each local project to get the individual project latesthoursreport and append 
            const latestHoursPromises = listOflocalICCProjects.map(async project => {
                const { data: latestHours } = await http.get(icclatestHoursReport(project[0]));
                return latestHours;
            });
            const latestHoursArray = await Promise.all(latestHoursPromises);
            //console.log("THIS COULD BE IT ----", latestHoursArray)
            const finalLatestHoursReport = [];
            latestHoursArray.forEach(project =>{
                    if(project.length > 0){
                        const newFormatedHoursObject = {
                            office: project[0].office,
                            project_name: project[0].project_name,
                            resource: project[0].resource,
                            Last_Hours_Entry_Date: project[0].Last_Hours_Entry_Date
                        }
                        finalLatestHoursReport.push(newFormatedHoursObject)
                    }
                })


            setLatestHoursReport(finalLatestHoursReport);
            setShowLoading(false)
        } catch (error) {
            console.error("Error fetching latest hours report:", error);
            toast.warning("Project detail info is not complete."); // Toast for 405 status
        }
    };

    const columns = [
        //path is what its called from back end
        { path: "office", label: "Office"},
        { path: "project_name", label: "Project Name"},
        { path: "resource", label: "Resource Name" },
        { path: "Last_Hours_Entry_Date", label: "Last Hours Entry Date" }
    ];

    return (


     
       <>
         {/*  Lastest Hours Report */}
        <Container className='latest-report-container'>
        <Row>
                <div>
                    <p>This grid displays the latest dates for which resources on this project have entered hours.</p>
                    <p>Note that they may not have entered hours for this project on the displayed date - the purpose 
                        of this display is more so to inform you that they have NOT entered hours on this project since the displayed date.</p>
                </div>
            </Row>
            <Row>
                <Col md={8}>
                    <CustomForm
                        formInfo={formInfo}
                        formStructure={formStructure}
                        doSubmit={doSubmit}
                        schema={schema} />
                </Col>
            </Row>
            <Row className='mt-5'>
                {showLoader && <div style={{ marginTop: '-250px', marginLeft: '-250px', padding: '20px' }}><Spinner /></div>}


                {LatestHoursReport && LatestHoursReport.length !== 0 && !showLoader && <CustomTable headerData={columns} bodyData={LatestHoursReport} />}
                {LatestHoursReport && LatestHoursReport.length == 0 && !showLoader && <h3>There is no hours data for this project</h3>}
            </Row>




        </Container></>
    
    
    
    
    
    
    );

};

export default ICCLatestHoursReport;