import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Joi from "joi-browser";
import Select, { components } from 'react-select'

import FormUnitFrame from "../../../components/common/FormUnitFrame";
import GoBackButton from '../../../components/GoBackButton';

const MultiValueRemove = (props) => {
  if (props.data.isFixed) {
    return null;
  }
  return <components.MultiValueRemove {...props} />;
};

function NewProjectForm({
  formInfo,
  formStructure,
  schema,
  doSubmit,
  pillars
}) {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;
    const newErrors = {};
    for (let item of error.details) newErrors[item.path[0]] = item.message;
    return newErrors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const propertySchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, propertySchema);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = event => {
    event.preventDefault();
    console.log('NewProjectForm - handleSubmit - data', data)

    const newErrors = validate();
    console.log('NewProjectForm - handleSubmit - newErrors', newErrors)
    setErrors(newErrors || {});

    if (newErrors) return;
    doSubmit(data, errors, setData, setErrors);
  };

  const handleChange = ({ currentTarget: input }) => {
    handleSetError(input.name, input.value)
    handleSetData({ ...data }, input.name, input.value)
  };

  const handleMultiSelectChange = (selectedList, input) => {
    const targetName = input.name;
    let previousData = { ...data };

    if (targetName === 'business_units') handleBUChange(selectedList)

    else {
      if (targetName === 'pillars') {
        if (input.action === "remove-value") handleDeletePillarSpilt(input.removedValue)
        else handleAddPillarSplite(selectedList)
      }
      handleSetError(targetName, selectedList)
      handleSetData(previousData, targetName, selectedList)
    }
  };

  const handleDeletePillarSpilt = (pilarItem) => {
    formInfo.forEach((item, index) => {
      if (item.type === 'pillar_splite' && item.id === pilarItem.id) {
        formInfo.splice(index, 1);
      }
    });

    delete schema[pilarItem.id];
  };

  const handleAddPillarSplite = (selectedPillarList) => {
   // const pillarSplitTotal = 0
    selectedPillarList.forEach(({ id, label }) => {
      
      const pllrSpltFrmInfIndx = formInfo.findIndex(e => e.id === parseInt(id));

      if (pllrSpltFrmInfIndx === -1) {
        formInfo.push({
          type: 'pillar_splite',
          label: 'Pillar split ' + label,
          id: id
        });
        schema[id] = Joi.number().required().label(label);
      }
    });
  };

  const handleSetError = (targetName, targetValue) => {
    let previousErrors = { ...errors };
    const errorMessage = validateProperty({ name: targetName, value: targetValue });
    if (errorMessage) previousErrors[targetName] = errorMessage;
    else delete previousErrors[targetName];
    setErrors(previousErrors)
  };

  const handleSetData = (previousData, targetName, targetValue) => {
    previousData[targetName] = targetValue;
    setData(previousData)
  };

  const handleBUChange = (selectedBU) => {
    // prepare selectable pillars according to the selected BU
    const pillarsIndex = formInfo.findIndex(item => item.id === 'pillars');
    if (pillarsIndex !== -1) {
      formInfo[pillarsIndex].options = pillars.filter(pillar => pillar.business_unit_item_id === selectedBU.id);
    }

    // change the selected pillars to []
    let previousData = { ...data }
    previousData.pillars = [];

    // change the business_units to selectedBU, this radio step is written out because of the hysteresis of react
    previousData.business_units = selectedBU
    setData(previousData)

    // delete all pillar_splite in formInfo, forEach() method: when remove an element, the remaining elements shift down by one index, which means that the next element will be skipped. We need to iterate over the array in reverse order 
    for (let i = formInfo.length - 1; i >= 0; i--) {
      if (formInfo[i].type === 'pillar_splite') {
        formInfo.splice(i, 1);
      }
    }
  };

  return (
    <Form>
      {formInfo.map((formInfo) => (
        <Form.Group
          className="mb-3"
          controlId={formInfo.id}
          key={formInfo.id}
        >
          {formInfo.type === "multiselect" && (
            <FormUnitFrame
              errors={errors[formInfo.id]}
              label={formInfo.label}
              formText={formInfo.formText} >
              <Select
                options={formInfo.options}
                defaultValue={formInfo.defaultValue}
                isMulti={formInfo.isMulti}
                isClearable={false}
                name={formInfo.id}
                onChange={handleMultiSelectChange}
                components={{ MultiValueRemove }}
                value={data[formInfo.id]}
              />
            </FormUnitFrame>
          )}
          {(formInfo.type === "text" || formInfo.type === "pillar_splite") && (
            <FormUnitFrame
              errors={errors[formInfo.id]}
              label={formInfo.label}
              formText={formInfo.formText} >
              <Form.Control
                onChange={handleChange}
                name={formInfo.id}
                value={data[formInfo.id]}
                type={formInfo.type}
                disabled={formInfo.disabled || false}
              />
            </FormUnitFrame>
          )}
        </Form.Group>
      ))}

      {formStructure.back_button && <GoBackButton />}

      {formStructure.submit_button &&
        <Button className="mt-3 pull-right" variant="outline-primary" onClick={handleSubmit}>
          {formStructure.submit_button.label || 'Submit'}
        </Button>}
    </Form>
  );
}

export default NewProjectForm;
