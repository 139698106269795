import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Container, Col, Button } from "react-bootstrap";
import _ from 'lodash'

import { patchProjectHeader } from "../../../../store/PM-actions";
import Spinner from "../../../../components/Spinner";
import { getPMCloseoutTrackers } from "../../../../store/PM-actions";
import { projectStatus } from "../../../../services/constService"; 
import PMCloseoutTrackerCmpnnt from "./PMCloseoutTrackerCmpnnt";

const ProjectStatus = () => {
    const dispatch = useDispatch();
    const selectedProject = useSelector((state) => state.PM.selectedProject);

    const openToCompleted = () => {
        dispatch(patchProjectHeader(selectedProject.id, {
            "status": {
                "id": projectStatus.COMPLETED,
                "label": "Completed",
                "value": "Completed"
            }
        },
        true));
    };

    return (
        <Container>
            <h3>Change status for project {selectedProject.id} --- {selectedProject.value}</h3>
            <h5>This project is still open, if you want to closeout, you need to change the status of the project to completed!</h5>
            <Col>
                <Button className="mt-3" variant="outline-primary" onClick={openToCompleted}> Change project status from OPEN to COMPLETED</Button>
            </Col>
        </Container>
    )
};

const PMCloseoutTracker = () => {
    const closeoutTrackers = useSelector((state) => state.PM.PMCloseoutTrackers);
    const selectedProject = useSelector((state) => state.PM.selectedProject);

    const dataIsReady = closeoutTrackers.length !== 0 && closeoutTrackers[0].project == selectedProject.id

    // console.log('PMCloseoutTracker -  - dataIsReady', dataIsReady)

    return (
        <>{dataIsReady ? <PMCloseoutTrackerCmpnnt closeoutTrackers={closeoutTrackers} /> : <Spinner />}</>
    )
};

const ICCPMCloseout = () => {
    const selectedProject = useSelector((state) => state.PM.selectedProject);
    const projectHeader = useSelector((state) => state.PM.projectHeader);
    const history = useHistory();

    useEffect(() => {
        if (Object.keys(selectedProject).length === 0) history.push('/pmo');
    }, [selectedProject]);

    const dataIsReady = !_.isEmpty(projectHeader) && projectHeader.project == selectedProject.id

    // console.log('PMCloseout -  - dataIsReady', dataIsReady)

    return (
        // status 1: Open; status 2: Completed; status 3: Closed
        <>{dataIsReady ? <>{projectHeader.status.id == projectStatus.COMPLETED ? 
        <PMCloseoutTracker /> : (projectHeader.status.id == projectStatus.OPEN? 
        <ProjectStatus />:<ICCPMCloseoutClose/>)}</> : <Spinner />} </>
    );
};

export default ICCPMCloseout;

const ICCPMCloseoutClose=()=>{
    return (
        <div>This project is closed.</div>
    )
}