import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import http from "../../../services/httpService";
import { projectsForCloseoutTeamAPI } from "../../../services/backendURLService";
import Spinner from '../../../components/Spinner'
import CloseoutPillarView from './CloseoutPillarView'

const CloseoutTeamView = ({ team }) => {
    const [pillarsWithProjects, setPillarsWithProjects] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: projects_pending_approval } = await http.get(
                    `${projectsForCloseoutTeamAPI(team.id)}`
                );

                setPillarsWithProjects(projects_pending_approval)
            } catch (error) {
                toast.error("ERROR IN getting closeout team pending projects!");
            }
        }
        fetchData();
    }, []);

    const renderPillarView = () => {
        if (!pillarsWithProjects) return <Spinner />;

        if (pillarsWithProjects.length !== 0) {
            return (
                <Tabs>
                    <TabList>
                        {pillarsWithProjects.map(pillarProjects => <Tab>{pillarProjects.pillar_title}</Tab>)}
                    </TabList>
                    {pillarsWithProjects.map(pillarProjects => <TabPanel>
                        <CloseoutPillarView projects={pillarProjects.project_list} teamID={team.id} />
                    </TabPanel>)}
                </Tabs>
            );
        }

        return <h3>There are currently no pending items</h3>;
    };

    return <>{renderPillarView()}</>;
};

export default CloseoutTeamView;