import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi-browser";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import http from "../../services/httpService";
import { listProjectHoursAPI, updateDaySummariesAPI, listDaySummariesAPI, updateProjectHoursAPI, postProjectHoursAPI } from '../../services/backendURLService'
import ProjectHoursTable from './ProjectHoursTable';
import Spinner from '../../components/Spinner';
import { postWeekSummary, patchWeekSummary } from "../../store/Coh-actions"
import { office as lkuOffice } from "../../services/constService";


const ProjectHours = () => {
    const { week_id: weekID, week_summary_id: weekSummaryID } = useParams();
    const history = useHistory();
    const LKUStates = useSelector((state) => state.generic.LKUs.LKUStates);
    const dispatch = useDispatch();
    const activeWeekIDs = useSelector((state) => state.COH.activeWeekIDs);

    const editable = activeWeekIDs && activeWeekIDs.includes(parseInt(weekID, 10));

    const [anchoredHeader, setAnchoredHeader] = useState()
    const [shownHeader, setShownHeader] = useState()
    const [stateHeader, setStateHeader] = useState()
    const [hourHeader, setHourHeader] = useState()
    const [stateBody, setStateBody] = useState()
    const [hourBody, setHourBody] = useState()
    const user = useSelector((state) => state.generic.currentUser);
    const office = user.office_id;
   // const office = lkuOffice.BARCELONA;
    useEffect(() => {
      const getProjectHours = async () => {
        const { data: project_hours } = await http.get(
          `${listProjectHoursAPI(weekID)}`
        );
       // console.log("ProjectHours - useEffect - project_hours", project_hours);

        // Function to filter out weekend data
        const filterWeekendData = (data) => {
          const filteredData = {};
          for (const key in data) {
            if (
              key !== "title" &&
              !(
                data[key].day_of_week === "Saturday" ||
                data[key].day_of_week === "Sunday"
              )
            ) {
              filteredData[key] = data[key];
            }
          }
          return filteredData;
        };

        // Check the office and filter data if necessary
        let filteredProjectHours = project_hours;
        if (office === lkuOffice.BARCELONA || office ===  lkuOffice.MADRID) {
          filteredProjectHours[0] = filterWeekendData(project_hours[0]);
        }

        initialAnchoredHeader(filteredProjectHours[0]);
        initialShownHeader(filteredProjectHours[0]);
        initialStateHeader(filteredProjectHours[0]);
        initialHourHeader(filteredProjectHours[0]);
        initialStateBody(filteredProjectHours[0]);
        initialHourBody(filteredProjectHours.slice(1));
      };

      getProjectHours();
    }, [weekID]);

    useEffect(() => {
        /* update week summary when unmount this component */
        return () => {
            if (weekSummaryID == "null") dispatch(postWeekSummary(weekID))
            else dispatch(patchWeekSummary(weekID, weekSummaryID))
        };
    }, []);

    // ******************************************** come in data **********************************************************
    const initialAnchoredHeader = (daySummaryData) => {

        const anchored_header = [{ id: 'title', sortPath: 'title.project_role_id' }];

        Object.entries(daySummaryData).forEach(([id, item]) => {
            if (id !== "title") {
                anchored_header.push({ id: parseInt(id), day_summary_id: item.day_summary_id, sortPath: `${id}.hours` });
            }
        });
        setAnchoredHeader(anchored_header)

        console.log('ProjectHours - initialAnchoredHeader - anchored_header', anchored_header)
    };
    const initialShownHeader = (daySummaryData) => {
        const shown_header = [];
 
        // Create the "title" row
        const titleRow = { title: "Day of Week" };
        for (const key in daySummaryData ) {
            if (key !== "title") {
                titleRow[key] = daySummaryData[key].day_of_week;
            }
        }
        shown_header.push(titleRow);

        // Create the "Date" row
        const dateRow = { title: "Date" };
        for (const key in daySummaryData) {
            if (key !== "title") {
                dateRow[key] = daySummaryData[key].date;
            }
        }
        shown_header.push(dateRow);

        // Create the "(8<=) Sum hours (<=12)" row
        const sumHoursRow = { title: "(8<=) Sum hours (<=12)" };
        for (const key in daySummaryData) {
            if (key !== "title") {
                sumHoursRow[key] = daySummaryData[key].sum_hours;
            }
        }
        shown_header.push(sumHoursRow);

        setShownHeader(shown_header)

        console.log('ProjectHours - initialShownHeader - shown_header', shown_header)
    };
    const initialStateHeader = (daySummaryData) => {
        const stateContent = {
            type: "inputbox",
            formInfo: {
                type: 'multiselect',
                id: "state",
                options: LKUStates,
                isMulti: false,
            },
            schema: { 'state': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('State') },
            doPost: postState,
            doPatch: patchState
        }

        const state_header = [];

        // Add the "title" entry
        state_header.push({ id: "title", path: "title" });

        // Add entries for each key in the daySummaryData object
        for (const key in daySummaryData) {
            if (key !== "title") {
                state_header.push({ id: parseInt(key), path: `${key}.value`, ...(editable && { customContent: stateContent }) });
            }
        }

        setStateHeader(state_header)

        console.log('ProjectHours - initialStateHeader - state_header', state_header)
    };
    const initialHourHeader = (daySummaryData) => {
        const hourContent = {
            type: "inputbox",
            formInfo: {
                type: 'text',
                id: 'hours',
            },
            schema: { 'hours': Joi.number().required().label('Project Hours') },
            doPost: postHour,
            doPatch: patchHour,
        }
        const hour_header = [];
        // Add the "title" entry
        hour_header.push({ id: "title", path: "title.project" });
        // Add entries for each key in the daySummaryData object
        for (const key in daySummaryData) {
            if (key !== "title") {
                hour_header.push({ id: parseInt(key), path: `${key}.hours`, ...(editable && { customContent: hourContent }) });
            }
        }
        setHourHeader(hour_header)
        console.log('ProjectHours - initialHourHeader - hour_header', hour_header)
    };
    const initialStateBody = (daySummaryData) => {
        const state_body = [
            {
                title: "State",
                id: "state"
            }
        ];

        // Iterate over keys in the input object
        for (const key in daySummaryData) {
            if (key !== "title") {
                const stateData = daySummaryData[key];
                const stateEntry = {
                    id: key,
                    value: stateData.state,
                    label: stateData.state,
                    // id: stateData.state_id, // If you want to include the state_id as "id"
                };
                state_body[0][key] = stateEntry;
            }
        }
        setStateBody(state_body)
        console.log('ProjectHours - initialStateBody - state_body', state_body)
    };
    const initialHourBody = (projectHourData) => {

        const hour_body = projectHourData.map(projectHour => {
            // Extract the "project_role_id" from the "title" object
            const projectRoleID = projectHour.title.project_role_id;
            // Create the output object
            return {
                ...projectHour,
                id: projectRoleID
            };
        })
        setHourBody(hour_body)
        console.log('ProjectHours - initialHourBody - hour_body', hour_body)
    };

    // ******************************************** go out data **********************************************************
    const patchState = async (daySummaryID, updatedDaySummaryData) => {
        await http.patch(`${updateDaySummariesAPI(weekID, daySummaryID)}`, updatedDaySummaryData);
    };
    const postState = async (newDaySummaryData) => {
        const { data: newDaySummary } = await http.post(`${listDaySummariesAPI(weekID)}`, newDaySummaryData);
        return newDaySummary
    };
    const patchHour = async (daySummaryID, projectHourID, updatedProjectHourData) => {
        try {
            const response=await http.patch(`${updateProjectHoursAPI(weekID, daySummaryID, projectHourID)}`, updatedProjectHourData);
            return response;
        } catch (error) {
            toast.error(`Error updating project hour: ${error.response ? error.response.data.detail : error.message}`);
        }
    };
    
    const postHour = async (daySummaryID, newProjectHourData) => {
        try {
            const response= await http.post(`${postProjectHoursAPI(weekID, daySummaryID)}`, newProjectHourData);
            return response;
        } catch (error) {
            toast.error(`Error creating project hour: ${error.response ? error.response.data.detail : error.message}`);
        }
    };

    return (
        <>{
            (anchoredHeader && shownHeader && stateHeader && stateBody && hourHeader && hourBody && activeWeekIDs) ? <>
                <h4 className='mb-5'>Weekly Project Hours - Detail</h4>
                <ProjectHoursTable anchoredHeader={anchoredHeader} shownHeader={shownHeader} stateHeader={stateHeader} hourHeader={hourHeader} stateBody={stateBody} hourBody={hourBody}  />
                <Button variant="outline-primary" className='mt-3 mb-3' onClick={() => history.goBack()}>Go Back</Button>
                <Button variant="outline-primary pull-right" onClick={() => history.push(`/coh/manage-project-role`)}>Manage Project Roles</Button>
            </> : <Spinner />
        }</>

    );
};

export default ProjectHours;