import React, { useEffect } from 'react';
import _ from "lodash";
import Joi from "joi-browser";
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";

import CustomForm from "../../../components/custom/Form/CustomForm";
import { postProjectRole } from '../../../store/staffing-actions';

const NewProjectRoleInStaffing = () => {
    const dispatch = useDispatch()
    const allResourcesOptions = useSelector((state) => state.generic.LKUs.LKUResources)
    const allProjectsOptions = useSelector((state) => state.Staffing.projectOptions)
    const allPostitionsOptions = useSelector((state) => state.generic.LKUs.LKUPosition)

    const formInfo = [
        {
            type: 'multiselect',
            label: 'Project',
            options: allProjectsOptions,
            id: 'project',
            isMulti: false
        },
        {
            type: 'multiselect',
            label: 'Resource',
            options: allResourcesOptions,
            id: 'resource',
            isMulti: false
        }, {
            type: 'multiselect',
            label: 'Position',
            options: allPostitionsOptions,
            id: 'position',
            isMulti: false
        }
    ];

    const formStructure = {
        submit_button: 'New Project Role',
        back_button: true
    };

    const formSchema = {
        [formInfo[0].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[0].label),
        [formInfo[1].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[1].label),
        [formInfo[2].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[2].label),
    };

    const submitData = async (data) => {
        // console.log('NewProjectRoleInStaffing - submitData - data', data)
        // console.log('NewProjectRoleInStaffing - submitData - selectedWeek', selectedWeek)

        const postData = {
            'resource': data.resource.id,
            'project': data.project.id,
            'position': data.position.id
        }

        dispatch(postProjectRole(postData));
    };

    return (
        <>
            <Container>
                <Row>
                    <Col></Col>
                    <Col>
                        <CustomForm
                            formInfo={formInfo}
                            formStructure={formStructure}
                            formSchema={formSchema}
                            doSubmit={submitData}
                        />
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </>
    );
};

export default NewProjectRoleInStaffing;