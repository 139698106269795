import React from "react";
import { useSelector } from "react-redux";
import _ from 'lodash'

import Spinner from "../../../components/Spinner";
import CustomTable from "../../../components/custom/Table/CustomTable";
import { prepareDateHeader, removeFirstElement } from '../../../utils/staffingUtil'

const WorkingDays = () => {
    const workingDays = useSelector((state) => state.Staffing.workingDays);
    // const basicWeekHeader = useSelector((state) => state.Staffing.basicWeekHeader);
    const selectedWeek = useSelector((state) => state.Staffing.selectedWeek);

    let header_data = []
    let body_data = []
    let dataIsReady = false

    if (workingDays) {
        // -------------------------------------- prepare header data --------------------------------------
        header_data = [{ path: "office", label: "Office" }, ...prepareDateHeader(workingDays)]

        // -------------------------------------- prepare body data --------------------------------------
        /* Because working days contains two parts, the first part is weeks (week ids, week names)(this part is for make table header), and the second part is workings days itself */
        const workingDaysItself = removeFirstElement(workingDays)

        /* id is for preventing error message from TableBody - tr */
        body_data = workingDaysItself.map(obj => ({ ...obj, id: obj.office }));

        /* header data index 0 is 'office', 1 is the selected week id */
        dataIsReady = header_data[1].path == selectedWeek.id
    }

    // console.log('WorkingDays -  - workingDays', workingDays);
    // console.log('WorkingDays -  - header_data', header_data);
    // console.log('WorkingDays -  - body_data', body_data);
    // console.log('WorkingDays -  - selectedWeek', selectedWeek);

    return (
        <>
            {dataIsReady ? <CustomTable headerData={header_data} bodyData={body_data} tableTitle={'Working Days'} /> : <Spinner />}
        </>
    );
};

export default WorkingDays;