import React, { useEffect } from "react";
import auth from "../../services/authService";

function Logout() {
  useEffect(() => {
    window.location = "/login";
    auth.logout();
  }, []);
  return null;
}

export default Logout;
