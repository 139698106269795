import React, { useState, useEffect } from 'react';
import _ from "lodash";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown, faPencil } from "@fortawesome/free-solid-svg-icons";

import TableFormCell from '../../components/custom/Table/TableFormCell';
import { toast } from 'react-toastify';
import { applySort } from '../../utils/tableUtil';
import { useSelector } from 'react-redux';
import http from '../../services/httpService';
import { listDaySummariesAPI } from '../../services/backendURLService';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { office as lkuOffice, response as lkuResponse } from "../../services/constService";
import Spinner from '../../components/Spinner';


const ProjectHoursTableHeader = ({ anchoredHeader, shownHeader, sortColumn, setSortColumn }) => {
    const handleSort = (path) => {
        if (sortColumn.path === path) {
            const sortOrder = sortColumn.order === "asc" ? "desc" : "asc";
            setSortColumn({ path, order: sortOrder });
        } else setSortColumn({ path, order: "desc" });

    };
    const renderSortIcon = (anchoredColumn) => {
        if (sortColumn.path == anchoredColumn.sortPath) {
            if (sortColumn.order === "asc")
                return <FontAwesomeIcon icon={faSortUp} />;
            return <FontAwesomeIcon icon={faSortDown} />;
        } else return null;
    };

    /*
        headerRow： { title: "Day of Week", 267: "Sunday", 268: "Monday" }
        anchoredColumn.id： title, 267, 268
    */
    return (
        <thead >
            {shownHeader.map((headerRow, index) =>
                <tr key={headerRow.title}>
                    {anchoredHeader.map((anchoredColumn) =>
                        <th
                            style={{ cursor: "pointer" }}
                            className="clickable"
                            key={headerRow.title + anchoredColumn.id}
                            onClick={() => handleSort(anchoredColumn.sortPath)}
                        >
                            {index == 0 && renderSortIcon(anchoredColumn)}
                            {headerRow[anchoredColumn.id]}
                        </th>
                    )}
                </tr>)}
        </thead>
    );
};

const ProjectHoursTableBody = ({ headerData, bodyData, handleTableSubmit, hourPart = false, anchoredHeader }) => {
    const [selectedInputIndex, setSelectedInputIndex] = useState({ "inputRowID": 0, "inputColumnPath": '' });
    const user = useSelector((state) => state.generic.currentUser);


    const handleClick = (rowID, columnPath) => {
        /* if there is no day_summary_id, warning the user, "select a state firstly" */
        if (hourPart) {
            const dayIDToCheck = columnPath.split('.')[0];
            const daySummary = anchoredHeader.find(dayInfo => dayInfo.id === parseInt(dayIDToCheck));
            const officeID = user.office_id

            if (officeID === lkuOffice.INDIANAPOLIS || officeID === lkuOffice.MONTREAL) {
                if (daySummary.day_summary_id === null) {
                    toast.warning("Please select the STATE firstly.")
                    return
                }
            }
        }

        setSelectedInputIndex({
            'inputRowID': rowID,
            "inputColumnPath": columnPath
        })
    };

    const handleSubmit = (data, row, doPost, doPatch) => {
        handleTableSubmit(data, row, doPost, doPatch)
        setSelectedInputIndex({ inputRowID: 0, inputColumnPath: "" });
    }

    const renderCell = (row, column) => {
        const customContent = column.customContent
        let contentString = _.get(row, column.path)

        if (customContent) {
            if (customContent.type === 'inputbox') {
                const cellKey = `${row.id}-${column.path}`
                return <td key={cellKey} onClick={() => handleClick(row.id, column.path)}  >
                    {selectedInputIndex.inputRowID + '-' + selectedInputIndex.inputColumnPath === cellKey ?
                        <TableFormCell
                            formInfo={customContent.formInfo}
                            initializedData={{ [column.path]: contentString }}
                            doSubmit={(data) => handleSubmit(data, row, customContent.doPost, customContent.doPatch)}
                            formSchema={customContent.schema} /> :
                        <>{customContent.formInfo.type == 'multiselect' ? (contentString != null ? contentString : '') : (contentString !== 0 ? contentString : '')}</>
                    }
                </td>
            }
        }
        if (!customContent)
            return <td key={`${row.id}-${column.path}`} >
                {contentString}
            </td>;
    };

    return (
        <tbody>
            {bodyData.map((row) => (
                <tr
                    key={row.id}
                >
                    {headerData.map((column) => (
                        <React.Fragment key={`${column.path}-${row.id}`}>
                            {renderCell(row, column)}
                        </React.Fragment>
                    ))}
                </tr>
            ))
            }
        </tbody >
    );
};

const ProjectHoursTable = ({ anchoredHeader: anchored_header, shownHeader: shown_header, stateHeader: state_header, hourHeader: hour_header, stateBody: state_body, hourBody: hour_body }) => {
    const [anchoredHeader, setAnchoredHeader] = useState()
    const [shownHeader, setShownHeader] = useState()
    const [stateHeader, setStateHeader] = useState()
    const [hourHeader, setHourHeader] = useState()
    const [stateBody, setStateBody] = useState()
    const [hourBody, setHourBody] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false);


    useEffect(() => {
        setAnchoredHeader(anchored_header)
        setShownHeader(shown_header)
        setStateHeader(state_header)
        setHourHeader(hour_header)
        setStateBody(state_body)
        setHourBody(hour_body)
    }, [anchored_header, shown_header, state_header, state_body, hour_header, hour_body]);

    const handleSubmitState = async (data, row, doPostState, doPatchState) => {
        // console.log('ProjectHoursTable - handleSubmitState - data', data)
        // console.log('ProjectHoursTable - handleSubmitState - row', row)

        const dayID = parseInt(Object.keys(data)[0].split(".")[0]);
        const daySummaryID = anchoredHeader.find(dayInfo => dayInfo.id === dayID).day_summary_id;

        /* update the frontend state info */
        const updatedStateBody = [...stateBody];
        updatedStateBody[0][dayID] = data.state;
        setStateBody(updatedStateBody);
        if (daySummaryID === null) {
            /* post day summary/state to backend and database */
            const newDaySummary = await doPostState({ "state": data.state.id, "day": dayID })
            /* when post state, we need to update day_summary_if from the API returned data to anchoredHeader */
            const updatedAnchoredHeader = anchoredHeader.map(item => item.id === dayID ? { ...item, day_summary_id: newDaySummary.day_summary_id } : item);
            setAnchoredHeader(updatedAnchoredHeader);
        }
        else doPatchState(daySummaryID, { "state": data.state.id })
    };

    const user = useSelector((state) => state.generic.currentUser);
    const userOfficeID = user.office_id
    const { week_id: weekID, week_summary_id: weekSummaryID } = useParams();

    const handleSubmitHour = async (data, row, doPostProjectHour, doPatchProjectHour) => {
        setIsSubmitting(true);
        try {
            const dayID = parseInt(Object.keys(data)[0].split(".")[0]);
            var daySummaryID = anchoredHeader.find(dayInfo => dayInfo.id === dayID).day_summary_id;
            if (userOfficeID != lkuOffice.INDIANAPOLIS || userOfficeID != lkuOffice.MONTREAL) {
                if (daySummaryID === null) {
                    const newDaySummary = await http.post(`${listDaySummariesAPI(weekID)}`, { "state": null, "day": dayID })
                    const updatedAnchoredHeader = anchoredHeader.map(item => item.id === dayID ? { ...item, day_summary_id: newDaySummary.data.day_summary_id } : item);
                    setAnchoredHeader(updatedAnchoredHeader);
                    daySummaryID = newDaySummary.data.day_summary_id
                }
            }

            const projectRoleID = row.id
            const previousHours = parseFloat(row[dayID].hours)
            const newHours = parseFloat(data.hours)
            let projectHourID = row[dayID].project_hour_id
            const previousSumHours = parseFloat(shownHeader[2][dayID])

            // console.log('ProjectHoursTable - handleSubmitHour - previousSumHours', previousSumHours)
            // console.log('ProjectHoursTable - handleSubmitHour - previousHours', previousHours)
            // console.log('ProjectHoursTable - handleSubmitHour - newHours', newHours)

            const sumHours = previousSumHours - previousHours + newHours

            // console.log('ProjectHoursTable - handleSubmitHour - sumHours', sumHours)

            /* validate sum hour, no larger than 12 */
            let maxLegalHour;
            if (userOfficeID == lkuOffice.BARCELONA || userOfficeID == lkuOffice.MADRID) {
                maxLegalHour = 8.5;
            }
            else if (userOfficeID == lkuOffice.BOGOTA || userOfficeID == lkuOffice.GUATEMALA) {
                maxLegalHour = 8;
            }
            else {
                maxLegalHour = 12;
            }


            if (sumHours > maxLegalHour) toast.warning(`Sum of project hours in one day shouldn't be larger than ${maxLegalHour} hours!`)
            else {
                let response;
                if (newHours == 0) {
                    if (projectHourID !== null) {
                        // console.log('ProjectHoursTable - handleSubmitHour - delete - back - sumHours', sumHours)
                        // console.log('ProjectHoursTable - handleSubmitHour - delete - back - newHours', newHours)
                        response = await doPatchProjectHour(daySummaryID, projectHourID, { "hours": newHours, "sum_hours": sumHours });
                        if (response && response.status && (response.status === lkuResponse.DELETED)) {
                            projectHourID = null;
                            // console.log('ProjectHoursTable - handleSubmitHour - delete - front - sumHours', sumHours)
                            // console.log('ProjectHoursTable - handleSubmitHour - delete - front - newHours', newHours)
                            updateFrontEnd(row, dayID, projectHourID, newHours, projectRoleID, sumHours);
                        }
                    }
                } else {
                    if (projectHourID === null) {
                        /* when there is no projectHourID, post project hour, and use the API returned data to update projectHourID */
                        // console.log('ProjectHoursTable - handleSubmitHour - post - back - sumHours', sumHours)
                        // console.log('ProjectHoursTable - handleSubmitHour - post - back - newHours', newHours)
                        const response = await doPostProjectHour(daySummaryID, {
                            "project_role": projectRoleID,
                            "hours": newHours,
                            "sum_hours": sumHours
                        })
                        if (response && response.status && (response.status === lkuResponse.CREATED)) {
                            projectHourID = response.data.project_hour_id;
                            // console.log('ProjectHoursTable - handleSubmitHour - post - front - sumHours', sumHours)
                            // console.log('ProjectHoursTable - handleSubmitHour - post - front - newHours', newHours)
                            updateFrontEnd(row, dayID, projectHourID, newHours, projectRoleID, sumHours);
                        }
                    }
                    else {
                        // console.log('ProjectHoursTable - handleSubmitHour - patch - back - sumHours', sumHours)
                        // console.log('ProjectHoursTable - handleSubmitHour - patch - back - newHours', newHours)
                        const response = await doPatchProjectHour(daySummaryID, projectHourID, { "hours": newHours, "sum_hours": sumHours });
                        if (response && response.status && (response.status === lkuResponse.UPDATED)) {
                            // console.log('ProjectHoursTable - handleSubmitHour - patch - front - sumHours', sumHours)
                            // console.log('ProjectHoursTable - handleSubmitHour - patch - front - newHours', newHours)
                            updateFrontEnd(row, dayID, projectHourID, newHours, projectRoleID, sumHours);
                        }
                    }
                }
            }
        } catch (error) {
            console.error("An error occurred:", error);
            toast.error("An error occurred while processing the request.");
        } finally {
            setIsSubmitting(false);
        }
    };

    const updateFrontEnd = (row, dayID, projectHourID, newHours, projectRoleID, sumHours) => {
        let newHourRow = { ...row };

        /* update project hour in frontend */
        newHourRow[dayID] = { project_hour_id: projectHourID, hours: newHours };
        const updatedHourBody = hourBody.map(projectHourRow => projectHourRow.id === projectRoleID ? newHourRow : projectHourRow);
        setHourBody(updatedHourBody);

        /* update sum_hours in frontend */
        const updatedShownHeader = [...shownHeader];
        updatedShownHeader[2][dayID] = sumHours;
        setShownHeader(updatedShownHeader);
    };

    // ------------------------------------------------- sorting -------------------------------------------------------
    const [sortColumn, setSortColumn] = useState({ path: "title.project_role_id", order: "desc" });
    //const sortedHourBody = applySort(hourBody, sortColumn);
    const sortedHourBody = applySort(
        hourBody,
        userOfficeID === 11 || userOfficeID === 13 ? { path: "title.project_type_id", order: "asc" } : sortColumn
    );


    return (
        <>{
            (anchoredHeader && shownHeader && stateHeader && stateBody && hourHeader && hourBody && !isSubmitting) ? <Table bordered hover>
                <ProjectHoursTableHeader anchoredHeader={anchoredHeader} shownHeader={shownHeader} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                {userOfficeID === 1 && <ProjectHoursTableBody headerData={stateHeader} bodyData={stateBody} handleTableSubmit={handleSubmitState} />}
                <ProjectHoursTableBody headerData={hourHeader} bodyData={sortedHourBody} handleTableSubmit={handleSubmitHour} hourPart anchoredHeader={anchoredHeader} />
            </Table> : <Spinner />
        }</>
    );
};


export default ProjectHoursTable;