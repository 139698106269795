import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash'
import Joi from "joi-browser";
import { Row, Col } from 'react-bootstrap'

import Spinner from "../../../../components/Spinner";
import StaffingTable from "./StaffingTable";
import WorkingDays from "../WorkingDays";
import { postForecastedDays, patchForecastedDays, deleteForecastedDays } from '../../../../store/staffing-actions'
import { prepareDateHeader, removeFirstElement } from '../../../../utils/staffingUtil'
import { office as lkuOffice } from "../../../../services/constService";

const Staffing = () => {
    const dispatch = useDispatch();
    const forecastedDays = useSelector((state) => state.Staffing.forecastedDays);
    const allResourcesOptions = useSelector((state) => state.generic.LKUs.LKUResources)
    const allProjectsOptions = useSelector((state) => state.Staffing.projectOptions)
    const selectedWeek = useSelector((state) => state.Staffing.selectedWeek);
    const utilizations = useSelector((state) => state.Staffing.utilizations);
    const allManagers = useSelector((state) => state.generic.allManagers);
    const user = useSelector((state) => state.generic.currentUser);
    const office = user.office_id
    const accessPermissions = useSelector((state) => state.generic.accessPermissions);

    const handleSubmit = (data) => {
        // console.log('Staffing - doSubmit - data', data)

        const weekID = data.week_id;
        const updatedForecastedDays = data.forecasted_days;
        const staffingID = forecastedDays.find((e) => e.id == data.project_role)[data.week_id].id;

        if (updatedForecastedDays == 0 && staffingID !== null) dispatch(deleteForecastedDays(weekID, staffingID, data));
        else if (updatedForecastedDays !== 0) {
            if (staffingID == null) dispatch(postForecastedDays(weekID, data));
            else dispatch(patchForecastedDays(weekID, staffingID, data));
        }
    };

    let header_data = []
    let body_data = []
    let dataIsReady = false

    if (forecastedDays && utilizations) {
        // -------------------------------------- prepare header data --------------------------------------
        const basic_header_data = prepareDateHeader(forecastedDays)
        // console.log('Staffing -  - basic_header_data', basic_header_data)

        function createHeaderItem(item, includeCustomContent) {
            const baseItem = { ...item, sortPath: item.path + '.value' };

            if (includeCustomContent) {
                return {
                    ...baseItem,
                    customContent: {
                        type: 'inputbox',
                        formInfo: {
                            id: item.path,
                            type: 'text',
                        },
                        schema: {
                            [item.path]: Joi.number().required().label('Forecasted Days'),
                        },
                        doSubmit: handleSubmit,
                    },
                };
            }

            return baseItem;
        }

        /* for Spain offices, general employee, they can only access, they cannot edit staffing  */
        const includeCustomContent = !((office === lkuOffice.BARCELONA || office === lkuOffice.MADRID) && !accessPermissions["projectManager"] && !accessPermissions["developTeam"]);

        const hader_with_custom_content = basic_header_data.map(item =>
            createHeaderItem(item, includeCustomContent)
        );

        header_data = [
            { path: 'project', label: "Project", sortPath: 'project.id' },
            { path: 'manager', label: "Manager", sortPath: 'manager.id' },
            { path: 'resource', label: "Resource", sortPath: 'resource.id' },
            ...hader_with_custom_content
        ];

        // -------------------------------------- prepare body data --------------------------------------
        /* Because available days contains two parts, the first part is weeks (week ids, week names)(this part is for make table header), and the second part is available days itself */
        body_data = removeFirstElement(forecastedDays)


        /* header data index 0 is 'project', 1 is 'manager', 2 is 'resource', 3 is the selected week id */
        /* Check if all path values(week IDs) are present in the forecastedDays keys */
        const containsAllWeeks = basic_header_data.every(weekData => utilizations[0].hasOwnProperty(weekData.path));
        dataIsReady = header_data[3].path == selectedWeek.id && containsAllWeeks
    }


    // console.log('Staffing -  - header_data', header_data)
    // console.log('Staffing -  - body_data', body_data)
    // console.log('Staffing -  - forecastedDays', forecastedDays)
    // console.log('Staffing -  - selectedWeek.id', selectedWeek.id)


    const filterGroup = [
        {
            'id': 'project',
            'path': 'project',
            "options": allProjectsOptions,
            "label": "Project"
        },
        {
            'id': 'manager',
            'path': 'manager',
            "options": allManagers,
            "label": "Manager"
        },
        {
            'id': 'resource',
            'path': 'resource',
            "options": allResourcesOptions,
            "label": "Resource",
        },
    ]

    return (
        <>
            {dataIsReady ? <>
                <Row>
                    <Col sm='1'></Col>
                    <Col>
                        <WorkingDays />
                    </Col>
                </Row>
                <div style={{ marginTop: '80px' }}>
                    <StaffingTable headerData={header_data} bodyData={body_data.length == 0 ? [] : body_data} filterGroup={filterGroup} />
                </div>
            </> : <Spinner />}
        </>
    );
};

export default Staffing;