import React from "react";
import _ from "lodash";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import http from '../../services/httpService'
import CustomTable from '../../components/custom/Table/CustomTable'
import CustomForm from "../../components/custom/Form/CustomForm";
import { listSubcontractorAPI } from '../../services/backendURLService'
import { office } from "../../services/constService";
import { getAllSubcontractors, updateSubcontractors } from "../../store/settings-actions";
import { multiSelectTransformFromObjectNestedInArray } from '../../utils/publicUtil'
import { transformTitle } from "../../utils/publicUtil";

// - Add table to edit current clients 
const NewSubcontractor = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.generic.currentUser);


//***********************************New Client Forum**************************************** */

    const formInfo = [
        {
            label: "Subcontract Company Name",
            id: "title",
            type: "text",
         }
    ];
    const formStructure = {
        submit_button: 'Save new Subcontract Comapny',
        back_button: true
    };
    const formSchema = {
        [formInfo[0].id]: Joi.string().required().label(formInfo[0].label)
    };
    const submitData = async (data, errors) => {
        
        const newSubconName = String(data.title).toLowerCase().trim()
        let subconExists = false;
        subcontactors.forEach(subcon =>{
            const fixedSubconName = String(subcon.title).toLowerCase().trim()
            if(fixedSubconName.includes(newSubconName)){
                subconExists = true;
                return; 
            }
        })

        if (subconExists) {
            toast.warning("Client already exists");
            return; // Exit the entire function
        }
        
        
        const officeID = user.office_id
        try { 
            const newValues = {
                title: data.title,
                office: officeID,
              };
            await http.post(
                `${listSubcontractorAPI(officeID)}`, newValues
            );
            toast.success("Saved Successfully."); 
        }
        catch (error) { 
            console.log("Error: " + error); 
        }
        dispatch(getAllSubcontractors(officeID))
    };


//*********************************Existing subcontract Table**************************************** */

const handleUpdate = async (selectedRow) => {  
    const officeID = user.office_id
    const subconID = selectedRow.rowInfo.id

  
    const value = Object.entries(selectedRow.updatedValue)[0][1]
    const key = Object.entries(selectedRow.updatedValue)[0][0]


    let newData = selectedRow.updatedValue
   // if (typeof value == 'object') newData = { [key]: value.id }
    //newData.industry_id = newData.industry
    //delete newData.industry;

    if (!selectedRow) {
        toast.warning("Please select a subcontract company!")
    }
    else{
        dispatch(updateSubcontractors(officeID, subconID ,newData))
    } 
}


const subcontactors = useSelector((state) => state.settings.subcontactors);


    const columns = [
        { path: "title", label: "Subcontract Company Name",
            customContent: {
                type: 'inputbox',
                formInfo: {
                    type: 'text',
                    id: 'title',
                },
                schema: { 'title': Joi.string().required().label('Subcontract Company Name') },
                doSubmit: handleUpdate
            }
        }
    ];
    function transformedSubcon(arrayData) {
        return arrayData.map((obj) => {
            const transformedObj = {};
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (value && typeof value === "object") {
                  transformedObj[key] = {
                    id: value.id,
                    value: value.title,
                    label: value.title,
                  };
                } else {
                  transformedObj[key] = value;
                }
              }
            }
            transformedObj.lookup = {
                id: transformedObj.id,
                value: transformedObj.name,
                label: transformedObj.name
            }
            return transformedObj; 
        });
    }
    const filterGroup = [
        {
            'id': 'Subcontractor Name',
            "options": transformTitle(subcontactors),
            "label": "Subcontractor Name",
            'path': 'lookup',
        },
    ]


//*********************************Rendering**************************************** */

    return (
        <div className="container">
                <div className="custom-test">
                    <div className="title-center banner">
                        <h1 className="section-title">New Subcontract Company</h1>
                    </div>
                    <div>
                    <p>Please fill in the new subcontract company's name.</p>
                    </div>
            <CustomForm
                formInfo={formInfo}
                formStructure={formStructure}
                formSchema={formSchema}
                doSubmit={submitData}
            />
            </div>
            <div>
                    <div className="title-center banner">
                        <h1 className="section-title">Existing Subcontract Companies</h1>
                    </div>
                    <div>
                        <p>Below is a list of exisiting subcontract companies in the database, here is where you can edit the name of the subcontract company. Note: Subcontract comapnies belonging to only your working office will show up.</p>
                    </div>
            <h6>
                 <CustomTable
                    headerData={columns}
                    bodyData={transformedSubcon(subcontactors)}
                    pageSize={15}
                    filterGroup={filterGroup}
                /> 
            </h6>
            </div>
        </div>
    );
};
export default NewSubcontractor;