import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import CommercialDetails from '../../../pmo/ProjectInitialization/CommercialDetails';
import ProjectDetails from '../../../pmo/ProjectInitialization/ProjectDetails'
import ProjectSponsor from "../../../pmo/ProjectInitialization/ProjectSponsor";

function ICCNavProjectInitialization() {
    const selectedProject = useSelector((state) => state.PM.selectedProject);
    const history = useHistory();

    useEffect(() => {
        //everytime selectedProject is updated check if selectedProject is empty then push back to pmo nav page
        if (Object.keys(selectedProject).length === 0) history.push('/pmo');
    }, [selectedProject]);

    return (
        <div>
            <Row>
                <Col> <h3 className="mb-5">ICC Project Initialization</h3></Col>
                <Col><h3 className="mb-5">{selectedProject.value}</h3></Col>
            </Row>
            {selectedProject.length !== 0 &&
                <Tabs>
                    <TabList>
                        <Tab>Project Details and Rates</Tab>
                        <Tab>Commercial Details</Tab>
                    </TabList>


                    <TabPanel>
                        <Tabs>
                            <TabList>
                                <Tab>Details</Tab>
                                <Tab>Sponsor</Tab>
                            </TabList>

                            <TabPanel>
                                <ProjectDetails projectID={selectedProject.id} />
                            </TabPanel>
                            <TabPanel>
                                <ProjectSponsor projectID={selectedProject.id} />
                            </TabPanel>
                        </Tabs>
                    </TabPanel>
                    <TabPanel>
                        <CommercialDetails projectID={selectedProject.id} />
                    </TabPanel>
                </Tabs>}
        </div>
    );
}

export default ICCNavProjectInitialization;