import React from "react";
import { useDispatch } from "react-redux";
import Joi from "joi-browser";

import { updateAdminSubcontract } from '../../../store/admin-actions'
import { generateOptions } from "../../../utils/publicUtil";
import FrameModal from "../../../components/common/FrameModal";
import CustomForm from "../../../components/common/CustomForm";
import Spinner from "../../../components/Spinner";


const AdminConnectInvoiceFrameModel = ({ selectedSubconInfo, invoicesToConnect, show, setShow }) => {
  const dispatch = useDispatch();

  let projectInvoicesOptions = [];
  if (Array.isArray(invoicesToConnect)) {
    projectInvoicesOptions = generateOptions(
      invoicesToConnect,
      'id',
      'invoice_number',
      'id',
    );
  };

  const formInfo = [
    {
      label: 'Service Invoice Number',
      id: 'invoice',
      type: 'multiselect',
      isMulti: false,
      options: projectInvoicesOptions
    },
  ];

  const formStructure = {
    submit_button: { label: 'Submit' }
  };

  const schema = {
    [formInfo[0].id]: Joi.object({ id: Joi.number().required(), value: Joi.number(), label: Joi.string() }).required().label(formInfo[0].label),
  };

  const modalTitle = <>
    <h5 style={{ display: 'inline' }}>Subcontractor Invoice Number: </h5> {selectedSubconInfo.invoice_number} <br />
    <h5 style={{ display: 'inline' }}>Concept: </h5> {selectedSubconInfo.concept} <br />
    <h5 style={{ display: 'inline' }}>Invoicing Company: </h5> {selectedSubconInfo.invoicing_company !== null ? selectedSubconInfo.invoicing_company.value : ''} <br />
    <h5 style={{ display: 'inline' }}>Project: </h5> {selectedSubconInfo.project.name} <br />
    <br />
  </>

  const doFormSubmit = async (selectedRow) => {
    dispatch(
      updateAdminSubcontract({
        updatedInfo: selectedRow,
        originalRowInfo: {
          subcontractID: selectedSubconInfo.id,
          projectID: selectedSubconInfo.project.id,
        },
      })
    );
    setShow(false)
  };

  // console.log('AdminConnectInvoiceFrameModel -  - selectedSubconInfo', selectedSubconInfo)
  // console.log('AdminConnectInvoiceFrameModel -  - invoicesToConnect', invoicesToConnect)


  const showInvoiceForm = invoicesToConnect[0].project_id == selectedSubconInfo.project.id


  return (
    <>
      <FrameModal
        modalTitle={'Select the corresponding client invoice'}
        show={show}
        setShow={setShow}
      >
        {modalTitle}
        {showInvoiceForm ? <CustomForm
          formInfo={formInfo}
          formStructure={formStructure}
          doSubmit={doFormSubmit}
          schema={schema}
        /> : <Spinner />}
      </FrameModal>
    </>
  );

};

export default AdminConnectInvoiceFrameModel;
