import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";

import http from '../../services/httpService';
import { listSpainBlockedWeeksAPI, updateSpainBlockedWeeksAPI, updateSpainUnBlockedWeeksAPI } from '../../services/backendURLService'
import GoBackButton from '../../components/GoBackButton';
import Spinner from '../../components/Spinner';
import { getCOHActiveWeekIDs } from '../../store/Coh-actions';

const SpainBlockWeek = () => {
    const dispatch = useDispatch();
    const [spainBlockedWeeks, setSpainBlockedWeeks] = useState()
    const currentWeek = useSelector((state) => state.generic.currentWeek);

    useEffect(() => {
        const getSpainBlockedWeeks = async () => {
            const { data: spain_blocked_weeks } = await http.get(`${listSpainBlockedWeeksAPI}`);
            setSpainBlockedWeeks(spain_blocked_weeks)
        };

        getSpainBlockedWeeks()
    }, []);

    const handleBlockWeek = async (week) => {
        try {
            const response = await http.toastPost(`${updateSpainBlockedWeeksAPI}`, { "week_id": week });
            if (response.status === 202) {
                setSpainBlockedWeeks((prevData) =>
                    prevData.map((item) =>
                        item.week_id == week ? { ...item, blocked: true } : item
                    )
                );
            }
        } catch (error) {
            console.error('Error updating status', error);
        }
    };

    const handleUnBlockWeek = async (week) => {
        try {
            console.log('week',week)
            const response = await http.toastDelete(updateSpainUnBlockedWeeksAPI(week));
           
            if (response.status === 204) {
                setSpainBlockedWeeks((prevData) =>
                    prevData.map((item) =>
                        item.week_id == week ? { ...item, blocked: false } : item
                    )
                );
            }
        } catch (error) {
            console.error('Error updating status', error);
        }
    };

    useEffect(() => {
        /* update editable week when unmount this component */
        return () => {
            dispatch(getCOHActiveWeekIDs());
        };
    }, []);


    return (
        <>
            {
                spainBlockedWeeks&& currentWeek?.week_id ? (  <>
                    <h1>Block Week</h1>
                    <Row className='mt-5'>
                        {spainBlockedWeeks.map((item) => {
                            return <Col lg={4} className='mt-1 mb-1' key={item.week_id}>

                                <div key={item.month} >
                                    {item.week_id == currentWeek.week_id ? <>📅 </> : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                    {item.blocked ? (
                                        <Button variant="danger" onClick={() => handleUnBlockWeek(item.week_id)}>
                                        unblock {item.week_name}
                                    </Button>
                                    ) : (
                                        <Button onClick={() => handleBlockWeek(item.week_id)}>
                                            block {item.week_name}
                                        </Button>
                                        
                                    )}
                                </div>
                            </Col>

                        })}
                    </Row>
                    <GoBackButton />
                </>) : <Spinner />
            }
        </>
    );
};

export default SpainBlockWeek;
