import React, { useState, useEffect, Fragment } from 'react';
import { useSelector,useDispatch } from "react-redux";
import Joi from "joi-browser";
import { getPrepareNewProject } from '../../../store/admin-actions';
import NewProjectForm from '../../admin/NewProject/NewProjectForm';
import { toast } from 'react-toastify';
import { patchProjectOffer } from '../../../store/PM-actions';
import http from '../../../services/httpService';
import { updatePillarSplitAPI } from '../../../services/backendURLService';


const ICCPatchLocalProjectDetailPage = ({project_id}) => {
    const dispatch = useDispatch();
    const prepareNewProject = useSelector((state) => state.admin.prepareNewProject);

    const formInfo = [
        {
            label: "Offer Number",
            id: "offer_number",
            type: "text",
        },
        {
            type: 'multiselect',
            label: 'Business units',
            options: prepareNewProject.business_units,
            id: 'business_units',
            isMulti: false
        },
        {
            type: 'multiselect',
            label: 'Pillar(s)',
            options: [],
            id: 'pillars',
            isMulti: true
        }
    ]

    const formStructure = {
        submit_button: 'Save Project Details'
    };

    const formSchema = {
        //offer number
        [formInfo[0].id]: Joi.string().required().label(formInfo[0].label),
        //Buiness Units
        [formInfo[1].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string(), business_unit_item_id: Joi.number() }).required().label(formInfo[1].label),
        //Pillars
        [formInfo[2].id]: Joi.array().min(1).required().label(formInfo[2].label),
    }

    const organizePostData = (data) => {
        return {
            offer_number: data.offer_number,
            pillar_split: data.pillars.map((pillar) => ({
                pillar_item: pillar.id,
                percent: parseFloat(data[pillar.id.toString()])
            }))
        };
    };


    const doSubmit = async (data) => {
        const newProjectData = organizePostData(data)
        const pillarPercent = newProjectData.pillar_split.reduce((acc, cur) => acc + cur.percent, 0);
        if (pillarPercent === 1) {
            //offer_number projectmaster dispatch & patch
            dispatch(patchProjectOffer(project_id,data));
            //pillar_split post
            const listOfPillars = newProjectData.pillar_split
            listOfPillars.forEach(pillar =>{
                 http.toastPost(
                    `${updatePillarSplitAPI(project_id)}`, { 
                        project: project_id,
                        percent: pillar.percent,
                        pillar_item: pillar.pillar_item
                        
                    }
                );
            })


            
        }
        else toast.error("Please Fill the pillar splits to add up to 1");
    };

    

    return (
        <>
            <NewProjectForm
                formInfo={formInfo}
                formStructure={formStructure}   
                doSubmit={doSubmit}
                schema={formSchema}
                pillars={prepareNewProject.pillars}/>

        </>
    )
}

export default ICCPatchLocalProjectDetailPage;