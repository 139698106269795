import jwtDecode from "jwt-decode";
import _ from "lodash";
import { toast } from "react-toastify";

import http from "./httpService";
import { loginAPI } from "./backendURLService";

const tokenKey = "token";

http.setJwt(getJwt());

async function login(data) {
  const { data: jwt } = await http.post(loginAPI, data);
  localStorage.setItem(tokenKey, jwt.access);
}

function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

function logout() {
  console.log("logout");
  localStorage.removeItem(tokenKey);
}

function getJwt() {
  return localStorage.getItem(tokenKey);
}

function getTokenContent() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return {};
  }
}

function isActiveToken() {
  const tokenContent = getTokenContent();
  const isEmpty = _.isEmpty(tokenContent);
  // + 1000 * 60 * 60 * 24
  const isExpired = tokenContent.exp * 1000 < Date.now() ? true : false;
  if (isExpired) {
    logout();
    toast.error("Timed out! You need to log in again");
  }
  return !isEmpty && !isExpired;
}

export default {
  login,
  logout,
  getTokenContent,
  getJwt,
  isActiveToken,
};
