import React, { useState, useEffect } from "react";
import { Table, Row, Col } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { BsPlus, BsDash } from "react-icons/bs";
import FeeSummaryTable from "./ICCFeeSummaryTable";
import { feeSummaryAPI,updateProjectHeaderAPI, listAllOfficesAPI} from '../../../services/backendURLService';
import http from "../../../services/httpService";
import Spinner from '../../../components/Spinner';
import { toast } from 'react-toastify';
import { conditionalRenderCurrencyAmount } from "../../../utils/publicUtil";

const DisplayList = ({ data, feeSummary, formatValue }) => (
    <Table>
      {data.map((row) => (
        <>
          <tr>
            <td>{row.title}</td>
          </tr>
          <tr className="itemFeeSummary">
            <td className="itemData">
            {row.isCurrency
                ? conditionalRenderCurrencyAmount(feeSummary[row.key],)
                : formatValue(feeSummary[row.key], row.isPercentage)}
            </td>
          </tr>
        </>
      ))}
    </Table>
  );

const ICCFeeSummaryBreakdownTableComponent = ({ projectID, office_name}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    //API Call to pull data
    const [feeSummary, setFeeSummary] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await http.get(`${feeSummaryAPI(projectID)}`);
            console.log('FeeSummary - useEffect - response', response.data)
            if (response.status == 200) {
            const data = response.data; // Directly use response.data
            setFeeSummary(data);
            } else {
            console.error('Error fetching fee summary:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error fetching fee summary:', error);
            toast.warning('Project detail info is not complete.'); // Toast for 405 status
        }
        };

        fetchData();
    }, []);

    if (feeSummary === null) {
        return <div><Spinner /></div>;
    }

    const formatValue = (value, isPercentage = false) => {
        if (value === undefined || value === null) return 'N/A';
        return isPercentage ? `${(value * 100).toFixed(2)}%` : `${value}`;
    };

    const actualRecordData = [
        { title: 'Internal Actuals to Date', key: 'actual_internal_spend', isCurrency: true },
        { title: 'Percent of Total Budget Consumed (Excluding External)', key: 'actual_internal_percent', isPercentage: true },
        { title: 'External Actuals to Date', key: 'actual_external_spend', isCurrency: true },
        { title: 'Percent of External Budget Consumed', key: 'actual_external_percent', isPercentage: true },
        { title: 'Total Fees Consumed to Date', key: 'actual_total_spend', isCurrency: true },
        { title: 'Percent of Total Budget Consumed (Including External)', key: 'actual_total_percent', isPercentage: true },
    ];

    const predictSpendData = [
        { title: 'Predicted Spend to Date', key: 'predicted_internal_spend', isCurrency: true },
        { title: 'Additional Predicted Percent Budget Consumed', key: 'predicted_internal_percent', isPercentage: true },
    ];

    const forecastedSpendData = [
        { title: 'Forecasted Spend', key: 'forecasted_internal_spend', isCurrency: true },
        { title: 'Forecasted Internal Budget Consumption', key: 'forecasted_internal_percent', isPercentage: true },
    ];

    return (
        <>
            <tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                <h2>{office_name}</h2>
                </td>
            </tr>     
            <Row>
                <Col colSpan={2}>
                <Table className="breakDown">
                    <thead>
                    <tr>
                        <th>
                        Breakdown
                        <button
                            className="collapseButton"
                            onClick={handleToggleCollapse}>
                                
                            {isCollapsed ? (
                            <BsPlus style={{ fontSize: "24px", width: "32px" }} />
                            ) : (
                            <BsDash style={{ fontSize: "24px", width: "32px" }} />
                            )}
                        </button>
                        </th>
                    </tr>
                    </thead>
                    {!isCollapsed && (
                    <tbody>               
                        <Tabs>
                        <TabList id="breakDownTab">
                            <Tab>Actuals Recorded</Tab>
                            <Tab>Predicted Spend to Date</Tab>
                            <Tab>Forecasted Incremental Spend</Tab>
                            <Tab>Assumptions and Details</Tab>
                        </TabList>

                        <TabPanel className="actualRecord">
                            <DisplayList data={actualRecordData} feeSummary={feeSummary} formatValue={formatValue} />
                            <FeeSummaryTable feeSummary={feeSummary} />
                        </TabPanel>

                        <TabPanel className="predictSpend">
                            <DisplayList data={predictSpendData} feeSummary={feeSummary} formatValue={formatValue} />
                            <FeeSummaryTable feeSummary={feeSummary} />
                        </TabPanel>

                        <TabPanel className="forecasteSpend">
                            <DisplayList data={forecastedSpendData} feeSummary={feeSummary} formatValue={formatValue} />
                            <FeeSummaryTable feeSummary={feeSummary} />
                        </TabPanel>

                        <TabPanel>
                            <p className="assumption">
                            The "Predicted Total Cost" is built using actuals
                            recorded, predicted spend to date, forecasted
                            incremental spend, and 100% of subcontracted fees.
                            <br />
                            <br />
                            Actuals recorded reflect actual project hours saved by
                            team members.
                            <br />
                            <br />
                            Predicted spend to date reflects the "lead time"
                            between working hours and hours entry by taking all
                            projected hours from staffing that were forecasted to
                            occur before today.
                            <br />
                            <br />
                            Forecasted incremental spend reflects the future hours
                            predicted to be consumed using staffing (e.g. 5 days
                            staffed on a project in a given week = 40 hours
                            forecasted on project).
                            <br />
                            <br />
                            The "Actuals Recorded" tab combines estimated
                            subcontracting consumption to date and actual hours
                            consumption to date to calculate the total fees to
                            date and percent of budget consumed to date.
                            <br />
                            <br />
                            Travel fees are not considered in total budget or
                            budget consumption; they are managed in the "Travel
                            Fees" tab at the top
                            </p>
                        </TabPanel>
                        </Tabs>
                    </tbody>
                    )}
                </Table>
                </Col>
            </Row>
        </>    
    );
};

export default ICCFeeSummaryBreakdownTableComponent;
