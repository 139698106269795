import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Joi from "joi-browser";
import FormUnitFrame from "./FormUnitFrame";
import Select, { components } from 'react-select'
const MultiValueRemove = (props) => {
  if (props.data.isFixed) {
    return null;
  }
  return <components.MultiValueRemove {...props} />;
};
function FormForTableCell({
  formInfo,
  initializedData = [],
  schema,
  doSubmit
}) {
  
  const [data, setData] = useState(initializedData);

  const [errors, setErrors] = useState({});
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;
    const newErrors = {};
    for (let item of error.details) newErrors[item.path[0]] = item.message;
    return newErrors;
  };

  const validateProperty = ({ name, value, type, checked }) => {

    const obj = type !== "checkbox" ? { [name]: value } : { [name]: checked };
    const propertySchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, propertySchema);
    return error ? error.details[0].message : null;

  };

  const handleSubmit = e => {
    e.preventDefault();

    const newErrors = validate();
    setErrors(newErrors || {});
    if (newErrors) return;

    doSubmit(data, errors, setData, setErrors);
  };

  const handleChange = ({ currentTarget: input }) => {
    const previousErrors = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) previousErrors[input.name] = errorMessage;
    else delete previousErrors[input.name];
    setErrors(previousErrors)

    const previousData = { ...data };
    previousData[input.id] = input.value;
    setData(previousData)
  };

  const handleMultiSelectChange = (selectedList, input) => {

    const targetValue = selectedList;
    const targetName = input.name;

    const previousData = { ...data };
    previousData[targetName] = targetValue;
    
    setData(previousData)

    const previousErrors = { ...errors };
    const errorMessage = validateProperty({ name: targetName, value: targetValue });
    if (errorMessage) previousErrors[targetName] = errorMessage;
    else delete previousErrors[targetName];
    setErrors(previousErrors)
  };
  return (
    <Form onBlur={handleSubmit} onSubmit={(event) => { event.preventDefault(); }}>
      {formInfo.map((formInfo) => (
        <Form.Group
          className="mb-3"
          controlId={formInfo.id}
          key={formInfo.id}
        >
          {(formInfo.type === "text" || formInfo.type === "date") && (
            <>
              <Form.Control
                onChange={handleChange}
                name={formInfo.id}
                value={data[formInfo.id]}
                type={formInfo.type}
                disabled={formInfo.disabled || false}
              />
              {errors[formInfo.id] && (
                <div size="sm" className="alert alert-danger">
                  {errors[formInfo.id]}
                </div>
              )}
            </>
          )}
          {formInfo.type === "multiselect" && (
            <FormUnitFrame>
              <Select
                options={formInfo.options}
                defaultValue={formInfo.defaultValue}
                isMulti={formInfo.isMulti}
                isClearable={true}
                name={formInfo.id}
                onChange={(selectedList, input) => handleMultiSelectChange(selectedList, input, formInfo)}
                components={{ MultiValueRemove }}
              />
              {errors[formInfo.id] && (
                <div size="sm" className="alert alert-dark">
                  {errors[formInfo.id]}
                </div>
              )}
            </FormUnitFrame>
          )}
        </Form.Group>
      ))}
    </Form>
  );
}

export default FormForTableCell;
