import { toast } from "react-toastify";
import { settingsActions } from "./settings-slice";
import http from "../services/httpService";
import {
  listProjectMastersAPI,
  listProjectHeaderAPI,
  listProjectDetailAPI,
  listClientAPI,
  updateClientAPI,
  listSubcontractorAPI,
  updateSubcontractorAPI
} from "../services/backendURLService";
import { office } from "../services/constService";


//*********************************Projects**************************************** */
export const getAllProjectMasters = () => {
  return async (dispatch) => {
    try {
      const { data: project_masters } = await http.get(
        `${listProjectMastersAPI}`
      );
      dispatch(
        settingsActions.setProjectMasters({
          projectMasters: project_masters,
        })
      );
    } catch (error) {
      console.log("settings-acitons - getAllProjectMasters - Error: " + error);
      toast.error(error);
    }
  };
};
export const getAllProjectHeaders = () => {
  return async (dispatch) => {
    try {
      const { data: project_headers } = await http.get(
        `${listProjectHeaderAPI()}`
      );
      dispatch(
        settingsActions.setProjectHeaders({
          projectHeaders: project_headers,
        })
      );
    } catch (error) {
      console.log("settings-acitons - getAllProjectHeaders - Error: " + error);
      toast.error(error);
    }
  };
};
export const getAllProjectDetails = () => {
  return async (dispatch) => {
    try {
      const { data: project_details } = await http.get(
        `${listProjectDetailAPI()}`
      );
      dispatch(
        settingsActions.setProjectDetails({
          projectDetails: project_details,
        })
      );
    } catch (error) {
      console.log("settings-acitons - getAllProjectDetails - Error: " + error);
      toast.error(error);
    }
  };
};

//*********************************Client**************************************** */
export const getAllClients = (officeID) => {
  return async (dispatch) => {
    try {
      const { data: allClients } = await http.get(
        `${listClientAPI(officeID)}`
      );
      dispatch(
        settingsActions.setClient({
          clients: allClients,
        })
      );
    } catch (error) {
      console.log("settings-acitons - getAllClients - Error: " + error);
      toast.error(error);
    }
  };
};

export const postClient = (officeID) => {
  return async (dispatch) => {
    try {
      const response = await http.post(`${listClientAPI(officeID)}`);
      dispatch(
        settingsActions.addClient({
          response,
        })
      );
    } catch (error) {
      toast.error("Updating invoice has something wrong!");
    }
  };
};


export const updateClient = (officeID, clientID , newData) => {
  return async (dispatch) => {
    try {
      const response = await http.patch(`${updateClientAPI(officeID, clientID)}`,newData);
      dispatch(
        settingsActions.updateClient({response})
      );
    } catch (error) {
      toast.error("Updating Client has something wrong!");
      console.log("API ERROR: " + error)
    }
  };
};



//*********************************Subcontractor**************************************** */
export const getAllSubcontractors = (officeID) => {
  return async (dispatch) => {
    try {
      const { data: allSubCon } = await http.get(
        `${listSubcontractorAPI(officeID)}`
      );
      dispatch(
        settingsActions.setSubcontractor({
          subcontactors: allSubCon,
        })
      );
    } catch (error) {
      console.log("settings-acitons - getAllSubcontractors - Error: " + error);
      toast.error(error);
    }
  };
};

export const postSubcontractors = (officeID) => {
  return async (dispatch) => {
    try {
      const response = await http.post(`${listSubcontractorAPI(officeID)}`);
      dispatch(
        settingsActions.addsubcontractor({
          response,
        })
      );
    } catch (error) {
      toast.error("Creating Subcontract Company has something wrong!");
    }
  };
};


export const updateSubcontractors = (officeID, subcontractorID , newData) => {
  return async (dispatch) => {
    try {
      const response = await http.patch(`${updateSubcontractorAPI(officeID, subcontractorID)}`,newData);
      dispatch(
        settingsActions.updateSubcontractor({response})
      );
    } catch (error) {
      toast.error("Updating Subcontract Company has something wrong!");
      console.log("API ERROR: " + error)
    }
  };
};
