import { createSlice } from "@reduxjs/toolkit";


// const initialStateValue = {
//   active: false
// }

const iccSlice = createSlice({
  name: "icc",
  initialState: {
    iccSwitchState: false, //Intial state of the switch is off
    iccUsers: [],
    ICCAdminInvoices: null,
    ICCAdminSubcontracts: null,
    /* 
      projectsForAdminDropdown used in:
      1. create invoice
      2. create subcon
      3. update project info
    */
    projectsForICCAdminDropdown: [],
    localICCOffices: [],
  },
  reducers: {
// ************************************************* project ******************************************
    setProjectsForICCAdminDropdown(state, action) {
      state.projectsForICCAdminDropdown = action.payload.projectsForICCAdminDropdown;
    },
// ************************************************* Swtich ******************************************  
    toggleOn: (state, action) => {
      state.iccSwitchState = true;
    },
    toggleOff: (state, action) => {
      state.iccSwitchState = false;
    },
// ************************************************* Users ******************************************  

    setAllICCUsers(state, action) {
      state.iccUsers = action.payload.iccUsers;
    },
// ************************************************* Invoices ******************************************  

    setICCAdminInvoices(state, action) {
      state.ICCAdminInvoices = action.payload.ICCAdminInvoices;
    },
    
// *********************************************** subcontract *****************************************
    setICCAdminSubcontracts(state, action) {
      state.ICCAdminSubcontracts = action.payload.ICCAdminSubcontracts;
    },


  },
});

export const ICCAction = iccSlice.actions;

export const {toggleOn, toggleOff} = iccSlice.actions;
export default iccSlice;
