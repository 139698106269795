import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.log("Logging the error", error);
    toast.error("An unexpected error occurred");
  }

  return Promise.reject(error);
});

const toastMessage = {
  pending: "Processing...",
  success: "Process Successfully👌",
  error: "Process Failed",
};

function setJwt(jwt) {
  if (jwt !== "undefined" && jwt) {
    axios.defaults.headers.common["authorization"] = "JWT " + jwt;
    return true;
  } else {
    axios.defaults.headers.common["authorization"] = null;
    return false;
  }
}

function toastGet(url) {
  return toast.promise(axios.get(url), toastMessage);
}
function toastPost(url, body) {
  return toast.promise(axios.post(url, body), toastMessage);
}
function toastPut(url, body) {
  return toast.promise(axios.put(url, body), toastMessage);
}
function toastPatch(url, body) {
  return toast.promise(axios.patch(url, body), toastMessage);
}
function toastDelete(url) {
  return toast.promise(axios.delete(url), toastMessage);
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  toastGet: toastGet,
  toastPost: toastPost,
  toastPut: toastPut,
  toastPatch: toastPatch,
  toastDelete: toastDelete,
  setJwt,
};
