import React from 'react';
import { useSelector } from "react-redux";
import { Col, Row, Button } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";

import Spinner from "../../components/Spinner";
import { office as lkuOffice } from '../../services/constService';

const WeekSummaries = () => {
    const weekSummaries = useSelector((state) => state.COH.weekSummaries);
    const currentWeek = useSelector((state) => state.generic.currentWeek);
    const history = useHistory();
    const user = useSelector((state) => state.generic.currentUser);
    const office = user.office_id
    const superuser = user.is_superuser

    // console.log('WeekSummaries - - weekSummaries', weekSummaries)
    // console.log('WeekSummaries - - currenctWeek', currentWeek)

    /* for Spain offices, only superuser has access to block month part  */
    const SpainSuperuser = (office === lkuOffice.BARCELONA || office === lkuOffice.MADRID) && superuser;

    return (
        <> {weekSummaries && currentWeek ?
            <>
                <h3>COH Week Summaries</h3>
                {SpainSuperuser && <Button className='pull-right' onClick={() => history.push(`/coh/block-month`)}>Block Month</Button>}
                <h6 className='mt-5'>Click on the Week name hyperlink to get the weekly summary details</h6>
                <Row className='mt-5'>
                    {weekSummaries.map((weekSummary) => {
                        const linkStyle = {
                            textDecoration: 'none',
                            color: weekSummary.is_complete ? 'green' : 'red',
                        };

                        return (
                            <Col lg={4} className='mt-3 mb-3' key={weekSummary.week_id}>
                                <div className='text-center'>
                                    {weekSummary.week_id == currentWeek.week_id && <>📅 </>}
                                    <Link to={`/coh/week-summaries/${weekSummary.week_id}-${weekSummary.week_summary_id}`} style={linkStyle}>{weekSummary.week_name}</Link>
                                </div>
                            </Col>)
                    })}
                </Row>

            </> : <Spinner />}
        </>
    );
};

export default WeekSummaries;