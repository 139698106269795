import React, { useEffect, useState } from 'react';
import Joi from "joi-browser";
import { Row, Col } from 'react-bootstrap';
import { toast } from "react-toastify";

import http from "../../../services/httpService";
import { getPrepareAssignProjectManagerAPI, updateProjectHeaderAPI } from '../../../services/backendURLService'
import Spinner from '../../../components/Spinner';
import CustomForm from '../../../components/custom/Form/CustomForm'
import { processAPIData2 } from '../../../utils/publicUtil'

const AssginProjectManager = () => {
    const [projects, setProjects] = useState(null)
    const [resources, setResources] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: rawPrepareAssignPM } = await http.get(`${getPrepareAssignProjectManagerAPI}`);
                const organizedPrepareAssignPM = processAPIData2(rawPrepareAssignPM)

                setProjects(organizedPrepareAssignPM.projects)
                setResources(organizedPrepareAssignPM.resources)
            }
            catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);

    // console.log('AssginProjectManager - - projects', projects)
    // console.log('AssginProjectManager - - resources', resources)

    const formInfo = [
        {
            type: 'multiselect',
            label: 'Project',
            options: projects,
            id: 'project',
            isMulti: false,
        },
        {
            type: 'multiselect',
            label: 'Manager',
            options: resources,
            id: 'manager',
            isMulti: false,
        }
    ];

    const formStructure = {
        submit_button: 'Assign Project Manager',
        back_button: true
    };

    const formSchema = {
        [formInfo[0].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[0].label),
        [formInfo[1].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[1].label),
    };

    const doAssignPM = async (data) => {
        console.log('AssginProjectManager - doAssignPM - data', data)
        const projectID = data.project.id
        const managerID = data.manager.id
        try {
            await http.toastPatch(`${updateProjectHeaderAPI(projectID)}`, {
                manager: managerID,
            });
        } catch (error) {
            console.log("ERROR in AssginProjectManager - doAssignPM!");
        }

    };

    return (
        <>
            {
                projects && resources ?
                    <Row>
                        <Col></Col>
                        <Col>
                            <CustomForm
                                formInfo={formInfo}
                                formStructure={formStructure}
                                formSchema={formSchema}
                                doSubmit={doAssignPM}
                            />
                        </Col>
                        <Col></Col>
                    </Row> : <Spinner />
            }
        </>
    );
};

export default AssginProjectManager;