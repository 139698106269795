import React from "react";
import { Route, Redirect } from "react-router-dom";
import _ from "lodash";

import auth from "../services/authService";

function ProtectedRoute({ path, component: Component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.isActiveToken()) {
          return <Redirect to="/login" />;
        }

        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
}

export default ProtectedRoute;