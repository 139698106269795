import React, { useState } from 'react';
import { Row, Form, Col, Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import Select from 'react-select'

const NewProjectRoleInBatchStaff = ({ index, doSubmit, removeProjectRole }) => {
    const allResourcesOptions = useSelector((state) => state.generic.LKUs.LKUResources)
    const allPostitionsOptions = useSelector((state) => state.generic.LKUs.LKUPosition)

    const [selectedValues, setSelectedValues] = useState({
        resource: null,
        position: null
    });

    const handleChange = (name, selectedOption) => {
        setSelectedValues((prevSelectedValues) => ({
            ...prevSelectedValues,
            [name]: selectedOption
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const newProjectRole = {
            'resource': selectedValues.resource ? selectedValues.resource.id : null,
            'position': selectedValues.position ? selectedValues.position.id : null
        };

        doSubmit({
            'index': index,
            'newProjectRole': newProjectRole
        });
    };

    const handle = (e) => {

    };

    return (
        <>
            <Form onBlur={handleSubmit} className='m-3'>
                <Row>
                    <Col>
                        <Form.Label>Resource</Form.Label>
                        <Select
                            options={allResourcesOptions}
                            isMulti={false}
                            isClearable={false}
                            name={`resource`}
                            value={selectedValues.resource}
                            onChange={(selectedOption) => handleChange('resource', selectedOption)}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Position</Form.Label>
                        <Select
                            options={allPostitionsOptions}
                            isMulti={false}
                            isClearable={false}
                            name={`position`}
                            value={selectedValues.position}
                            onChange={(selectedOption) => handleChange('position', selectedOption)}
                        />
                    </Col>
                    <Col>
                        <Form.Label>&nbsp;</Form.Label>
                        <br />
                        <Button variant='outline-danger' onClick={() => removeProjectRole(index)} >&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp;</Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default NewProjectRoleInBatchStaff;