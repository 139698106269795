import React from "react";
import { useTable, usePagination, useSortBy } from "react-table";
// import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faForward, faStepBackward, faStepForward, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { conditionalRenderCurrencyAmount } from "../../../utils/publicUtil";

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage, // Added gotoPage function
    prepareRow, // Added prepareRow function
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 6,
        sortBy: [{ id: "hours", desc: false }],
      },
    },
    useSortBy,
    usePagination
  );


  return (

    <div className="FeeSummaryComponent">
      <div className="pagination-bar">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <FontAwesomeIcon icon={faStepBackward} />
        </button>
        <button onClick={previousPage} disabled={!canPreviousPage}>
          <FontAwesomeIcon icon={faBackward} />
        </button>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <button onClick={nextPage} disabled={!canNextPage}>
          <FontAwesomeIcon icon={faForward} />
        </button>
        <button
          onClick={() => gotoPage(pageOptions.length - 1)}
          disabled={!canNextPage}
        >
          <FontAwesomeIcon icon={faStepForward} />
        </button>
      </div>
      <table {...getTableProps()} className="table table-striped">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.id !== "expander" && (
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        column.accessor === "hours" && (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      )}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const FeeSummaryTable = ({ feeSummary }) => {
  const data = feeSummary.fee_report.map(item => ({
    resourceName: item.resource,
    projectPosition: item.position,
    rate: `${item.rate}`,
    hours: `${item.hours}`,
    totalSpend: `${item.total_spend}`,
  }));

  const columns = [
    {
      Header: "Resource Name",
      accessor: "resourceName",
    },
    {
      Header: "Project Position",
      accessor: "projectPosition",
    },
    {
      Header: "Rate",
      accessor: "rate",
    },
    {
      Header: "Actual Recorded Hours",
      accessor: "hours",
      sortType: "alphanumeric",
      disableSortBy: false, // Enable sorting for the column
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    },
    {
      Header: "Total Spend",
      accessor: "totalSpend",
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{conditionalRenderCurrencyAmount(value)}</div>,
    },
  ];

  return (
    <div className="App">
      <Table columns={columns} data={data} />
    </div>
  );
};

export default FeeSummaryTable;
