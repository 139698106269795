import React from "react";
import { Form } from "react-bootstrap";
import _ from "lodash";

import FormUnitFrame from "./FormUnitFrame";
import { conditionalRenderCurrencyAmount } from '../../utils/publicUtil'

function FormForDisplay({
  displayedData,
  formInfo
}) {

  const processDisplayData = (formInfo) => {
    let displayData = _.get(displayedData, formInfo.id)
    if (formInfo.displayType && formInfo.displayType == 'currency_amount') displayData = conditionalRenderCurrencyAmount(parseFloat(displayData))
    return displayData
  };

  return (
    <Form>
      {formInfo.map((formInfo) => (
        <Form.Group
          className="mb-3"
          controlId={formInfo.id}
          key={formInfo.id}
        >
          <FormUnitFrame
            label={formInfo.label}
            formText={formInfo.formText} >
            <Form.Control
              value={processDisplayData(formInfo)}
              disabled={true}

            // displayedData[formInfo.id]
            />
          </FormUnitFrame>
        </Form.Group>
      ))}
    </Form>
  );
}

export default FormForDisplay;
