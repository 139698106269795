import React, { useEffect,useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector,useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import FrameModal from "../../../components/common/FrameModal";
import CommercialDetails from './CommercialDetails';
import ProjectDetails from './ProjectDetails'
import ProjectRates from "./ProjectRates";
import ProjectSponsor from "./ProjectSponsor"
import { getProjectMaster, getProjectSummary } from "../../../store/PM-actions";
import ICCPatchLocalProjectDetailPage from "../../ICC/PMO/ICCPatchLocalProjectDetailPage";

function NavProjectInitialization() {
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedProject = useSelector((state) => state.PM.selectedProject);
    const projectMaster = useSelector((state) => state.PM.projectMaster);
    const projectSummary = useSelector((state) => state.PM.projectSummary);
    const [showAddNewOfferAndPillars, setshowAddNewOfferAndPillars] = useState(false)

    const checkIfICCProject = () => {
        if(projectMaster.ICC != null){
            if(projectMaster.offer_number === null){
                setshowAddNewOfferAndPillars(true)
            }
        }
    }
   


    useEffect(() => {
        dispatch(getProjectMaster(selectedProject.id));
        dispatch(getProjectSummary(selectedProject.id));
        checkIfICCProject();
        //everytime selectedProject is updated check if selectedProject is empty then push back to pmo nav page
        if (Object.keys(selectedProject).length === 0) history.push('/pmo');
    }, [selectedProject]);

    return (
        <div>
            <FrameModal modalTitle={"Please add an Offer Number, Business Unit and Pillars "} 
                    show={showAddNewOfferAndPillars} 
                    setShow={setshowAddNewOfferAndPillars} 
                    children={<ICCPatchLocalProjectDetailPage project_id={selectedProject.id}/>} />

            <Row>
                <Col> <h3 className="mb-5">Project Initialization</h3></Col>
                <Col><h3 className="mb-5">{selectedProject.value}</h3></Col>
            </Row>
            {selectedProject.length !== 0 &&
                <Tabs>
                    <TabList>
                        <Tab>Project Details and Rates</Tab>
                        <Tab>Commercial Details</Tab>
                    </TabList>


                    <TabPanel>
                        <Tabs>
                            <TabList>
                                <Tab>Details</Tab>
                                <Tab>Rate Management</Tab>
                                <Tab>Sponsor</Tab>
                            </TabList>

                            <TabPanel>
                                <ProjectDetails projectID={selectedProject.id} />
                            </TabPanel>
                            <TabPanel>
                                <ProjectRates projectID={selectedProject.id} />
                            </TabPanel>
                            <TabPanel>
                                <ProjectSponsor projectID={selectedProject.id} />
                            </TabPanel>
                        </Tabs>
                    </TabPanel>
                    <TabPanel>
                        <CommercialDetails projectID={selectedProject.id} />
                    </TabPanel>
                </Tabs>}
        </div>
    );
}

export default NavProjectInitialization;