import React from 'react';

const Reports = () => {
    return (
        <>
            <h3 className='mt-5'>
                Reports are located at the following Teams Channel: <a href="https://teams.microsoft.com/l/team/19%3A97MdAEjZs-iG0wi6vr7ICoZLIAQoAfgnCaWMjn-5k3Q1%40thread.tacv2/conversations?groupId=5d0829b5-864a-4ef8-9ab6-2281c6c7bb3e&tenantId=" target="_blank">GLOBAL MATER | General | Microsoft Teams</a> by office, in files/reports.
            </h3>
            <h5>DO NOT CLICK the link, you can copy this link and paste it to teams, and open it in teams</h5>
        </>

    );
};

export default Reports;