import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from 'lodash'
import { Table } from "react-bootstrap";
import { connect } from 'react-redux';

import { applyFilter, applySort } from "../../../utils/tableUtil";
import Spinner from "../../../components/Spinner";
import WorkingDays from "./WorkingDays";
import { prepareDateHeader, removeFirstElement, conditionalRenderColor } from '../../../utils/staffingUtil'
import TableFilter from './../../../components/custom/Table/TableFilter';
import TableHeader from './../../../components/custom/Table/TableHeader';
import { staffingActions } from '../../../store/staffing-slice'

const TableBody = ({ data, columns }) => {
    const renderCell = (item, column) => {
        if (typeof column.path != 'number') {
            const contentString = _.get(item, column.path)
            return <td key={`${data.resource_id}-${column.path}`}>
                {contentString}
            </td>
        }
        else {
            const utilizationValue = _.get(item, column.path + '.utilization')
            const cellColor = conditionalRenderColor(utilizationValue)
            return <td key={`${data.resource_id}-${column.path}`} style={{ backgroundColor: cellColor, textAlign: 'center', verticalAlign: 'middle' }}>
                {(utilizationValue * 100).toFixed(0) + '%'}
            </td >;
        }
    };

    // console.log('UtilizationSummary - TableBody - - data', data)
    // console.log('UtilizationSummary - TableBody - - columns', columns)

    return (
        <tbody>
            {data.map((item) => (
                <React.Fragment>
                    <tr>
                        {columns.map((column) => (
                            <React.Fragment key={`${column.path}-${item.resource_id}`}>
                                {renderCell(item, column)}
                            </React.Fragment>
                        ))}
                    </tr>
                </React.Fragment>
            ))}
        </tbody>
    );
}

const UtilizationSummaryTable = ({ headerData, completeBodyData, filterGroup, selectedGenre, setSelectedGenre }) => {
    // const [selectedGenre, setSelectedGenre] = useState([]);
    const [sortColumn, setSortColumn] = useState({ path: "id", order: "asc" });

    let filteredBodyData = completeBodyData;
    if (!_.isEmpty(selectedGenre)) filteredBodyData = applyFilter(selectedGenre, completeBodyData)

    const sorted = applySort(filteredBodyData, sortColumn)
    const itemsDisplayed = _(sorted).value();


    // console.log('UtilizationSummary - UtilizationSummaryTable - headerData', headerData)
    // console.log('UtilizationSummary - UtilizationSummaryTable - completeBodyData', completeBodyData)

    return (
        <>{headerData && completeBodyData &&
            <>
                <TableFilter filterGroup={filterGroup} completeBodyData={completeBodyData} selectedGenre={selectedGenre} setSelectedGenre={setSelectedGenre} />
                <Table className="table" bordered hover>
                    <TableHeader headerData={headerData} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                    <TableBody columns={headerData} data={itemsDisplayed} />
                </Table>
            </>
        }</>
    )
};

const mapStateToProps = (state) => {
    return {
        selectedGenre: state.Staffing.utilizationSelectedGenre,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedGenre: (newGenre) => dispatch(staffingActions.setUtilizationSelectedGenre({
            selectedGenre: newGenre,
        })),
    };
};

const CoveredUtilizationTable = connect(mapStateToProps, mapDispatchToProps)(UtilizationSummaryTable);

const UtilizationSummary = () => {
    const utilizations = useSelector((state) => state.Staffing.utilizations);
    const selectedWeek = useSelector((state) => state.Staffing.selectedWeek);
    let allResourcesOptions = useSelector((state) => state.generic.LKUs.LKUResources)
    const allPostionsOptions = useSelector((state) => state.generic.LKUs.LKUPosition)
    const allPillarsOptions = useSelector((state) => state.generic.LKUs.LKUPillars)
 
    let header_data = []
    let body_data = []
    let dataIsReady = false

    if (utilizations) {
        // -------------------------------------- prepare header data --------------------------------------
        const basic_header_data = prepareDateHeader(utilizations)
        const with_sort_info_header = basic_header_data.map(pathAndLabelObj => ({ ...pathAndLabelObj, sortPath: pathAndLabelObj.path + '.utilization' }));

        header_data = [
            { path: 'resource.full_name', label: "Resource" },
            { path: 'resource.pillar_item.title', label: "Pillar" },
            { path: 'resource.position.title', label: "Position" },
            ...with_sort_info_header
        ];

     // -------------------------------------- prepare body data --------------------------------------
     const adminValues = new Set(["Bernard Tremblay", "Victoria Ma", "Nick Banich"]);
     const initialBodyData = removeFirstElement(utilizations);
     body_data = initialBodyData.filter(item => 
         !adminValues.has(item.resource.full_name) && 
         item.resource.position.id !== 8 && 
         item.resource.position.id !== 9
     );

     // Get the full names of removed items
     const removedFullNames = new Set(
         initialBodyData
             .filter(item => adminValues.has(item.resource.full_name) || item.resource.position.id === 8 || item.resource.position.id === 9)
             .map(item => item.resource.full_name)
     );

     // Filter allResourcesOptions to exclude removed full names
     allResourcesOptions = allResourcesOptions.filter(option => 
         !removedFullNames.has(option.value)
     );

        // -------------------------------------- prepare is ready ? --------------------------------------
        dataIsReady = basic_header_data[0].path == selectedWeek.id
    }

    // console.log('UtilizationSummary -  - utilizations', utilizations)

    const filterGroup = [
        {
            'id': 'resource',
            'path': 'resource',
            "options": allResourcesOptions,
            "label": "Resource",
        },
        {
            'id': 'pillar',
            'path': 'resource.pillar_item',
            "options": allPillarsOptions,
            "label": "Pillar",
        },
        {
            'id': 'position',
            'path': 'resource.position',
            "options": allPostionsOptions,
            "label": "Position",
        }
    ]

    return (
        <>
            {dataIsReady ? <>
                <WorkingDays />
                <div style={{ marginTop: '80px' }}>
                    {/* <CustomTable headerData={header_data} bodyData={body_data} tableTitle={'Utilization Summary'} /> */}
                    <CoveredUtilizationTable headerData={header_data} completeBodyData={body_data} filterGroup={filterGroup} />
                </div>
            </> : <Spinner />}
        </>
    );
};

export default UtilizationSummary;