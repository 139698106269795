import React, { useState } from 'react';
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col } from 'react-bootstrap';
import Joi from "joi-browser";
import { toast } from "react-toastify";

import http from "../../../services/httpService";
import { listStaffingAPI } from "../../../services/backendURLService";
import NewProjectRoleInBatchStaff from './NewProjectRoleInBatchStaff'
import CustomForm from '../../../components/custom/Form/CustomForm';

const BatchStaffing = () => {
    const allProjectsOptions = useSelector((state) => state.Staffing.projectOptions)
    const weekLists = useSelector((state) => state.Staffing.weekLists);

    // console.log('BatchStaffing - allProjectsOptions', allProjectsOptions)
    // console.log('BatchStaffing - weekLists', weekLists)


    /* ----------------------------------- manage project roles ----------------------------------- */
    const [projectRoles, setProjectRoles] = useState([])

    const handleClickAddButton = () => {
        const newProjectRole = { 'resource': null, 'position': null };
        const updatedProjectRoles = [...projectRoles, newProjectRole];
        setProjectRoles(updatedProjectRoles);
    };

    const handleEnterNewProjectRole = ({ index, newProjectRole }) => {
        // console.log('BatchStaffing - handleEnterNewProjectRole - data', data)
        setProjectRoles(prevProjectRoles => {
            const newProjectRoles = [...prevProjectRoles];
            newProjectRoles[index] = newProjectRole;
            return newProjectRoles;
        });
    };

    // console.log('BatchStaffing - projectRoles', projectRoles)
    const handleClickDeleteButton = (index) => {
        const updatedProjectRoles = projectRoles.filter((_, i) => i !== index);
        setProjectRoles(updatedProjectRoles);
    };

    /* ----------------------------------- manage project and weeks  ----------------------------------- */
    const formInfo = [
        {
            type: 'multiselect',
            label: 'Project',
            options: allProjectsOptions,
            id: 'project',
            isMulti: false
        },
        {
            type: 'multiselect',
            label: 'Start Week',
            options: weekLists.map(element => ({ value: element.week_name, id: element.week_id, label: element.week_name })),
            id: 'start_week',
            isMulti: false
        },
        {
            type: 'text',
            label: 'Number of weeks',
            id: 'number_of_weeks',
        },
        {
            type: 'text',
            label: 'Number of days',
            id: 'number_of_days',
        },
    ];

    const formSchema = {
        [formInfo[0].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[0].label),
        [formInfo[1].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[1].label),
        [formInfo[2].id]: Joi.number().required().label(formInfo[2].label),
        [formInfo[3].id]: Joi.number().required().label(formInfo[3].label),
    };

    const formStructure = {
        submit_button: 'Batch Staffing',
        back_button: true
    };

    const submitData = async (data) => {
        // console.log('BatchStaffing - submitData - data', data)

        const postData = {
            'project_role_list': projectRoles,
            'project': data.project.id,
            'start_week': data.start_week.id,
            'number_of_weeks': data.number_of_weeks,
            'forecasted_days': data.number_of_days
        }

        try {
            await http.toastPost(`${listStaffingAPI(9999999)}`, postData);
        } catch (error) {
            toast.error("ERROR in batch staffing!");
        }

    };

    return (
        <>
            <Row>
                <Col>

                </Col>
                <Col>
                    Resource
                    <Button onClick={handleClickAddButton} className='m-3' variant='outline-primary'>&nbsp;&nbsp;&nbsp; + &nbsp;&nbsp;&nbsp;</Button>
                    {projectRoles.map((singlePR, index) => <div key={index}>
                        <NewProjectRoleInBatchStaff index={index} doSubmit={handleEnterNewProjectRole} removeProjectRole={handleClickDeleteButton} />
                    </div>)}

                    <CustomForm
                        formInfo={formInfo}
                        formStructure={formStructure}
                        formSchema={formSchema}
                        doSubmit={submitData}
                    />
                </Col>
                <Col></Col>
            </Row>
        </>
    );
};

export default BatchStaffing;