import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import { updatePMInvoice } from "../../../store/PM-actions";
import { processUpdateInvoice } from '../../../utils/publicUtil';
import http from "../../../services/httpService";
import { PMInvoiceSummaryAPI } from "../../../services/backendURLService";
import Spinner from "../../../components/Spinner";
import CustomTable from "../../../components/custom/Table/CustomTable";

const PMAllInvoices = ({ invoices, projectID }) => {
  const dispatch = useDispatch();
  const [invoiceSummary, setInvoiceSummary] = useState(null);
  const [travelInvoiceSummary, setTravelInvoiceSummary] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get(`${PMInvoiceSummaryAPI(projectID)}`);
        setTravelInvoiceSummary({ ...response.data.travel_invoice_summary, id: projectID });
        setInvoiceSummary({ ...response.data.invoice_summary, id: projectID });
      } catch (error) {
        toast.error("Getting PM invoice summary has something wrong!");
      }
    };
    fetchData();
  }, []);

  const columnsTotalAmount = [
    { path: "paid", label: "Total Amount, Paid Invoices", displayType: 'currency_amount', },
    { path: "sent", label: "Total Amount, Invoices Submitted but Not Yet Paid", displayType: 'currency_amount', },
    { path: "in_process", label: "Total Amount, Invoices in Creation/Approval Process", displayType: 'currency_amount', },
    { path: "forecasted", label: "Estimated & Amount, Forecasted Invoices", displayType: 'currency_amount', },
    { path: "still_need_invoice", label: "Still need to be invoiced", displayType: 'currency_amount', },
    { path: "total", label: "Invoice total", displayType: 'currency_amount', },
    { path: "total_budget", label: "Total budget", displayType: 'currency_amount', },
  ];
  const columnsTravelTotalAmount = [
    { path: "travel_paid", label: "Total Amount, Paid Invoices", displayType: 'currency_amount', },
    { path: "travel_sent", label: "Total Amount, Invoices Submitted but Not Yet Paid", displayType: 'currency_amount', },
    { path: "travel_in_process", label: "Total Amount, Invoices in Creation/Approval Process", displayType: 'currency_amount', },
    { path: "travel_forecasted", label: "Estimated & Amount, Forecasted Invoices", displayType: 'currency_amount', },
    { path: "travel_still_need_invoice", label: "Still need to be invoiced", displayType: 'currency_amount', },
    { path: "travel_total", label: "Invoice total", displayType: 'currency_amount', },
    { path: "travel_total_budget", label: "Total budget", displayType: 'currency_amount', },
  ];
  const columnsAllInvoices = [
    {
      path: "concept",
      label: "Concept",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "concept",
          id: "concept",
          type: "text",
        },
        schema: { concept: Joi.string().required().label("Concept") },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    { path: "type.title", label: "Invoice Type" },
    { path: "status.title", label: "Invoice status" },
    {
      path: "invoice_date",
      label: "Date on invoice(yyyy-mm-dd)",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "invoice_date",
          id: "invoice_date",
          type: "date",
        },
        schema: { invoice_date: Joi.date().required().label("Estimated Date") },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    { path: "amount_actual", label: "Amount", displayType: 'currency_amount', },
    {
      path: "amount_estimate",
      label: "Estimated amount",
      displayType: 'currency_amount',
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "amount_estimate",
          id: "amount_estimate",
          type: "text",
        },
        schema: {
          amount_estimate: Joi.number().required().label("Estimate Amount"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
  ];

  return (
    <>
      {invoiceSummary === null ? (
        <div><Spinner /></div>
      ) : (
        <div className="table-container">
          {invoices[0].empty_message ? (
            <p>There is no data</p>
          ) : (
            <>
              <h3>All Invoices Total</h3>
              <CustomTable
                headerData={columnsTotalAmount}
                bodyData={[invoiceSummary]}
              />
              <h3>Travel Invoices Total</h3>
              <CustomTable
                headerData={columnsTravelTotalAmount}
                bodyData={[travelInvoiceSummary]}
              />
              <h3>All Invoices</h3>
              <CustomTable
                headerData={columnsAllInvoices}
                bodyData={invoices}
              />
              <br />
              <br />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PMAllInvoices;
