import React from 'react';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Joi from "joi-browser";

import { mergeProjectMasterHeader, generateOptions, processPatchData } from '../../utils/publicUtil'
import http from '../../services/httpService';
import { updateProjectHeaderAPI, updateProjectMasterAPI, patchTemporaryProjectDetailAPI } from '../../services/backendURLService';
import CustomTable from '../../components/custom/Table/CustomTable'

const Projects = () => {
    const projectMasters = useSelector((state) => state.settings.projectMasters);
    const projectHeaders = useSelector((state) => state.settings.projectHeaders);
    const projectDetails = useSelector((state) => state.settings.projectDetails);
    const mergedProjects = mergeProjectMasterHeader(projectMasters, projectHeaders, projectDetails)
    const LKUCurrencies = useSelector((state) => state.generic.LKUs.LKUCurrencies);
    const clients = useSelector((state) => state.settings.clients).map(client => ({ id: client.id, label: client.name, value: client.name }));
    const allManagers = useSelector((state) => state.generic.allManagers);
    const LKUStatus = useSelector((state) => state.generic.LKUs.LKUProjectStatus);
    const LKUType = useSelector((state) => state.generic.LKUs.LKUProjectType);
    const LKUInvoiceStructures = useSelector((state) => state.generic.LKUs.LKUInvoiceStructures);
    const LKUInvoiceFrequencies = useSelector((state) => state.generic.LKUs.LKUInvoiceFrequencies);
    const LKUTravelInvoiceStructures = useSelector((state) => state.generic.LKUs.LKUTravelInvoiceStructures);
    const LKUInvoiceInstructions = useSelector((state) => state.generic.LKUs.LKUInvoiceInstructions);
    const LKUPaymentTerms = useSelector((state) => state.generic.LKUs.LKUPaymentTerms);


    // console.log('Projects - - projectMasters', projectMasters)
    // console.log('Projects - - projectHeaders', projectHeaders)
    // console.log('Projects - - mergedProjects', mergedProjects)

    console.log('Projects - - LKUStatus', LKUStatus)

    const handleUpdateMaster = async (selectedRow) => {
        try {
            // console.log('RentProspectProject - columns - selectedRow', selectedRow)
            const projectID = selectedRow.rowInfo.id
            await http.patch(
                `${updateProjectMasterAPI(projectID)}`, selectedRow.updatedValue
            );
        } catch (error) {
            console.log("ERROR: " + error)
            toast.error("Path Project Master in UpdateProjectInfo has something wrong!");
        }
    }

    const handleUpdateHeader = async (selectedRow) => {
        try {
            const projectID = selectedRow.rowInfo.id
            const key = Object.entries(selectedRow.updatedValue)[0][0]
            const value = Object.entries(selectedRow.updatedValue)[0][1]

            let submitData = selectedRow.updatedValue
            if (typeof value == 'object') submitData = { [key]: value.id }
            // console.log('Projects - handleUpdateHeader - submitData', submitData)

            await http.patch(
                `${updateProjectHeaderAPI(projectID)}`, submitData
            );
        } catch (error) {
            console.log("ERROR: " + error)
            toast.error("Getting Project Header in UpdateProjectInfo has something wrong!");
        }
    }

    const handleUpdateDetail = async (selectedRow) => {
        try {
            const projectID = selectedRow.rowInfo.id
            const submitData = processPatchData(selectedRow.updatedValue)

            await http.patch(
                `${patchTemporaryProjectDetailAPI(projectID)}`, submitData
            );
        } catch (error) {
            console.log("ERROR: " + error)
            toast.error("Getting Project Detail in UpdateProjectInfo has something wrong!");
        }
    };

    const columns = [
        { path: "id", label: "ID", },
        { path: "project_number", label: "Project Number", },
        {
            path: "name", label: "Project Name",
            customContent: {
                type: 'inputbox',
                formInfo: {
                    type: 'text',
                    id: 'name',
                },
                schema: { 'name': Joi.string().required().label('Project Name') },
                doSubmit: handleUpdateMaster
            }
        },
        {
            path: "manager", label: "Manager",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'multiselect',
                    id: "manager",
                    options: allManagers,
                    isMulti: false,
                },
                schema: { 'manager': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Manager') },
                doSubmit: handleUpdateHeader
            },
        },
        {
            path: "offer_number", label: "Offer Number",
            customContent: {
                type: 'inputbox',
                formInfo: {
                    type: 'text',
                    id: 'offer_number',
                },
                schema: { 'offer_number': Joi.string().required().label('Offer Number') },
                doSubmit: handleUpdateMaster
            }
        },
        {
            path: "description", label: "Description",
            customContent: {
                type: 'inputbox',
                formInfo: {
                    type: 'text',
                    id: 'description',
                },
                schema: { 'description': Joi.string().required().label('Description') },
                doSubmit: handleUpdateMaster
            }
        },
        { path: "status.value", label: "Status", },
        { path: "type.value", label: "Type", },
        {
            path: "duration_in_days", label: "Duration in Days",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'text',
                    id: 'duration_in_days',
                },
                schema: { 'duration_in_days': Joi.number().required().label('Duration in Days') },
                doSubmit: handleUpdateHeader
            },
        },
        {
            path: "downpayment",
            label: "Downpayment",
            displayType: 'currency_amount',
            customContent: {
                type: 'inputbox',
                formInfo: {
                    type: 'text',
                    id: 'downpayment',
                },
                schema: { 'downpayment': Joi.number().required().label('Downpayment') },
                doSubmit: handleUpdateHeader
            }
        },
        {
            path: "currency", label: "Currency",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'multiselect',
                    id: "currency",
                    options: LKUCurrencies,
                    isMulti: false,
                },
                schema: { 'currency': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('currency') },
                doSubmit: handleUpdateHeader
            },
        },
        {
            path: "client", label: "Client",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'multiselect',
                    id: "client",
                    options: clients,
                    isMulti: false,
                },
                schema: { 'client': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Client') },
                doSubmit: handleUpdateHeader
            },
        },
        {
            path: 'invoice_structure', label: 'Invoice Structure',
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'multiselect',
                    id: "invoice_structure",
                    options: LKUInvoiceStructures,
                    isMulti: false,
                },
                schema: { 'invoice_structure': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Invoice Structure') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: 'invoice_frequency', label: 'Invoice Frequency',
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'multiselect',
                    id: "invoice_frequency",
                    options: LKUInvoiceFrequencies,
                    isMulti: false,
                },
                schema: { 'invoice_frequency': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Invoice Frequency') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: 'travel_invoice_structure', label: 'Travel Invoice Structure',
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'multiselect',
                    id: "travel_invoice_structure",
                    options: LKUTravelInvoiceStructures,
                    isMulti: false,
                },
                schema: { 'travel_invoice_structure': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Travel Invoice Structure') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: 'invoice_instruction', label: 'Invoice Instruction',
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'multiselect',
                    id: "invoice_instruction",
                    options: LKUInvoiceInstructions,
                    isMulti: false,
                },
                schema: { 'invoice_instruction': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Invoice Instruction') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: "invoice_detail", label: "Invoice Detail",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'text',
                    id: 'invoice_detail',
                },
                schema: { 'invoice_detail': Joi.string().required().label('Invoice Detail') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: "number_of_invoices", label: "Number of Invoices",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'text',
                    id: 'number_of_invoices',
                },
                schema: { 'number_of_invoices': Joi.number().required().label('Number of Invoices') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: 'payment_term', label: 'Payment Term',
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'multiselect',
                    id: "payment_term",
                    options: LKUPaymentTerms,
                    isMulti: false,
                },
                schema: { 'payment_term': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Payment Term') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: "internal_fees",
            label: "Internal Fees",
            displayType: 'currency_amount',
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'text',
                    id: 'internal_fees',
                },
                schema: { 'internal_fees': Joi.number().required().label('Internal Fees') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: "travel_fees",
            label: "Travel Fees",
            displayType: 'currency_amount',
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'text',
                    id: 'travel_fees',
                },
                schema: { 'travel_fees': Joi.number().required().label('Travel Fees') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: "external_fees_subcontract",
            label: "External Fees",
            displayType: 'currency_amount',
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'text',
                    id: 'external_fees_subcontract',
                },
                schema: { 'external_fees_subcontract': Joi.number().required().label('External Fees') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: "contract_start_date", label: "Contract Start Date",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'date',
                    id: 'contract_start_date',
                },
                schema: { 'contract_start_date': Joi.date().required().label('Contract Start Date') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: "project_start_date", label: "Project Start Date",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'date',
                    id: 'project_start_date',
                },
                schema: { 'project_start_date': Joi.date().required().label('Project Start Date') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: "duration_in_weeks", label: "Duration in Weeks",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'text',
                    id: 'duration_in_weeks',
                },
                schema: { 'duration_in_weeks': Joi.number().required().label('Duration in Weeks') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: "po_number", label: "PO Number",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'text',
                    id: 'po_number',
                },
                schema: { 'po_number': Joi.string().required().label('PO Number') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: "invoice_to_company", label: "Invoice To Company",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'text',
                    id: 'invoice_to_company',
                },
                schema: { 'invoice_to_company': Joi.string().required().label('Invoice To Company') },
                doSubmit: handleUpdateDetail
            },
        },
        {
            path: "invoice_contact", label: "Invoice Contact",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'text',
                    id: 'invoice_contact',
                },
                schema: { 'invoice_contact': Joi.string().required().label('Invoice Contact') },
                doSubmit: handleUpdateDetail
            },
        },

    ];

    const projectNameOptions = generateOptions(
        mergedProjects,
        'id',
        'name',
        'name'
    );
    const projectNumberOptions = generateOptions(
        mergedProjects,
        'id',
        'project_number',
        'project_number'
    );
    const projectManagerOptions = generateOptions(
        mergedProjects,
        'manager.id',
        'manager.value',
        'manager.id'
    );

    const filterGroup = [
        {
            'id': 'Project Name',
            'path': 'id',
            "options": projectNameOptions,
            "label": "Project Name"
        },
        {
            'id': 'Project Number',
            'path': 'id',
            "options": projectNumberOptions,
            "label": "Project Number"
        },
        {
            'id': 'Project Manager',
            'path': 'manager',
            "options": projectManagerOptions,
            "label": "Project Manager"
        },
        {
            'id': 'status',
            'path': 'status',
            "options": LKUStatus,
            "label": "Status"
        },
        {
            'id': 'type',
            'path': 'type',
            "options": LKUType,
            "label": "Type"
        },
        {
            'id': 'currency',
            'path': 'currency',
            "options": LKUCurrencies,
            "label": "Currency"
        },
        {
            'id': 'client',
            'path': 'client',
            "options": clients,
            "label": "Client"
        },
    ]

    return (
        <div>
            <h1>Projects</h1>
            <CustomTable
                headerData={columns}
                bodyData={mergedProjects}
                filterGroup={filterGroup}
                pageSize={10}
            />
        </div>
    );
};

export default Projects;