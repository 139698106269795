import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Form } from "react-bootstrap";
import http from "../services/httpService";
import { useHistory } from 'react-router-dom';
import { setSwitchState } from "../store/icc-actions";
import { connect } from 'react-redux';
import { toggleOff,toggleOn } from "../store/icc-slice";


function ICCSwitch() {
  const history = useHistory();
  const switchValue = useSelector((state) => state.ICC.iccSwitchState);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.generic.currentUser);


  function toggleSwitchHandler() {
    if (switchValue == true) {
      history.push('/home');
       dispatch(toggleOff());
    } else {
      history.push('/icc');
       dispatch(toggleOn());
    }
 }
  
  // useEffect(() => {
  //   setChecked(userInfo.is_global);
  // }, [userInfo]);

  const customLabel = `${switchValue ? "ICC" : "MATER"} View`;

  return (
    <Form>
      <Form.Check
        type="switch"
        id="custom-switch"
        label={customLabel}
        checked={switchValue}
        onChange={toggleSwitchHandler}
        // className="custom-switch"
      />
    </Form>
  );
}

export default ICCSwitch;
