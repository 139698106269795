import { useEffect, useState } from 'react';
import { toast } from "react-toastify";

import http from "../../../services/httpService";
import { PMSubcontractSummaryAPI } from "../../../services/backendURLService";
import Spinner from "../../../components/Spinner";
import CustomTable from "../../../components/custom/Table/CustomTable";

const PMAllSubcontracts = ({ Subcontracts, projectID }) => {
  //API Call to pull data
  const [subcontractSummary, setSubcontractSummary] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get(`${PMSubcontractSummaryAPI(projectID)}`);
        setSubcontractSummary({ ...response.data, id: projectID });
      } catch (error) {
        toast.error("Getting PM subcontract summary has something wrong!");
      }
    };
    fetchData();
  }, []);

  const columnsTotalAmount = [
    { path: "PAID", label: "Total Amount, Paid Subcontract", displayType: 'currency_amount', },
    { path: "PENDING_PAY", label: "Total Amount, Invoices Approved but Not Yet Paid", displayType: 'currency_amount', },
    { path: "in_process", label: "Total Amount, Invoices in Creation/Approval Process", displayType: 'currency_amount', },
    { path: "forecasted", label: "Total Amount, Pending Creation", displayType: 'currency_amount', },
    { path: "total", label: "Subcontract total", displayType: 'currency_amount', },
  ];
  const columnsAllSubcontract = [
    { path: "id", label: "Subcontract ID" },
    { path: "concept", label: "Concept" },
    { path: "amount", label: "Amount", displayType: 'currency_amount', },
    { path: "project.project_header.currency.value", label: "Project Currency" },
    { path: "invoice.paid_date", label: "Paid date" },
    { path: "invoicing_company.value", label: "Subcontractor",sortPath:"invoicing_company.value", },
    { path: "due_date", label: "Due date" },
    { path: "comment", label: "Comment" },
    { path: "status.value", label: "Status" },
    { path: "actual_amount", label: "Actual Amount"},
    { path: "actual_date_paid", label: "Actual Date Paid"},
  ];

  return (
    <>
      {subcontractSummary === null ? (
        <div><Spinner /></div>
      ) : (
        <div className="table-container">
          {Subcontracts[0].empty_message ? (
            <h3>There is no data.</h3>
          ) : (
            <>
              <h3>All Subcontracts Total</h3>
              <CustomTable
                headerData={columnsTotalAmount}
                bodyData={[subcontractSummary]}
              />
              <h3>All Subcontracts</h3>
              <CustomTable
                headerData={columnsAllSubcontract}
                bodyData={Subcontracts}
              />
              <br />
              <br />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PMAllSubcontracts;
