import { createSlice } from "@reduxjs/toolkit";

/*
  PM so many redux variables is to load relevant information in advance after selecting a project.
  closeoutInfo includes complete date, split pillar fees, and will not change when other content changes. When status changed to complete, it will re-getCloseoutInfo.
  PMCloseoutTrackers is prepared during the new project, all null.
*/

const PMSlice = createSlice({
  name: "PM",
  initialState: {
    projectsForPMDropdown: [],
    selectedProject: {},
    projectMaster: {},
    projectDetail: {},
    projectRates: {},
    projectSummary: {},
    PMInvoices: null,
    PMInvoiceSummary: {},
    projectHeader: {},
    PMCloseoutTrackers: [],
    closeoutInfo: {},
    PMSubcontracts: null,
    subcontractors: {},
  },
  reducers: {
    // ****************************** project dropdown list ***************************************
    setProjectsForPMDropdown(state, action) {
      state.projectsForPMDropdown = action.payload.projectsForPMDropdown;
    },
    setSelectedProject(state, action) {
      state.selectedProject = action.payload.selectedProject;
    },

    // ****************************** project initializaztion **************************************
    setProjectMaster(state, action) {
      state.projectMaster = action.payload.projectMaster;
    },
    setProjectDetail(state, action) {
      state.projectDetail = action.payload.projectDetail;
    },
    modifyProjectDetail(state, action) {
      const { modifiedProjectDetail } = action.payload;
      const currentProjectDetail = state.projectDetail;
      let updatedPrjctDtl = JSON.parse(JSON.stringify(currentProjectDetail));
      Object.keys(updatedPrjctDtl).forEach((currentKey) => {
        if (modifiedProjectDetail.hasOwnProperty(currentKey))
          updatedPrjctDtl[currentKey] = modifiedProjectDetail[currentKey];
      });
      state.projectDetail = updatedPrjctDtl;
    },
    setProjectRates(state, action) {
      state.projectRates = action.payload.projectRates;
    },
    modifyProjectRate(state, action) {
      const currentProjectRates = state.projectRates;
      const { modifiedProjectRate } = action.payload;
      for (let i = 0; i < currentProjectRates.rates.length; i++) {
        const rate = currentProjectRates.rates[i];
        if (rate.id === modifiedProjectRate.id) {
          rate.hourly_rate = parseFloat(modifiedProjectRate.hourly_rate);
          break;
        }
      }
      state.projectRates = currentProjectRates;
    },
    updateOfferNumber(state, action) {
      const { projectID, offer_number } = action.payload;
      const index = state.projectsForPMDropdown.findIndex(
        (project) => project.id == projectID
      );
      state.projectsForPMDropdown[index].offer_number = offer_number;
    },
    // *********************************** project management **************************************
    setProjectSummary(state, action) {
      state.projectSummary = action.payload.projectSummary;
    },
    modifyProjectSummary(state, action) {
      const { modifiedProjectSummary } = action.payload;
      const currentProjectSummary = state.projectSummary;
      let updatedPrjctSmmry = JSON.parse(JSON.stringify(currentProjectSummary));
      Object.keys(updatedPrjctSmmry).forEach((currentKey) => {
        if (modifiedProjectSummary.hasOwnProperty(currentKey))
          updatedPrjctSmmry[currentKey] = modifiedProjectSummary[currentKey];
      });
      state.projectSummary = updatedPrjctSmmry;
    },
    // ***************************************** PM invoices *****************************************
    setPMInvoices(state, action) {
      const { InvoicesData, projectID } = action.payload;
      /* In the PM invoice page, determine whether the invoices are in place and whether they are the invoices of this project. */
      if (InvoicesData.length === 0)
        InvoicesData.push({
          project: {
            id: projectID,
          },
          empty_message: "empty_invoices",
        });
      state.PMInvoices = InvoicesData;
    },

    addInvoice(state, action) {
      state.PMInvoices = state.PMInvoices.filter(
        (el) => !el.hasOwnProperty("empty_message")
      );
      state.PMInvoices.push(action.payload.newInvoice);
    },

    updateInvoice(state, action) {
      const { updatedInvoice } = action.payload;
      const index = state.PMInvoices.findIndex(
        (invoice) => invoice.id === updatedInvoice.id
      );

      if (index >= 0) {
        state.PMInvoices[index] = updatedInvoice;
      }
    },

    deleteInvoice(state, action) {
      const rowToDelete = state.PMInvoices.findIndex(
        (row) => row.id === action.payload.invoiceID
      );
      if (rowToDelete !== -1) {
        state.PMInvoices.splice(rowToDelete, 1);
      }
      const projectID = action.payload.projectID;

      if (state.PMInvoices.length === 0)
        state.PMInvoices.push({
          project: {
            id: projectID,
          },
          empty_message: "empty_invoices",
        });
    },

    // ***************************************** PM closeout *****************************************
    setProjectHeader(state, action) {
      state.projectHeader = action.payload.projectHeader;
    },
    modifyProjectHeader(state, action) {
      const { modifiedProjectHeader } = action.payload;
      const currentProjectHeader = state.projectHeader;
      let updatedPrjctHdr = JSON.parse(JSON.stringify(currentProjectHeader));
      Object.keys(updatedPrjctHdr).forEach((currentKey) => {
        if (modifiedProjectHeader.hasOwnProperty(currentKey))
          updatedPrjctHdr[currentKey] = modifiedProjectHeader[currentKey];
      });
      state.projectHeader = updatedPrjctHdr;
    },
    setPMCloseoutTrackers(state, action) {
      state.PMCloseoutTrackers = action.payload.PMCloseoutTrackers;
    },
    modifyPMCloseoutTrackers(state, action) {
      const { modifiedPMCloseoutTrackers } = action.payload;
      const currentPMCloseoutTrackers = state.PMCloseoutTrackers;
      const targetObject = currentPMCloseoutTrackers.find(
        (item) =>
          item.closeout_tracker_id ==
          modifiedPMCloseoutTrackers.closeout_tracker_id
      );
      if (targetObject)
        targetObject.comment = modifiedPMCloseoutTrackers.comment;
      state.PMCloseoutTrackers = currentPMCloseoutTrackers;
    },
    modifyPMCloseoutTrackerItem(state, action) {
      const { modifiedPMCloseoutTrackerItem } = action.payload;
      const currentPMCloseoutTrackerItem = state.PMCloseoutTrackers;
      currentPMCloseoutTrackerItem.forEach((item) => {
        const targetItem = item.closeout_tracker_item.find(
          (subItem) =>
            subItem.closeout_tracker_item_id ==
            modifiedPMCloseoutTrackerItem.closeout_tracker_item_id
        );

        if (targetItem) {
          targetItem.pm_choosed = modifiedPMCloseoutTrackerItem.pm_choosed;
        }
      });
      state.PMCloseoutTrackers = currentPMCloseoutTrackerItem;
    },

    setCloseoutInfo(state, action) {
      state.closeoutInfo = action.payload.closeoutInfo;
    },

    // *********************************************** PM subcontract *****************************************
    setPMSubcontracts(state, action) {
      const { SubcontractsData, projectID } = action.payload;
      if (SubcontractsData.length === 0)
        SubcontractsData.push({
          project: {
            id: projectID,
          },
          empty_message: "empty_subcontracts",
        });
      state.PMSubcontracts = SubcontractsData;
    },

    updateSubcontract(state, action) {
      const { updatedSubcontract } = action.payload;
      const index = state.PMSubcontracts.findIndex(
        (subcontract) => subcontract.id === updatedSubcontract.id
      );

      if (index >= 0) {
        state.PMSubcontracts[index] = updatedSubcontract;
      }
    },
    // *********************************************** options *****************************************
    setSubcontractors(state, action) {
      state.subcontractors = action.payload.subcontractors;
    },
  },
});

export const PMActions = PMSlice.actions;

export default PMSlice;
