import React, { useState, useRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import Joi from "joi-browser";
import { useSelector } from "react-redux";
import { updateSumLine } from '../../../../utils/tableUtil';

/*
  input validation
  set error and data
  update Utlization And Sum Line
*/
function StaffingTableFormCell({
  formInfo,
  formSchema,
  initializedData = [],
  doSubmit,
  cellLocatedRow,
  rows,
  setRows
}) {
  const forecastedDays = useSelector((state) => state.Staffing.forecastedDays);
  const availableDays = useSelector((state) => state.Staffing.availableDays);

  const [data, setData] = useState(initializedData);
  const [errors, setErrors] = useState({});

  /* make sure that focus will be automatically in input box */
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const validate = (data, formSchema) => {
    const { error } = Joi.validate(data, formSchema, { abortEarly: false });

    if (!error) return null;

    let newErrors = {};
    for (const singleError of error.details)
      newErrors[singleError.path[0]] = singleError.message;
    return newErrors;
  };

  const validateProperty = (name, value, formSchema) => {
    const { error } = Joi.validate(
      { [name]: value },
      { [name]: formSchema[name] }
    );
    return error ? error.details[0].message : null;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log("StaffingTableFormCell - handleSubmit - data", data);

    const newErrors = validate(data, formSchema);
    setErrors(newErrors || {});
    // console.log("StaffingTableFormCell - handleSubmit - newErrors", newErrors);

    if (newErrors) return;
    doSubmit(data, errors, setData, setErrors);
  };

  const setErrorAndData = (targetName, targetValue) => {
    updateUtlizationAndSumLine(targetName, targetValue)

    const previousErrors = { ...errors };
    const errorMessage = validateProperty(targetName, targetValue, formSchema);
    if (errorMessage) previousErrors[targetName] = errorMessage;
    else delete previousErrors[targetName];
    setErrors(previousErrors);

    const previousData = { ...data };
    previousData[targetName] = targetValue;
    setData(previousData);
  };

  const updateUtlizationAndSumLine = (weekID, modifiedForecastedDays) => {
    let temp_rows = Array.from(rows);
    const newUitlization = calculateUtilization(weekID, modifiedForecastedDays)

    /* update utilization line */
    const utilizationLineIndex = temp_rows.findIndex(element => 'pillar_item' in element.resource);
    temp_rows[utilizationLineIndex] = {
      ...temp_rows[utilizationLineIndex],
      [weekID]: {
        ...temp_rows[utilizationLineIndex][weekID],
        utilization: newUitlization
      }
    };

    /* update cell value */
    const cellLocatedIndex = temp_rows.findIndex(element => element.id == cellLocatedRow.id)
    temp_rows[cellLocatedIndex] = {
      ...temp_rows[cellLocatedIndex],
      [weekID]: {
        ...temp_rows[cellLocatedIndex][weekID],
        value: modifiedForecastedDays
      }
    }

    /* add sum line */
    temp_rows = updateSumLine(temp_rows)
    setRows(temp_rows)
  };

  const calculateUtilization = (weekID, modifiedForecastedDays) => {
    const resource_id = cellLocatedRow.resource.id
    const modified_forecasted_days = parseFloat(modifiedForecastedDays) || 0

    const available_days_this_resource = availableDays.find(e => e.resource && e.resource.id === resource_id)[weekID].value
    const working_days_this_week = availableDays.find(e => e.resource && e.resource.id === resource_id)[weekID].working_days

    let sum_other_forecasted_days = 0;
    for (const item of forecastedDays) {
      if (item.resource && item.project && item.resource.id === resource_id && item.project.id !== cellLocatedRow.project.id) {
        if (weekID in item) {
          sum_other_forecasted_days += parseFloat(item[weekID].value);
        }
      }
    }

    const new_uitlization = (working_days_this_week - available_days_this_resource + sum_other_forecasted_days + modified_forecasted_days) / working_days_this_week
    return new_uitlization
  }

  return (
    <Form onBlur={handleSubmit} onSubmit={event => event.preventDefault()}>
      {(formInfo.type === "text") && (
        <>
          <Form.Control
            ref={inputRef}
            onChange={({ currentTarget: formUnit }) => setErrorAndData(formUnit.name, formUnit.value)}
            name={formInfo.id}
            value={data[formInfo.id]}
            type={formInfo.type}
            disabled={formInfo.disabled || false}
          />
          {errors[formInfo.id] && (
            <div size="sm" className="alert alert-primary">
              {errors[formInfo.id]}
            </div>
          )}
        </>
      )}
    </Form>
  );
}

export default StaffingTableFormCell;
