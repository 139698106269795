import { toast } from "react-toastify";

import http from "../services/httpService";
import {
  getPrepareNewProjectAPI,
  adminInvoicesAPI,
  listInvoicesAPI,
  adminSubcontractAPI,
  listSubcontractAPI,
  updateSubcontractAPI,
  updateProjectMasterAPI,
  adminListProjectMastersAPI,
  listClientAPI,
  listSubcontractorAPI,
} from "../services/backendURLService";
import { adminActions } from "./admin-slice";
import { updateInvoiceAPI } from "../services/backendURLService";
import { processAPIData } from "../utils/publicUtil";

// *************************************************** project  **********************************************
export const getProjectsForAdminDropdown = () => {
  return async (dispatch) => {
    try {
      const { data: projectMasters } = await http.get(
        `${adminListProjectMastersAPI}`
      );
      var processedData;
      if (projectMasters.length > 0) {
        processedData = projectMasters.reduce((array, item, index) => {
          array[index] = processAPIData(item, ["name"]);
          return array;
        }, []);
      } else {
        processedData = projectMasters;
      }
      dispatch(
        adminActions.setProjectsForAdminDropdown({
          projectsForAdminDropdown: projectMasters,
        })
      );
    } catch (error) {
      toast.error(
        "Getting projects for Admin dropdown list has something wrong!"
      );
    }
  };
};
export const patchAdminProjectMaster = (projectID, data) => {
  return async (dispatch) => {
    try {
      // console.log("admin-actions - patchAdminProjectMaster - data", data);
      await http.patch(`${updateProjectMasterAPI(projectID)}`, data);

      dispatch(
        adminActions.updateProjectName({
          projectNewName: data.name,
          projectID: projectID,
        })
      );
    } catch (error) {
      toast.error("Patch project master in admin has something wrong!");
      console.log(error)
    }
  };
};
export const getPrepareNewProject = () => {
  return async (dispatch) => {
    try {
      const { data: rawPrprNwPrjct } = await http.get(
        `${getPrepareNewProjectAPI}`
      );
      const organizedPrprNwPrjct = {
        business_units: rawPrprNwPrjct.business_units.map((item) => ({
          business_unit_item_id: item.business_unit_item_id,
          id: item.business_unit_item_id,
          value: item.business_unit_title,
          label: item.business_unit_title,
        })),
        pillars: rawPrprNwPrjct.pillars.map((item) => ({
          id: item.pillar_item_id,
          value: item.pillar_item_id,
          label: item.pillar_title,
          business_unit_item_id: item.business_unit_item_id,
        })),
        // managers: rawPrprNwPrjct.resources.map((item) => ({
        //   id: item.id,
        //   value: item.title,
        //   label: item.title,
        // })),
      };
      dispatch(
        adminActions.setPrepareNewProject({
          prepareNewProject: organizedPrprNwPrjct,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
};
// **************************************************** invoices *********************************************
export const getAdminInvoices = () => {
  return async (dispatch) => {
    try {
      const { data: invoices } = await http.get(`${adminInvoicesAPI}`);
      var processedData;
      if (invoices.length > 0) {
        processedData = invoices.reduce((array, item, index) => {
          array[index] = processAPIData(item, ["title"]);
          return array;
        }, []);
      } else {
        processedData = invoices;
      }
      dispatch(
        adminActions.setAdminInvoices({
          AdminInvoices: processedData,
        })
      );
    } catch (error) {
      toast.error("Getting Admin invoices has something wrong!");
    }
  };
};

export const updateAdminInvoice = ({ updatedInfo, originalRowInfo }) => {
  return async (dispatch) => {
    try {
      let processedUpdatedInfo = { ...updatedInfo };
      for (let key in processedUpdatedInfo) {
        if (
          typeof processedUpdatedInfo[key] === "object" &&
          processedUpdatedInfo[key] !== null &&
          "id" in processedUpdatedInfo[key]
        ) {
          processedUpdatedInfo[key] = processedUpdatedInfo[key].id;
          break;
        }
      }

      const { data: invoices } = await http.patch(
        `${updateInvoiceAPI(
          originalRowInfo.projectID,
          originalRowInfo.invoiceID
        )}`,
        processedUpdatedInfo
      );

      let processedData = processAPIData(invoices, ["title"]);

      dispatch(
        adminActions.updateAdminInvoice({ updatedInvoice: processedData })
      );
    } catch (error) {
      toast.error("Updating invoice has something wrong!");
    }
  };
};

export const deleteAdminInvoice = ({ invoiceID, projectID }) => {
  return async (dispatch) => {
    try {
      dispatch(adminActions.deleteInvoice({ invoiceID, projectID }));
      const response = await http.delete(
        `${updateInvoiceAPI(projectID, invoiceID)}`
      );
    } catch (error) {
      toast.error("Delete invoice has something wrong!");
    }
  };
};
export const addAdminInvoice = ({ projectID, newInvoice }) => {
  return async (dispatch) => {
    try {
      const { data: new_invoice } = await http.toastPost(
        `${listInvoicesAPI(projectID)}`,
        newInvoice
      );

      dispatch(
        adminActions.addInvoice({
          newInvoice: processAPIData(new_invoice, ["title"]),
        })
      );
    } catch (error) {
      toast.error("Adding invoice has something wrong!");
    }
  };
};
// *************************************************** subcontract *******************************************
export const getAdminSubcontracts = () => {
  return async (dispatch) => {
    try {
      const { data: subcontracts } = await http.get(`${adminSubcontractAPI}`);
      var processedData;
      if (subcontracts.length > 0) {
        processedData = subcontracts.reduce((array, item, index) => {
          array[index] = processAPIData(item, ["title"]); // you can use other keys based on your needs
          return array;
        }, []);
      } else {
        processedData = subcontracts;
      }
      dispatch(
        adminActions.setAdminSubcontracts({
          AdminSubcontracts: processedData,
        })
      );
    } catch (error) {
      toast.error("Getting Admin subcontracts has something wrong!");
    }
  };
};
export const addAdminSubcontract = ({ projectID }) => {
  return async (dispatch) => {
    try {
      const response = await http.post(`${listSubcontractAPI(projectID)}`);
      dispatch(adminActions.addAdminSubcontract(response.data));
    } catch (error) {
      toast.error("Adding subcontract has something wrong!");
    }
  };
};
export const updateAdminSubcontract = ({ updatedInfo, originalRowInfo }) => {
  return async (dispatch) => {
    try {
      let processedUpdatedInfo = { ...updatedInfo };
      for (let key in processedUpdatedInfo) {
        if (
          typeof processedUpdatedInfo[key] === "object" &&
          processedUpdatedInfo[key] !== null &&
          "id" in processedUpdatedInfo[key]
        ) {
          processedUpdatedInfo[key] = processedUpdatedInfo[key].id;
          break;
        }
      }

      const { data: subcontracts } = await http.patch(
        `${updateSubcontractAPI(
          originalRowInfo.projectID,
          originalRowInfo.subcontractID
        )}`,
        processedUpdatedInfo
      );

      let processedData = processAPIData(subcontracts, ["title"]);

      dispatch(
        adminActions.updateAdminSubcontract({
          updatedSubcontract: processedData,
        })
      );
    } catch (error) {
      toast.error("Updating subcontract has something wrong!");
    }
  };
};
export const deleteAdminSubcontract = ({ subcontractID, projectID }) => {
  return async (dispatch) => {
    try {
      dispatch(
        adminActions.deleteAdminSubcontract({ subcontractID, projectID })
      );
      const response = await http.delete(
        `${updateSubcontractAPI(projectID, subcontractID)}`
      );
    } catch (error) {
      toast.error("Delete subcontract has something wrong!");
    }
  };
};
// *************************************************** options *******************************************
export const getAdminClients = (officeID) => {
  return async (dispatch) => {
    try {
      const { data: clients } = await http.get(`${listClientAPI(officeID)}`);
      const orgnizedClients = clients.map((client) => ({
        id: client.id,
        label: client.name,
        value: client.name,
      }));

      dispatch(
        adminActions.setClients({
          clients: orgnizedClients,
        })
      );
    } catch (error) {
      toast.error("Getting Admin clients has something wrong!");
    }
  };
};
export const getAdminSubcontractors = (officeID) => {
  return async (dispatch) => {
    try {
      const { data: subcontractors } = await http.get(
        `${listSubcontractorAPI(officeID)}`
      );
      console.log(
        "admin-actions - getSubcontractors - subcontractors",
        subcontractors
      );

      const orgnizedSubcontractors = subcontractors.map((subcontractor) => ({
        id: subcontractor.id,
        label: subcontractor.title,
        value: subcontractor.title,
      }));

      dispatch(
        adminActions.setSubcontractors({
          subcontractors: orgnizedSubcontractors,
        })
      );
    } catch (error) {
      toast.error("Getting Admin subcontractors has something wrong!");
    }
  };
};
