import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FaArrowUp, FaArrowDown, FaPlus, FaArrowRight } from "react-icons/fa";

import { updateAdminInvoice, addAdminInvoice } from '../../../../store/admin-actions'
import { generateOptions, processUpdateInvoice } from "../../../../utils/publicUtil";
import { invoiceStatus } from "../../../../services/constService";
import FrameModal from "../../../../components/common/FrameModal";
import InvoiceFileModal from "../../../../components/pages/InvoiceFileModal";
import CustomTable from "../../../../components/custom/Table/CustomTable";
import CustomForm from "../../../../components/common/CustomForm";
import AddInvoiceLineItemDetail from '../../../../components/pages/AddInvoiceLineItemDetail';

const ICCAdminCreateInvoices = ({ createInvoices }) => {
  const dispatch = useDispatch();
  const [createNewInvoiceShow, setCreateNewInvoiceShow] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedInvoiceID, setSelectedInvoiceID] = useState();
  const [selectedProjectID, setSelectedProjectID] = useState();
  const [showAddLineItemDetail, setShowAddLineItemDetail] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const allInvoiceTypesOptions = useSelector((state) => state.generic.LKUs.LKUInvoiceType)

  const columns = [
    { path: "project.name", label: "Project name" },
    {
      path: "project.project_detail.invoice_structure.value",
      label: "Payment structure",
    },
    { path: "concept", label: "Concept" },
    {
      path: "invoice_number",
      label: "Invoice ID",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "invoice_number",
          id: "invoice_number",
          type: "text",
        },
        schema: {
          invoice_number: Joi.string().required().label("Invoice ID"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    { path: "type.value", label: "Invoice Type" },
    {
      path: "invoice_date",
      label: "Date on Invoice",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "invoice_date",
          id: "invoice_date",
          type: "date",
        },
        schema: {
          invoice_date: Joi.date().required().label("Date on Invoice"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    {
      path: "created_date",
      label: "Date created",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "created_date",
          id: "created_date",
          type: "date",
        },
        schema: {
          created_date: Joi.date()
            .required()
            .label("Date created"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    {
      path: "amount_actual",
      label: "Amount",
      displayType: 'currency_amount',
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "amount_actual",
          id: "amount_actual",
          type: "text",
        },
        schema: {
          amount_actual: Joi.number().required().label("Amount"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    { path: "project.project_header.currency.value", label: "Currency" },
    {
      path: "amount_local",
      label: "Local Amount",
      displayType: 'currency_amount',
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "amount_local",
          id: "amount_local",
          type: "text",
        },
        schema: {
          amount_local: Joi.number().required().label("Local Amount"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    { path: "project.project_header.manager.value", label: "PM" },
    {
      path: "notes",
      label: "Communication Between PM and Admin",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "notes",
          id: "notes",
          type: "text",
        },
        schema: {
          notes: Joi.string().required().label("notes"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
  ];

  const buttonGroup = {
    upper_left: [
      {
        type: "primary",
        buttonText: (
          <Fragment>
            <FaArrowDown style={{ marginRight: "5px" }} />
            Reject and Send Back to PM
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice({
            originalRowInfo: {
              invoiceID: selectedRow.id,
              projectID: selectedRow.project.id,
            },
            updatedInfo: {
              id: selectedRow.id,
              status: invoiceStatus.REQUEST,
            },
          }))
        },
        id: 4,
      },
      {
        type: "primary",
        buttonText: (
          <Fragment>
            <FaArrowUp style={{ marginRight: "5px" }} />
            Created and Send to PM
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice({
            originalRowInfo: {
              invoiceID: selectedRow.id,
              projectID: selectedRow.project.id,
            },
            updatedInfo: {
              id: selectedRow.id,
              status: invoiceStatus.APPROVE,
            },
          }))
        },
        id: 0,
      },
      {
        type: "primary",
        buttonText: (
          <Fragment>
            <FaPlus style={{ marginRight: "5px" }} />
            Create New Invoice
          </Fragment>
        ),
        handleClick: () => {
          setCreateNewInvoiceShow(true);
        },
        id: 1
      },
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Attach Invoice PDF
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select invoice!");
          } else {
            setSelectedInvoiceID(selectedRow.id)
            setSelectedProjectID(selectedRow.project.id)
            setShowFileModal(true)
          }
        },
        id: 2,
      },
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FaPlus style={{ marginRight: "5px" }} /> View/Edit Line Item Detail
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select invoice!");
          } else {
            setSelectedInvoice(selectedRow)
            setShowAddLineItemDetail(true)
          }
        },
        id: 3,
      },
      {
        type: "success",
        buttonText: (
          <Fragment>
            <FaArrowRight style={{ marginRight: "5px" }} />
            Push Forward to 'Pending Send'
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice({
            originalRowInfo: {
              invoiceID: selectedRow.id,
              projectID: selectedRow.project.id,
            },
            updatedInfo: {
              id: selectedRow.id,
              status: invoiceStatus.PENDING_SEND,
            },
          }))
        },
        id: 5,
      },
    ],
  };

  const projectNameOptions = generateOptions(
    createInvoices,
    'project.id',
    'project.name',
    'project.name'
  );
  const projectNumberOptions = generateOptions(
    createInvoices,
    'project.id',
    'project.project_number',
    'project.project_number'
  );
  const projectManagerOptions = generateOptions(
    createInvoices,
    'project.project_header.manager.id',
    'project.project_header.manager.value',
    'project.project_header.manager.id'
  );

  ////prepare filter options data end
  const filterGroup = [
    {
      'id': 'Project Name',
      'path': 'project',
      "options": projectNameOptions,
      "label": "Project Name"
    },
    {
      'id': 'Project Number',
      'path': 'project',
      "options": projectNumberOptions,
      "label": "Project Number"
    },
    {
      'id': 'Project Manager',
      'path': 'project.project_header.manager',
      "options": projectManagerOptions,
      "label": "Project Manager"
    },
  ]

  ////Add new invoice module
  const projectMasters = useSelector((state) => state.admin.projectsForAdminDropdown);

  const projectsOptions = generateOptions(
    projectMasters,
    'id',
    'name',
    'id',
  );

  const formInfo = [
    {
      label: 'Project: ',
      id: 'project',
      type: 'multiselect',
      isMulti: false,
      options: projectsOptions
    },
    {
      label: 'Invoice Type: ',
      id: 'type',
      type: 'multiselect',
      isMulti: false,
      options: allInvoiceTypesOptions
    }
  ];

  const formStructure = {
    submit_button: { label: 'Submit' }
  };

  const schema = {
    [formInfo[0].id]: Joi.object({ id: Joi.number().required(), value: Joi.number(), label: Joi.string() }).required().label(formInfo[0].label),
    [formInfo[1].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[1].label),
  };

  const doFormSubmit = async (newInvoiceData) => {
    console.log('AdminCreateInvoices - doFormSubmit - newInvoiceData', newInvoiceData)

    dispatch(
      addAdminInvoice({
        projectID: newInvoiceData.project.id,
        newInvoice: {
          concept: newInvoiceData.type.value,
          type: newInvoiceData.type.id,
          status: invoiceStatus.CREATE,
        },
      })
    );
    setCreateNewInvoiceShow(false)
  };

  console.log('AdminCreateInvoices -  - createInvoices', createInvoices)

  return (
    <div className="table-container">
      <h2>Admin Responsibility - review submission, create PDF of invoice, email to PM, and push button to change invoice status</h2>
      <CustomTable
        headerData={columns}
        bodyData={createInvoices}
        buttonGroup={buttonGroup}
        filterGroup={filterGroup}
      />
      <br />
      <FrameModal modalTitle={'Adding new invoice'} show={createNewInvoiceShow} setShow={setCreateNewInvoiceShow} >
        <CustomForm
          formInfo={formInfo}
          formStructure={formStructure}
          doSubmit={doFormSubmit}
          schema={schema} />
      </FrameModal>

      <InvoiceFileModal show={showFileModal} setShow={setShowFileModal} projectID={selectedProjectID} invoiceID={selectedInvoiceID} editableView />
      <AddInvoiceLineItemDetail show={showAddLineItemDetail} setShow={setShowAddLineItemDetail} invoice={selectedInvoice} />
    </div>
  );
}
export default ICCAdminCreateInvoices;