import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Joi from "joi-browser";

import { updateAdminInvoice } from '../../../../store/admin-actions'
import { generateOptions, processUpdateInvoice } from "../../../../utils/publicUtil";
import ICCForecastedInvoicesTable from "./ICCForecastedInvoicesTable"

const ICCAdminForecastedInvoices = ({ forecastedInvoices }) => {
  const dispatch = useDispatch();

  const columns = [
    { path: "project.name", label: "Project name" },
    {
      path: "project.project_detail.invoice_structure.value",
      label: "Payment structure",
    },
    {
      path: "invoice_number",
      label: "Invoice ID",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "invoice_number",
          id: "invoice_number",
          type: "text",
        },
        schema: {
          invoice_number: Joi.string().required().label("Invoice ID"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    { path: "type.value", label: "Invoice Type" },
    {
      path: "invoice_date",
      label: "Estimated date on invoice (yyyy-mm-dd)",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "invoice_date",
          id: "invoice_date",
          type: "date",
        },
        schema: {
          invoice_date: Joi.date()
            .required()
            .label("Estimated Date"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    {
      path: "created_date",
      label: "Date created",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "created_date",
          id: "created_date",
          type: "date",
        },
        schema: {
          created_date: Joi.date()
            .required()
            .label("Date created"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    {
      path: "amount_actual",
      label: "Amount",
      displayType: 'currency_amount',
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "amount_actual",
          id: "amount_actual",
          type: "text",
        },
        schema: {
          amount_actual: Joi.number().required().label("Amount"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    { path: "project.project_header.currency.value", label: "Currency" },
    { path: "project.project_header.manager.value", label: "PM" },
    {
      path: "notes",
      label: "Communication Between PM and Admin",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "notes",
          id: "notes",
          type: "text",
        },
        schema: {
          notes: Joi.string().required().label("notes"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
  ];
  const projectNameOptions = generateOptions(
    forecastedInvoices,
    'project.id',
    'project.name',
    'project.name'
  );
  const projectNumberOptions = generateOptions(
    forecastedInvoices,
    'project.id',
    'project.project_number',
    'project.project_number'
  );
  const projectManagerOptions = generateOptions(
    forecastedInvoices,
    'project.project_header.manager.id',
    'project.project_header.manager.value',
    'project.project_header.manager.id'
  );
  const projectTypeOptions = generateOptions(
    forecastedInvoices,
    'type.id',
    'type.value',
    'type.id'
  );
  ////prepare filter options data end
  const filterGroup = [
    {
      'id': 'Project Name',
      'path': 'project',
      "options": projectNameOptions,
      "label": "Project Name"
    },
    {
      'id': 'Project Number',
      'path': 'project',
      "options": projectNumberOptions,
      "label": "Project Number"
    },
    {
      'id': 'Project Manager',
      'path': 'project.project_header.manager',
      "options": projectManagerOptions,
      "label": "Project Manager"
    },
    {
      'id': 'Project Type',
      'path': 'type',
      "options": projectTypeOptions,
      "label": "Project Type"
    },
  ]

  return (
    <div className="table-container">
      <ICCForecastedInvoicesTable
        headerData={columns}
        bodyData={forecastedInvoices}
        filterGroup={filterGroup}
      />
      <br />
      <br />
    </div>
  );
}
export default ICCAdminForecastedInvoices;