import { configureStore } from "@reduxjs/toolkit";

import genericSlice from "./generic-slice";
import PMSlice from "./PM-slice";
import adminSlice from "./admin-slice";
import StaffingSlice from "./staffing-slice";
import settingsSlice from "./settings-slice";
import COHSlice from "./Coh-slice";
import ICCSlice from "./icc-slice"

const store = configureStore({
  reducer: {
    generic: genericSlice.reducer,
    PM: PMSlice.reducer,
    admin: adminSlice.reducer,
    Staffing: StaffingSlice.reducer,
    settings: settingsSlice.reducer,
    COH: COHSlice.reducer,
    ICC: ICCSlice.reducer,
  },
});

export default store;
