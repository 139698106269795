import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

import { clearStorage } from '../../utils/azureUtil';
import auth from "../../services/authService";

const LogoutWithAzure = () => {
  const { instance } = useMsal();
  useEffect(() => {
    window.location = "/login";
    auth.logout();
    let account = instance.getActiveAccount();
    clearStorage(account);

    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  }, []);
  return null;
};

export default LogoutWithAzure;