import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService'
import { listInvoiceLineItemsAPI } from '../../services/backendURLService'
import Spinner from '../Spinner';
import CustomTable from '../custom/Table/CustomTable';

const ViewInvoiceLineItemDetail = ({ show, setShow, invoice }) => {
    const [loading, setLoading] = useState(true);
    const [lineItems, setLineItems] = useState([])

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            const project_id = invoice.project.id
            const invoice_id = invoice.id
            const { data: line_items } = await http.get(`${listInvoiceLineItemsAPI(project_id, invoice_id)}`);
            setLineItems(line_items)
            setLoading(false)
        };
        if (invoice) fetchData();
    }, [invoice])

    // console.log('ViewInvoiceLineItemDetail - - invoice', invoice)
    console.log('ViewInvoiceLineItemDetail - - lineItems', lineItems)


    const columns = [
        { path: "item", label: "Item" },
        { path: "description", label: "Description" },
        { path: "quantity", label: "Quantity" },
        { path: "rate", label: "Rate" },
        { path: "amount", label: "Amount" },
    ];

    return (
        <Modal show={show} onHide={() => setShow(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>Invoice Line Items</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {invoice && <>
                    <h5 style={{ display: 'inline' }}>Invoice Concept: </h5> {invoice.concept} <br />
                    <h5 style={{ display: 'inline' }}>Invoice Date: </h5> {invoice.invoice_date} <br />
                    <h5 style={{ display: 'inline' }}>Invoice ID: </h5> {invoice.invoice_number} <br />
                    <h5 style={{ display: 'inline' }}>Payment Term: </h5> {invoice.project.project_detail.payment_term?.value || ''} <br />
                    <h5 style={{ display: 'inline' }}>Bill to: </h5> {invoice.project.project_detail.invoice_to_company} <br />
                    <h5 style={{ display: 'inline' }}>Billing Instruction: </h5> {invoice.project.project_detail.invoice_instruction?.value || ''} <br />
                    <h5 style={{ display: 'inline' }}>PO Number: </h5> {invoice.project.project_detail.po_number} <br />
                </>}
                {!loading ?
                    <>
                        {lineItems.length !== 0 ?
                            <>
                                <CustomTable
                                    headerData={columns}
                                    bodyData={lineItems}
                                />
                                <h5 style={{ display: 'inline' }}>Total Amount: </h5> {lineItems.reduce((acc, item) => acc + Number(item.amount), 0)}
                                <br />
                                <h5 style={{ display: 'inline' }}>Invoice Actual Amount: </h5> {invoice.amount_actual}
                            </> :
                            <h3>There is no any line item.</h3>}
                    </> :
                    <div style={{ marginTop: '-100px', marginBottom: '100px' }}><Spinner /></div>}
            </Modal.Body>
        </Modal>
    );
};

export default ViewInvoiceLineItemDetail;