import React from 'react';


function Footer() {
    return (
        <footer className="footer text-center">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        ©2023 MIEBACH CONSULTING
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
