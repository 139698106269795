import React, { Fragment } from "react";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

import CustomTable from "../../components/custom/Table/CustomTable";

const TableTemplate = () => {
    const handleSingleCellSubmit = (data) => {
        // console.log('CustomTable - handleSubmit - data', data)
        toast.success(`Submit rowID: ${data.id} successfully, and the chagned key is: ${Object.keys(data)[0]}, and the changed value is ${Object.values(data)[0]}!`)
    };

    const multiSelectOptions = [
        { id: 0, value: 'zhao', label: 'Zhao' },
        { id: 1, value: 'qian', label: 'Qian' },
        { id: 2, value: 'sun', label: 'Sun' }
    ];

    const positionContent = {
        type: 'inputbox',
        formInfo: {
            type: 'multiselect',
            options: multiSelectOptions,
            id: 'position',
            isMulti: false
        },
        schema: { 'position': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Position') },
        doSubmit: handleSingleCellSubmit
    }

    const dateContent = {
        type: 'inputbox',
        formInfo: {
            id: 'date',
            type: 'date',
        },
        schema: { 'date': Joi.date().required().label('Start Date') },
        doSubmit: handleSingleCellSubmit
    }

    const currencyContent = {
        type: 'inputbox',
        formInfo: {
            id: 'currency',
            type: 'text',
        },
        schema: { 'currency': Joi.string().required().label('currency') },
        doSubmit: handleSingleCellSubmit
    }

    const hourlyRateContent = {
        type: 'inputbox',
        formInfo: {
            id: 'hourly_rate',
            type: 'text',
        },
        schema: { 'hourly_rate': Joi.number().required().label('Hourly Rate') },
        doSubmit: handleSingleCellSubmit
    }

    const headerData = [
        { path: "id", label: "ID" },
        { path: "project_number", label: "Project Number" },
        { path: "position", label: "Project Positon", customContent: positionContent },
        { path: "date", label: "Start Date", customContent: dateContent },
        { path: "currency", label: "Currency", customContent: currencyContent },
        { path: "hourly_rate", label: "Hourly Rate", customContent: hourlyRateContent }
    ]

    const initializedMultiSelectData = { id: 0, value: 'zhao', label: 'Zhao' }

    const bodyData = [
        {
            "id": 1,
            "project_number": "001-23",
            "position": initializedMultiSelectData,
            "date": "2023-06-01",
            "currency": 'CAD',
            "hourly_rate": 231
        }, {
            "id": 2,
            "project_number": "002-23",
            "position": initializedMultiSelectData,
            "date": "2023-05-31",
            "currency": 'CAD',
            "hourly_rate": 300
        }, {
            "id": 3,
            "project_number": "003-23",
            "position": initializedMultiSelectData,
            "date": "2023-06-01",
            "currency": 'CAD',
            "hourly_rate": 350
        }, {
            "id": 4,
            "project_number": "004-23",
            "position": 'manager',
            "date": "2023-06-01",
            "currency": 'CAD',
            "hourly_rate": 350
        }, {
            "id": 5,
            "project_number": "005-23",
            "position": 'manager',
            "date": "2023-06-01",
            "currency": 'CAD',
            "hourly_rate": 350
        },
        {
            "id": 6,
            "project_number": "006-23",
            "position": 'manager',
            "date": "2023-06-01",
            "currency": 'CAD',
            "hourly_rate": 350
        },
        {
            "id": 7,
            "project_number": "007-23",
            "position": 'manager',
            "date": "2023-06-01",
            "currency": 'CAD',
            "hourly_rate": 350
        }, {
            "id": 8,
            "project_number": "008-23",
            "position": 'manager',
            "date": "2023-06-01",
            "currency": 'CAD',
            "hourly_rate": 350
        }, {
            "id": 9,
            "project_number": "009-23",
            "position": 'manager',
            "date": "2023-06-01",
            "currency": 'CAD',
            "hourly_rate": 350
        }, {
            "id": 10,
            "project_number": "010-23",
            "position": 'manager',
            "date": "2023-06-01",
            "currency": 'CAD',
            "hourly_rate": 350
        },
        {
            "id": 11,
            "project_number": "011-23",
            "position": 'manager',
            "date": "2023-06-01",
            "currency": 'CAD',
            "hourly_rate": 350
        }
    ]

    const handleClickTestButton = (selectedRowID) => {
        if (selectedRowID === "") toast.warning("Please select a row first!");
        else toast.success(`Successfully selected ID-${selectedRowID} row!`);
    };

    const buttonGroup = {
        upper_left: [
            {
                type: "outline-primary",
                buttonText: <Fragment>
                    <FontAwesomeIcon icon={faArrowsRotate} /> Try To Click This Button
                </Fragment>,
                handleClick: handleClickTestButton,
                id: 0,
            },
            {
                type: "outline-primary",
                buttonText: `Do not click no function 1`,
                handleClick: () => { },
                id: 1,
            },
            {
                type: "outline-primary",
                buttonText: `Do not click no function 2`,
                handleClick: () => { },
                id: 2,
            },
            {
                type: "outline-primary",
                buttonText: `Do not click no function 3`,
                handleClick: () => { },
                id: 3,
            },
        ],
        lower_left: ["Go Back Button"],
        lower_right: [
            {
                type: "outline-primary",
                buttonText: (
                    <Fragment>
                        <FontAwesomeIcon icon={faArrowRight} /> Go To Another Page
                    </Fragment>
                ),
                handleClick: () => { },
                id: 4,
            },
        ],
    };

    const filterGroup = [
        {
            'id': 'position',
            "options": multiSelectOptions,
            "label": "Postion",
            'path': 'position',
        }
    ]

    return (
        <CustomTable
            headerData={headerData}
            bodyData={bodyData}
            buttonGroup={buttonGroup}
            filterGroup={filterGroup}
        />
    )
};

export default TableTemplate;