import React, { useState } from 'react';
import { Row, Form, Col, Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import Select from 'react-select'
import { generateOptions } from '../../../../utils/publicUtil';

const NewLocalOfficeinNewProject = ({ index, doSubmit, removeOffice }) => {
    const officeObjects = useSelector((state) => state.generic.allOffices);
    const allPostitionsOptions = useSelector((state) => state.generic.LKUs.LKUPosition)

    const formatOfficeObject = (listOfOffices) => {
        const formatedOffices = [];
        listOfOffices.forEach(office =>{
            if (office.head_office === null || office.head_office === undefined){
                const newFormatedOfficeObject = {
                    id: office.head_office,
                    label: office.title,
                    value: office.title
                }
                formatedOffices.push(newFormatedOfficeObject)
            }else{
                const newFormatedOfficeObject = {
                    id: office.id,
                    label: office.title,
                    value: office.title
                }
                formatedOffices.push(newFormatedOfficeObject)
            }
                
        })
        return formatedOffices;
    }
    const officeOptions = formatOfficeObject(officeObjects)


    const [selectedValues, setSelectedValues] = useState({
        office: null,
        budget: null,
        leadingOrExternal: null
    });

    const handleChange = (name, selectedOption) => {
        setSelectedValues((prevSelectedValues) => ({
            ...prevSelectedValues,
            [name]: selectedOption
        }));
    };
    const handleChangeINT = (fieldName, value) => {
        const intValue = parseInt(value, 10);
        if (!isNaN(intValue)) {
            // Update state with the integer value
            setSelectedValues(prevState => ({
                ...prevState,
                [fieldName]: intValue
            }));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const newLocalOffice = {
            'office': selectedValues.office ? selectedValues.office.id : null,
            'budget': selectedValues.budget ? selectedValues.budget : null,
            'leadingOrExternal': selectedValues.leadingOrExternal ? selectedValues.leadingOrExternal : null
        };

        doSubmit({
            'index': index,
            'newLocalOffice': newLocalOffice
        });
    };
    
        const contractedOffice = {
            id: 1,
            label: 'Contracted Office',
            value: 'Contracted Office'
        }
        const serviceOfferingOffice = {
            id: 2,
            label: 'Service Offering Office',
            value: 'Service Offering Office'
        }
        const leadingOrExternalOptions = [contractedOffice,serviceOfferingOffice]


    return (
        <>
            <Form onBlur={handleSubmit} className='m-3'>
                <Row>
                    <Col>
                        <Form.Label>Office</Form.Label>
                        <Select
                            options={officeOptions}
                            isMulti={false}
                            isClearable={false}
                            name={`office`}
                            value={selectedValues.office}
                            onChange={(selectedOption) => handleChange('office', selectedOption)}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Budget</Form.Label>
                        <input
                            type={'number'}
                            name={`budget`}
                            value={selectedValues.budget}
                            onChange={(event) => handleChangeINT('budget', event.target.value)}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Contracted/Service Offering</Form.Label>
                        <Select
                            options={leadingOrExternalOptions}
                            isMulti={false}
                            isClearable={false}
                            name={`leadingOrExternal`}
                            value={selectedValues.leadingOrExternal}
                            onChange={(selectedOption) => handleChange('leadingOrExternal', selectedOption)}
                        />
                    </Col>
                    <Col>
                        <Form.Label>&nbsp;</Form.Label>
                        <br />
                        <Button variant='outline-danger' onClick={() => removeOffice(index)} >&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp;</Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default NewLocalOfficeinNewProject;


//TODO:
// - Add function that takes all bugets for every office line and sums them to see if it =<external_fees_subcontract
// - change the do submit to fix any bugs