import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";

import { updateAdminInvoice, deleteAdminInvoice } from '../../../store/admin-actions'
import { generateOptions, processUpdateInvoice } from "../../../utils/publicUtil";
import CustomTable from "../../../components/custom/Table/CustomTable";

const AdminAllInvoices = ({ AdminInvoices }) => {
  const dispatch = useDispatch();
  const LKUInvoiceStatus = useSelector((state) => state.generic.LKUs.LKUInvoiceStatus);


  // console.log('AdminAllInvoices - - LKUInvoiceStatus', LKUInvoiceStatus)
  // console.log('AdminAllInvoices - - AdminInvoices', AdminInvoices)

  const columns = [
    { path: "project.project_number", label: "Project number" },
    { path: "invoice_number", label: "Invoice ID" },
    { path: "type.value", label: "Invoice Type" },
    {
      path: "status", label: "Invoice Status",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          type: 'multiselect',
          label: "Invoice Status",
          id: "status",
          options: LKUInvoiceStatus,
          isMulti: false,
        },
        schema: {
          'status': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Invoice Status')
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    { path: "invoice_timestamp[0].date", label: "Requested date" },
    { path: "invoice_date", label: "Date on Invoice" },
    { path: "created_date", label: "Date created" },
    { path: "sent_date", label: "Date sent" },
    { path: "due_date", label: "Date due" },
    { path: "paid_date", label: "Date paid" },
    { path: "amount_actual", label: "Actual Amount", displayType: 'currency_amount', },
    { path: "amount_estimate", label: "Estimate Amount", displayType: 'currency_amount', },
    {
      path: "amount_local",
      label: "Local Amount",
      displayType: 'currency_amount',
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "amount_local",
          id: "amount_local",
          type: "text",
        },
        schema: {
          amount_local: Joi.number().required().label("Local Amount"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    { path: "project.project_header.status.value", label: "Project Status" },
    { path: "project.project_header.currency.value", label: "Currency" },
    { path: "notes", label: "Communication Between PM and Admin" },
  ];

  const projectNameOptions = generateOptions(
    AdminInvoices,
    'project.id',
    'project.name',
    'project.name'
  );
  const projectNumberOptions = generateOptions(
    AdminInvoices,
    'project.id',
    'project.project_number',
    'project.project_number'
  );
  const projectManagerOptions = generateOptions(
    AdminInvoices,
    'project.project_header.manager.id',
    'project.project_header.manager.value',
    'project.project_header.manager.id'
  );
  const projectTypeOptions = generateOptions(
    AdminInvoices,
    'type.id',
    'type.value',
    'type.id'
  );
  const invoiceStatusOptions = generateOptions(
    AdminInvoices,
    'status.id',
    'status.value',
    'status.id'
  );
  const projectStatusOptions = generateOptions(
    AdminInvoices,
    'project.project_header.status.id',
    'project.project_header.status.value',
    'project.project_header.status.id'
  );
  ////prepare filter options data end

  const filterGroup = [
    {
      'id': 'Project Name',
      'path': 'project',
      "options": projectNameOptions,
      "label": "Project Name"
    },
    {
      'id': 'Project Number',
      'path': 'project',
      "options": projectNumberOptions,
      "label": "Project Number"

    },
    {
      'id': 'Project Manager',
      'path': 'project.project_header.manager',
      "options": projectManagerOptions,
      "label": "Project Manager"
    },
    {
      'id': 'Project Type',
      'path': 'type',
      "options": projectTypeOptions,
      "label": "Project Type"
    },
    {
      'id': 'Invoice Status',
      'path': 'status',
      "options": invoiceStatusOptions,
      "label": "Invoice Status"
    },
    {
      'id': 'Project Status',
      'path': 'project.project_header.status',
      "options": projectStatusOptions,
      "label": "Project Status"
    },
  ]

  const buttonGroup = {
    upper_left: [
      {
        type: "outline-danger",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faDeleteLeft} /> Delete
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(deleteAdminInvoice({
            invoiceID: selectedRow.id,
            projectID: selectedRow.project.id,
          }))
        },
        id: 0,
      }
    ],
  };

  console.log('AdminAllInvoices - - AdminInvoices', AdminInvoices)

  return (
    <div className="table-container">
      <CustomTable
        headerData={columns}
        bodyData={AdminInvoices}
        filterGroup={filterGroup}
        buttonGroup={buttonGroup}
        pageSize={10}
      />
      <br />
      <br />
    </div>
  );
}
export default AdminAllInvoices;