import React, { useState, useEffect } from 'react';
import _ from "lodash";
import moment from 'moment';
import { Button } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import CustomTable from '../../../components/custom/Table/CustomTable';

const ForecastedInvoicesTable = ({ headerData, bodyData, filterGroup }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [clearFilterTrigger, setClearFilterTrigger] = useState([]);

    useEffect(() => {
        if (clearFilterTrigger == true) {
            filterInvoices(new Date());
        }
        else {
            filterInvoices(selectedDate);
        }
    }, [bodyData, selectedDate, clearFilterTrigger]);

    const filterInvoices = (date) => {
        setFilteredInvoices(bodyData.filter(invoice => moment(invoice.invoice_date).isBefore(date)));
    };

    const handleDateChange = (date) => {
        setClearFilterTrigger([]);
        setSelectedDate(date);
        setShowDatePicker(false); // Hide the date picker
        filterInvoices(date);
    };

    return (
        <>
            <div className="table-container">
                <h2>Forecasted Invoices</h2>
                <Button onClick={() => setShowDatePicker(true)} variant="primary">
                    Date Filter
                </Button>
                {showDatePicker && (
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        inline
                    />
                )}
                <CustomTable
                    headerData={headerData}
                    bodyData={filteredInvoices}
                    filterGroup={filterGroup}
                    setClearFilterTrigger={setClearFilterTrigger}
                    pageSize={10}
                />
                <br />
                <br />
            </div>
        </>
    );
};

export default ForecastedInvoicesTable;
