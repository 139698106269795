import React from "react";
import _ from "lodash";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import CustomForm from "../../components/custom/Form/CustomForm";

const FormTemplate = () => {

    const multiSelectDefaultValue = [{ id: 0, value: 'zhao', label: 'Zhao', isFixed: true },
    { id: 1, value: 'qian', label: 'Qian' }]

    const formInfo = [
        {
            label: "Text",
            id: "test_text",
            type: "text",
            formText: 'formText'
        }, {
            label: "Date",
            id: "test_date",
            type: "date"
        }, {
            label: "Email",
            id: "test_email",
            type: "mail"
        }, {
            label: "Password",
            id: "test_password",
            type: "password"
        }, {
            label: "Textarea",
            id: "test_textarea",
            type: "textarea"
        }, {
            label: "Checkbox",
            id: "test_checkbox",
            type: "checkbox"
        }, {
            type: 'multiselect',
            label: 'Multi Select',
            options:
                [{ id: 0, value: 'zhao', label: 'Zhao' },
                { id: 1, value: 'qian', label: 'Qian' },
                { id: 2, value: 'sun', label: 'Sun' }],
            id: 'multi_select',
            isMulti: true
        }
    ];

    const formStructure = {
        submit_button: 'Modify Test Data',
        back_button: true
    };

    const testData = {
        "test_text": "text text text",
        "test_date": '2023-05-31',
        "test_email": 'yip@test.ca',
        'test_password': null || '',
        'test_textarea': 'textarea textarea textarea',
        'test_checkbox': false,
        'multi_select': multiSelectDefaultValue
    }

    const testInitializedData = {
        [formInfo[0].id]: testData[formInfo[0].id], // test_text
        [formInfo[1].id]: testData[formInfo[1].id], // test_date
        [formInfo[2].id]: testData[formInfo[2].id], // test_email
        [formInfo[3].id]: testData[formInfo[3].id], // test_password
        [formInfo[4].id]: testData[formInfo[4].id], // test_textarea
        [formInfo[5].id]: testData[formInfo[5].id], // test_checkbox
        [formInfo[6].id]: testData[formInfo[6].id], // multi_select
    };

    const formSchema = {
        [formInfo[0].id]: Joi.string().required().label(formInfo[0].label),
        [formInfo[1].id]: Joi.date().required().label(formInfo[1].label),
        [formInfo[2].id]: Joi.string().required().email().label(formInfo[2].label),
        [formInfo[3].id]: Joi.string().required().min(8).label(formInfo[3].label),
        [formInfo[4].id]: Joi.string().allow('').label(formInfo[4].label),
        [formInfo[5].id]: Joi.boolean().required().label(formInfo[5].label),
        [formInfo[6].id]: Joi.array().min(1).label(formInfo[6].label),
    };

    const submitData = async (data, errors, setData, setErrors) => {
        // console.log('FormTemplate - doSubmit - data', data)
        // {
        //     "test_text": "text text text",
        //     "test_date": "2023-05-31",
        //     "test_email": "yip@test.ca",
        //     "test_password": "skfl;ks;ldfks",
        //     "test_textarea": "textarea textarea textarea",
        //     "test_checkbox": false,
        //     "multi_select": [
        //         {
        //             "id": 0,
        //             "value": "zhao",
        //             "label": "Zhao",
        //             "isFixed": true
        //         },
        //         {
        //             "id": 1,
        //             "value": "qian",
        //             "label": "Qian"
        //         }
        //     ]
        // }

        try { toast.success("Saved Successfully."); }
        catch (error) { console.log(error); }
    };

    return (
        <CustomForm
            formInfo={formInfo}
            formStructure={formStructure}
            formSchema={formSchema}
            doSubmit={submitData}
            initializedData={testInitializedData}
        />
    );
};

export default FormTemplate;