import React, { useState } from 'react';
import { Table, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

import CustomPagination from './CustomPagination';
import { useEffect } from 'react';
import FormForTableCell from './FormForTableCell';
import GoBackButton from '../GoBackButton';
import TableFilterDropdown from './TableFilterDropdown';
import TableFilterSearchBox from './TableFilterSearchBox'

const CustomTable = ({ headerData, bodyData, buttonGroup = {}, filterGroup, tableTitle, pageSize = 5 }) => {
    const [completeBodyData, setCompleteBodyData] = useState(bodyData);

    {/********************************************* sort ******************************************************/ }
    const [sortColumn, setSortColumn] = useState({
        path: "id",
        order: "asc",
    });
    const handleSort = (path) => {
        if (sortColumn.path === path) {
            const sortOrder = sortColumn.order === "asc" ? "desc" : "asc";
            setSortColumn({ path, order: sortOrder });
        } else setSortColumn({ path, order: "asc" });
    };
    {/********************************************* pagination ******************************************************/ }
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = page => {
        setCurrentPage(page)
    };
    {/********************************************* filter: searchbox ******************************************************/ }
    {/*
        searchQuery data snapshot:
        [{queryPath: 'doh', value:'100', operator: '>='},{queryPath: 'sku', value:'123-xxx-xxxx', operator: '='}]
    */ }
    const [searchQuery, setSearchQuery] = useState([]);
    const handleSearch = (query, queryPath, operator) => {
        let search_query = [...searchQuery]
        if (search_query.filter(el => el.queryPath === queryPath && el.operator === operator).length !== 0) {
            search_query = search_query.filter(el => !(el.queryPath === queryPath && el.operator === operator))
        }
        search_query.push({ 'value': query, 'queryPath': queryPath, 'operator': operator })
        setSearchQuery(search_query)
    };
    {/********************************************* filter: dropdown ******************************************************/ }
    {/*
        selectedGenre data snapshot:
        [{genrePath: 'doh', value:'100'},{genrePath: 'life_cycle', value:'Down'}]
    */ }
    const [selectedGenre, setSelectedGenre] = useState([]);
    const handleGenreSelect = (selectedValue, genrePath) => {
        let selected_genre = [...selectedGenre]

        if (selected_genre.filter(el => el.genrePath === genrePath).length !== 0) {
            selected_genre = selected_genre.filter(el => el.genrePath !== genrePath)
        }
        selected_genre.push({ 'value': selectedValue, 'genrePath': genrePath })
        setSelectedGenre(selected_genre)
    };
    {/****************************************** render and clear filter *****************************************/ }
    const handleClearFilter = () => {
        setFilteredBodyData(completeBodyData)
        setSelectedGenre([]);
        setSearchQuery([]);
        setSelectedRowID([]);
        setCurrentPage(1);
    };
    const renderFilter = (filter) => {
        if (filter.type === 'searchbox')
            return <>
                <TableFilterSearchBox value={searchQuery.filter(element => element.queryPath === filter.id).length !== 0 ? searchQuery.filter(element => element.queryPath === filter.id).value : ''}
                    onChange={(e) => handleSearch(e, filter.id, filter.operator)}
                    label={filter.label} />{' '}
            </>
        if (filter.type === 'dropdown')
            return <>
                <TableFilterDropdown
                    label={filter.label}
                    dropdownList={filter.genres}
                    setSelectedValue={(selectedValue) => handleGenreSelect(selectedValue, filter.id)}
                    selectedValue={selectedGenre.filter(el => el.genrePath === filter.id).length !== 0 ? selectedGenre.filter(el => el.genrePath === filter.id)[0].value : ''}
                    buttonID={filter.id} />{' '}
            </>
        if (filter.type === 'range')
            return <>
                <TableFilterSearchBox value={filter.id === searchQuery.queryPath ? searchQuery.value : ''}
                    onChange={(e) => handleSearch(e, filter.id)}
                    label={`${filter.label} >=`} />{' '}
                <TableFilterSearchBox value={filter.id === searchQuery.queryPath ? searchQuery.value : ''}
                    onChange={(e) => handleSearch(e, filter.id)}
                    label={`${filter.label} <=`} />{' '}
            </>
        if (filter.type === 'multiselect')
            return 'mutiselect'
    }
    {/********************************************* select row ******************************************************/ }
    const [selectedRowID, setSelectedRowID] = useState("");
    const handleClickRow = (rowID) => {
        // console.log('rowID', rowID)
        selectedRowID === rowID ? setSelectedRowID('') : setSelectedRowID(rowID)
    };
    {/********************************************* render header ******************************************************/ }
    const renderSortIcon = (column) => {
        if (column.path) {
            if (sortColumn.path !== column.path) return null;
            if (sortColumn.order === "asc")
                return <FontAwesomeIcon icon={faSortUp} />;
            return <FontAwesomeIcon icon={faSortDown} />;
        }
    };
    {/********************************************* table unit: inputbox ******************************************************/ }
    const [selectedInputIndex, setSelectedInputIndex] = useState([]);
    const handleDoubleClick = (columnPath, rowID) => {
        setSelectedInputIndex([`${columnPath}`, `${rowID}`])
    };
    const handleInputChange = (value) => {
        const columnLable = selectedInputIndex[0]
        const rowID = selectedInputIndex[1]
        const complet_body_data = [...completeBodyData]

        const rowIndex = complet_body_data.findIndex(element => {
            return Number(element.id) == Number(rowID);
        })

        let tempAllRecords = Object.assign([], complet_body_data);

        let tempRec = Object.assign({}, tempAllRecords[rowIndex]);
        tempRec[columnLable] = value
        tempAllRecords[rowIndex] = tempRec;

        // complet_body_data[rowIndex][columnLable]= value 
        complet_body_data[rowIndex] = {
            ...complet_body_data[rowIndex],
            [columnLable]: value
        };
        setCompleteBodyData(tempAllRecords);
        setFilteredBodyData(tempAllRecords)
        setCompleteBodyData(complet_body_data);
        setSelectedInputIndex([])
    };
    const doSubmit = (data, id, doSubmit) => {
        handleInputChange(Object.values(data)[0]);
        // console.log('data in table do submit', data)
        data['id'] = id
        doSubmit(data)
    };
    {/********************************************* render body ******************************************************/ }
    const renderCell = (row, column) => {
        const customContent = column.customContent
        const contentString = _.get(row, column.path)
        if (customContent) {
            if (customContent.type === 'link')
                return <td key={`${column.path}-${row.id}`}>
                    <Link to={contentString.toPath}><h6 style={{ color: 'blue' }}>{contentString.label}</h6></Link>
                </td>
            if (customContent.type === 'inputbox') {
                const cellKey = `${column.path}-${row.id}`
                return <td key={cellKey} onDoubleClick={() => handleDoubleClick(column.path, row.id)}>
                    {selectedInputIndex[0] + '-' + selectedInputIndex[1] === cellKey ? <FormForTableCell
                        formInfo={customContent.formInfo}
                        initializedData={{ [column.path]: contentString }}
                        doSubmit={(data) => doSubmit(data, row.id, customContent.doSubmit)}
                        schema={customContent.schema} /> : contentString}
                </td>
            }
            if (customContent.type === 'boolean') {
                const content_string = customContent ? String(contentString) : 'false'
                return <td key={`${column.path}-${row.id}`}>
                    {content_string}
                </td>;
            }
        }
        if (!customContent)
            return <td key={`${column.path}-${row.id}`}>
                {contentString}
            </td>;
    };
    {/********************************************* prepare data ****************************************************/ }
    const [filteredBodyData, setFilteredBodyData] = useState(completeBodyData);
    const handleFilterBodyData = () => {
        setCurrentPage(1)
        let filtered = filteredBodyData;
        if (!_.isEmpty(searchQuery)) {
            // console.log('searchQuery', searchQuery)
            searchQuery.map(elementInSearchQuery => {
                if (elementInSearchQuery.operator === '=')
                    filtered = filtered.filter(m =>
                        m[elementInSearchQuery.queryPath].toLowerCase().startsWith(elementInSearchQuery.value.toLowerCase())
                    );
                if (elementInSearchQuery.operator === '>=') {
                    filtered = filtered.filter(m =>
                        m[elementInSearchQuery.queryPath] >= elementInSearchQuery.value
                    );
                }
                if (elementInSearchQuery.operator === '<=') {
                    filtered = filtered.filter(m =>
                        m[elementInSearchQuery.queryPath] <= elementInSearchQuery.value
                    );
                }
            })
        }

        if (!_.isEmpty(selectedGenre)) {
            filtered = filtered.filter(oneRowData => {
                let boolean_array = []
                boolean_array = selectedGenre.reduce(
                    (array, elementInSelectedGenre) => {
                        const oneGenreUnit = oneRowData[elementInSelectedGenre.genrePath]
                        let boolean_value = false
                        if (typeof oneGenreUnit === 'string') {
                            boolean_value = oneGenreUnit === elementInSelectedGenre.value
                        }
                        else if (typeof oneGenreUnit === 'object') {
                            boolean_value = oneGenreUnit.label === elementInSelectedGenre.value
                        }
                        return array.concat(boolean_value)
                    }
                    , []
                )
                return boolean_array.every(v => v === true)
            })
        }
        setFilteredBodyData(filtered)
    };

    const sorted = _.orderBy(filteredBodyData, [sortColumn.path], [sortColumn.order]);

    const itemsDisplayed = _(sorted)
        .slice((currentPage - 1) * pageSize)
        .take(pageSize)
        .value();

    const totalCount = filteredBodyData.length

    useEffect(() => {
        setCompleteBodyData(bodyData)
        setFilteredBodyData(bodyData)
    }, [bodyData]);

    // if (currentPage > Math.ceil(totalCount / pageSize)) setCurrentPage(currentPage - 1)

    return (
        <div>
            <Row>
                <Col>
                    {tableTitle && <h3>{tableTitle}</h3>}
                </Col>
            </Row>
            <Row>
                <Col>
                    {buttonGroup.hasOwnProperty('upper_right') && <>
                        {buttonGroup.upper_right.map((button) => (
                            <React.Fragment key={`fragment-${tableTitle}-${button.id}`}>
                                <Button
                                    className="m-2"
                                    variant={button.type}
                                    onClick={() => button.handleClick(selectedRowID)}
                                    key={`button-${tableTitle}-${button.buttonText}`}
                                >
                                    {button.buttonText}
                                </Button>
                            </React.Fragment>
                        ))}
                    </>}
                </Col>
            </Row>
            <Row>
                {filterGroup && <Col>
                    {/* <hr style={{ height: '3px', borderWidth: 0, color: 'black', backgroundColor: 'black' }} /> */}
                    {filterGroup.map((filter) => (
                        <React.Fragment key={`${tableTitle}-${filter.label}`}>
                            {renderFilter(filter)}
                        </React.Fragment>
                    ))}
                    <br />
                    <Button variant='outline-primary' className='m-3' onClick={handleFilterBodyData}>Start Filter</Button>
                    <Button variant='outline-primary' className='m-3' onClick={handleClearFilter}>Clear Filter</Button>
                </Col>}
            </Row>
            <Row>
                {/* <hr style={{ height: '3px', borderWidth: 0, color: 'black', backgroundColor: 'black' }} /> */}
                <Table className="table" bordered hover>
                    <thead>
                        <tr>
                            {headerData.map((column) => (
                                <th
                                    style={{ cursor: "pointer" }}
                                    className="clickable modal-title"
                                    key={column.path}
                                    onClick={() => handleSort(column.path)}
                                >
                                    {column.label} {renderSortIcon(column)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {itemsDisplayed.map((row) => (
                            <tr key={row.id} className='modal-title' onClick={() => handleClickRow(row.id)} style={
                                selectedRowID === row.id
                                    ? { background: '#f4f4f4' }
                                    : { background: 'white' }
                            } >
                                {headerData.map((column) => (
                                    <React.Fragment key={`${column.path}-${row.id}`}>
                                        {renderCell(row, column)}
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
            <Row>
                <CustomPagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </Row>
            <Row>
                <Col>
                    {buttonGroup.hasOwnProperty('lower_left') && <GoBackButton />}
                </Col>
                <Col>
                    {buttonGroup.hasOwnProperty('lower_right') && <Col>
                        {buttonGroup.lower_right.map((button) => (
                            <React.Fragment key={`fragment-${tableTitle}-${button.id}`}>
                                <Button
                                    className="m-2 pull-right"
                                    variant={button.type}
                                    onClick={() => button.handleClick(selectedRowID)}
                                    key={`button-${tableTitle}-${button.buttonText}`}
                                >
                                    {button.buttonText}
                                </Button>
                            </React.Fragment>
                        ))}
                    </Col>}
                </Col>
            </Row>
        </div >
    );
};

export default CustomTable;