import React, { useState, useEffect } from 'react';
import _ from "lodash";
import { Table } from "react-bootstrap";
import { connect } from 'react-redux';

import { applyFilter, applySort } from '../../../../utils/tableUtil';
import StaffingTableBody from './StaffingTableBody';
import TableFilter from './../../../../components/custom/Table/TableFilter';
import TableHeader from './../../../../components/custom/Table/TableHeader';
import { staffingActions } from '../../../../store/staffing-slice'

/* 
    complete body data
    filter
    sort
*/
const StaffingTable = ({ headerData, bodyData, filterGroup = [], selectedGenre, setSelectedGenre }) => {
    const [completeBodyData, setCompleteBodyData] = useState(bodyData);
    const [sortColumn, setSortColumn] = useState({ path: "id", order: "asc" });

    useEffect(() => {
        setCompleteBodyData(bodyData)
    }, [bodyData]);

    let filteredBodyData = completeBodyData;
    if (!_.isEmpty(selectedGenre)) filteredBodyData = applyFilter(selectedGenre, completeBodyData)
    const sorted = applySort(filteredBodyData, sortColumn)
    let itemsDisplayed = _(sorted).value();
    const totalCount = filteredBodyData.length

    // console.log("StaffingTable - - itemsDisplayed", itemsDisplayed)

    return (
        <>
            <h3>Project Days Per Week</h3>
            <TableFilter filterGroup={filterGroup} completeBodyData={completeBodyData} selectedGenre={selectedGenre} setSelectedGenre={setSelectedGenre} />

            {totalCount === 0 ? <h3>There is currently no data.</h3> :
                <Table bordered hover>
                    <TableHeader headerData={headerData} sortColumn={sortColumn} setSortColumn={setSortColumn} />
                    <StaffingTableBody itemsDisplayed={itemsDisplayed} headerData={headerData} />
                </Table>
            }
        </>
    );
};

// [{ resource: [{id: 1, label: 'Ching Yip', value: 'Ching Yip'}]},{ project: ... }]

const mapStateToProps = (state) => {
    return {
        selectedGenre: state.Staffing.staffingSelectedGenre,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedGenre: (newGenre) => dispatch(staffingActions.setStaffingSelectedGenre({
            selectedGenre: newGenre,
        })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffingTable);