import React, { useEffect, useState } from 'react';
import Joi from "joi-browser";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash'

import { getProjectDetail, patchProjectDetail } from '../../../store/PM-actions'

import CustomForm from "../../../components/common/CustomForm";
import Spinner from '../../../components/Spinner';


const CommercialDetails = ({ projectID }) => {
    const dispatch = useDispatch();
    const LKUInvoiceInstructions = useSelector((state) => state.generic.LKUs.LKUInvoiceInstructions);
    const projectDetail = useSelector((state) => state.PM.projectDetail);
    const iccSwitchState = useSelector((state) => state.ICC.iccSwitchState);

    const formInfo = [
        {
            label: "Invoice Contact",
            id: "invoice_contact",
            type: "text"
        },
        {
            label: "Invoice \"Bill to\" Info",
            id: "invoice_to_company",
            type: "textarea",
        },
        {
            label: "Invoice Instruction",
            id: "invoice_instruction",
            type: "multiselect",
            options: LKUInvoiceInstructions,
            defaultValue: projectDetail.invoice_instruction || '',
            isMulti: false,
        },
        {
            label: "Invoicing Instruction Details (OPTIONAL - e.g. \"Upload to X Platform\")",
            id: "invoice_detail",
            type: "textarea",
        }
    ];

    const schema = {
        [formInfo[0].id]: Joi.string().required().label(formInfo[0].label),
        [formInfo[1].id]: Joi.string().required().label(formInfo[1].label),
        [formInfo[2].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).label(formInfo[2].label),
        [formInfo[3].id]: Joi.string().required().label(formInfo[3].label)
    };

    const doSubmit = (data, errors, setData, setErrors) => {
        // console.log('CommercialDetails doSubmit - 1:', data)
        // data["commercial"] = 'true'
        if(iccSwitchState) {
            dispatch(patchProjectDetail(projectID, data, true, true))
        }
        else {
            dispatch(patchProjectDetail(projectID, data, true));
        }
    };
    const formStructure = {
        submit_button: {
            label: 'Modify Commercial Details'
        }
    };

    const projectDetailInitializedData = {
        [formInfo[0].id]: projectDetail[formInfo[0].id] || '',
        [formInfo[1].id]: projectDetail[formInfo[1].id] || '',
        [formInfo[2].id]: projectDetail[formInfo[2].id] || '',
        [formInfo[3].id]: projectDetail[formInfo[3].id] || '',
    };

    useEffect(() => {
        if (iccSwitchState) { 
            dispatch(getProjectDetail(projectID, true)); 
        }
        else { 
            dispatch(getProjectDetail(projectID)); 
        }
    }, [projectID]);

    const dataIsReady = !_.isEmpty(projectDetail) && projectDetail.project_id == projectID

    return (
        <>
            {dataIsReady ?
                <CustomForm
                    formInfo={formInfo}
                    formStructure={formStructure}
                    doSubmit={doSubmit}
                    schema={schema}
                    initializedData={projectDetailInitializedData} />
                : <Spinner />}
        </>
    );
};

export default CommercialDetails;