import React, { useEffect, useRef } from 'react';
import Joi from "joi-browser";
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash'

import { getProjectHeader, patchProjectHeader } from '../../../store/PM-actions'

import CustomForm from "../../../components/common/CustomForm";
import Spinner from '../../../components/Spinner';
import ProjectMasterDisplayForm from './ProjectMasterDisplayForm';

const ProjectSponsor = ({ projectID }) => {
    const dispatch = useDispatch();
    const projectHeader = useSelector((state) => state.PM.projectHeader);
    const allManagers = useSelector((state) => state.generic.allManagers);
    const iccSwitchState = useSelector((state) => state.ICC.iccSwitchState)

    // console.log("ProjectSponsor -  - projectHeader", projectHeader)
    // console.log("ProjectSponsor -  - allManagers", allManagers)

    const selectedProject = useSelector((state) => state.PM.selectedProject);
    const prevSelectedProject = useRef(selectedProject);
    useEffect(() => {
        if (selectedProject && !_.isEmpty(selectedProject) && selectedProject !== prevSelectedProject.current) {
            if (iccSwitchState) {
                getProjectHeader(projectID, true)
            }
            else {
                dispatch(getProjectHeader(projectID))
            }
        }
    }, [selectedProject]);



    const formInfo = [
        {
            type: 'multiselect',
            label: 'Sponsor',
            options: allManagers,
            defaultValue: projectHeader.sponsor || '',
            id: 'sponsor',
            isMulti: false,
            dependent: false,
        }
    ];
    const formStructure = {
        submit_button: {
            label: 'Modify Project Sponsor'
        }
    };
    const projectHeaderInitializedData = {
        [formInfo[0].id]: projectHeader[formInfo[0].id] || '',
    };
    const schema = {
        [formInfo[0].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[0].label),
    };


    const doSubmit = (data, errors, setData, setErrors) => {
        // console.log('data in do submit', data)
        if(iccSwitchState) {
            dispatch(patchProjectHeader(projectID, data, true));
        }
        else {
            dispatch(patchProjectHeader(projectID, data));
        }
    };

    const dataIsReady = !_.isEmpty(projectHeader) && projectHeader.project == projectID


    return (
        <>
            {dataIsReady ?
                <Row>
                    <Col md={9}>
                        <Row>
                            <Col></Col>
                            <Col md={8}>
                                <CustomForm
                                    formInfo={formInfo}
                                    formStructure={formStructure}
                                    doSubmit={doSubmit}
                                    schema={schema}
                                    initializedData={projectHeaderInitializedData}
                                />
                            </Col>
                            <Col></Col>
                        </Row>

                    </Col>
                    <Col>
                        <ProjectMasterDisplayForm projectID={projectID} />
                    </Col>
                </Row> : <Spinner />}
        </>

    );
};

export default ProjectSponsor;