import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import _ from "lodash"
import { toast } from "react-toastify";
import { Col, Row, Modal,Button } from 'react-bootstrap';
import { useSelector } from "react-redux";

import http from '../../../../services/httpService'
import { allICCProjectAPI} from '../../../../services/backendURLService'
import NewICCProjectForm from "./NewICCProjectForm";
import Spinner from "../../../../components/Spinner";
import { transformTitle } from "../../../../utils/publicUtil";

const ICCNewProject = () => {
    const clients = useSelector((state) => state.admin.clients);
    const LKUCurrencies = useSelector((state) => state.generic.LKUs.LKUCurrencies);
    const officeObjects = useSelector((state) => state.generic.allOffices);
    const iccUserObjects = useSelector((state) => state.ICC.iccUsers);
    const prepareNewProject = useSelector((state) => state.admin.prepareNewProject);
    const [showSuccessCreated, setShowSuccessCreated] = useState(false)
    const [newProjectInfo, setNewProjectInfo] = useState({})

    //Both formatOfficeObject and formatUserObject are used to take the given array and format the object in the array to a readable forum object that has ID, label, value
    const formatOfficeObject = (listOfOffices) => {
        const formatedOffices = [];
        listOfOffices.forEach(office =>{
            if (office.head_office === null || office.head_office === undefined){
                const newFormatedOfficeObject = {
                    id: office.head_office,
                    label: office.title,
                    value: office.title
                }
                formatedOffices.push(newFormatedOfficeObject)
            }else{
                const newFormatedOfficeObject = {
                    id: office.id,
                    label: office.title,
                    value: office.title
                }
                formatedOffices.push(newFormatedOfficeObject)
            }
                
        })
        return formatedOffices;
    }

    const formInfo = [
        {
            type: 'multiselect',
            label: 'ICC Contract Holding Office',
            options: formatOfficeObject(officeObjects),
            id: 'ICC_contract_holding_office',
            isMulti: false,
        },
        {
            label: "Internal Fees",
            id: "icc_internal_fees",
            type: "internal_fees",
        },
        {
            label: "External Fees",
            id: "icc_external_fees",
            type: "external_fees",
        },
        {
            label: "localOffices",
            id: "localOffices",
            type: "localOffices",
        },
        {
            label: 'ICC Offer number (BD assigned number in CRM - starts with year-assigned number of prospect project)',
            id: 'offer_number',
            type: 'text',
        },
        {
            type: 'multiselect',
            label: 'Client',
            options: clients,
            id: 'client',
            isMulti: false,
        },
        {
            label: "Description (only the project name (30 character max), no client name, no number…, avoid abbreviation & symbols)",
            id: "description",
            type: "text",
        },
        {
            type: 'multiselect',
            label: 'Internal Invoicing Currency',
            options: LKUCurrencies,
            id: 'currency',
            isMulti: false,
        },
        {
            label: "Consultant Days",
            id: "duration_in_days",
            type: "text",
        },
        {
            type: 'multiselect',
            label: 'ICC PM',
            options: transformTitle(iccUserObjects),
            id: 'manager',
            isMulti: false,
        },
    ];
    
    const schema = {
        //contract holding office
        [formInfo[0].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[0].label),
        //Internal fees
        //[formInfo[1].id]: Joi.number().required().label(formInfo[1].label),
        //External fees
        //[formInfo[2].id]: Joi.number().required().label(formInfo[2].label),
        //localOffices
        //[formInfo[3].id]: Joi.object({}).required().label(formInfo[3].label),
        //offer number
        [formInfo[4].id]: Joi.string().required().label(formInfo[4].label),
        //Client
        [formInfo[5].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[5].label),
        //Desc
        [formInfo[6].id]: Joi.string().allow('').label(formInfo[6].label),
        //Currency
        [formInfo[7].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[7].label),
        //Duration in days
        [formInfo[8].id]: Joi.number().required().label(formInfo[8].label),
        //ICC PM
        [formInfo[9].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[9].label)
    };
    const formStructure = {
        back_button: true,
        submit_button: { label: 'Save New Project' }
    };

    const organizePostData = (data) => {
        return {
            offer_number: data.offer_number,
            client: data.client.id,
            description: data.description,
            currency: data.currency.id, 
            duration_in_days: parseInt(data.duration_in_days),
            internal_fees: parseInt(data.icc_internal_fees),
            external_fees_subcontract: parseInt(data.icc_external_fees),
            offices: data.localOffices,
            ICC_contract_holding_office: data.ICC_contract_holding_office.id,
            manager: data.manager.id
        };
    };

    const doSubmit = async (data) => {
        const newICCProjectData = organizePostData(data)
        var leadingOfficeID = 0
        var ServiceOfferingOfficesBudgetSum = 0
        newICCProjectData.offices.forEach(office =>{
            if(office.leadingOrExternal === 1){
                leadingOfficeID = office.office
            }
            ServiceOfferingOfficesBudgetSum = ServiceOfferingOfficesBudgetSum + parseFloat(office.budget)
        })
        if(newICCProjectData.ICC_contract_holding_office != leadingOfficeID){
            toast.warning("The ICC Contract Holding Office has to be the same as the Contract Office in local projects");
            return  
        }
        const ContractedAmount = newICCProjectData.internal_fees + newICCProjectData.external_fees_subcontract;
        if(ServiceOfferingOfficesBudgetSum != ContractedAmount){
            toast.warning("The contracted amount (Internal + External) should equal the sum of all local office project's budget");
            return 
        }

        newICCProjectData.pillar_split =  [{
                 "pillar_item": 40,
                 "percent": 1
                }]

        if (data.description.toLowerCase() === "dev test") {
            console.log(newICCProjectData)
            window.history.back();
            toast.success("Project Saved!")
        } else {                   
            try {
                const { data: new_ICCproject } = await http.toastPost(
                    `${allICCProjectAPI}`,
                    newICCProjectData
                );
                console.log('NewProject - doSubmit - new_project', new_ICCproject)
                setNewProjectInfo(new_ICCproject)
            } catch (error) {
                toast.error("Error with iccproject: " + error.response.data);
            }
            finally { setShowSuccessCreated(true) }
        }
    };
    const dataIsReady = !_.isEmpty(prepareNewProject)

    const modalMessage = <>
        <h5 style={{ display: 'inline' }}>Project ID: </h5>{newProjectInfo.id} <br />
        <h5 style={{ display: 'inline' }}>Project Name: </h5>{newProjectInfo.name} <br />
        <h5 style={{ display: 'inline' }}>Offer Number: </h5>{newProjectInfo.offer_number} <br />
        <h5 style={{ display: 'inline' }}>Project Number: </h5>{newProjectInfo.project_number} <br />
        Tip: This information will be sent to your email again. When you close this message interface, you will automatically jump back to the admin view main page.
    </>

    const handleCloseModal = () => {
        setShowSuccessCreated(false)
        window.history.back();
    };

    return (
        <>
            {dataIsReady ?
                <Row>
                    <Col lg={3}></Col>
                    <Col lg={6}>
                        <h2 className="mb-5">New ICC Project Entry Details</h2>
                        <NewICCProjectForm
                            formInfo={formInfo}
                            formStructure={formStructure}
                            doSubmit={doSubmit}
                            schema={schema}
                            pillars={prepareNewProject.pillars}
                        />

                        <Modal show={showSuccessCreated} onHide={handleCloseModal} centered >
                            <Modal.Header><h3>New Created ICC Project Info</h3></Modal.Header>
                            <Modal.Body>{modalMessage}</Modal.Body>
                        </Modal>
                    </Col>
                    <Col lg={3}></Col>
                </Row>
                :
                <Spinner />}
        </>
    )
}
export default ICCNewProject;