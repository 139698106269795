import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash'

import { PMActions } from '../../store/PM-slice'
import { getProjectMaster, getCloseoutInfo, getPMCloseoutTrackers, getProjectDetail, getProjectHeader, getProjectRates, getProjectSummary, getPMInvoices, getPMSubcontracts } from '../../store/PM-actions';
import ProjectsDropdownList from '../../components/pages/ProjectsDropdownList'
// import Spinner from "../../components/Spinner";

const PMProjectsDropdownList = () => {
    const dispatch = useDispatch();
    const projectMasters = useSelector((state) => state.PM.projectsForPMDropdown);
    const selectedProject = useSelector((state) => state.PM.selectedProject);
    const iccSwitchState = useSelector((state)=>state.ICC.iccSwitchState) 

    const prevSelectedProject = useRef(selectedProject);

    useEffect(() => {
        if (selectedProject && !_.isEmpty(selectedProject) && selectedProject !== prevSelectedProject.current) {
            if(!iccSwitchState) {
                dispatch(getProjectMaster(selectedProject.id));
                dispatch(getProjectDetail(selectedProject.id));
                dispatch(getProjectRates(selectedProject.id));
                dispatch(getProjectSummary(selectedProject.id));
                dispatch(getPMInvoices(selectedProject.id));
                dispatch(getProjectHeader(selectedProject.id));
                dispatch(getPMCloseoutTrackers(selectedProject.id));
                dispatch(getCloseoutInfo(selectedProject.id));
                dispatch(getPMSubcontracts(selectedProject.id));
            }
            else{
                dispatch(getProjectMaster(selectedProject.id, true));
                dispatch(getProjectDetail(selectedProject.id, true));
                dispatch(getProjectRates(selectedProject.id));
                dispatch(getProjectSummary(selectedProject.id,true)); 
                dispatch(getPMInvoices(selectedProject.id));
                dispatch(getProjectHeader(selectedProject.id, true)); 
                dispatch(getPMCloseoutTrackers(selectedProject.id, true));
                dispatch(getCloseoutInfo(selectedProject.id, true));
                dispatch(getPMSubcontracts(selectedProject.id));
            }
        }
    }, [selectedProject]);


    // useEffect(() => {
    //     dispatch(PMActions.setSelectedProject({ selectedProject: {} }));
    // }, []);

    const handleSelectProject = (selected_project) => {
        // console.log('PMProjectsDropdownList - handleSelectProject - selected_project', selected_project)
        dispatch(PMActions.setSelectedProject({ selectedProject: selected_project, }));
    };

    return (
        <ProjectsDropdownList allProjects={projectMasters} selectedProject={selectedProject} handleSelectProject={handleSelectProject} />
    );
};

export default PMProjectsDropdownList;