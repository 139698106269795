import React, { useState } from 'react';
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { Container, Row, Col } from 'react-bootstrap';


import http from "../../../services/httpService";
import { latestHoursReport } from "../../../services/backendURLService";
import CustomForm from '../../../components/common/CustomForm';
import CustomTable from '../../../components/common/CustomTable';
import Spinner from '../../../components/Spinner'

const LatestHoursReport = ({ projectID }) => {
    const [LatestHoursReport, setLatestHoursReport] = useState(null);
    const [showLoader, setShowLoading] = useState(false)

    const formInfo = [{}];

    const formStructure = { submit_button: { label: 'Calculate Latest Hours Entry Dates'} };

    const schema = {};

    const doSubmit = async (data, errors, setData, setErrors) => {
        console.log('Latest Hours Report - doSubmit - data', data)
        setShowLoading(true)
        try {
            const { data: latestHours_report } = await http.get(latestHoursReport(projectID));
            console.log(latestHours_report)
            setLatestHoursReport(latestHours_report);
            console.log(latestHoursReport)
            setShowLoading(false)
        } catch (error) {
            console.error("Error fetching latest hours report:", error);
            toast.warning("Project detail info is not complete."); // Toast for 405 status
        }
    };

    const columns = [
        //path is what its called from back end
        { path: "resource", label: "Resource Name" },
        { path: "Last Hours Entry Date", label: "Last Hours Entry Date" }
    ];

    return (


     
       <>
         {/*  Lastest Hours Report */}
        <Container className='latest-report-container'>
        <Row>
                <div>
                    <p>This grid displays the latest dates for which resources on this project have entered hours.</p>
                    <p>Note that they may not have entered hours for this project on the displayed date - the purpose 
                        of this display is moreso to inform you that they have NOT entered hours on this project since the displayed date.</p>
                </div>
            </Row>
            <Row>
                <Col md={8}>
                    <CustomForm
                        formInfo={formInfo}
                        formStructure={formStructure}
                        doSubmit={doSubmit}
                        schema={schema} />
                </Col>
            </Row>
            <Row className='mt-5'>
                {showLoader && <div style={{ marginTop: '-250px', marginLeft: '-250px', padding: '20px' }}><Spinner /></div>}


                {LatestHoursReport && LatestHoursReport.length !== 0 && !showLoader && <CustomTable headerData={columns} bodyData={LatestHoursReport} />}
                {LatestHoursReport && LatestHoursReport.length == 0 && !showLoader && <h3>There is no hours data for this project</h3>}
            </Row>




        </Container></>
    
    
    
    
    
    
    );

};

export default LatestHoursReport;