import React from "react";
import { Card } from "react-bootstrap";

function FrameCard({children }) {
    return (
        <Card className="frane-card-box text-center mb-3">
            <Card.Body className="frane-card-box">
                {children}
            </Card.Body>
        </Card>
    );
}

export default FrameCard;