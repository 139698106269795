import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import Joi from "joi-browser";

import http from '../../services/httpService'
import { listInvoiceLineItemsAPI, updateInvoiceLineItemAPI, fillWithLatestHoursReportAPI } from '../../services/backendURLService'
import Spinner from '../Spinner';
import CustomTable from '../custom/Table/CustomTable';

const AddInvoiceLineItemDetail = ({ show, setShow, invoice }) => {
    const projectID = invoice && invoice.project.id
    const invoiceID = invoice && invoice.id

    const [loading, setLoading] = useState(true);
    const [lineItems, setLineItems] = useState([])

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            const { data: line_items } = await http.get(`${listInvoiceLineItemsAPI(projectID, invoiceID)}`);
            setLineItems(line_items)
            setLoading(false)
        };
        if (invoice) fetchData();
    }, [invoice])

    // console.log('AddInvoiceLineItemDetail - - invoice', invoice)
    // console.log('AddInvoiceLineItemDetail - - lineItems', lineItems)

    const handleAddLineItem = async () => {
        const { data: new_line_item } = await http.post(`${listInvoiceLineItemsAPI(projectID, invoiceID)}`);
        setLineItems(prevState => [...prevState, new_line_item])
    };

    const handleDeleteLineItem = async (selectedLineItem) => {
        if (!selectedLineItem) toast.warning("Please select invoice line item!")
        else {
            const lineItemID = selectedLineItem.id
            try { await http.delete(`${updateInvoiceLineItemAPI(projectID, invoiceID, lineItemID)}`); }
            finally { setLineItems(prevState => prevState.filter(item => item.id != lineItemID)) }
        }
    };

    const handleModifyLineItem = async (selectedLineItem) => {
        // console.log('AddInvoiceLineItemDetail - handleModifyLineItem - selectedLineItem', selectedLineItem)
        const lineItemID = selectedLineItem.rowInfo.id
        const updatedData = selectedLineItem.updatedValue
        try { await http.patch(`${updateInvoiceLineItemAPI(projectID, invoiceID, lineItemID)}`, updatedData); }
        finally {
            setLineItems(prevState => prevState.map(item => {
                if (item.id == lineItemID) {
                    return {
                        ...item,
                        ...updatedData
                    };
                }
                return item;
            }));
        }
    };

    const columns = [
        {
            path: "item", label: "Item", customContent: {
                type: "inputbox",
                formInfo: {
                    label: "item",
                    id: "item",
                    type: "text",
                },
                schema: {
                    item: Joi.string().required().label("item"),
                },
                doSubmit: (selectedRow) => handleModifyLineItem(selectedRow),
            }
        },
        {
            path: "description", label: "Description", customContent: {
                type: "inputbox",
                formInfo: {
                    label: "description",
                    id: "description",
                    type: "text",
                },
                schema: {
                    description: Joi.string().required().label("description"),
                },
                doSubmit: (selectedRow) => handleModifyLineItem(selectedRow),
            }
        },
        {
            path: "quantity", label: "Quantity", customContent: {
                type: "inputbox",
                formInfo: {
                    label: "quantity",
                    id: "quantity",
                    type: "text",
                },
                schema: {
                    quantity: Joi.number().required().label("quantity"),
                },
                doSubmit: (selectedRow) => handleModifyLineItem(selectedRow),
            }
        },
        {
            path: "rate", label: "Rate", customContent: {
                type: "inputbox",
                formInfo: {
                    label: "rate",
                    id: "rate",
                    type: "text",
                },
                schema: {
                    rate: Joi.number().required().label("rate"),
                },
                doSubmit: (selectedRow) => handleModifyLineItem(selectedRow),
            }
        },
        {
            path: "amount", label: "Amount", customContent: {
                type: "inputbox",
                formInfo: {
                    label: "amount",
                    id: "amount",
                    type: "text",
                },
                schema: {
                    amount: Joi.number().required().label("amount"),
                },
                doSubmit: (selectedRow) => handleModifyLineItem(selectedRow),
            }
        },
    ];

    const buttonGroup = {
        upper_left: [
            {
                type: "danger",
                buttonText: 'Delete Line Item',
                handleClick: (selectedRow) => handleDeleteLineItem(selectedRow),
                id: 0,
            },
        ]
    };

    const handlFillWithHoursReport = async () => {
        try {
            setLoading(true)
            const { data } = await http.get(`${fillWithLatestHoursReportAPI(projectID, invoiceID)}`);
            setLineItems(data)
        }
        catch { toast.error("You shouldn't update it in the same day twice!") }
        finally { setLoading(false) }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>Invoice Line Items</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {invoice && <>
                    <h5 style={{ display: 'inline' }}>Invoice Concept: </h5> {invoice.concept} <br />
                    <h5 style={{ display: 'inline' }}>Invoice ID: </h5> {invoice.invoice_number} <br />
                </>}
                {!loading ?
                    <>
                        {lineItems.length !== 0 ?
                            <>
                                <CustomTable
                                    headerData={columns}
                                    bodyData={lineItems}
                                    buttonGroup={buttonGroup}
                                />
                                <h5 style={{ display: 'inline' }}>Line Items Total Amount: </h5> {lineItems.reduce((acc, item) => acc + Number(item.amount), 0)}
                                <br />
                                <h5 style={{ display: 'inline' }}>Invoice Actual Amount: </h5> {invoice.amount_actual}
                            </> :
                            <h3>There is no any line item.</h3>}
                        <Button onClick={handlFillWithHoursReport} className='m-2 pull-right' >Fill With Latest Hours Report</Button>
                        <Button onClick={handleAddLineItem} className='m-2 pull-right' >Add New Line Item</Button>
                    </> :
                    <div style={{ marginTop: '-100px', marginBottom: '100px' }}><Spinner /></div>}
            </Modal.Body>
        </Modal>
    );
};

export default AddInvoiceLineItemDetail;