import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { FaArrowUp } from "react-icons/fa";

import { updatePMInvoice, deletePMInvoice, addPMInvoice } from "../../../store/PM-actions";
import { invoiceStatus, invoiceType } from "../../../services/constService";
import { processUpdateInvoice } from '../../../utils/publicUtil';
import CustomTable from "../../../components/custom/Table/CustomTable";


const PMForecastedInvoices = ({ forecastedInvoices, projectID }) => {
  const dispatch = useDispatch();
  const columns = [
    {
      path: "concept",
      label: "Concept",
      customContent: {
        type: 'inputbox',
        formInfo:
        {
          label: 'concept',
          id: 'concept',
          type: 'text',
        },
        schema: { 'concept': Joi.string().required().label('Concept') },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        }
      },
    },
    { path: "status.title", label: "Invoice status" },
    {
      path: "invoice_date",
      label: "Estimated date on invoice (yyyy-mm-dd)",
      customContent: {
        type: 'inputbox',
        formInfo:
        {
          label: 'invoice_date',
          id: 'invoice_date',
          type: 'date',
        },
        schema: { 'invoice_date': Joi.date().required().label('Estimated Date') },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        }
      },
    },
    {
      path: "amount_estimate",
      label: "Estimate Amount",
      displayType: 'currency_amount',
      customContent: {
        type: 'inputbox',
        formInfo:
        {
          label: 'amount_estimate',
          id: 'amount_estimate',
          type: 'text',
        },
        schema: {
          amount_estimate: Joi.number().required().label('Estimate Amount'),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        }
      },
    },
  ];
  /**************************************** button group ***************************************************/
  const buttonGroup = {
    upper_left: [
      {
        type: "primary",
        buttonText: (
          <Fragment>
            <FaArrowUp style={{ marginRight: "5px" }} />
            Move to Active Invoices
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice({
            originalRowInfo: {
              invoiceID: selectedRow.id,
              projectID: selectedRow.project.id,
            },
            updatedInfo: {
              id: selectedRow.id,
              status: invoiceStatus.REQUEST,
            },
          }))
        },
        id: 0,
      },
      {
        type: "danger",
        buttonText: <Fragment>Delete</Fragment>,
        handleClick: (value) => {
          if (!value) toast.warning("Please select invoice!")
          else dispatch(deletePMInvoice({ invoiceID: value.id, projectID: projectID }))
        },
        id: 1,
      },
    ],
    lower_right: [
      {
        type: "outline-primary",
        buttonText: <Fragment>Add Project Fees Invoice</Fragment>,
        handleClick: () => {
          dispatch(
            addPMInvoice({
              projectID: projectID,
              newInvoice: {
                concept: "Project Fees",
                type: invoiceType.PROJECT_FEE,
                status: invoiceStatus.FORECASTED,
              },
            })
          )
        },
        id: 2,
      },
    ],
  };

  console.log('PMForecastedInvoices - - forecastedInvoices', forecastedInvoices)

  return (
    <div className="table-container">
      <h3>Forecasted Invoices</h3>
      <CustomTable
        headerData={columns}
        bodyData={forecastedInvoices}
        buttonGroup={buttonGroup}
      />
      <p>A down payment should be placed as a project fee invoice.</p>
    </div>
  );
};

export default PMForecastedInvoices;
