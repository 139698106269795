import React from 'react';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useDispatch } from "react-redux";

import { patchPMCloseoutTracker, patchPMCloseoutTrackerItem } from '../../../store/PM-actions'
import ProjectDetailDisplayForm from './ProjectDetailDisplayForm'
import closeoutImage from '../../../assets/closeoutInstructions.png';
import { closeoutPMChoice, closeoutTeamChoice, closeoutTask } from '../../../services/constService';

const CloseoutTrackerItem = ({ sgnlClsTrckrItm, projectID, closeoutTrackerID }) => {
    const dispatch = useDispatch();

    const patchCloseoutTrackerItem = (event) => {
        // console.log('PMCloseoutTrackerCmpnnt - SignleCloseoutTracker - event.target.id', event.target.id)
        // console.log('PMCloseoutTrackerCmpnnt - SignleCloseoutTracker - event.target.value', event.target.value)

        const closeoutTrackerItemID = event.target.id.split('-')[0]
        dispatch(patchPMCloseoutTrackerItem(projectID, closeoutTrackerID, closeoutTrackerItemID, { "pm_choosed": event.target.value }));
    };

    return (<Row>
        <Col md={4}>
            <h5>
                {sgnlClsTrckrItm.task_title}{' '}
                {sgnlClsTrckrItm.task_title === 'Client Survey Sent' && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-placeholder">Once a project is completed, 
                        make sure the Client Survey is sent to the client with the proper email. 
                        If this is Change Order, then choose “Yes” and add a comment.</Tooltip>}
                    >
                        <i class="bi bi-info-circle"></i>
                    </OverlayTrigger>
                )}
                {sgnlClsTrckrItm.task === closeoutTask.THREE_PL_DATABASE_T3 && (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            (props)=>(
                                <Tooltip id="tooltip-placeholder" {...props} className="custom-tooltip">
                            If your project interfaced with a 3PL provider, 
                            please mark “Yes” and email Michael Morasca. 
                            Include the following information: 
                            Project name, service provider's name, individual contact information, 
                            and relevant information such as capabilities, industries, and locations. 
                            This can be compiled individually or 
                            provided by the 3PL in a capability presentation.</Tooltip>)}
                    >
                        <i class="bi bi-info-circle"></i>
                    </OverlayTrigger>
                )}
                {sgnlClsTrckrItm.task === closeoutTask.WMS_DATABASE_T3 && (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            (props)=>(
                                <Tooltip id="tooltip-placeholder" {...props} className="custom-tooltip">
                            If your project interfaced with a WMS provider, 
                            please mark “Yes” and email Griffin Lenz the following information: 
                            detail the vendor contact along with pros and cons realized during the project. 
                            If the project was a WMS/IT Tendering project include all relevant pricing information 
                            (quantity of users, licensing agreement, deployment type, etc).</Tooltip>)}
                    >
                        <i class="bi bi-info-circle"></i>
                    </OverlayTrigger>
                )}
                {/* {sgnlClsTrckrItm.task_title === 'MHE Vendor Database' && (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            (props)=>(
                                <Tooltip id="tooltip-placeholder" {...props} className="custom-tooltip">
                            If your project interfaced with a material handling equipment vendor, 
                            please fill out its relevant information in the MHE Vendor Database, 
                            located at the following server location: 
                            Q:\05_Knowledge\02_Pillar\01_EOE\01_Templates and Tools\04_Tools\01_Material Handling Equipment Vendor Database\MHE Vendor Database_2023-11-06_v03_GL.xlsx. 
                            
                            When complete save over the file, do not save a new version.</Tooltip>)}
                    >
                        <i class="bi bi-info-circle"></i>
                    </OverlayTrigger>
                )} */}
                {sgnlClsTrckrItm.task === closeoutTask.PROJECT_DOCUMENTATION_ON_SERVER_T3 && (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            (props)=>(
                                <Tooltip id="tooltip-placeholder" {...props} className="custom-tooltip">
                            Please place project documentation such as final Phase 1, 2, and 3 documentation, 
                            tools and templates developed, etc in this project's folder: 
                            09_Debriefing\B_Project Documentation. 
                            This documentation should be self-explanatory enough that someone 
                            who is not familiar with the project would understand what was done. 
                            <br/>
                            <br/>
                            In addition, if documentation is deemed valuable for Knowledge Management, 
                            please place it in the relevant pillar under 
                            Q:\05_Knowledge\00_Documents to be Filed. 
                            These files must have the appropriate naming structure 
                            to ensure the appropriate placement: 
                            Name of File_YYYY_MM_DD_v00_Initials. 
                            Files which do not have this naming structure will be deleted.</Tooltip>)}
                    >
                        <i class="bi bi-info-circle"></i>
                    </OverlayTrigger>
                )}
            </h5>
        </Col>
        {/************************************************* PM choosed *************************************************/}
        <Col md={5}>
            {sgnlClsTrckrItm.pm_choosed == closeoutPMChoice.NO &&
                <>
                    <Form.Check
                        inline
                        type='radio'
                        label={`Yes`}
                        id={`${sgnlClsTrckrItm.closeout_tracker_item_id}-Yes`}
                        value={closeoutPMChoice.YES}
                        onClick={patchCloseoutTrackerItem} />
                    {sgnlClsTrckrItm.task_not_applicable &&
                        <Form.Check
                            inline
                            type='radio'
                            label={`Not Applicable`}
                            id={`${sgnlClsTrckrItm.closeout_tracker_item_id}-NotApplicable`}
                            value={closeoutPMChoice.NOT_APPLICABLE}
                            onClick={patchCloseoutTrackerItem} />
                    }
                </>}
            {sgnlClsTrckrItm.pm_choosed == closeoutPMChoice.YES && <i className="fas fa-check" style={{ color: 'green' }}> Yes</i>}
            {sgnlClsTrckrItm.pm_choosed == closeoutPMChoice.NOT_APPLICABLE && <i className="fas fa-not-equal" style={{ color: 'grey' }}> Not Applicable</i>}
        </Col>
        {/************************************************* Team choosed *************************************************/}
        <Col>
            {sgnlClsTrckrItm.team_choosed == closeoutTeamChoice.PENDING && <i className="fas fa-ellipsis-h" style={{ color: 'grey' }}> Pending Approval</i>}
            {sgnlClsTrckrItm.team_choosed == closeoutTeamChoice.YES && <i className="fas fa-check" style={{ color: 'green' }}> Approved</i>}
            {sgnlClsTrckrItm.team_choosed == closeoutTeamChoice.NO && <i className="fas fa-times" style={{ color: 'red' }}> Not Approved</i>}
        </Col>
    </Row>)
};

const SignleCloseoutTracker = ({ sgnlClsTrckr }) => {
    const dispatch = useDispatch();

    const patchComment = (event) => {
        // console.log('PMCloseoutTrackerCmpnnt - SignleCloseoutTracker - event.target.id', event.target.id)
        const modifiedComment = event.target.value
        const projectID = event.target.id.split('-')[0]
        const closeoutTrackerID = event.target.id.split('-')[1]
        dispatch(patchPMCloseoutTracker(projectID, closeoutTrackerID, { "comment": modifiedComment }));
    };
    return (<>
        <hr />
        <h3 style={{ marginBottom: '20px' }}>{`${sgnlClsTrckr.team_title} Closeout Tasks`}</h3>
        {sgnlClsTrckr.closeout_tracker_item.map(clstTrckrItem => <CloseoutTrackerItem sgnlClsTrckrItm={clstTrckrItem} key={clstTrckrItem.closeout_tracker_item_id} projectID={sgnlClsTrckr.project} closeoutTrackerID={sgnlClsTrckr.closeout_tracker_id} />)}

        <Form.Label htmlFor="comment" className='mt-3'>{`Comment`}</Form.Label>
        <Form.Control
            as="textarea"
            id={`${sgnlClsTrckr.project}-${sgnlClsTrckr.closeout_tracker_id}-comment`}
            onBlur={patchComment}
            defaultValue={sgnlClsTrckr.comment}
        />
    </>)
};

const PMCloseoutTrackerCmpnnt = ({ closeoutTrackers }) => {

    // console.log('PMCloseoutTrackerCmpnnt - - closeoutTrackers', closeoutTrackers)

    return (<Row>
        <Col md={5}>
            <ProjectDetailDisplayForm projectID={closeoutTrackers[0].project} />
        </Col>
        <Col>
            {closeoutTrackers.map(clstTrckr => <SignleCloseoutTracker sgnlClsTrckr={clstTrckr} key={clstTrckr.closeout_tracker_id} />)}
        </Col>
    </Row>);
};

export default PMCloseoutTrackerCmpnnt;