import React from "react";
import { Form } from "react-bootstrap";

const TableFilterSearchBox = ({ label, value, onChange }) => {
  return (
    <>
      <label>{label}</label>{' '}
      <input
        type="text"
        name="query"
        className="form-control my-1 search-box-input"
        placeholder="Search..."
        value={value}
        onChange={e => onChange(e.currentTarget.value)}
      />
    </>
  );
};

export default TableFilterSearchBox;
