import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FaArrowUp, FaPlus } from "react-icons/fa";

import { updateAdminSubcontract, deleteAdminSubcontract, addAdminSubcontract } from '../../../store/admin-actions'
import { generateOptions, processUpdateSubcontract } from "../../../utils/publicUtil";
import { subcontractStatus } from "../../../services/constService";
import CustomForm from "../../../components/common/CustomForm";
import FrameModal from "../../../components/common/FrameModal";
import SubcontractFileModal from '../../../components/pages/SubcontractFileModal';
import CustomTable from "../../../components/custom/Table/CustomTable";

const AdminCreateSubcontracts = ({ createSubcontracts }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const LKUPaymentTerms = useSelector((state) => state.generic.LKUs.LKUPaymentTerms);
  const subcontractCompanies = useSelector((state) => state.admin.subcontractors);
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedSubcontractID, setSelectedSubcontractID] = useState();
  const [selectedProjectID, setSelectedProjectID] = useState();

  const columns = [
    { path: "project.project_number", label: "Project Number" },
    { path: "project.name", label: "Project Name" },
    { path: "project.project_header.manager.value", label: "Project Manager" },
    { path: "project.project_header.status.value", label: "Project Status" },
    { path: "project.project_header.currency.value", label: "Project Currency" },
    {
      path: "invoicing_company", label: "Subcontractor", sortPath: "invoicing_company.value",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          type: 'multiselect',
          label: "Invoicing Company",
          id: "invoicing_company",
          options: subcontractCompanies,
          isMulti: false,
        },
        schema: {
          'invoicing_company': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Invoicing company')
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "invoice_number", label: "Subcontractor Invoice Number", customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "invoice_number",
          id: "invoice_number",
          type: "text",
        },
        schema: {
          'invoice_number': Joi.string().required().label("invoice_number"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "invoice_date", label: "Subcontractor Invoice Date", customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "invoice_date",
          id: "invoice_date",
          type: "date",
        },
        schema: {
          'invoice_date': Joi.date().required().label("invoice_date"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "concept", label: "Concept", customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "concept",
          id: "concept",
          type: "text",
        },
        schema: {
          'concept': Joi.string().required().label("concept"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "amount",
      label: "Amount",
      displayType: 'currency_amount',
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "amount",
          id: "amount",
          type: "text",
        },
        schema: {
          'amount': Joi.number().required().label("amount"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "due_date", label: "Due date",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "due_date",
          id: "due_date",
          type: "date",
        },
        schema: {
          'due_date': Joi.date().required().label("due_date"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "payment_term", label: "Payment terms",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          type: 'multiselect',
          label: "payment_term",
          id: "payment_term",
          options: LKUPaymentTerms,
          isMulti: false,
        },
        schema: {
          'payment_term': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Payment term')
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "comment", label: "Comment",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "comment",
          id: "comment",
          type: "text",
        },
        schema: {
          comment: Joi.string().required().label("comment"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "status.value", label: "Status" },
  ];

  const buttonGroup = {
    upper_left: [
      {
        type: "danger",
        buttonText: (
          <Fragment>
            Delete
          </Fragment>
        ),
        handleClick: (value) => {
          if (!value) toast.warning("Please select subcontract!")
          else {
            dispatch(
              deleteAdminSubcontract({
                subcontractID: value.id,
                projectID: value.project.id,
              })
            );
          }
        },
        id: 0,
      },
      {
        type: "primary",
        buttonText: (
          <Fragment>
            <FaArrowUp style={{ marginRight: "5px" }} />
            Move it to Created Pending Approval
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select subcontract!")
          else dispatch(updateAdminSubcontract({
            originalRowInfo: {
              subcontractID: selectedRow.id,
              projectID: selectedRow.project.id,
            },
            updatedInfo: {
              id: selectedRow.id,
              status: subcontractStatus.APPROVE,
            }
          }))
        },
        id: 1,
      },
      {
        type: "primary",
        buttonText: (
          <Fragment>
            <FaPlus style={{ marginRight: "5px" }} />
            Create new subcontract
          </Fragment>
        ),
        handleClick: () => {
          setShow(true);
        },
        id: 2
      },
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Attach/View PDF files
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select subcontract!");
          } else {
            setSelectedSubcontractID(selectedRow.id)
            setSelectedProjectID(selectedRow.project.id)
            setShowFileModal(true)
          }
        },
        id: 3,
      },
    ],
  };


  // console.log('AdminCreateSubcontracts - - createSubcontracts', createSubcontracts)

  ////prepare filter options data end
  const projectNameOptions = generateOptions(
    createSubcontracts,
    'project.id',
    'project.name',
    'project.project_number'
  );
  const projectNumberOptions = generateOptions(
    createSubcontracts,
    'project.id',
    'project.project_number',
    'project.project_number'
  );
  const projectManagerOptions = generateOptions(
    createSubcontracts,
    'project.project_header.manager.id',
    'project.project_header.manager.value',
    'project.project_number'
  );
  const projectStatusOptions = generateOptions(
    createSubcontracts,
    'project.project_header.status.id',
    'project.project_header.status.value',
    'project.project_number'
  );
  const subcontractorCompanyOptions = generateOptions(
    createSubcontracts,
    'invoicing_company.id',
    'invoicing_company.value',
    'invoicing_company'
  );

  ////prepare filter group data end
  const filterGroup = [
    {
      'id': 'Project Name',
      'path': 'project',
      "options": projectNameOptions,
      "label": "Project Name"
    },
    {
      'id': 'Project Number',
      'path': 'project',
      "options": projectNumberOptions,
      "label": "Project Number"
    },
    {
      'id': 'Project Manager',
      'path': 'project.project_header.manager',
      "options": projectManagerOptions,
      "label": "Project Manager"
    },
    {
      'id': 'Project Status',
      'path': 'project.project_header.status',
      "options": projectStatusOptions,
      "label": "Project Status"
    },
    {
      'id': 'Subcontractor Company',
      'path': 'invoicing_company',
      "options": subcontractorCompanyOptions,
      "label": "Subcontractor"
    },
  ]

  ////Add new subcontract module
  const modalTitle = 'Adding new subcontract';

  const projectMasters = useSelector((state) => state.admin.projectsForAdminDropdown);

  const projectsOptions = generateOptions(
    projectMasters,
    'id',
    'name',
    'id',
  );

  const formInfo = [
    {
      label: 'Project: ',
      id: 'project',
      type: 'multiselect',
      isMulti: false,
      options: projectsOptions
    },
  ];

  const formStructure = {
    submit_button: { label: 'Submit' }
  };

  const schema = {
    [formInfo[0].id]: Joi.object({ id: Joi.number().required(), value: Joi.number(), label: Joi.string() }).required().label(formInfo[0].label),
  };

  const doFormSubmit = async (selectedRow) => {
    dispatch(addAdminSubcontract({
      projectID: selectedRow.project.id,
    }));
  };
  ////Add new subcontract ends

  return (
    <div className="table-container">
      <h2>Admin Responsibility - Add Subcontractor Invoice, Move to PM to approve</h2>
      <CustomTable
        headerData={columns}
        bodyData={createSubcontracts}
        buttonGroup={buttonGroup}
        filterGroup={filterGroup}
      />
      <FrameModal modalTitle={modalTitle} show={show} setShow={setShow} >
        <CustomForm
          formInfo={formInfo}
          formStructure={formStructure}
          doSubmit={doFormSubmit}
          schema={schema} />
      </FrameModal>

      <SubcontractFileModal show={showFileModal} setShow={setShowFileModal} projectID={selectedProjectID} subcontractID={selectedSubcontractID} editableView />
    </div>
  );
};

export default AdminCreateSubcontracts;
