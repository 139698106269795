import React from 'react';
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";

import CustomForm from "../../components/common/CustomForm";
import auth from "../../services/authService";

function Login() {
  const formInfo = [
    {
      label: "Email address",
      id: "username",
      type: "text",
    },
    {
      label: "Password",
      id: "password",
      type: "password",
    },
  ];

  const formStructure = {
    submit_button: {
      label: 'Login'
    }
  };

  const initializedData = {
    [formInfo[0].id]: "",
    [formInfo[1].id]: "",
  };

  const schema = {
    [formInfo[0].id]: Joi.string().required().email().label(formInfo[0].label),
    [formInfo[1].id]: Joi.string().required().min(8).label(formInfo[1].label)
  };

  const doSubmit = async (data) => {
    // console.log(data)
    try {
      await auth.login(data);
      window.location = "/home";
      toast.success("Login Sucessfully!");
    } catch (error) {
      if (error.response.status == 401) toast.error(error.response.data.detail);
      console.log(error.response);
    }
  };

  return (
    <Row>
      <Col></Col>
      <Col>
        <CustomForm
          formInfo={formInfo}
          formStructure={formStructure}
          doSubmit={doSubmit}
          schema={schema}
          initializedData={initializedData}
        />
      </Col>
      <Col></Col>
    </Row>
  );
}

export default Login;
