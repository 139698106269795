import React, { useState } from 'react';
import _ from "lodash";
import { useSelector } from "react-redux";

import TableFormCell from './TableFormCell';
import { conditionalRenderCurrencyAmount } from '../../../utils/publicUtil'

const TableBody = ({ completeBodyData, setCompleteBodyData, itemsDisplayed, headerData, selectedRow, setSelectedRow }) => {
    const user = useSelector((state) => state.generic.currentUser);
    {/********************************************* handle unit cell change *********************************************/ }

    const [selectedInputIndex, setSelectedInputIndex] = useState({ "inputRowID": 0, "inputColumnPath": '' });

    const handleClick = (rowID, columnPath) => {
        setSelectedInputIndex({
            'inputRowID': rowID,
            "inputColumnPath": columnPath
        })
    };

    const handleInputChange = (value) => {
        const { inputColumnPath: columnPath, inputRowID: rowID } =
            selectedInputIndex;

        const complet_body_data = Array.from(completeBodyData);

        const rowIndex = complet_body_data.findIndex(
            (element) => element.id == rowID
        );

        complet_body_data[rowIndex] = {
            ...complet_body_data[rowIndex],
            [columnPath]: value
        };

        setCompleteBodyData(complet_body_data);

        setSelectedInputIndex({ inputRowID: 0, inputColumnPath: "" });
    };

    const handleSubmit = (data, row, doSumbitInOuterCmpnnt) => {
        const updatedData = {
            updatedValue: data,
            rowInfo: row,
        }
        handleInputChange(Object.values(data)[0]);

        doSumbitInOuterCmpnnt(updatedData);
    };
    {/************************************************ render body ******************************************************/ }
    const renderCell = (row, column) => {
        const customContent = column.customContent
        const displayType = column.displayType
        let contentString = _.get(row, column.path)

        if (displayType) {
            if (displayType == 'currency_amount') contentString = conditionalRenderCurrencyAmount(contentString, user.office_id)
        }

        // console.log('TableBody - renderCell - row', row)
        // console.log('TableBody - renderCell - column', column)

        // if (row.resource && row.resource.id == 1 && column.path == '25.value') {
        //     console.log('TableBody - renderCell - column.path', column.path)
        //     console.log('TableBody - renderCell - contentString', contentString)
        //     console.log('TableBody - renderCell - row', row)
        // }

        if (customContent) {
            if (customContent.type === 'inputbox') {
                const cellKey = `${row.id}-${column.path}`
                return <td key={cellKey} onClick={() => handleClick(row.id, column.path)}  >
                    {selectedInputIndex.inputRowID + '-' + selectedInputIndex.inputColumnPath === cellKey ?
                        <TableFormCell
                            formInfo={customContent.formInfo}
                            initializedData={{ [column.path]: contentString }}
                            doSubmit={(data) => handleSubmit(data, row, customContent.doSubmit)}
                            formSchema={customContent.schema} /> :
                        <>{customContent.formInfo.type == 'multiselect' ? (contentString != null ? contentString.label : '') : contentString}</>
                    }
                </td>
            }
        }
        if (!customContent)
            return <td key={`${row.id}-${column.path}`}>
                {contentString}
            </td>;
    };

    return (
        <tbody>
            {itemsDisplayed.map((row) => (
                <tr
                    key={row.id}
                    onClick={() => setSelectedRow(row)}
                    className={selectedRow.id === row.id ? 'customeTableSelectedRow' : 'customeTableNormalRow'}
                >
                    {headerData.map((column) => (
                        <React.Fragment key={`${column.path}-${row.id}`}>
                            {renderCell(row, column)}
                        </React.Fragment>
                    ))}
                </tr>
            ))
            }
        </tbody >
    );
};

export default TableBody;