import { createSlice } from "@reduxjs/toolkit";

const COHSlice = createSlice({
  name: "COH",
  initialState: {
    weekSummaries: null,
    activeWeekIDs: null,
  },

  reducers: {
    setWeekSummaries(state, action) {
      state.weekSummaries = action.payload.weekSummaries;
    },
    addWeekSummary(state, action) {
      const { newWeekSummary } = action.payload;
      state.weekSummaries = state.weekSummaries.map((weekSummary) =>
        weekSummary.week_id == newWeekSummary.week_id
          ? newWeekSummary
          : weekSummary
      );
    },
    updateWeekSummary(state, action) {
      const { updatedWeekSummary } = action.payload;
      const index = state.weekSummaries.findIndex(
        (weekSummary) => weekSummary.week_id == updatedWeekSummary.week_id
      );
      if (index >= 0) {
        state.weekSummaries[index] = updatedWeekSummary;
      }
    },
    setActiveWeekIDs(state, action) {
      state.activeWeekIDs = action.payload.activeWeekIDs;
    },
  },
});
export const COHActions = COHSlice.actions;
export default COHSlice;
