import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { updatePMSubcontract } from "../../../store/PM-actions";
import { processUpdateSubcontract } from "../../../utils/publicUtil";
import SubcontractFileModal from '../../../components/pages/SubcontractFileModal';
import CustomTable from "../../../components/custom/Table/CustomTable";

const PMConnectSubcontracts = ({ connectSubcontracts, projectID }) => {
  const dispatch = useDispatch();
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedSubcontractID, setSelectedSubcontractID] = useState();

  const columns = [
    { path: "invoicing_company.value", label: "Subcontractor",sortPath:"invoicing_company.value", },
    { path: "invoice_number", label: "Subcontractor Invoice Number" },
    { path: "invoice_date", label: "Subcontractor Invoice Date" },
    { path: "concept", label: "Concept" },
    { path: "amount", label: "Amount", displayType: 'currency_amount', },
    { path: "due_date", label: "Due date" },
    { path: "payment_term.value", label: "Payment terms" },
    {
      path: "comment", label: "Comment",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "comment",
          id: "comment",
          type: "text",
        },
        schema: {
          comment: Joi.string().required().label("comment"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "status.value", label: "Status" },
    { path: "invoice.invoice_number", label: "Corresponding customer invoice number" },
    { path: "invoice.sent_date", label: "Customer date sent" },
    { path: "invoice.paid_date", label: "Customer invoice paid date" },
  ];
  /**************************************** button group ***************************************************/

  const buttonGroup = {
    upper_left: [
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Attach/View PDF files
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select subcontract!");
          } else {
            setSelectedSubcontractID(selectedRow.id)
            setShowFileModal(true)
          }
        },
        id: 0,
      },
    ],
  };


  return (
    <div className="table-container">
      <h2>
      Admin Responsibility - Connect to Client Invoice, once paid, move to PM to approve to pay subcontractor
      </h2>
      <CustomTable
        headerData={columns}
        bodyData={connectSubcontracts}
        buttonGroup={buttonGroup}
      />
      <br />
      <br />
      <SubcontractFileModal show={showFileModal} setShow={setShowFileModal} projectID={projectID} subcontractID={selectedSubcontractID} />
    </div>
  );
};

export default PMConnectSubcontracts;
