import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Joi from "joi-browser";

import { updatePMInvoice } from "../../../store/PM-actions";
import { processUpdateInvoice } from '../../../utils/publicUtil';
import CustomTable from "../../../components/custom/Table/CustomTable";

const PMPaidInvoices = ({ paidInvoices, projectID }) => {
  const dispatch = useDispatch();

  const columns = [
    { path: "status.title", label: "Invoice status" },
    { path: "concept", label: "Concept" },
    { path: "invoice_number", label: "Invoice ID" },
    { path: "sent_date", label: "Date sent" },
    { path: "due_date", label: "Date due" },
    { path: "paid_date", label: "Date paid" },
    { path: "amount_actual", label: "Amount", displayType: 'currency_amount', },
    {
      path: "notes",
      label: "Communication Between PM and Admin",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "notes",
          id: "notes",
          type: "text",
        },
        schema: { notes: Joi.string().required().label("Notes") },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        }
      },
    },
  ];

  return (
    <div className="table-container">
      <h2>Historical log of invoices</h2>
      <CustomTable
        headerData={columns}
        bodyData={paidInvoices}
      />
      <br />
      <br />
    </div>
  );
};

export default PMPaidInvoices;
