export const invoiceStatus = {
  FORECASTED: 1,
  REQUEST: 2,
  CREATE: 3,
  APPROVE: 4,
  PENDING_SEND: 5,
  SENT: 6,
  PAID: 7,
};

export const invoiceType = {
  PROJECT_FEE: 1,
  TRAVEL: 3,
};

export const subcontractStatus = {
  CREATE: 1,
  APPROVE: 2,
  CONNECT: 3,
  CONFIRM: 4,
  PENDING_PAY: 5,
  PAID: 6,
};

export const appRole = {
  PROJECT_MANAGER: 1,
  ADMIN: 2,
  STAFFING_MANAGER: 3,
  LEADER_TEAM: 4,
  BUSINESS_DEVELOPMENT: 5,
  ICC_USER: 6,
  ICC_ADMIN: 7,
  DEVELOPER: 8
};

export const closeoutPMChoice = {
  NO: 1,
  YES: 2,
  NOT_APPLICABLE: 3,
};

export const closeoutTeamChoice = {
  PENDING: 1,
  YES: 2,
  NO: 3,
};

export const closeoutTask = {
  ALL_PROJECT_FEES_INVOICED_T1: 1,
  ALL_TRAVEL_EXPENSES_INVOICED_T1: 2,
  ALL_INVOICE_PAID_T1: 3,
  FEEDBACK_SESSIONS_COMPLETED_T2: 4,
  THREE_PL_DATABASE_T3: 5,
  WMS_DATABASE_T3: 6,
  PROJECT_DOCUMENTATION_ON_SERVER_T3: 7,
  PROJECT_CASE_STUDY_COMPLETE_T4: 8,
  CONTACT_LIST_SAVED_IN_PROJECT_FOLDER_T4: 9,
  ALL_PROJECT_FEES_INVOICED_T5: 10,
  ALL_TRAVEL_EXPENSES_INVOICED_T5: 11,
  ALL_INVOICE_PAID_T5: 12,
  FEEDBACK_SESSIONS_HAD_T6: 13,
  ONE_PAGE_REFERENCE_COMPLETE_T7: 14,
  PROJECT_CASE_STUDY_REFERENCE_COMPLETE_T7: 15,
  WEB_PAGE_REFERENCE_COMPLETE_T7: 16,
  CONTACT_LIST_SAVED_IN_PROJECT_FOLDER_T7: 17,
  CLOSEOUT_MEETING_T6: 18,
  ALL_SUBCONTRACTORS_INVOICES_PAID_T1: 19,
  ALL_SUBCONTRACTORS_INVOICES_PAID_T5: 20,
  CLIENT_SURVEY_SENT_T4: 21,
  CLIENT_SURVEY_SENT_T7: 22
}

export const projectStatus = {
  OPEN: 1,
  COMPLETED: 2,
  CLOSED: 3
};

export const office = {
  INDIANAPOLIS: 1,
  MONTREAL: 2,
  FRANKFURT: 3,
  MUNICH: 4,
  PARIS: 5,
  BERLIN: 6,
  MEXICO: 7,
  BARCELONA: 8,
  BOGOTA:9,
  SAO_PAULO: 11,
  BUENOS_AIRES: 13,
  GUATEMALA: 16,
  MADRID: 21,
};

export const response = {
  UPDATED: 200,
  CREATED: 201,
  DELETED: 204,
};