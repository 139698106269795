import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { updatePMInvoice } from "../../../store/PM-actions";
import { processUpdateInvoice } from '../../../utils/publicUtil';
import InvoiceFileModal from '../../../components/pages/InvoiceFileModal';
import CustomTable from "../../../components/custom/Table/CustomTable";
import ViewInvoiceLineItemDetail from "../../../components/pages/ViewInvoiceLineItemDetail";

const PMSentInvoices = ({ sendInvoices, projectID }) => {
  const dispatch = useDispatch();
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedInvoiceID, setSelectedInvoiceID] = useState();
  const [showViewLineItemModal, setShowViewLineItemModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();

  const columns = [
    { path: "status.title", label: "Invoice status" },
    { path: "concept", label: "Concept" },
    { path: "invoice_number", label: "Invoice ID" },
    {
      path: "due_date",
      label: "Date due",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "due_date",
          id: "due_date",
          type: "date",
        },
        schema: { due_date: Joi.date().required().label("Date due") },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        }
      },
    },
    { path: "paid_date", label: "Date paid" },
    { path: "amount_actual", label: "Amount", displayType: 'currency_amount', },
    {
      path: "notes",
      label: "Communication Between PM and Admin",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "notes",
          id: "notes",
          type: "text",
        },
        schema: { notes: Joi.string().required().label("Notes") },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        }
      },
    },
  ];
  /**************************************** button group ***************************************************/
  const buttonGroup = {
    upper_left: [
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Attach/View PDF files
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select invoice!");
          } else {
            setSelectedInvoiceID(selectedRow.id)
            setShowFileModal(true)
          }
        },
        id: 0,
      },
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> View Invoice Line Item
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select invoice!");
          } else {
            setSelectedInvoice(selectedRow)
            setShowViewLineItemModal(true)
          }
        },
        id: 1,
      },
    ],
  };

  return (
    <div className="table-container">
      <h3>
        Client responsibility - pay invoice
      </h3>
      <h3>
        PM responsibility - stay on top of invoices in this category to make sure they get paid
      </h3>
      <h3>
        Admin responsibility - once paid, update status to reflect that invoice is paid
      </h3>
      <CustomTable
        headerData={columns}
        bodyData={sendInvoices}
        buttonGroup={buttonGroup}
      />
      <br />
      <br />
      <InvoiceFileModal show={showFileModal} setShow={setShowFileModal} projectID={projectID} invoiceID={selectedInvoiceID} />
      <ViewInvoiceLineItemDetail show={showViewLineItemModal} setShow={setShowViewLineItemModal} invoice={selectedInvoice} />
    </div>
  );
};

export default PMSentInvoices;
