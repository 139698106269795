import React from 'react';
import { Button } from "react-bootstrap";

const TableButton = ({ buttonInfo, styleInfo, selectedRow }) => {
    return (
        <React.Fragment key={`${buttonInfo.id}`}>
            <Button
                className={styleInfo}
                variant={buttonInfo.type}
                onClick={() => buttonInfo.handleClick(selectedRow)}
                key={`button-${buttonInfo.id}`}
            >
                {buttonInfo.buttonText}
            </Button>
        </React.Fragment>
    )
};

export default TableButton;