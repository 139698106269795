import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import _ from "lodash";

import { updatePMSubcontract } from "../../../store/PM-actions";
import { processUpdateSubcontract } from "../../../utils/publicUtil";
import CustomTable from "../../../components/custom/Table/CustomTable";

const PMCreateSubcontracts = ({ createSubcontracts }) => {
  const dispatch = useDispatch();

  const columns = [
    { path: "invoicing_company.value", label: "Subcontractor",sortPath:"invoicing_company.value", },
    { path: "invoice_number", label: "Subcontractor Invoice Number" },
    { path: "invoice_date", label: "Subcontractor Invoice Date" },
    { path: "concept", label: "Concept" },
    { path: "amount", label: "Amount", displayType: 'currency_amount', },
    { path: "due_date", label: "Due date" },
    { path: "payment_term.value", label: "Payment terms" },
    {
      path: "comment", label: "Comment",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "comment",
          id: "comment",
          type: "text",
        },
        schema: {
          comment: Joi.string().required().label("comment"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "status.value", label: "Status" },
  ];

  // console.log('PMCreateSubcontracts - - createSubcontracts', createSubcontracts)

  return (
    <div className="table-container">
      <h2>Admin Responsibility - Add Subcontractor Invoice, Move to PM to approve</h2>
      <CustomTable
        headerData={columns}
        bodyData={createSubcontracts}
      />
    </div>
  );
};

export default PMCreateSubcontracts;
