// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-inline {
  border: 1px solid rgb(206, 205, 205);
  padding: 10px 5px;
  display: inline-block;
  min-width: 125px;
}
.comment {
  min-width: 375px; 
}
.summary-pillar {
  min-width: 240px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/ICC/ProjectManagement/ICCProjectSummary.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,iBAAiB;EACjB,qBAAqB;EACrB,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".summary-inline {\n  border: 1px solid rgb(206, 205, 205);\n  padding: 10px 5px;\n  display: inline-block;\n  min-width: 125px;\n}\n.comment {\n  min-width: 375px; \n}\n.summary-pillar {\n  min-width: 240px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
