import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useState } from "react";
import _ from "lodash";

import { subcontractStatus } from "../../../services/constService";
import Spinner from "../../../components/Spinner";
import PMCreateSubcontracts from "./PMCreateSubcontracts";
import PMApproveSubcontracts from "./PMApproveSubcontracts";
import PMConnectSubcontracts from "./PMConnectSubcontracts";
import PMConfirmSubcontracts from "./PMConfirmSubcontracts";
import PMPendingPaySubcontracts from "./PMPendingPaySubcontracts";
import PMPaidSubcontracts from "./PMPaidSubcontracts";
import PMAllSubcontracts from "./PMAllSubcontracts";
import { office as lkuOffice } from "../../../services/constService";

/* Spain Offfice - Subcontract - only need the first two steps */
const NavPMSubcontract = () => {
  const selectedProject = useSelector((state) => state.PM.selectedProject);
  const history = useHistory();
  const Subcontracts = useSelector((state) => state.PM.PMSubcontracts);
  const [createSubcontracts, setCreateSubcontracts] = useState([]);
  const [approveSubcontracts, setApproveSubcontracts] = useState([]);
  const [connectSubcontracts, setConnectSubcontracts] = useState([]);
  const [confirmSubcontracts, setConfirmSubcontracts] = useState([]);
  const [pendingPaySubcontracts, setPendingPaySubcontracts] = useState([]);
  const [paidSubcontracts, setPaidSubcontracts] = useState([]);
  const user = useSelector((state) => state.generic.currentUser);
  const office = user.office_id

  useEffect(() => {
    if (!selectedProject.hasOwnProperty('id')) {
      console.log('redirect to another page')
      history.push('/pmo');
    }
  }, [selectedProject]);

  useEffect(() => {
    if (Subcontracts && !('empty_message' in Subcontracts[0]) && Subcontracts[0].project.id == selectedProject.id) {
      setCreateSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.CREATE))
      setApproveSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.APPROVE))
      setConnectSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.CONNECT))
      setConfirmSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.CONFIRM))
      setPendingPaySubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.PENDING_PAY))
      setPaidSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.PAID))
    }
  }, [Subcontracts]
  );

  return (
    <>
      {!Subcontracts || !Subcontracts.length || Subcontracts[0].project.id !== selectedProject.id ? (
        <div><Spinner /></div>
      ) : (
        <>
          <Row>
            <Col><h3 className="mb-5">Subcontracting</h3></Col>
            <Col><h3 className="mb-5">{selectedProject.value}</h3></Col>
          </Row>

          <Tabs>
            <TabList>
              <Tab>Subcontract Management</Tab>
              <Tab>All Subcontracts</Tab>
            </TabList>

            <TabPanel>
              <Row>
                <Col xs={12}>
                  <Tabs>
                    <TabList>
                      <Tab>Add Subcontractor Invoice</Tab>
                      <Tab>Approve Project</Tab>

                      {(office != lkuOffice.BARCELONA && office !== lkuOffice.MADRID) &&
                        <>
                          <Tab>Connect to Client Invoice</Tab>
                          <Tab>Approve to Pay Subcontractor</Tab>
                          <Tab>Confirm Payment Date</Tab>
                          <Tab>Subcontract Paid</Tab>
                        </>}
                    </TabList>

                    <TabPanel>
                      <PMCreateSubcontracts createSubcontracts={createSubcontracts} projectID={selectedProject.id} />
                    </TabPanel>
                    <TabPanel>
                      <PMApproveSubcontracts approveSubcontracts={approveSubcontracts} projectID={selectedProject.id} />
                    </TabPanel>
                    {(office != lkuOffice.BARCELONA && office !== lkuOffice.MADRID) &&
                      <>
                        <TabPanel>
                          <PMConnectSubcontracts connectSubcontracts={connectSubcontracts} projectID={selectedProject.id} />
                        </TabPanel>
                        <TabPanel>
                          <PMConfirmSubcontracts confirmSubcontracts={confirmSubcontracts} projectID={selectedProject.id} />
                        </TabPanel>
                        <TabPanel>
                          <PMPendingPaySubcontracts pendingPaySubcontracts={pendingPaySubcontracts} projectID={selectedProject.id} />
                        </TabPanel>
                        <TabPanel>
                          <PMPaidSubcontracts paidSubcontracts={paidSubcontracts} projectID={selectedProject.id} />
                        </TabPanel>
                      </>}
                  </Tabs>
                </Col>
              </Row>
            </TabPanel>
            <TabPanel>
              <PMAllSubcontracts Subcontracts={Subcontracts} projectID={selectedProject.id} />
            </TabPanel>
          </Tabs>
        </>
      )}
    </>
  );
};

export default NavPMSubcontract;