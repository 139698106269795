import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { updateAdminInvoice, deleteAdminInvoice } from '../../../store/admin-actions'
import { generateOptions, processUpdateInvoice } from "../../../utils/publicUtil";
import InvoiceFileModal from "../../../components/pages/InvoiceFileModal";
import CustomTable from "../../../components/custom/Table/CustomTable";
import ViewInvoiceLineItemDetail from "../../../components/pages/ViewInvoiceLineItemDetail";
import { office as officeID } from "../../../services/constService";

const AdminPaidInvoices = ({ paidInvoices }) => {
  const dispatch = useDispatch();
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedInvoiceID, setSelectedInvoiceID] = useState();
  const [selectedProjectID, setSelectedProjectID] = useState();
  const [showViewLineItemModal, setShowViewLineItemModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();

  const user=useSelector((state)=>state.generic.currentUser);
  const office=user.office_id;

  const columns = [
    { path: "project.name", label: "Project name" },
    {
      path: "project.project_detail.invoice_structure.value",
      label: "Payment structure",
    },
    { path: "concept", label: "Concept" },
    { path: "invoice_number", label: "Invoice ID" },
    { path: "type.value", label: "Invoice Type" },
    { path: "due_date", label: "Date due" },
    {
      path: "paid_date",
      label: "Date paid",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "paid_date",
          id: "paid_date",
          type: "date",
        },
        schema: {
          paid_date: Joi.date().required().label("Date paid"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    { path: "amount_actual", label: "Amount", displayType: 'currency_amount', },
    { path: "project.project_header.currency.value", label: "Currency" },
    {
      path: "amount_local",
      label: "Local Amount",
      displayType: 'currency_amount',
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "amount_local",
          id: "amount_local",
          type: "text",
        },
        schema: {
          amount_local: Joi.number().required().label("Local Amount"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    { path: "project.project_header.manager.value", label: "PM" },
    {
      path: "notes",
      label: "Communication Between PM and Admin",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "notes",
          id: "notes",
          type: "text",
        },
        schema: {
          notes: Joi.string().required().label("notes"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
  ];

  if(office==officeID.MONTREAL || office==officeID.INDIANAPOLIS){
    columns.push({ path: "amount_editable_USD", label: "Amount in USD when applicable",
      displayType: 'currency_amount',
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "amount_editable_USD",
          id: "amount_editable_USD",
          type: "text",
        },
        schema: {
          amount_editable_USD: Joi.number().required().label("USD Amount"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!")
          else dispatch(updateAdminInvoice(processUpdateInvoice(selectedRow)))
        },
      }, });
  }

  const buttonGroup = {
    upper_left: [
      {
        type: "danger",
        buttonText: <Fragment>Delete</Fragment>,
        handleClick: (value) => {
          if (!value) {
            toast.warning("Please select invoice!");
          } else {
            dispatch(
              deleteAdminInvoice({ invoiceID: value.id, projectID: value.project.id })
            );
          }
        },
        id: 0,
      },
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Attach/View PDF files
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select invoice!");
          } else {
            setSelectedInvoiceID(selectedRow.id)
            setSelectedProjectID(selectedRow.project.id)
            setShowFileModal(true)
          }
        },
        id: 1,
      },
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> View Invoice Line Item
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select invoice!");
          } else {
            setSelectedInvoice(selectedRow)
            setShowViewLineItemModal(true)
          }
        },
        id: 2,
      },
    ],
  };

  const projectNameOptions = generateOptions(
    paidInvoices,
    'project.id',
    'project.name',
    'project.project_number'
  );
  const projectNumberOptions = generateOptions(
    paidInvoices,
    'project.id',
    'project.project_number',
    'project.project_number'
  );
  const projectManagerOptions = generateOptions(
    paidInvoices,
    'project.project_header.manager.id',
    'project.project_header.manager.value',
    'project.project_header.manager.id'
  );

  ////prepare filter options data end
  const filterGroup = [
    {
      'id': 'Project Name',
      'path': 'project',
      "options": projectNameOptions,
      "label": "Project Name"
    },
    {
      'id': 'Project Number',
      'path': 'project',
      "options": projectNumberOptions,
      "label": "Project Number"
    },
    {
      'id': 'Project Manager',
      'path': 'project.project_header.manager',
      "options": projectManagerOptions,
      "label": "Project Manager"
    },
  ]
  return (
    <div className="table-container">
      <h2>Historical log of invoices</h2>
      <CustomTable
        headerData={columns}
        bodyData={paidInvoices}
        buttonGroup={buttonGroup}
        filterGroup={filterGroup}
        pageSize={10}
      />
      <br />
      <br />
      <InvoiceFileModal show={showFileModal} setShow={setShowFileModal} projectID={selectedProjectID} invoiceID={selectedInvoiceID} editableView />
      <ViewInvoiceLineItemDetail show={showViewLineItemModal} setShow={setShowViewLineItemModal} invoice={selectedInvoice} />
    </div>
  );
}
export default AdminPaidInvoices;