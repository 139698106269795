import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import http from "../../services/httpService";
import { listProjectRolesAPI, updateProjectRoleAPI, listCOHProjectsAPI } from '../../services/backendURLService'
import FrameModal from "../../components/common/FrameModal"
import CustomTable from './../../components/custom/Table/CustomTable';
import CustomForm from './../../components/custom/Form/CustomForm';
import Spinner from '../../components/Spinner';
import { processPatchData } from "../../utils/publicUtil"

const NewProjectRoleForm = ({ projectRoles, setProjectRoles }) => {
    const LKUPosition = useSelector((state) => state.generic.LKUs.LKUPosition);
    const [allProjects, setAllProjects] = useState();

    useEffect(() => {
        const getProjects = async () => {
            const { data: projects } = await http.get(`${listCOHProjectsAPI}`);

            // console.log('NewProjectRoleForm - useEffect - projects', projects)

            const organizedProjects = projects.map(project => ({
                id: project.id,
                label: project.project_number + " " + project.name,
                value: project.project_number + " " + project.name,
            }))

            setAllProjects(organizedProjects)
        };

        getProjects()
    }, []);

    const formInfo = [
        {
            type: 'multiselect',
            label: 'Project',
            options: allProjects,
            id: 'project',
            isMulti: false
        },
        {
            type: 'multiselect',
            label: 'Position',
            options: LKUPosition,
            id: 'position',
            isMulti: false
        },
    ]

    const formStructure = {
        submit_button: 'Add Project Role'
    };

    const formSchema = {
        [formInfo[0].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[0].label),
        [formInfo[1].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[1].label),
    }

    const doFormSubmit = async (newProjectRole) => {

        const postData = {
            "project": newProjectRole.project.id,
            "position": newProjectRole.position.id,
        };

        try {
            // ************************************************** maitain backend data *********************************************************
            const { data: returnData } = await http.toastPost(`${listProjectRolesAPI}`, postData);
            // ************************************************** maitain frontend data *********************************************************
            const newProjectRoleToBeAddToState = {
                id: returnData.project_role_id,
                project: {
                    id: returnData.project,
                    project_number: returnData.project_number,
                    name: returnData.project_name,
                },
                position: { id: returnData.position, value: returnData.position_title, label: returnData.position_title },
                is_active: { id: 1, value: "Active", label: "Active" }
            };
            const updatedProjectRoles = [...projectRoles, newProjectRoleToBeAddToState];
            setProjectRoles(updatedProjectRoles);
        } catch (error) {
            toast.error("You already have this project role!");
        }
    }

    return <>{
        allProjects ?
            <CustomForm
                formInfo={formInfo}
                formStructure={formStructure}
                formSchema={formSchema}
                doSubmit={doFormSubmit} /> :
            <Spinner />
    }</>
}

const ManageProjectRole = () => {
    const [projectRoles, setProjectRoles] = useState()
    const [showAddNewProjectRole, setShowAddNewProjectRole] = useState(false)
    const LKUPosition = useSelector((state) => state.generic.LKUs.LKUPosition);

    useEffect(() => {
        const getProjectRoles = async () => {
            const { data: project_roles } = await http.get(`${listProjectRolesAPI}`);
            // console.log('ProjectHours - useEffect - project_roles', project_roles)

            const organizedProjectRoles = project_roles.map((projectRole) => ({
                id: projectRole.id,
                project: {
                    id: projectRole.project.id,
                    project_number: projectRole.project.project_number,
                    name: projectRole.project.name,
                },
                position: { id: projectRole.position.id, value: projectRole.position.title, label: projectRole.position.title },
                is_active: projectRole.is_active ? { id: 1, value: "Active", label: "Active" } : { id: 0, value: "Inactive", label: "Inactive" },
                resource: projectRole.resource,
            }));

            setProjectRoles(organizedProjectRoles)
        };

        getProjectRoles()
    }, []);

    const doSubmit = async ({ updatedValue, rowInfo }) => {
        const projectRoleID = rowInfo.id
        const organizedPatchData = processPatchData(updatedValue)

        await http.toastPatch(`${updateProjectRoleAPI(projectRoleID)}`, organizedPatchData);
    }

    const columns = [
        { path: "project.project_number", label: "Project Number" },
        { path: "project.name", label: "Project Name" },
        {
            path: "position", label: "Position", sortPath: "position.id",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'multiselect',
                    id: "position",
                    options: LKUPosition,
                    isMulti: false,
                },
                schema: { 'position': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Position') },
                doSubmit: doSubmit
            },
        },
        {
            path: "is_active", label: "Status", sortPath: "is_active.id",
            customContent: {
                type: "inputbox",
                formInfo: {
                    type: 'multiselect',
                    id: "is_active",
                    options: [{ id: 1, label: "Active", value: "Active" }, { id: 0, label: "Inactive", value: "Inactive", }],
                    isMulti: false,
                },
                schema: { 'is_active': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('is_active') },
                doSubmit: doSubmit
            },
        }
    ]

    const buttonGroup = {
        lower_left: true,
        lower_right: [
            {
                type: "outline-primary",
                buttonText: <Fragment>Show New Project Role Form</Fragment>,
                handleClick: () => setShowAddNewProjectRole(true),
                id: 0,
            },
        ]
    };

    return (
        <>
            {projectRoles ?
                <>
                    <CustomTable
                        headerData={columns}
                        bodyData={projectRoles}
                        buttonGroup={buttonGroup}
                        pageSize={10}
                    />
                    <FrameModal modalTitle={"Add New Project Role"} show={showAddNewProjectRole} setShow={setShowAddNewProjectRole} children={<NewProjectRoleForm projectRoles={projectRoles} setProjectRoles={setProjectRoles} />} />
                </> : <Spinner />}
        </>
    );
};

export default ManageProjectRole;