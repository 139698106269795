import { toast } from "react-toastify";
import _ from "lodash";

import http from "../services/httpService";
import {
  PMlistProjectMastersAPI,
  updateProjectDetailAPI,
  patchCommercialDetailAPI,
  listProjectRateAPI,
  updateProjectRateAPI,
  updateProjectSummaryAPI,
  listInvoicesAPI,
  updateInvoiceAPI,
  updateProjectHeaderAPI,
  listCloseoutTrackerAPI,
  listICCCloseoutTrackerAPI,
  updateCloseoutTrackerAPI,
  updateCloseoutTrackerItemAPI,
  closeoutInfoAPI,
  ICCCloseoutInfoAPI,
  listSubcontractAPI,
  updateSubcontractAPI,
  updateProjectMasterAPI,
  listSubcontractorAPI,
  listICCPMProjectsAPI,
} from "../services/backendURLService";
import { PMActions } from "./PM-slice";
import { processAPIData } from "../utils/publicUtil";

// *************************************** project dropdown list ***************************************
export const getProjectsForPMDropdown = () => {
  return async (dispatch) => {
    try {
      const { data: projectMasters } = await http.get(`${PMlistProjectMastersAPI}`);
      dispatch(
        PMActions.setProjectsForPMDropdown({
          projectsForPMDropdown: projectMasters,
        })
      );
    } catch (error) {
      toast.error("Getting projects for PM dropdown list has something wrong!");
    }
  };
};
//************************************** icc project dropdown list ************************************
export const getICCProjectsForPMDropdown = () => {
  return async (dispatch) => {
    try {
      const { data: projectMasters } = await http.get(`${listICCPMProjectsAPI}`);
      dispatch(
        PMActions.setProjectsForPMDropdown({
          projectsForPMDropdown: projectMasters,
        })
      );
    } catch (error) {
      toast.error("Getting projects for PM dropdown list has something wrong!");
    }
  };
};
// *************************************** project initialization ***************************************
export const getProjectMaster = (projectID, iccProjects = false) => {
  return async (dispatch) => {
    try {
      const { data: projectMaster } = await http.get(
        `${updateProjectMasterAPI(projectID)}`, {
        headers: {
          'icc': iccProjects
        }
      }
      );
      dispatch(
        PMActions.setProjectMaster({
          projectMaster: projectMaster,
        })
      );
    } catch (error) {
      toast.error("Getting Project Master has something wrong!");
    }
  };
};
export const getProjectDetail = (projectID, iccProjects = false) => {
  return async (dispatch) => {
    try {
      const { data: projectDetail } = await http.get(
        `${updateProjectDetailAPI(projectID)}`, {
        headers: {
          'icc': iccProjects
        }
      }
      );
      const project_detail_organized = processAPIData(projectDetail, ["title"]);
      dispatch(
        PMActions.setProjectDetail({
          projectDetail: project_detail_organized,
        })
      );
    } catch (error) {
      toast.error("Getting Project Detail has something wrong!");
    }
  };
};
export const patchProjectDetail = (
  projectID,
  modifiedProjectDetail,
  commercial = false,
  iccProjects = false
) => {
  return async (dispatch) => {
    try {
      modifiedProjectDetail = {...modifiedProjectDetail, 'icc': iccProjects};
      if (commercial)
        await http.toastPatch(
          `${patchCommercialDetailAPI(projectID)}`,
          modifiedProjectDetail
        );
      else
        await http.toastPatch(
          `${updateProjectDetailAPI(projectID)}`,
          modifiedProjectDetail
        );
      dispatch(
        PMActions.modifyProjectDetail({
          modifiedProjectDetail: {
            ...modifiedProjectDetail,
            project_id: projectID,
          },
        })
      );
    } catch (error) {
      toast.error("Updating project Detail has something wrong!");
    }
  };
};
export const getProjectRates = (projectID) => {
  return async (dispatch) => {
    try {
      const { data: projectRates } = await http.get(
        `${listProjectRateAPI(projectID)}`
      );
      dispatch(
        PMActions.setProjectRates({
          projectRates: projectRates,
        })
      );
    } catch (error) {
      toast.error(error.response.data);
    }
  };
};
export const postProjectRate = (projectID, selectedPillarItemID) => {
  return async (dispatch) => {
    try {
      const { data: newCreatedProjectRates } = await http.toastPost(
        `${listProjectRateAPI(projectID)}`,
        selectedPillarItemID
      );
      dispatch(
        PMActions.setProjectRates({
          projectRates: { rates: newCreatedProjectRates },
        })
      );
    } catch (error) {
      toast.error(error.response.data);
    }
  };
};
export const patchProjectRate = (
  projectID,
  projectRateID,
  modifiedHourlyRate
) => {
  return async (dispatch) => {
    try {
      const { data: newProjectRate } = await http.patch(
        `${updateProjectRateAPI(projectID, projectRateID)}`,
        modifiedHourlyRate
      );
      dispatch(
        PMActions.modifyProjectRate({
          modifiedProjectRate: newProjectRate,
        })
      );
    } catch (error) {
      toast.error(error.response.data);
    }
  };
};


export const patchProjectOffer = (projectID, data) => {
  return async (dispatch) => {
    try {
      await http.patch(`${updateProjectMasterAPI(projectID)}`, data);

      dispatch(
        PMActions.updateOfferNumber({
          offer_number: data.offer_number,
          projectID: projectID,
        })
      );
    } catch (error) {
      toast.error("Patch project offer number has something wrong!");
      console.log(error)
    }
  };
};
// *************************************** project management ******************************************
export const getProjectSummary = (projectID) => {
  return async (dispatch) => {
    try {
      const { data: projectSummary } = await http.get(
        `${updateProjectSummaryAPI(projectID)}`
      );
      // console.log("PM-actions - getProjectSummary - projectSummary", projectSummary);
      let organizedProjectSummary = processAPIData(projectSummary, ["title"]);
      organizedProjectSummary = {
        ...organizedProjectSummary,
        pillar_involved: organizedProjectSummary.pillar_involved.map((obj) => ({
          id: obj.id,
          value: obj.title,
          label: obj.title,
          isFixed: false,
        })),
        pillar_options: organizedProjectSummary.pillar_options.map((obj) => ({
          id: obj.pillar_item_id,
          value: obj.pillar_title,
          label: obj.pillar_title,
          isFixed: false,
        })),
      };
      // console.log("PM-actions - getProjectSummary - organizedProjectSummary", organizedProjectSummary);
      dispatch(
        PMActions.setProjectSummary({
          projectSummary: organizedProjectSummary,
        })
      );
    } catch (error) {
      //toast.error("Error with getting project summary");
      console.log(error.response.data)
    }
  };
};
export const patchProjectSummary = (projectID, projectSummaryData) => {
  return async (dispatch) => {
    try {
      await http.toastPatch(
        `${updateProjectSummaryAPI(projectID)}`,
        projectSummaryData
      );
      dispatch(
        PMActions.modifyProjectSummary({
          modifiedProjectSummary: {
            ...projectSummaryData,
            project_id: projectID,
          },
        })
      );
    } catch (error) {
      toast.error(error.response.data);
    }
  };
};
// ********************************************* PM invoicing ******************************************
export const getPMInvoices = (projectID) => {
  return async (dispatch) => {
    try {
      const { data: invoices } = await http.get(
        `${listInvoicesAPI(projectID)}`
      );
      dispatch(
        PMActions.setPMInvoices({
          InvoicesData: invoices,
          projectID: projectID,
        })
      );
    } catch (error) {
      toast.error("Getting PM invoices has something wrong!");
    }
  };
};
export const addPMInvoice = ({ projectID, newInvoice }) => {
  return async (dispatch) => {
    try {
      const { data: newCreatedInvoice } = await http.post(
        `${listInvoicesAPI(projectID)}`,
        newInvoice
      );

      // console.log(
      //   "PM-actions - addPMInvoice - newCreatedInvoice",
      //   newCreatedInvoice
      // );

      dispatch(PMActions.addInvoice({ newInvoice: newCreatedInvoice }));
    } catch (error) {
      toast.error("Adding invoice in PM has something wrong!");
    }
  };
};
export const updatePMInvoice = ({ updatedInfo, originalRowInfo }) => {
  return async (dispatch) => {
    try {
      const response = await http.patch(
        `${updateInvoiceAPI(
          originalRowInfo.projectID,
          originalRowInfo.invoiceID
        )}`,
        updatedInfo
      );
      dispatch(PMActions.updateInvoice({ updatedInvoice: response.data }));
    } catch (error) {
      toast.error("Updating invoice has something wrong!");
    }
  };
};
export const deletePMInvoice = ({ invoiceID, projectID }) => {
  return async (dispatch) => {
    try {
      dispatch(PMActions.deleteInvoice({ invoiceID, projectID }));
      const response = await http.delete(
        `${updateInvoiceAPI(projectID, invoiceID)}`
      );
    } catch (error) {
      toast.error("Delete invoice has something wrong!");
    }
  };
};
// *********************************************** PM closeout *****************************************
export const getProjectHeader = (projectID, iccProjects = false) => {
  return async (dispatch) => {
    try {
      const { data: projectHeader } = await http.get(
        `${updateProjectHeaderAPI(projectID)}`, {
        headers: {
          'icc': iccProjects
        }
      }
      );
      // console.log(
      //   "PM-actions - getProjectHeader - projectHeader",
      //   projectHeader
      // );
      const project_header_organized = processAPIData(projectHeader, ["title"]);
      dispatch(
        PMActions.setProjectHeader({ projectHeader: project_header_organized })
      );
    } catch (error) {
      toast.error("Getting PM project header has something wrong!");
    }
  };
};
export const  patchProjectHeader = (projectID, modifiedProjectHeader, iccProjects = false) => {
  return async (dispatch) => {
    console.log('iccTest', modifiedProjectHeader)
    try {
      const key = Object.keys(modifiedProjectHeader)[0];
      const sumitData = { [key]: modifiedProjectHeader[key].id , icc:iccProjects.toString()};
      //const headers={'icc':iccProjects.toString()}

      await http.toastPatch(`${updateProjectHeaderAPI(projectID)}`, sumitData);
      /* There is no need to get closeout tracker here, because the closeout tracker is prepared during the new project. When changing a project from open to complete, normally the closeout tracker should be empty, it comes directly from the database, and there will be no change. */
      dispatch(getCloseoutInfo(projectID));

      console.log(
        "PM-actions - patchProjectHeader - modifiedProjectHeader",
        modifiedProjectHeader
      );

      dispatch(
        PMActions.modifyProjectHeader({
          modifiedProjectHeader: modifiedProjectHeader,
        })
      );
    } catch (error) {
      toast.error("Patching PM project header has something wrong!");
    }
  };
};
export const getPMCloseoutTrackers = (projectID, iccProjects=false) => {
  return async (dispatch) => {
    try {
      const apiUrl = iccProjects ? listICCCloseoutTrackerAPI(projectID) : listCloseoutTrackerAPI(projectID);
      const { data: closeout_tracker } = await http.get(apiUrl);
      dispatch(
        PMActions.setPMCloseoutTrackers({
          PMCloseoutTrackers: closeout_tracker,
        })
      );
    } catch (error) {
      toast.error("Getting PM closeout tracker has something wrong!");
    }
  };
};
export const patchPMCloseoutTracker = (
  projectID,
  closeoutTrackerID,
  comment
) => {
  return async (dispatch) => {
    try {
      await http.patch(
        `${updateCloseoutTrackerAPI(projectID, closeoutTrackerID)}`,
        comment
      );

      // console.log("PM-actions - patchPMCloseoutTracker - comment", comment);

      dispatch(
        PMActions.modifyPMCloseoutTrackers({
          modifiedPMCloseoutTrackers: {
            ...comment,
            closeout_tracker_id: closeoutTrackerID,
          },
        })
      );
    } catch (error) {
      toast.error("Patching PM project header has something wrong!");
    }
  };
};
export const patchPMCloseoutTrackerItem = (
  projectID,
  closeoutTrackerID,
  closeoutTrackerItemID,
  updatedClsTrckrItm
) => {
  return async (dispatch) => {
    try {
      await http.patch(
        `${updateCloseoutTrackerItemAPI(
          projectID,
          closeoutTrackerID,
          closeoutTrackerItemID
        )}`,
        updatedClsTrckrItm
      );

      dispatch(
        PMActions.modifyPMCloseoutTrackerItem({
          modifiedPMCloseoutTrackerItem: {
            ...updatedClsTrckrItm,
            closeout_tracker_item_id: closeoutTrackerItemID,
          },
        })
      );
    } catch (error) {
      toast.error("Patching PM project header has something wrong!");
    }
  };
};
export const getCloseoutInfo = (projectID, iccProjects=false) => {
  return async (dispatch) => {
    try {
      const apiUrl = iccProjects ? ICCCloseoutInfoAPI(projectID) : closeoutInfoAPI(projectID);
      const { data: closeout_info } = await http.get(apiUrl);
      // console.log(
      //   "PM-actions - getCloseoutInfo - closeout_info",
      //   closeout_info
      // );
      dispatch(PMActions.setCloseoutInfo({ closeoutInfo: closeout_info }));
    } catch (error) {
      toast.error("getCloseoutInfo wrong!");
    }
  };
};
// *********************************************** PM subcontract *****************************************
export const getPMSubcontracts = (projectID) => {
  return async (dispatch) => {
    try {
      const { data: subcontracts } = await http.get(
        `${listSubcontractAPI(projectID)}`
      );
      var processedData;
      if (subcontracts.length > 0) {
        processedData = subcontracts.reduce((array, item, index) => {
          array[index] = processAPIData(item, ["title"]); // you can use other keys based on your needs
          return array;
        }, []);
      } else {
        processedData = subcontracts;
      }
      dispatch(
        PMActions.setPMSubcontracts({
          SubcontractsData: processedData,
          projectID: projectID,
        })
      );
    } catch (error) {
      toast.error("Getting PM subcontracts has something wrong!");
    }
  };
};
export const updatePMSubcontract = ({ updatedInfo, originalRowInfo }) => {
  return async (dispatch) => {
    try {
      let processedUpdatedInfo = { ...updatedInfo };
      for (let key in processedUpdatedInfo) {
        if (
          typeof processedUpdatedInfo[key] === "object" &&
          processedUpdatedInfo[key] !== null &&
          "id" in processedUpdatedInfo[key]
        ) {
          processedUpdatedInfo[key] = processedUpdatedInfo[key].id;
          break;
        }
      }

      const { data: subcontracts } = await http.patch(
        `${updateSubcontractAPI(
          originalRowInfo.projectID,
          originalRowInfo.subcontractID
        )}`,
        processedUpdatedInfo
      );

      let processedData = processAPIData(subcontracts, ["title"]);

      dispatch(
        PMActions.updateSubcontract({ updatedSubcontract: processedData })
      );
    } catch (error) {
      toast.error("Updating subcontract has something wrong!");
    }
  };
};
// *********************************************** PMO options *****************************************
export const getPMSubcontractors = (officeID) => {
  return async (dispatch) => {
    try {
      const { data: subcontractors } = await http.get(
        `${listSubcontractorAPI(officeID)}`
      );

      const orgnizedSubcontractors = subcontractors.map((subcontractor) => ({
        id: subcontractor.id,
        label: subcontractor.title,
        value: subcontractor.title,
      }));

      dispatch(
        PMActions.setSubcontractors({
          subcontractors: orgnizedSubcontractors,
        })
      );
    } catch (error) {
      toast.error("Getting PM subcontractors has something wrong!");
    }
  };
};
