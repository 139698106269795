import React, { useState, useEffect } from "react";
import { Table, Row, Col } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { BsPlus, BsDash } from "react-icons/bs";
import FeeSummaryTable from "./FeeSummaryTable";
import { feeSummaryAPI } from '../../../services/backendURLService';
import http from "../../../services/httpService";
import Spinner from '../../../components/Spinner';
import { toast } from 'react-toastify';
import { conditionalRenderCurrencyAmount } from "../../../utils/publicUtil";
import { useSelector } from "react-redux";
import { office } from "../../../services/constService";

const DisplayList = ({ data, feeSummary, formatValue }) => (
  <Table>
    {data.map((row) => (
      <>
        <tr>
          <td>{row.title}</td>
        </tr>
        <tr className="itemFeeSummary">
          <td className="itemData">
            {row.isCurrency
              ? conditionalRenderCurrencyAmount(feeSummary[row.key],)
              : formatValue(feeSummary[row.key], row.isPercentage)}
          </td>
        </tr>
      </>
    ))}
  </Table>
);

const FeeSummary = ({ projectID }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const user = useSelector((state) => state.generic.currentUser);
  const officeID = user.office_id;

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  //API Call to pull data
  const [feeSummary, setFeeSummary] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get(`${feeSummaryAPI(projectID)}`);
        console.log("FeeSummary - useEffect - response", response)

        const data = response.data;
        setFeeSummary(data);
      } catch (error) {
        const errorMessage = error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'An unknown error occurred';

        toast.warning(errorMessage);
      }
    };

    fetchData();
  }, []);

  if (feeSummary === null) {
    return <div><Spinner /></div>;
  }

  const formatValue = (value, isPercentage = false) => {
    if (value === undefined || value === null) return 'N/A';
    return isPercentage ? `${(value * 100).toFixed(2)}%` : `${value}`;
  };

  const feeSummaryData = [
    { title: 'Estimated Internal Fees Spend', key: 'total_internal_spend', isCurrency: true },
    { title: 'Internal Fees Budget', key: 'internal_budget', isCurrency: true },
    { title: 'Estimated Internal Budget Consumption', key: 'total_internal_percent', isPercentage: true },
    { title: 'External Invoice to Date', key: 'total_external_spend', isCurrency: true },
    { title: 'External Fees Budget', key: 'external_budget', isCurrency: true },
    { title: 'External Budget Consumption', key: 'total_external_percent', isPercentage: true },
    { title: 'Invoiced to Date (Excluding Travel)', key: 'invoiced', isCurrency: true },
    { title: 'Estimated Total Internal + External Fees', key: 'total_spend', isCurrency: true },
    { title: 'Total Internal + External Fees Budget', key: 'total_budget', isCurrency: true },
    { title: 'Estimated Total Budget Consumption', key: 'total_percent', isPercentage: true },
  ];

  if (officeID==office.BUENOS_AIRES || officeID==office.SAO_PAULO){
    feeSummaryData.push( { title: 'Total Percent of Consumed Hours', key: 'total_percent_consumed_hour', isPercentage: true });
  }

  const actualRecordData = [
    { title: 'Internal Actuals to Date', key: 'actual_internal_spend', isCurrency: true },
    { title: 'Percent of Total Budget Consumed (Excluding External)', key: 'actual_internal_percent', isPercentage: true },
    { title: 'External Actuals to Date', key: 'actual_external_spend', isCurrency: true },
    { title: 'Percent of External Budget Consumed', key: 'actual_external_percent', isPercentage: true },
    { title: 'Total Fees Consumed to Date', key: 'actual_total_spend', isCurrency: true },
    { title: 'Percent of Total Budget Consumed (Including External)', key: 'actual_total_percent', isPercentage: true },
  ];

  const predictSpendData = [
    { title: 'Predicted Spend to Date', key: 'predicted_internal_spend', isCurrency: true },
    { title: 'Additional Predicted Percent Budget Consumed', key: 'predicted_internal_percent', isPercentage: true },
  ];

  const forecastedSpendData = [
    { title: 'Forecasted Spend', key: 'forecasted_internal_spend', isCurrency: true },
    { title: 'Forecasted Internal Budget Consumption', key: 'forecasted_internal_percent', isPercentage: true },
  ];

  console.log('office',office);
  console.log('feeSummary',feeSummary);
  return (
    <>
      <Table className="feesSummary">
        <thead>
          <tr>
            <th colSpan="2">Fees Summary</th>
          </tr>
        </thead>

        <tbody>
          <Row>
            <Table className="refreshTable">
              <DisplayList data={feeSummaryData} feeSummary={feeSummary} formatValue={formatValue} />
            </Table>
          </Row>

          <Row>
            <Col colSpan={2}>
              <Table className="breakDown">
                <thead>
                  <tr>
                    <th>
                      Breakdown
                      <button
                        className="collapseButton"
                        onClick={handleToggleCollapse}
                      >
                        {isCollapsed ? (
                          <BsPlus style={{ fontSize: "24px", width: "32px" }} />
                        ) : (
                          <BsDash style={{ fontSize: "24px", width: "32px" }} />
                        )}
                      </button>
                    </th>
                  </tr>
                </thead>
                {!isCollapsed && (
                  <tbody>
                    <Tabs>
                      <TabList id="breakDownTab">
                        <Tab>Actuals Recorded</Tab>
                        <Tab>Predicted Spend to Date</Tab>
                        <Tab>Forecasted Incremental Spend</Tab>
                        <Tab>Assumptions and Details</Tab>
                      </TabList>

                      <TabPanel className="actualRecord">
                        <DisplayList data={actualRecordData} feeSummary={feeSummary} formatValue={formatValue} />
                        <FeeSummaryTable feeSummary={feeSummary} />
                      </TabPanel>

                      <TabPanel className="predictSpend">
                        <DisplayList data={predictSpendData} feeSummary={feeSummary} formatValue={formatValue} />
                        <FeeSummaryTable feeSummary={feeSummary} />
                      </TabPanel>

                      <TabPanel className="forecasteSpend">
                        <DisplayList data={forecastedSpendData} feeSummary={feeSummary} formatValue={formatValue} />
                        <FeeSummaryTable feeSummary={feeSummary} />
                      </TabPanel>

                      <TabPanel>
                        <p className="assumption">
                          The "Predicted Total Cost" is built using actuals
                          recorded, predicted spend to date, forecasted
                          incremental spend, and 100% of subcontracted fees.
                          <br />
                          <br />
                          Actuals recorded reflect actual project hours saved by
                          team members.
                          <br />
                          <br />
                          Predicted spend to date reflects the "lead time"
                          between working hours and hours entry by taking all
                          projected hours from staffing that were forecasted to
                          occur before today.
                          <br />
                          <br />
                          Forecasted incremental spend reflects the future hours
                          predicted to be consumed using staffing (e.g. 5 days
                          staffed on a project in a given week = 40 hours
                          forecasted on project).
                          <br />
                          <br />
                          The "Actuals Recorded" tab combines estimated
                          subcontracting consumption to date and actual hours
                          consumption to date to calculate the total fees to
                          date and percent of budget consumed to date.
                          <br />
                          <br />
                          Travel fees are not considered in total budget or
                          budget consumption; they are managed in the "Travel
                          Fees" tab at the top
                        </p>
                      </TabPanel>
                    </Tabs>
                  </tbody>
                )}
              </Table>
            </Col>
          </Row>
        </tbody>
      </Table>
    </>
  );
};

export default FeeSummary;
