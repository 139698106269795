import _ from "lodash";

// transformAvailableDaysBody: convert origian data availableDays to id & value
export function transformAvailableDaysBody(availableDays) {
  /* splite the first element(date info) and the other elements(available days) */
  const dateInfo = availableDays.shift();

  const organizedAvailableDays = [...availableDays].map((item) => {
    const newItem = {
      id: item.resource.resource_id,
      resource: {
        id: item.resource.resource_id,
        value: item.resource.resource_full_name,
      },
    };

    for (let key in item) {
      if (key !== "resource") {
        newItem[key] = {
          id: item[key].available_day_id,
          value: item[key].available_days,
          working_days: item[key].working_days,
        };
      }
    }

    return newItem;
  });

  const dateAndAvailable = [...[dateInfo], ...organizedAvailableDays];
  return dateAndAvailable;
}

/*
{
    "6": {
        "staffing_id": 56,
        "forecasted_days": 1
    },
    "7": {
        "staffing_id": null,
        "forecasted_days": 0
    },
    "project_role_id": 30,
    "project": {
        "project_number": "002-23",
        "project_id": 27,
        "project_name": "GLOCAL CF Material Flow GLO-10"
    },
    "resource": {
        "resource_id": 1,
        "resource_full_name": "Ching Yip"
    }
}
------>
{
    "6": {
        "id": 56,
        "value": 1
    },
    "7": {
        "id": null,
        "value": 0
    },
    "id": 30,
    "resource": {
        "id": 1,
        "value": "Ching Yip"
    },
    "project": {
        "id": 27,
        "value": "002-23 GLOCAL CF Material Flow GLO-10"
    }
}
*/

export function transformForecastedDaysBody(forecastedDays) {
  const dateInfo = forecastedDays.shift();

  const organizedForecastedDays = [...forecastedDays].map((item) => {
    const newItem = {
      id: item.project_role_id,
      resource: {
        id: item.resource.resource_id,
        value: item.resource.resource_full_name,
      },
      project: {
        id: item.project.project_id,
        value: item.project.project_number + " " + item.project.project_name,
      },
      manager: {
        id: item.manager.manager_id,
        value: item.manager.manager_name,
      },
    };

    for (let key in item) {
      if (
        key !== "resource" &&
        key !== "project" &&
        key !== "manager" &&
        key !== "project_role_id"
      ) {
        newItem[key] = {
          id: item[key].staffing_id,
          value: item[key].forecasted_days,
        };
      }
    }

    return newItem;
  });

  // console.log(
  //   "staffing utils - transformForecastedDaysBody - organizedForecastedDays",
  //   organizedForecastedDays
  // );

  const dateAndForecasted = [...[dateInfo], ...organizedForecastedDays];

  return dateAndForecasted;
}

/*
data = [
  {
    "6": "2023-10-01",
    "7": "2023-10-08",
    "8": "2023-10-15",
    "9": "2023-10-22",
    "10": "2023-10-29",
    "25": "2023-07-23",
    "26": "2023-07-30",
    "27": "2023-08-06",
    "28": "2023-08-13",
    "29": "2023-08-20",
    "30": "2023-08-27",
    "title": "Week of",
  },
  {
    "6": 5,
    "7": 4,
    "8": 5,
    "9": 4,
    "10": 5,
    "25": 5,
    "26": 5,
    "title": "Indianapolis",
  },
  {
    "6": 2,
    "7": 2,
    "8": 2,
    "9": 2,
    "10": 2,
    "25": 5,
    "26": 5,
    "title": "Montreal",
  },
] --->
result = [
    { path: 25, label: "2023-07-23" },
    { path: 26, label: "2023-07-30" },
    { path: 27, label: "2023-08-06" },
    { path: 28, label: "2023-08-13" },
    { path: 29, label: "2023-08-20" },
    { path: 30, label: "2023-08-27" },
    { path: 6, label: "2023-10-01" },
    { path: 7, label: "2023-10-08" },
    { path: 8, label: "2023-10-15" },
    { path: 9, label: "2023-10-22" },
    { path: 10, label: "2023-10-29" },
]

We sort from the front end, in fact, the back end has already been sorted once. But after http transmission, the attribute of js is that the object does not retain the sort. So it must be sorted from the front end.
*/
export function prepareDateHeader(data) {
  // console.log("staffingUtil - prepareDateHeader - data", data);
  /* get dates */
  const dates = Object.keys(data[0]).map((key) => ({
    path: parseInt(key),
    label: data[0][key],
  }));

  /* Sort dates by date value */
  dates.sort((a, b) => {
    const dateA = new Date(a.label);
    const dateB = new Date(b.label);
    return dateA - dateB;
  });

  // console.log("staffingUtil - prepareDateHeader - dates", dates);
  return dates;
}

/* The first row of data is week data, which is used as header. It needs to be removed when preparing body data. */
export function removeFirstElement(data) {
  const dataArray = [...data];

  // Create a copy of the array using slice()
  const dataArrayCopy = dataArray.slice();

  // Remove the first element from the dataArrayCopy
  const removeFirstElement = dataArrayCopy.shift();

  return dataArrayCopy;
}

export function conditionalRenderColor(utilizationValue) {
  let cellColor = null;
  if (utilizationValue >= 1.2) cellColor = "#d27f6f"; // red
  else if (utilizationValue < 1.2 && utilizationValue >= 0.9)
    cellColor = "#b9c69b"; // green
  else if (utilizationValue < 0.9 && utilizationValue >= 0.75)
    cellColor = "#ddb05d"; // yellow
  else if (utilizationValue < 0.75) cellColor = null; // no color
  return cellColor;
}
