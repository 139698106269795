import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    prepareNewProject: {},
    AdminInvoices: null,
    AdminSubcontracts: null,
    /* 
      projectsForAdminDropdown used in:
      1. create invoice
      2. create subcon
      3. update project info
    */
    projectsForAdminDropdown: [],
    clients: [],
    subcontractors: [],
  },
  reducers: {
    // ************************************************* project ******************************************
    setProjectsForAdminDropdown(state, action) {
      state.projectsForAdminDropdown = action.payload.projectsForAdminDropdown;
    },
    updateProjectName(state, action) {
      const { projectID, projectNewName } = action.payload;
      const index = state.projectsForAdminDropdown.findIndex(
        (project) => project.id == projectID
      );
      // console.log("admin-slice - updateProjectName - index", index);
      state.projectsForAdminDropdown[index].name = projectNewName;
    },
    setPrepareNewProject(state, action) {
      state.prepareNewProject = action.payload.prepareNewProject;
    },
    // ************************************************* invoices *****************************************
    setAdminInvoices(state, action) {
      state.AdminInvoices = action.payload.AdminInvoices;
    },
    updateAdminInvoice(state, action) {
      const { updatedInvoice } = action.payload;
      const index = state.AdminInvoices.findIndex(
        (invoice) => invoice.id === updatedInvoice.id
      );
      if (index >= 0) {
        state.AdminInvoices[index] = updatedInvoice;
      }
    },
    addInvoice(state, action) {
      const newRow = {
        ...action.payload.newInvoice,
        invoice_date: "",
      };
      state.AdminInvoices.push(newRow);
    },
    deleteInvoice(state, action) {
      const rowToDelete = state.AdminInvoices.findIndex(
        (invoice) => invoice.id === action.payload.invoiceID
      );
      if (rowToDelete !== -1) {
        state.AdminInvoices.splice(rowToDelete, 1);
      }
    },
    // *********************************************** subcontract *****************************************
    setAdminSubcontracts(state, action) {
      state.AdminSubcontracts = action.payload.AdminSubcontracts;
    },
    addAdminSubcontract(state, action) {
      const newRow = {
        ...action.payload,
      };
      state.AdminSubcontracts.push(newRow);
    },
    updateAdminSubcontract(state, action) {
      const { updatedSubcontract } = action.payload;
      const index = state.AdminSubcontracts.findIndex(
        (subcontract) => subcontract.id === updatedSubcontract.id
      );

      if (index >= 0) {
        state.AdminSubcontracts[index] = updatedSubcontract;
      }
    },
    deleteAdminSubcontract(state, action) {
      const rowToDelete = state.AdminSubcontracts.findIndex(
        (row) => row.id === action.payload.subcontractID
      );
      if (rowToDelete !== -1) {
        state.AdminSubcontracts.splice(rowToDelete, 1);
      }
    },
    updateAdminInvoicesToConnect(state, action) {
      const { InvoicesToConnect } = action.payload;
      const index = state.AdminInvoicesToConnect.findIndex(
        (subcontract) => subcontract.id === InvoicesToConnect.id
      );

      if (index >= 0) {
        state.AdminInvoicesToConnect[index] = InvoicesToConnect;
      }
    },
    // *********************************************** options *****************************************
    setClients(state, action) {
      state.clients = action.payload.clients;
    },
    setSubcontractors(state, action) {
      state.subcontractors = action.payload.subcontractors;
    },
  },
});

export const adminActions = adminSlice.actions;

export default adminSlice;
