import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useSelector } from "react-redux";
import { useState } from "react";

import { subcontractStatus } from "../../../services/constService";
import AdminConfirmSubcontracts from "./AdminConfirmSubcontracts";
import AdminConnectSubcontracts from "./AdminConnectSubcontracts";
import AdminApproveSubcontracts from "./AdminApproveSubcontracts";
import AdminPendingPaySubcontracts from "./AdminPendingPaySubcontracts";
import AdminPaidSubcontracts from "./AdminPaidSubcontracts";
import AdminCreateSubcontracts from "./AdminCreateSubcontracts";
import AdminAllSubcontracts from "./AdminAllSubcontracts";
import Spinner from "../../../components/Spinner";
import { office as lkuOffice } from "../../../services/constService";

const NavAdminSubcontract = () => {
  const Subcontracts = useSelector((state) => state.admin.AdminSubcontracts);
  const [createSubcontracts, setCreateSubcontracts] = useState([]);
  const [approveSubcontracts, setApproveSubcontracts] = useState([]);
  const [connectSubcontracts, setConnectSubcontracts] = useState([]);
  const [confirmSubcontracts, setConfirmSubcontracts] = useState([]);
  const [pendingPaySubcontracts, setPendingPaySubcontracts] = useState([]);
  const [paidSubcontracts, setPaidSubcontracts] = useState([]);
  const user = useSelector((state) => state.generic.currentUser);
  const office = user.office_id

  useEffect(() => {
    if (Subcontracts && Subcontracts.length > 0) {
      setCreateSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.CREATE))
      setApproveSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.APPROVE))
      setConnectSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.CONNECT))
      setConfirmSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.CONFIRM))
      setPendingPaySubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.PENDING_PAY))
      setPaidSubcontracts(Subcontracts.filter(e => e.status.id == subcontractStatus.PAID))
    }
    if (Subcontracts && Subcontracts.length == 0) {
      setCreateSubcontracts([])
      setApproveSubcontracts([])
      setConnectSubcontracts([])
      setConfirmSubcontracts([])
      setPendingPaySubcontracts([])
      setPaidSubcontracts([])
    }
  }, [Subcontracts]
  );

  return (
    <>
      {Subcontracts === null ? (
        <div><Spinner /></div>
      ) : (
        <>
          <Row>
            <Col>
              <h1 className="mb-5">Admin Subcontract View</h1>
              <br />
              <h3 className="mb-5">
                Welcome to the Miebach project subcontract management page!
              </h3>
            </Col>
          </Row>

          <Tabs>
            <TabList>
              <Tab>Subcontract Management</Tab>
              <Tab>All Subcontracts</Tab>
            </TabList>

            <TabPanel>
              {(office == lkuOffice.BARCELONA || office == lkuOffice.MADRID) ?
                /* Spain Offfice - Subcontract - only need the first two steps */
                <Row>
                  <Col xs={12}>
                    <Tabs>
                      <TabList>
                        <Tab>Add Subcontractor Invoice</Tab>
                        <Tab>Approve Project</Tab>
                      </TabList>

                      <TabPanel>
                        <AdminCreateSubcontracts createSubcontracts={createSubcontracts} />
                      </TabPanel>
                      <TabPanel>
                        <AdminApproveSubcontracts approveSubcontracts={approveSubcontracts} />
                      </TabPanel>
                    </Tabs>
                  </Col>
                </Row> :
                <Row>
                  <Col xs={12}>
                    <Tabs>
                      <TabList>
                        <Tab>Admin Workload</Tab>
                        <Tab>PM Workload</Tab>
                      </TabList>

                      <TabPanel>
                        <Row>
                          <Col xs={12}>
                            <Tabs>
                              <TabList>
                                <Tab>Add Subcontractor Invoice</Tab>
                                <Tab>Connect to Client Invoice</Tab>
                                <Tab>Confirm Payment Date</Tab>
                                <Tab>Subcontract Paid</Tab>
                              </TabList>

                              <TabPanel>
                                <AdminCreateSubcontracts createSubcontracts={createSubcontracts} />
                              </TabPanel>
                              <TabPanel>
                                <AdminConnectSubcontracts connectSubcontracts={connectSubcontracts} />
                              </TabPanel>
                              <TabPanel>
                                <AdminPendingPaySubcontracts pendingPaySubcontracts={pendingPaySubcontracts} />
                              </TabPanel>
                              <TabPanel>
                                <AdminPaidSubcontracts paidSubcontracts={paidSubcontracts} />
                              </TabPanel>
                            </Tabs>
                          </Col>
                        </Row>
                      </TabPanel>
                      <TabPanel>
                        <Row>
                          <Col xs={12}>
                            <Tabs>
                              <TabList>
                                <Tab>Approve Project</Tab>
                                <Tab>Approve to Pay Subcontractor</Tab>
                              </TabList>
                              <TabPanel>
                                <AdminApproveSubcontracts approveSubcontracts={approveSubcontracts} />
                              </TabPanel>
                              <TabPanel>
                                <AdminConfirmSubcontracts confirmSubcontracts={confirmSubcontracts} />
                              </TabPanel>
                            </Tabs>
                          </Col>
                        </Row>
                      </TabPanel>
                    </Tabs>
                  </Col>
                </Row>}
            </TabPanel>

            <TabPanel>
              <AdminAllSubcontracts Subcontracts={Subcontracts} />
            </TabPanel>
          </Tabs>
        </>
      )}
    </>
  );
};

export default NavAdminSubcontract;
