import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { FaSignOutAlt } from 'react-icons/fa';

import logo from "../assets/logo.svg";
import ICCSwitch from "./ICCSwitch";
import { toggleOn } from "../store/icc-slice";




function NavBarForICCHomePage() {
  const user = useSelector((state) => state.generic.currentUser);
  const accessPermissions = useSelector((state) => state.generic.accessPermissions);
  const switchValue = useSelector((state) => state.ICC.iccSwitchState);
  const dispatch = useDispatch();

  if (switchValue == false) {
     dispatch(toggleOn());
  } 

  return (
    <div>
      <Navbar bg="light" expand="lg" className="mb-3 justify-content-between">
        <Navbar.Brand as={Link} to="/home">
          <img src={logo} alt="logo" width={"110px"} />
        </Navbar.Brand>

        <Nav>

                {/* {(accessPermissions.ICC_USER || accessPermissions.ICC_ADMIN) &&(
                <ICCSwitch/>)} */}

          {user.username ? (
            <>
              <span className="greeting">Hello {user.first_name}! &nbsp;&nbsp;&nbsp;</span>
              <Nav.Link as={Link} to="/logout">
                <FaSignOutAlt />&nbsp;&nbsp;Logout&nbsp;&nbsp;&nbsp;
              </Nav.Link>

            </>
          ) : (
            <Nav.Link as={Link} to="/login">
              Login&nbsp;&nbsp;&nbsp;
            </Nav.Link>
          )}
        </Nav>
      </Navbar>
    </div>
  );
}

export default NavBarForICCHomePage;
