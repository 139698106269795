import { toast } from "react-toastify";
import _ from "lodash";

import { ICCAction } from "./icc-slice";
import http from "../services/httpService";
import {
  allICCUsersAPI,
} from "../services/backendURLService";
import { appRole } from "../services/constService";
import { processAPIData2, generateOptions,processAPIData } from "../utils/publicUtil";
import { adminActions } from "./admin-slice";
import { 
  adminInvoicesAPI,
  adminSubcontractAPI,
  adminListProjectMastersAPI
 } from "../services/backendURLService";
 

// ******************************************** Users ***********************************
export const getAllICCUsers = () => {
  return async (dispatch) => {
    try {
      const { data: all_icc_users } = await http.get(allICCUsersAPI);
      dispatch(
        ICCAction.setAllICCUsers({
          iccUsers: all_icc_users,
        })
      );
    } catch (error) {
      toast.error("ERROR in get all icc users!");
      console.log("ERROR: " + error);
    }
  };
};


// **************************************************** invoices *********************************************
export const getICCAdminInvoices = (iccProjects = false) => {
  return async (dispatch) => {
    try {
      const { data: invoices } = await http.get(`${adminInvoicesAPI}`,
      {headers: {
          'icc': iccProjects.toString()
        }
      });
      var processedData;
      if (invoices.length > 0) {
        processedData = invoices.reduce((array, item, index) => {
          array[index] = processAPIData(item, ["title"]);
          return array;
        }, []);
      } else {
        processedData = invoices;
      }
      dispatch(
        adminActions.setAdminInvoices({
          AdminInvoices: processedData,
        })
      );
    } catch (error) {
      toast.error("Getting Admin invoices has something wrong!");
    }
  };
};


// *************************************************** subcontract *******************************************
export const getICCAdminSubcontracts = (iccProjects = false) => {
  return async (dispatch) => {
    try {
      const { data: subcontracts } = await http.get(`${adminSubcontractAPI}`,
      {headers: {
        'icc': iccProjects.toString()
      }
    });
      var processedData;
      if (subcontracts.length > 0) {
        processedData = subcontracts.reduce((array, item, index) => {
          array[index] = processAPIData(item, ["title"]); // you can use other keys based on your needs
          return array;
        }, []);
      } else {
        processedData = subcontracts;
      }
      dispatch(
        adminActions.setAdminSubcontracts({
          AdminSubcontracts: processedData,
        })
      );
    } catch (error) {
      toast.error("Getting Admin subcontracts has something wrong!");
    }
  };
};


export const getICCProjectsForAdminDropdown = (iccProjects = false) => {
  return async (dispatch) => {
    try {
      const { data: projectMasters } = await http.get(
        `${adminListProjectMastersAPI}`,
        {headers: {
          'icc': iccProjects.toString()
        }
      });
      var processedData;
      if (projectMasters.length > 0) {
        processedData = projectMasters.reduce((array, item, index) => {
          array[index] = processAPIData(item, ["name"]);
          return array;
        }, []);
      } else {
        processedData = projectMasters;
      }
      dispatch(
        adminActions.setProjectsForAdminDropdown({
          projectsForAdminDropdown: projectMasters,
        })
      );
    } catch (error) {
      toast.error(
        "Getting projects for Admin dropdown list has something wrong!"
      );
    }
  };
};