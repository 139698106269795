import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector ,useDispatch} from "react-redux";
import { FaSignOutAlt } from 'react-icons/fa';
import logo from "../assets/logo.svg"
import ICCSwitch from "./ICCSwitch";
import { toggleOn } from "../store/icc-slice";



function ICCNavBar() {
  const user = useSelector((state) => state.generic.currentUser);
  const accessPermissions = useSelector((state) => state.generic.accessPermissions);
  const location = useLocation();
  const switchValue = useSelector((state) => state.ICC.iccSwitchState);
  const dispatch = useDispatch();

  if (switchValue == false) {
     dispatch(toggleOn());
  } 

  return (
    <div>
      <Navbar bg="light" expand="lg" className="mb-3">
        <Navbar.Brand as={Link} to="/home">
          <img src={logo} alt="logo" width={"110px"} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {user.username && (
              <>
                <Nav.Link as={Link} to={{
                  pathname: '/icc',                       //This causes bugs when user puts URL straight into search bar
                  state: { from: `${location.pathname}` } // Pass the current path as the 'from' property
                }}>   
                  ICC Home
                </Nav.Link>
                {accessPermissions.projectManager && (
                  <NavLink className="nav-item nav-link"
                    to={{
                      pathname: '/icc/pmo',
                      state: { from: `${location.pathname}` }
                    }}
                  >
                    PMO
                  </NavLink>
                )}
                {accessPermissions.admin && (
                  <Nav.Link as={Link} to={{
                    pathname: '/icc/admin',
                    state: { from: `${location.pathname}` }
                  }}>
                    Admin
                  </Nav.Link>
                )}
                {accessPermissions.generalStaff && (<Nav.Link as={Link} to={{
                  pathname: '/icc/settings',
                  state: { from: `${location.pathname}` }
                }}>
                  Settings
                </Nav.Link>
                )}

                  {/* {(accessPermissions.ICC_USER || accessPermissions.ICC_ADMIN) &&(
                <ICCSwitch/>)} */}
                  
              </>
            )}
          </Nav>
        </Navbar.Collapse>

        {user.username ? (<>
          <span className="greeting">Hello {user.first_name}!</span>
          {/* <Nav.Link as={Link} to="/me">
            {user.first_name}
          </Nav.Link> */}
          <Nav.Link as={Link} to="/logout">
            <FaSignOutAlt />&nbsp;&nbsp;Logout&nbsp;&nbsp;&nbsp;
          </Nav.Link>
        </>) :
          <Nav.Link as={Link} to="/login">
            Login&nbsp;&nbsp;&nbsp;
          </Nav.Link>
        }
      </Navbar>
    </div>
  );
}

export default ICCNavBar;
