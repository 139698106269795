import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { MsalProvider } from "@azure/msal-react";

import {
  getCurrentUser,
  getLKUs,
  getCurrentWeek,
  getAllManagers,
  getAllOffices,
  getAllAdmins,
} from "./store/generic-actions";
import { getWeekSummaries, getCOHActiveWeekIDs } from "./store/Coh-actions";

import auth from "./services/authService";
import ProtectedRoute from "./components/ProtectedRoute";
import NavBar from "./components/NavBar";
import navPage from "./screens/NavHome";
import NotFound from "./screens/NotFound";
import PageTest from "./screens/test/PageTest";

import Login from "./screens/auth/Login";
import Logout from "./screens/auth/Logout";
// import Register from "./screens/auth/Register";
// import Me from "./screens/auth/Me";
import LoginWithAzure from "./screens/auth/LoginWithAzure";
import LogoutWithAzure from "./screens/auth/LogoutWithAzure";

import ProjectInitialization from "./screens/pmo/ProjectInitialization/NavProjectInitialization";
import ProjectManagement from "./screens/pmo/ProjectManagement/NavProjectManagement";
import PMInvoicing from "./screens/pmo/Invoicing/NavPMInvoicing";
import PMCloseout from "./screens/pmo/Closeout/PMCloseout";
import NavBarForHomePage from "./components/NavBarForHomePage";

import NewProject from "./screens/admin/NewProject/NewProject";
import CloseoutView from "./screens/approvals/closeout/CloseoutView";

import StaffPlanning from "./screens/staffing/staffPlanning/StaffPlanning";
import NewProjectRoleInStaffing from "./screens/staffing/staffPlanning/NewProjectRoleInStaffing";
import NavAdminInvoicing from "./screens/admin/Invoice/NavAdminInvoicing";
import NavPMSubcontract from "./screens/pmo/Subcontract/NavPMSubcontract";
import NavAdminSubcontract from "./screens/admin/Subcontract/NavAdminSubcontract";
import Footer from "./components/Footer";
import AssginProjectManager from "./screens/staffing/projectInitiation/AssginProjectManager";
import BatchStaffing from "./screens/staffing/projectInitiation/BatchStaffing";
import Welcome from "./screens/auth/Welcome";
import RentProspectProject from "./screens/staffing/projectInitiation/RentProspectProject";
import Projects from "./screens/settings/Projects";
import NewClient from "./screens/settings/NewClient";
import WeekSummaries from "./screens/coh/WeekSummaries";
import ProjectHours from "./screens/coh/ProjectHours";
import ManageProjectRole from "./screens/coh/ManageProjectRole";
import Reports from "./screens/Reports";
import NewSubcontractor from "./screens/settings/NewSubcontractor";
import ICCNavProjectInitialization from "./screens/ICC/PMO/ProjectInitialization/ICCNavProjectInitialization";
import ICCNewProject from "./screens/ICC/Admin/New ICC Project/ICCNewProject";
import ICCPMCloseout from "./screens/ICC/PMO/Closeout/ICCPMCloseout";
import { toggleOff } from "./store/icc-slice";
import NavBarForICCHomePage from "./components/NavBarForICCHomePage";
import ICCNavBar from "./components/ICCNavBar";
import ICCNavAdminInvoicing from "./screens/ICC/Admin/Invoice/ICCNavAdminInvoicing";
import ICCNavAdminSubcontract from "./screens/ICC/Admin/Subcontract/ICCNavAdminSubcontract";
import ICCNavProjectManagement from "./screens/ICC/ProjectManagement/ICCNavProjectManagement";

import SpainBlockWeek from "./screens/coh/SpainBlockWeek";

function App({ instance }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const accessPermissions = useSelector(
    (state) => state.generic.accessPermissions
  );

  const user = useSelector((state) => state.generic.currentUser);


  useEffect(() => {
    if (auth.isActiveToken()) {
      dispatch(getCurrentUser());
      dispatch(getLKUs());
      dispatch(getCurrentWeek());
      dispatch(getAllManagers());
      dispatch(getAllAdmins());
      dispatch(getWeekSummaries());
      dispatch(getAllOffices());
      dispatch(getCOHActiveWeekIDs());
    }
  }, [dispatch]);


  let navComponent;

  if (location.pathname === "/home") {
    navComponent = <NavBarForHomePage />;
  } else if (location.pathname === "/icc") {
    navComponent = <NavBarForICCHomePage />;
  } else if (location.pathname.includes("/icc/")) {
    navComponent = <ICCNavBar />;
  } else {
    navComponent = <NavBar />;
  }

  return (
    <React.Fragment>
      <MsalProvider instance={instance}>
        <ToastContainer />

        {navComponent}

        <main style={{ padding: "30px" }}>
          <Switch>
            {/********************************* auth *********************************/}
            <Route
              path="/login"
              render={(props) => <LoginWithAzure {...props} />}
            />
            <Route
              path="/logout"
              render={(props) => <LogoutWithAzure {...props} />}
            />
            <Route
              path="/login-origin"
              render={(props) => <Login {...props} />}
            />
            <Route path="/welcome" render={(props) => <Welcome {...props} />} />
            {/*<ProtectedRoute path="/logout" component={Logout} />
           <ProtectedRoute path="/register" component={Register} />
          <ProtectedRoute path="/me" render={(props) => <Me {...props} />} /> */}
            {/********************************* generic ******************************/}
            <Route path="/not-found" component={NotFound} />
            <ProtectedRoute
              path="/home"
              render={(props) => <navPage.navHome {...props} />}
            />
            <ProtectedRoute
              path="/test-page"
              render={(props) => <PageTest {...props} />}
            />
            {/*********************************** COH *********************************/}
            <ProtectedRoute
              path="/coh/week-summaries/:week_id-:week_summary_id"
              render={(props) => <ProjectHours {...props} />}
            />
            <ProtectedRoute
              path="/coh/week-summaries"
              render={(props) => <WeekSummaries {...props} />}
            />
            <ProtectedRoute
              path="/coh/manage-project-role"
              render={(props) => <ManageProjectRole {...props} />}
            />
            <ProtectedRoute
              path="/coh/block-month"
              render={(props) => <SpainBlockWeek {...props} />}
            />
            {/********************************* staffing *******************************/}
            <ProtectedRoute
              path="/staffing/staff-planning/new-project-role-staffing"
              render={(props) => <NewProjectRoleInStaffing {...props} />}
            />
            <ProtectedRoute
              path="/staffing/project-initiation/rent-prospect-project"
              render={(props) => <RentProspectProject {...props} />}
            />
            <ProtectedRoute
              path="/staffing/project-initiation/assign-project-manager"
              render={(props) => <AssginProjectManager {...props} />}
            />
            <ProtectedRoute
              path="/staffing/project-initiation/batch-staffing"
              render={(props) => <BatchStaffing {...props} />}
            />
            <ProtectedRoute
              path="/staffing/project-initiation"
              render={(props) => <navPage.navPrjctInit {...props} />}
            />
            <ProtectedRoute
              path="/staffing/staff-planning"
              render={(props) => <StaffPlanning {...props} />}
            />
            <ProtectedRoute
              path="/staffing"
              render={(props) => <navPage.navStaffing {...props} />}
            />
            {/************************************ PMO *********************************/}
            <ProtectedRoute
              path="/pmo/project-initialization"
              render={(props) => <ProjectInitialization {...props} />}
            />
            <ProtectedRoute
              path="/pmo/project-management"
              render={(props) => <ProjectManagement {...props} />}
            />
            <ProtectedRoute
              path="/pmo/pm-invoicing"
              render={(props) => <PMInvoicing {...props} />}
            />
            <ProtectedRoute
              path="/pmo/pm-closeout"
              render={(props) => <PMCloseout {...props} />}
            />
            <ProtectedRoute
              path="/pmo/pm-subcon"
              render={(props) => <NavPMSubcontract {...props} />}
            />
            <ProtectedRoute
              path="/pmo"
              render={(props) => <navPage.navPMO {...props} />}
            />
            {/********************************* admin view *********************************/}
            <ProtectedRoute
              path="/admin/new-project"
              render={(props) => <NewProject {...props} />}
            />
            <ProtectedRoute
              path="/admin/admin-invoice"
              render={(props) => <NavAdminInvoicing {...props} />}
            />
            <ProtectedRoute
              path="/admin/admin-subcontract"
              render={(props) => <NavAdminSubcontract {...props} />}
            />
            <ProtectedRoute
              path="/admin"
              render={(props) => <navPage.navAdmin {...props} />}
            />

            {/********************************** approvals ********************************/}
            <ProtectedRoute
              path="/approvals/closeout-view"
              render={(props) => <CloseoutView />}
            />
            <ProtectedRoute
              path="/approvals"
              render={(props) => <navPage.navApprovals {...props} />}
            />
            {/********************************** reports ********************************/}
            <ProtectedRoute path="/reports" render={(props) => <Reports />} />
            {/********************************* settings *********************************/}
            <ProtectedRoute
              path="/settings/projects"
              render={(props) => <Projects />}
            />
            <ProtectedRoute
              path="/settings/new-client"
              render={(props) => <NewClient />}
            />
            <ProtectedRoute
              path="/settings/new-subcontractor"
              render={(props) => <NewSubcontractor />}
            />
            <ProtectedRoute
              path="/settings"
              render={(props) => <navPage.navSettings {...props} />}
            />
            {/********************************* ICC *********************************/}


            {/******************* ICC PMO **********************/}
            <ProtectedRoute
              path="/icc/pmo/project-initialization"
              render={(props) => <ICCNavProjectInitialization {...props} />}
            />
            <ProtectedRoute
              path="/icc/pmo/project-management"
              render={(props) => <ICCNavProjectManagement {...props} />}
            />
            <ProtectedRoute
              path="/icc/pmo/pm-invoicing"
              render={(props) => <PMInvoicing {...props} />}
            />
            <ProtectedRoute
              path="/icc/pmo/pm-subcon"
              render={(props) => <NavPMSubcontract {...props} />}
            />
            <ProtectedRoute
              path="/icc/pmo/pm-closeout"
              render={(props) => <ICCPMCloseout {...props} />}
            />
            <ProtectedRoute
              path="/icc/pmo/"
              render={(props) => <navPage.navICCPMO {...props} />}
            />
            {/******************* ICC Admin **********************/}
            <ProtectedRoute
              path="/icc/admin/admin-subcontract"
              render={(props) => <ICCNavAdminSubcontract {...props} />}
            />
            <ProtectedRoute
              path="/icc/admin/new-project/"
              render={(props) => <ICCNewProject {...props} />}
            />
            <ProtectedRoute
              path="/icc/admin/admin-invoice"
              render={(props) => <ICCNavAdminInvoicing {...props} />}
            />

            <ProtectedRoute
              path="/icc/admin/"
              render={(props) => <navPage.navICCAdmin {...props} />}
            />
            <ProtectedRoute
              path="/icc/"
              render={(props) => <navPage.navICCHome {...props} />}
            />
            {/********************************* redirect *********************************/}
            {/* <Redirect from="/" exact to="/home" />
            <Redirect to="/not-found" /> */}
          </Switch>
        </main>
        {/* <Footer /> */}
      </MsalProvider>
    </React.Fragment>
  );
}

export default App;



