import React from 'react';
import { useSelector } from "react-redux";

import FormForDisplay from '../../../components/common/FormForDisplay';

/* Used in PMO - Closeout */
const ProjectDetailDisplayForm = ({ projectID }) => {
    const projectDetail = useSelector((state) => state.PM.projectDetail);
    const projectHeader = useSelector((state) => state.PM.projectHeader);
    const closeoutInfo = useSelector((state) => state.PM.closeoutInfo);

    console.log('ProjectDetailDisplayForm - - closeoutInfo - 1', closeoutInfo)

    const formInfo = [
        {
            label: "Project Status",
            id: "status",
        },
        {
            label: "Project Completed Date",
            id: "completed_date",
        },
        {
            label: "Final Internal Fee",
            id: "internal_fees",
            displayType: 'currency_amount',
        },
        {
            label: "Final External Fee",
            id: "external_fees_subcontract",
            displayType: 'currency_amount',
        },
        {
            label: "Final Travel Fee",
            id: "travel_fees",
            displayType: 'currency_amount',
        }
    ]

    /* Add the split fee in closeoutInfo into formInfo. Because the split fee is not fixed, the split pillar of each project is different. */
    Object.keys(closeoutInfo).forEach((key) => {
        if (key !== 'completed_date') formInfo.push({
            label: key,
            id: key,
            displayType: 'currency_amount',
        });
    });

    const initializedData = {
        [formInfo[0].id]: projectHeader[formInfo[0].id].value || '', // status
        [formInfo[1].id]: closeoutInfo[formInfo[1].id] || '', // completed_date
        [formInfo[2].id]: projectDetail[formInfo[2].id] || '', // internal_fee
        [formInfo[3].id]: projectDetail[formInfo[3].id] || '', // external_fee
        [formInfo[4].id]: projectDetail[formInfo[4].id] || '', // travel_fee
    }

    /* Add the split fee in closeoutInfo into initializedData. */
    Object.keys(closeoutInfo).forEach((key) => {
        if (key !== 'completed_date') initializedData[key] = closeoutInfo[key];
    });

    // console.log('ProjectDetailDisplayForm - - projectHeader', projectHeader)
    console.log('ProjectDetailDisplayForm - - closeoutInfo - 2', closeoutInfo)

    return (
        <FormForDisplay
            formInfo={formInfo}
            displayedData={initializedData}
        />
    );
};

export default ProjectDetailDisplayForm;