import React, { useEffect, useState, useRef } from 'react';
import Joi from "joi-browser";
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash'

import { getProjectDetail, patchProjectDetail } from '../../../store/PM-actions'

import CustomForm from "../../../components/common/CustomForm";
import Spinner from '../../../components/Spinner';
import ProjectMasterDisplayForm from './ProjectMasterDisplayForm';
import { conditionalRenderCurrencyAmount } from "../../../utils/publicUtil";

const ProjectDetails = ({ projectID }) => {
    const dispatch = useDispatch();
    const LKUPaymentTerms = useSelector((state) => state.generic.LKUs.LKUPaymentTerms);
    const LKUInvoiceStructures = useSelector((state) => state.generic.LKUs.LKUInvoiceStructures);
    const LKUTravelInvoiceStructures = useSelector((state) => state.generic.LKUs.LKUTravelInvoiceStructures);
    const LKUInvoiceFrequencies = useSelector((state) => state.generic.LKUs.LKUInvoiceFrequencies);
    const LKUServiceOfferings = useSelector((state) => state.generic.LKUs.LKUServiceOfferings);
    const projectDetail = useSelector((state) => state.PM.projectDetail);
    const [originalServiceOffering, setOriginalServiceOffering] = useState(['empty']);
    const LKUCurrencies = useSelector((state) => state.generic.LKUs.LKUCurrencies);
    const selectedProject = useSelector((state) => state.PM.selectedProject);
    const iccSwitchState = useSelector((state) => state.ICC.iccSwitchState);

    const prevSelectedProject = useRef(selectedProject);

    // useEffect(() => {
    //     dispatch(getProjectDetail(projectID));
    // }, [projectID]);


    useEffect(() => {
        if (selectedProject && !_.isEmpty(selectedProject) && selectedProject !== prevSelectedProject.current) {
            dispatch(getProjectDetail(projectID));
        }
    }, [selectedProject]);




    useEffect(() => {
        if (!_.isEmpty(projectDetail) && projectDetail.project_id == projectID) {
            // console.log('projectDetail.service_offering', projectDetail)
            const newArr = projectDetail.service_offering.map(obj => ({
                id: obj.id,
                value: obj.title,
                label: obj.title,
                isFixed: false
            }));
            setOriginalServiceOffering(newArr)
            // console.log('newArr', newArr)
            // console.log('LKUServiceOfferings', LKUServiceOfferings)
        }
    }, [projectDetail]);


    const formInfo = [
        {
            type: 'multiselect',
            label: 'Contract Currency',
            options: LKUCurrencies,
            defaultValue: projectDetail.currency || '',
            id: 'currency',
            isMulti: false,
            dependent: false,
        },
        {
            label: "Down Payment",
            id: "downpayment",
            type: "text",
        },
        {
            label: "Project Duration in Consulting days",
            id: "duration_in_days",
            type: "text",
        },
        {
            label: "PO Number",
            id: "po_number",
            type: "text",
        },
        {
            label: "Timeline in Weeks (Start Date to Finish Date)",
            id: "duration_in_weeks",
            type: "text",
        },
        {
            label: "Contract Start Date",
            id: "contract_start_date",
            type: "date",
        },
        {
            label: "Internal Fees",
            id: "internal_fees",
            type: "text",
        },
        {
            label: "Travel Budget",
            id: "travel_fees",
            type: "text",
        },
        {
            label: "External Fees (Other Subcontract)",
            id: "external_fees_subcontract",
            type: "text",
        },
        {
            label: "Payment Terms",
            id: "payment_term",
            type: "multiselect",
            options: LKUPaymentTerms,
            defaultValue: projectDetail.payment_term || '',
            isMulti: false,
        },
        {
            label: "Payment Structure",
            id: "invoice_structure",
            type: 'multiselect',
            options: LKUInvoiceStructures,
            defaultValue: projectDetail.invoice_structure || '',
            isMulti: false,
            dependent: false,
        },
        {
            label: "Invoice Frequency",
            id: "invoice_frequency",
            type: "multiselect",
            options: LKUInvoiceFrequencies,
            defaultValue: projectDetail.invoice_frequency || '',
            isMulti: false,
        },
        {
            label: "Anticipated Number of Invoices",
            id: "number_of_invoices",
            type: "text",
        },
        {
            label: "Service Offering",
            id: "service_offering",
            type: "multiselect",
            options: LKUServiceOfferings,
            defaultValue: originalServiceOffering,
            isMulti: true,
        },
        {
            label: "Project Start Date",
            id: "project_start_date",
            type: "date",
        },
        {
            label: "Travel Invoice Structure",
            id: "travel_invoice_structure",
            type: "multiselect",
            options: LKUTravelInvoiceStructures,
            defaultValue: projectDetail.travel_invoice_structure || '',
            isMulti: false,
        }
    ];

    const formStructure = {
        submit_button: {
            label: 'Modify Project Details'
        }
    };

    const projectDetailInitializedData = {
        [formInfo[0].id]: projectDetail[formInfo[0].id] || '',
        [formInfo[1].id]: projectDetail[formInfo[1].id]? 
        (conditionalRenderCurrencyAmount(projectDetail[formInfo[1].id])):(''),
        [formInfo[2].id]: projectDetail[formInfo[2].id] || '',
        [formInfo[3].id]: projectDetail[formInfo[3].id] || '',
        [formInfo[4].id]: projectDetail[formInfo[4].id] || '',
        [formInfo[5].id]: projectDetail[formInfo[5].id] || '',
        [formInfo[6].id]: projectDetail[formInfo[6].id]? 
        (conditionalRenderCurrencyAmount(projectDetail[formInfo[6].id])):(''),
        [formInfo[7].id]: projectDetail[formInfo[7].id]? 
        (conditionalRenderCurrencyAmount(projectDetail[formInfo[7].id])):(''),
        [formInfo[8].id]: projectDetail[formInfo[8].id]? 
        (conditionalRenderCurrencyAmount(projectDetail[formInfo[8].id])):(''),
        [formInfo[9].id]: projectDetail[formInfo[9].id] || '',
        [formInfo[10].id]: projectDetail[formInfo[10].id] || '',
        [formInfo[11].id]: projectDetail[formInfo[11].id] || '',
        [formInfo[12].id]: projectDetail[formInfo[12].id] || '',
        [formInfo[13].id]: projectDetail[formInfo[13].id] || '',
        [formInfo[14].id]: projectDetail[formInfo[14].id] || '',
        [formInfo[15].id]: projectDetail[formInfo[15].id] || ''
    };


    const schema = {
        [formInfo[0].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label(formInfo[0].label),
        [formInfo[1].id]: Joi.number().required().label(formInfo[1].label),
        [formInfo[2].id]: Joi.number().required().label(formInfo[2].label),
        [formInfo[3].id]: Joi.string().required().label(formInfo[3].label),
        [formInfo[4].id]: Joi.number().less(9999).required().label(formInfo[4].label),
        [formInfo[5].id]: Joi.date().required().label(formInfo[5].label),
        [formInfo[6].id]: Joi.number().required().label(formInfo[6].label),
        [formInfo[7].id]: Joi.number().required().label(formInfo[7].label),
        [formInfo[8].id]: Joi.number().required().label(formInfo[8].label),
        [formInfo[9].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).label(formInfo[9].label),
        [formInfo[10].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).label(formInfo[10].label),
        [formInfo[11].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).label(formInfo[11].label),
        [formInfo[12].id]: Joi.number().required().label(formInfo[12].label),
        [formInfo[13].id]: Joi.array().min(1).label(formInfo[13].label),
        [formInfo[14].id]: Joi.date().required().label(formInfo[14].label),
        [formInfo[15].id]: Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).label(formInfo[15].label)
    };


    const doSubmit = (data, errors, setData, setErrors) => {
        // console.log('data in do submit', data)
        if(iccSwitchState) {
            dispatch(patchProjectDetail(projectID, data, false, true));
        }
        else {
            dispatch(patchProjectDetail(projectID, data));
        }
    };

    const dataIsReady = !_.isEmpty(projectDetail) && projectDetail.project_id == projectID && originalServiceOffering[0] != 'empty'

    return (
        <>
            {dataIsReady ?
                <Row>
                    <Col md={9}>
                        <Row>
                            <Col></Col>
                            <Col md={8}>
                                <CustomForm
                                    formInfo={formInfo}
                                    formStructure={formStructure}
                                    doSubmit={doSubmit}
                                    schema={schema}
                                    initializedData={projectDetailInitializedData}
                                />
                            </Col>
                            <Col></Col>
                        </Row>

                    </Col>
                    <Col>
                        <ProjectMasterDisplayForm projectID={projectID} />
                    </Col>
                </Row> : <Spinner />}
        </>

    );
};

export default ProjectDetails;