import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import { updatePMInvoice } from "../../../store/PM-actions";
import { processUpdateInvoice } from '../../../utils/publicUtil';
import CustomTable from "../../../components/custom/Table/CustomTable";

const PMCreateInvoices = ({ createInvoices }) => {
  const dispatch = useDispatch();


  const columns = [
    { path: "status.title", label: "Invoice status" },
    { path: "concept", label: "Concept" },
    {
      path: "invoice_date",
      label: "Date on invoice (yyyy-mm-dd)",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "invoice_date",
          id: "invoice_date",
          type: "date",
        },
        schema: { invoice_date: Joi.date().required().label("Estimated Date") },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        }
      },
    },
    { path: "amount_actual", label: "Amount", displayType: 'currency_amount', },
    {
      path: "notes",
      label: "Communication Between PM and Admin",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "notes",
          id: "notes",
          type: "text",
        },
        schema: { notes: Joi.string().required().label("Notes") },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        }
      },
    },
  ];

  return (
    <div className="table-container">
      <h2>
        Admin Responsibility - review submission, create PDF of invoice, email
        to PM, and push button to change invoice status
      </h2>
      <CustomTable
        headerData={columns}
        bodyData={createInvoices}
      />
      <br />
      <br />
    </div>
  );
};

export default PMCreateInvoices;
