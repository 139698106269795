import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NavPage from '../components/NavPage';
import { getStaffingWeekList, getProspectProjectMasters, getProspectProjectHeaders, getProjectOptions } from "../store/staffing-actions";
import { staffingActions } from "../store/staffing-slice";
import { PMActions } from '../store/PM-slice'
import { getProjectsForPMDropdown, getPMSubcontractors, getICCProjectsForPMDropdown } from "../store/PM-actions";
import { getAllProjectMasters, getAllProjectHeaders, getAllProjectDetails, getAllClients, getAllSubcontractors } from '../store/settings-actions'
import {
    getPrepareNewProject,
    getAdminInvoices,
    getProjectsForAdminDropdown,
    getAdminSubcontracts,
    getAdminClients,
    getAdminSubcontractors
} from "../store/admin-actions";
import {

    FaRegClock, FaUsers, FaProjectDiagram,
    FaRegUser, FaRegThumbsUp, FaRegFileAlt,
    FaCog, FaWrench, FaUserFriends,
    FaPlayCircle, FaUserPlus, FaUsersCog,
    FaClipboardList, FaEye, FaFileInvoice,
    FaDoorClosed, FaHandshake, FaFolderPlus,
    FaMoneyCheckAlt, FaUserTie, FaRegListAlt, FaAddressBook, FaCloud, FaSitemap
} from 'react-icons/fa';
import {
    getAllICCUsers,
    getICCAdminInvoices,
    getICCAdminSubcontracts,
    getICCProjectsForAdminDropdown
} from '../store/icc-actions';
function NavHome() {

    const cardNavGroup = [
        {
            label: <div className='NavIcon'><FaRegClock /><div className='NavText'>COH</div></div>,
            path: '/coh/week-summaries',
            accessLimitaion: ['generalStaff']
        },
        {
            label: <div className='NavIcon'><FaUsers /><div className='NavText'>Staffing</div></div>,
            path: '/staffing',
            accessLimitaion: ['generalStaff']
        },
        {
            label: <div className='NavIcon'><FaProjectDiagram /><div className='NavText'>PMO</div></div>,
            path: '/pmo',
            accessLimitaion: ['projectManager']
        },
        {
            label: <div className='NavIcon'><FaRegUser /><div className='NavText'>Admin</div></div>,
            path: '/admin',
            accessLimitaion: ['admin']
        },
        {
            label: <div className='NavIcon'><FaRegThumbsUp /><div className='NavText'>Approvals</div></div>,
            path: '/approvals',
            accessLimitaion: ['closeoutTeam']
        },
        {
            label: <div className='NavIcon'><FaRegFileAlt /><div className='NavText'>Reports</div></div>,
            path: '/reports',
            accessLimitaion: ['generalStaff']
        },
        {
            label: <div className='NavIcon'><FaSitemap /><div className='NavText'>ICC</div></div>,
            path: '/icc',
            accessLimitaion: ['ICC_ADMIN', 'ICC_USER']
        },
        {
            label: <div className='NavIcon'><FaCog /><div className='NavText'>Settings and Extras</div></div>,
            path: '/settings',
            accessLimitaion: ['admin', 'staffingManager', 'developTeam']
        },
        {
            label: <div className='NavIcon'><FaWrench /><div className='NavText'>Custom Examples</div></div>,
            path: '/test-page',
            accessLimitaion: ['developTeam']
        }
    ]

    return (
        <NavPage
            titleMessage="Welcome to the Global Miebach Admin Tool for Enterprise Resources (Global MATER)!"
            cardNavGroup={cardNavGroup} />
    );
}

function NavStaffing() {
    const dispatch = useDispatch();
    const currentWeek = useSelector((state) => state.generic.currentWeek);

    useEffect(() => {
        console.log('NavHome - NavStaffing - refresh')
        dispatch(getStaffingWeekList());
        dispatch(getProjectOptions());
        if (currentWeek) dispatch(staffingActions.setSelectedWeek({ selectedWeek: { value: currentWeek.week_name, id: currentWeek.week_id } }));
    }, [currentWeek]);

    const cardNavGroup = [
        {
            label: <div className='NavIcon'><FaUserFriends /><div className='NavText'>Staff Planning</div></div>,
            path: '/staffing/staff-planning',
            accessLimitaion: ['generalStaff']
        },
        {
            label: <div className='NavIcon'><FaPlayCircle /><div className='NavText'>Project Initiation</div></div>,
            path: '/staffing/project-initiation',
            accessLimitaion: ['staffingManager']
        }
    ]

    return (
        <NavPage
            titleMessage="Staffing"
            cardNavGroup={cardNavGroup} />
    );
}

function NavPrjctInit() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProspectProjectMasters());
        dispatch(getProspectProjectHeaders());
    }, []);


    const cardNavGroup = [
        {
            label: <div className='NavIcon'><FaUserPlus /><div className='NavText'>Assign Project Manager</div></div>,
            path: '/staffing/project-initiation/assign-project-manager',
            accessLimitaion: ['staffingManager']
        },
        {
            label: <div className='NavIcon'><FaUsersCog /><div className='NavText'>Batch Staffing</div></div>,
            path: '/staffing/project-initiation/batch-staffing',
            accessLimitaion: ['staffingManager']
        },
        {
            label: <div className='NavIcon'><FaHandshake /><div className='NavText'>Rent Prospect Project</div></div>,
            path: '/staffing/project-initiation/rent-prospect-project',
            accessLimitaion: ['staffingManager']
        }
    ]

    return (
        <NavPage
            titleMessage="Project Initiation"
            cardNavGroup={cardNavGroup} />
    );
}

function NavPMO() {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.generic.currentUser);

    useEffect(() => {
        // console.log('NavHome - NavPMO - history.location.state.from', history.location.state.from)
        const previousPathname = history.location.state.from
        if (!previousPathname.includes('/pmo')) {
            console.log('NavHome - NavPMO - come back from other router')
            dispatch(getProjectsForPMDropdown())
            dispatch(PMActions.setSelectedProject({ selectedProject: {} }));
            dispatch(getPrepareNewProject())
        }
    }, []);

    useEffect(() => {
        const officeID = user.office_id
        if (officeID) {
            dispatch(getPMSubcontractors(officeID))
        }
    }, [user]);

    const cardNavGroup = [
        {
            label: <div className='NavIcon'><FaClipboardList /><div className='NavText'>Project Detail Entry</div></div>,
            path: '/pmo/project-initialization',
            accessLimitaion: ['projectManager']
        },
        {
            label: <div className='NavIcon'><FaEye /><div className='NavText'>Project Management View</div></div>,
            path: '/pmo/project-management',
            accessLimitaion: ['projectManager']
        },
        {
            label: <div className='NavIcon'><FaFileInvoice /><div className='NavText'>Invoice Process</div></div>,
            path: '/pmo/pm-invoicing',
            accessLimitaion: ['projectManager']
        },
        {
            label: <div className='NavIcon'><FaUserTie /><div className='NavText'>Subcontract Process</div></div>,
            path: '/pmo/pm-subcon',
            accessLimitaion: ['projectManager']
        },
        {
            label: <div className='NavIcon'><FaDoorClosed /><div className='NavText'>Closeout</div></div>,
            path: '/pmo/pm-closeout',
            accessLimitaion: ['projectManager']
        },
    ];

    return (
        <NavPage
            titleMessage="PMO"
            cardNavGroup={cardNavGroup}
            showProjectMasterDropdown={true}
        />

    );
}

function NavAdmin() {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.generic.currentUser);

    useEffect(() => {
        const previousPathname = history.location.state.from
        if (!previousPathname.includes('/admin')) {
            console.log('NavHome - NavAdmin - 0')
            dispatch(getPrepareNewProject())
            dispatch(getAdminInvoices());
            dispatch(getAdminSubcontracts());
            dispatch(getProjectsForAdminDropdown());
        }
    }, []);

    useEffect(() => {
        const officeID = user.office_id
        if (officeID) {
            /* 
                1. Because clients and subcontractors will be modified in settings, we need to load client and subcon data here. 
                our rule in MATER dev is "Treat each part(PMO/AdminView/Settings) as a separate app", which is more convenient and easier for maintaining redux.
                2. when app user refresh nav page, CurrentUser is null, officeID will null, there will be an error. so when CurrentUser is changed re-load get all options
            */
            dispatch(getAdminClients(officeID))
            dispatch(getAdminSubcontractors(officeID))
        }
    }, [user]);


    const cardNavGroup = [
        {
            label: <div className='NavIcon'><FaFolderPlus /><div className='NavText'>New Project</div></div>,
            path: '/admin/new-project',
            accessLimitaion: ['admin']
        },
        {
            label: <div className='NavIcon'><FaMoneyCheckAlt /><div className='NavText'>Invoice Process</div></div>,
            path: '/admin/admin-invoice',
            accessLimitaion: ['admin']
        },
        {
            label: <div className='NavIcon'><FaUserTie /><div className='NavText'>Subcontract Process</div></div>,
            path: '/admin/admin-subcontract',
            accessLimitaion: ['admin']
        }
    ]

    return (
        <NavPage
            titleMessage="Admin View"
            cardNavGroup={cardNavGroup}
        />
    );
}

function NavApprovals() {
    const cardNavGroup = [{
        label: <div className='NavIcon'><FaEye /><div className='NavText'>Closeout View</div></div>,
        path: '/approvals/closeout-view',
        accessLimitaion: ['closeoutTeam']
    }]

    return (
        <NavPage
            titleMessage="Approvals View"
            cardNavGroup={cardNavGroup} />
    );
}


function NavSettings() {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.generic.currentUser);

    useEffect(() => {
        dispatch(getAllProjectMasters())
        dispatch(getAllProjectHeaders())
        dispatch(getAllProjectDetails())
    }, []);

    useEffect(() => {
        const officeID = user.office_id
        if (officeID) {
            dispatch(getAllClients(officeID))
            dispatch(getAllSubcontractors(officeID))
        }
    }, [user]);

    // useEffect(() => {
    //     const previousPathname = history.location.state.from
    //     if (!previousPathname.includes('/settings')) {
    //         console.log('NavHome - navSettings - 0')
    //         dispatch(getAllProjectMasters())
    //         dispatch(getAllProjectHeaders())
    //     }
    // }, []);

    const cardNavGroup = [{
        label: <div className='NavIcon'><FaRegListAlt /><div className='NavText'>Projects</div></div>,
        path: '/settings/projects',
        accessLimitaion: ['admin', 'staffingManager', 'developTeam']
    },
    {
        label: <div className='NavIcon'><FaAddressBook /><div className='NavText'>New Client</div></div>,
        path: '/settings/new-client',
        accessLimitaion: ['admin', 'staffingManager', 'developTeam']
    },
    {
        label: <div className='NavIcon'><FaAddressBook /><div className='NavText'>New Subcontract Company</div></div>,
        path: '/settings/new-subcontractor',
        accessLimitaion: ['admin', 'staffingManager', 'developTeam']
    }
    ]

    return (
        <NavPage
            titleMessage="Settings"
            cardNavGroup={cardNavGroup} />
    );
}

//************************************************ ICC *********************************************************************/


function navICCHome() {


    const cardICCNavGroup = [

        {
            label: <div className='NavIcon'><FaProjectDiagram /><div className='NavText'>ICC PMO</div></div>,
            path: '/icc/pmo',
            accessLimitaion: ['ICC_USER', 'ICC_ADMIN', 'developTeam']
        },
        {
            label: <div className='NavIcon'><FaRegUser /><div className='NavText'>ICC Admin</div></div>,
            path: '/icc/admin',
            accessLimitaion: ['ICC_ADMIN', 'developTeam']
        },
        // {
        //     label: <div className='NavIcon'><FaRegUser /><div className='NavText'>ICC Approval</div></div>,
        //     path: '/icc/approvals',
        //     accessLimitaion: ['ICC_ADMIN', 'developTeam']
        // },
        {
            label: <div className='NavIcon'><FaCog /><div className='NavText'>Settings and Extras</div></div>,
            path: '/icc/settings',
            accessLimitaion: ['ICC_ADMIN', 'developTeam']
        },

    ]

    return (
        <NavPage
            titleMessage="Welcome to the ICC Module! This is for managing Inter Company Contract projects."
            cardNavGroup={cardICCNavGroup} />
    );
}




function NavICCPMO() {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {

        const previousPathname = history.location.state.from
        if (!previousPathname.includes('/ICC/pmo')) {
            console.log('NavICCHome - NavICCPMO - come back from other router')
            dispatch(getICCProjectsForPMDropdown())
            dispatch(PMActions.setSelectedProject({ selectedProject: {} }));
        }
    }, []);


    const cardNavGroup = [
        {
            label: <div className='NavIcon'><FaClipboardList /><div className='NavText'>Project Detail Entry</div></div>,
            path: '/icc/pmo/project-initialization',
            accessLimitaion: ['ICC_USER', 'developTeam']
        },
        {
            label: <div className='NavIcon'><FaEye /><div className='NavText'>Project Management View</div></div>,
            path: '/icc/pmo/project-management',
            accessLimitaion: ['ICC_USER', 'developTeam']
        },
        {
            label: <div className='NavIcon'><FaFileInvoice /><div className='NavText'>Invoice Process</div></div>,
            path: '/icc/pmo/pm-invoicing',
            accessLimitaion: ['ICC_USER', 'developTeam']
        },
        {
            label: <div className='NavIcon'><FaUserTie /><div className='NavText'>Subcontract Process</div></div>,
            path: '/icc/pmo/pm-subcon',
            accessLimitaion: ['ICC_USER', 'developTeam']
        },
        {
            label: <div className='NavIcon'><FaDoorClosed /><div className='NavText'>Closeout</div></div>,
            path: '/icc/pmo/pm-closeout',
            accessLimitaion: ['ICC_USER', 'developTeam']
        },

    ];

    return (
        <NavPage
            titleMessage="ICC PMO"
            cardNavGroup={cardNavGroup}
            showProjectMasterDropdown={true}
        />

    );
}

function NavICCAdmin() {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.generic.currentUser);
    const officeID = user.office_id

    useEffect(() => {
        const previousPathname = history.location.state.from
        if (!previousPathname.includes('/icc/admin')) {
            console.log('ICCNavHome - ICCNavAdmin - 0')
            dispatch(getPrepareNewProject())
            dispatch(getICCAdminInvoices(true));
            dispatch(getICCAdminSubcontracts(true));
            dispatch(getICCProjectsForAdminDropdown(true));
            dispatch(getAllICCUsers());
        }
    }, []);

    useEffect(() => {
        const officeID = user.office_id
        if (officeID) {
            /* 
                1. Because clients and subcontractors will be modified in settings, we need to load client and subcon data here. 
                our rule in MATER dev is "Treat each part(PMO/AdminView/Settings) as a separate app", which is more convenient and easier for maintaining redux.
                2. when app user refresh nav page, CurrentUser is null, officeID will null, there will be an error. so when CurrentUser is changed re-load get all options
            */
            dispatch(getAdminClients(officeID))
            dispatch(getAdminSubcontractors(officeID))
        }
    }, [user]);

    const cardNavGroup = [
        {
            label: <div className='NavIcon'><FaFolderPlus /><div className='NavText'>New Project</div></div>,
            path: '/icc/admin/new-project',
            accessLimitaion: ['ICC_ADMIN']
        },
        {
            label: <div className='NavIcon'><FaMoneyCheckAlt /><div className='NavText'>Invoice Process</div></div>,
            path: '/icc/admin/admin-invoice',
            accessLimitaion: ['ICC_ADMIN']
        },
        {
            label: <div className='NavIcon'><FaUserTie /><div className='NavText'>Subcontract</div></div>,
            path: '/icc/admin/admin-subcontract',
            accessLimitaion: ['ICC_ADMIN']
        }
    ]

    return (
        <NavPage
            titleMessage="ICC Admin View"
            cardNavGroup={cardNavGroup}
        />
    );
}

// function NavICCApprovals() {
//     const cardNavGroup = [{
//         label: <div className='NavIcon'><FaEye/><div className='NavText'>Closeout Team View</div></div>,
//         path: '/icc/approvals/closeout-view',
//         accessLimitaion: ['ICC_ADMIN']
//     }]

//     return (
//         <NavPage
//             titleMessage="ICC Approvals View"
//             cardNavGroup={cardNavGroup} />
//     );
// }

const nav = {
    navHome: NavHome,
    navStaffing: NavStaffing,
    navPrjctInit: NavPrjctInit,
    navPMO: NavPMO,
    navAdmin: NavAdmin,
    navApprovals: NavApprovals,
    navSettings: NavSettings,

    navICCHome: navICCHome,
    navICCAdmin: NavICCAdmin,
    navICCPMO: NavICCPMO,
    // navICCApprovals: NavICCApprovals,
};

export default nav