import React, { useState, Fragment } from "react";
import Joi from "joi-browser";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { updatePMSubcontract } from "../../../store/PM-actions";
import { processUpdateSubcontract } from "../../../utils/publicUtil";
import { subcontractStatus } from "../../../services/constService";
import SubcontractFileModal from '../../../components/pages/SubcontractFileModal';
import CustomTable from "../../../components/custom/Table/CustomTable";
import { office as lkuOffice } from "../../../services/constService";

const PMApproveSubcontracts = ({ approveSubcontracts, projectID }) => {
  const dispatch = useDispatch();
  const LKUPaymentTerms = useSelector((state) => state.generic.LKUs.LKUPaymentTerms);
  const subcontractCompanies = useSelector((state) => state.PM.subcontractors);

  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedSubcontractID, setSelectedSubcontractID] = useState();
  const user = useSelector((state) => state.generic.currentUser);
  const office = user.office_id;
  // const office = lkuOffice.BARCELONA;

  const columns = [
    {
      path: "invoicing_company", label: "Subcontractor", sortPath: "invoicing_company.value",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          type: 'multiselect',
          label: "Invoicing Company",
          id: "invoicing_company",
          options: subcontractCompanies,
          isMulti: false,
        },
        schema: {
          'invoicing_company': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Invoicing company')
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "invoice_number", label: "Subcontractor Invoice Number", customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "invoice_number",
          id: "invoice_number",
          type: "text",
        },
        schema: {
          'invoice_number': Joi.string().required().label("invoice_number"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "invoice_date", label: "Subcontractor Invoice Date", customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "invoice_date",
          id: "invoice_date",
          type: "date",
        },
        schema: {
          'invoice_date': Joi.date().required().label("invoice_date"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "concept", label: "Concept", customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "concept",
          id: "concept",
          type: "text",
        },
        schema: {
          'concept': Joi.string().required().label("concept"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "amount",
      label: "Amount",
      displayType: 'currency_amount',
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "amount",
          id: "amount",
          type: "text",
        },
        schema: {
          'amount': Joi.number().required().label("amount"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "due_date", label: "Due date",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "due_date",
          id: "due_date",
          type: "date",
        },
        schema: {
          'due_date': Joi.date().required().label("due_date"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "payment_term", label: "Payment terms",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          type: 'multiselect',
          label: "payment_term",
          id: "payment_term",
          options: LKUPaymentTerms,
          isMulti: false,
        },
        schema: {
          'payment_term': Joi.object({ id: Joi.number().required(), value: Joi.string(), label: Joi.string() }).required().label('Payment term')
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    {
      path: "comment", label: "Comment",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "comment",
          id: "comment",
          type: "text",
        },
        schema: {
          comment: Joi.string().required().label("comment"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "status.value", label: "Status" },
  ];

  const buttonGroup = {
    upper_left: [
      ...(office !== lkuOffice.BARCELONA && office !== lkuOffice.MADRID
        ? [
          {
            type: "success",
            buttonText: (
              <Fragment>
                <FaArrowUp style={{ marginRight: "5px" }} />
                Approve and Move it to Connect Client Invoice
              </Fragment>
            ),
            handleClick: (selectedRow) => {
              if (!selectedRow) toast.warning("Please select invoice!");
              else dispatch(updatePMSubcontract({
                originalRowInfo: {
                  subcontractID: selectedRow.id,
                  projectID: selectedRow.project.id,
                },
                updatedInfo: {
                  id: selectedRow.id,
                  status: subcontractStatus.CONNECT,
                },
              }))
            },
            id: 0,
          },
        ]
        : []),
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FaArrowDown style={{ marginRight: "5px" }} />
            Reject and Move it Back to Pending Creation
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract({
            originalRowInfo: {
              subcontractID: selectedRow.id,
              projectID: selectedRow.project.id,
            },
            updatedInfo: {
              id: selectedRow.id,
              status: subcontractStatus.CREATE,
            },
          }))
        },
        id: 1,
      },
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Attach/View PDF files
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select subcontract!");
          } else {
            setSelectedSubcontractID(selectedRow.id)
            setShowFileModal(true)
          }
        },
        id: 2,
      },
    ],
  };

  return (
    <div className="table-container">
      <h2>PM Responsiblility - Approve that the subcontractor invoice belongs to the Client project, move to Admin to connect Client Invoice</h2>
      <CustomTable
        headerData={columns}
        bodyData={approveSubcontracts}
        buttonGroup={buttonGroup}
      />
      <SubcontractFileModal show={showFileModal} setShow={setShowFileModal} projectID={projectID} subcontractID={selectedSubcontractID} />
    </div>
  );
};

export default PMApproveSubcontracts;
