import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Table } from "react-bootstrap";

import GoBackButton from "../common/GoBackButton";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import TableButton from "./TableButton";
import TablePagination from "./TablePagination";
import TableFilter from "./TableFilter";
import { applyFilter, applySort } from "../../../utils/tableUtil";

const CustomTable = ({
  headerData,
  bodyData,
  buttonGroup = {},
  filterGroup = [],
  pageSize = 5,
  tableTitle,
  setClearFilterTrigger = null,
}) => {
  const [completeBodyData, setCompleteBodyData] = useState(bodyData);
  const [sortColumn, setSortColumn] = useState({ path: "id", order: "asc" });
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState("");
  const [selectedGenre, setSelectedGenre] = useState([]);

  let filteredBodyData = completeBodyData;
  if (!_.isEmpty(selectedGenre))
    filteredBodyData = applyFilter(selectedGenre, completeBodyData);
  const sorted = applySort(filteredBodyData, sortColumn);

  const itemsDisplayed = _(sorted)
    .slice((currentPage - 1) * pageSize)
    .take(pageSize)
    .value();
  const totalCount = filteredBodyData.length;

  useEffect(() => {
    setCompleteBodyData(bodyData);
  }, [bodyData]);

  // console.log('CustomTable - - sorted', sorted)
  // console.log('CustomTable - - selectedGenre', selectedGenre)

  return (
    <div style={{ width: '100%', overflow: 'auto' }}>
      {tableTitle && <h3>{tableTitle}</h3>}
      {buttonGroup.hasOwnProperty("upper_left") && (
        <>
          {buttonGroup.upper_left.map((button) => (
            <TableButton
              buttonInfo={button}
              styleInfo="m-2"
              selectedRow={selectedRow}
              key={button.id}
            />
          ))}
        </>
      )}

      {filterGroup.length !== 0 && (
        <TableFilter
          filterGroup={filterGroup}
          completeBodyData={completeBodyData}
          setCurrentPage={setCurrentPage}
          selectedGenre={selectedGenre}
          setSelectedGenre={setSelectedGenre}
          setClearFilterTrigger={setClearFilterTrigger}
        />
      )}

      {totalCount === 0 ? (
        <h3>There is currently no data.</h3>
      ) : (
        <Table hover style={{overflow: "auto"}} >
          <TableHeader
            headerData={headerData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
          />
          <TableBody
            completeBodyData={completeBodyData}
            setCompleteBodyData={setCompleteBodyData}
            itemsDisplayed={itemsDisplayed}
            headerData={headerData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
          />
        </Table>
      )}

      <TablePagination
        numItems={totalCount}
        maxNumItemDisplayed={pageSize}
        activePage={currentPage}
        setPage={setCurrentPage}
      />

      {buttonGroup.hasOwnProperty("lower_left") && <GoBackButton />}
      {buttonGroup.hasOwnProperty("lower_right") && (
        <>
          {buttonGroup.lower_right.map((button) => (
            <TableButton
              buttonInfo={button}
              styleInfo="m-2 pull-right"
              selectedRow={selectedRow}
              key={button.id}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default CustomTable;
