import { createSlice } from "@reduxjs/toolkit";

const genericSlice = createSlice({
  name: "generic",
  initialState: {
    LKUs: {},
    currentUser: {},
    accessPermissions: {
      generalStaff: true,
      projectManager: false,
      admin: false,
      closeoutTeam: false,
      developTeam: false,
      staffingManager: false,
      ICC_USER: false,
      ICC_ADMIN: false,
    },
    currentWeek: null,
    allManagers: [],
    allAdmins: [],
    allOffices: [],
  },
  reducers: {
    setLKUs(state, action) {
      state.LKUs = action.payload.LKUs;
    },
    setAllManagers(state, action) {
      state.allManagers = action.payload.allManagers;
    },
    setAllAdmins(state, action) {
      state.allAdmins = action.payload.allAdmins;
    },
    setCurrentUser(state, action) {
      state.currentUser = action.payload.currentUser;
    },
    setAccessPermissions(state, action) {
      state.accessPermissions = action.payload.accessPermissions;
    },
    setCurrentWeek(state, action) {
      state.currentWeek = action.payload.currentWeek;
    },
    setAllOffices(state, action) {
      state.allOffices = action.payload.allOffices;
    },
  },
});

export const genericActions = genericSlice.actions;

export default genericSlice;
