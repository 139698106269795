import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';

import http from "../../../services/httpService";
import { updateCloseoutTrackerItemAPI, updateCloseoutTrackerAPI } from "../../../services/backendURLService";
import { closeoutPMChoice, closeoutTeamChoice } from '../../../services/constService'

const CheckNotApprove = ({ sgnlClsTrckrItm, patchCloseoutTrackerItem }) => {
    return (
        <Form.Check
            inline
            type='radio'
            label={`No`}
            id={`${sgnlClsTrckrItm.closeout_tracker_item_id}-No`}
            value={closeoutTeamChoice.NO}
            onClick={patchCloseoutTrackerItem} />
    )
};

const CheckApprove = ({ sgnlClsTrckrItm, patchCloseoutTrackerItem }) => {
    return (
        <Form.Check
            inline
            type='radio'
            label={`Approve`}
            id={`${sgnlClsTrckrItm.closeout_tracker_item_id}-Approve`}
            value={closeoutTeamChoice.YES}
            onClick={patchCloseoutTrackerItem} />
    )
};

const CloseoutTrackerItem = ({ sgnlClsTrckrItm, projectID, closeoutTrackerID, closeoutTeamTracker, setCloseoutTeamTracker }) => {
    const modifyTrackerItemForScreen = (modifiedCloseoutTeamTrackerItem) => {
        let currentCloseoutTeamTrackerItem = { ...closeoutTeamTracker };
        const targetItem =
            currentCloseoutTeamTrackerItem.closeout_tracker_item.find(
                (subItem) =>
                    subItem.closeout_tracker_item_id ==
                    modifiedCloseoutTeamTrackerItem.closeout_tracker_item_id
            );
        if (targetItem) {
            targetItem.team_choosed = modifiedCloseoutTeamTrackerItem.team_choosed;
        }
        setCloseoutTeamTracker(currentCloseoutTeamTrackerItem)
    };

    const patchCloseoutTrackerItem = async (event) => {
        // console.log('PMCloseoutTrackerCmpnnt - SignleCloseoutTracker - event.target.id', event.target.id)
        // console.log('PMCloseoutTrackerCmpnnt - SignleCloseoutTracker - event.target.value', event.target.value)

        const closeoutTrackerItemID = event.target.id.split('-')[0]
        /* modify the closeout tracker in backend */
        await http.toastPatch(
            `${updateCloseoutTrackerItemAPI(
                projectID,
                closeoutTrackerID,
                closeoutTrackerItemID
            )}`,
            { "team_choosed": event.target.value }
        );
        /* modify the closeout tracker in frontend */
        modifyTrackerItemForScreen({ "team_choosed": event.target.value, closeout_tracker_item_id: closeoutTrackerItemID })
    };

    return (<Row>
        <Col md={4}><h5>{sgnlClsTrckrItm.task_title}</h5></Col>
        {/************************************************* Team choosed *************************************************/}
        <Col>
            {sgnlClsTrckrItm.pm_choosed == closeoutPMChoice.NO && <i className="fas fa-times" style={{ color: 'red' }}> Not Checked</i>}
            {sgnlClsTrckrItm.pm_choosed == closeoutPMChoice.YES && <i className="fas fa-check" style={{ color: 'green' }}> Yes</i>}
            {sgnlClsTrckrItm.pm_choosed == closeoutPMChoice.NOT_APPLICABLE && <i className="fas fa-not-equal" style={{ color: 'grey' }}> Not Applicable</i>}
        </Col>
        {/************************************************* PM choosed *************************************************/}
        <Col md={5}>
            {sgnlClsTrckrItm.team_choosed == closeoutTeamChoice.PENDING && sgnlClsTrckrItm.pm_choosed !== closeoutPMChoice.NO && <>
                <CheckApprove sgnlClsTrckrItm={sgnlClsTrckrItm} patchCloseoutTrackerItem={patchCloseoutTrackerItem} />
                <CheckNotApprove sgnlClsTrckrItm={sgnlClsTrckrItm} patchCloseoutTrackerItem={patchCloseoutTrackerItem} /></>}
            {sgnlClsTrckrItm.team_choosed == closeoutTeamChoice.YES && <i className="fas fa-check" style={{ color: 'green' }}> Approved</i>}
            {sgnlClsTrckrItm.team_choosed == closeoutTeamChoice.NO && <>
                <i className="fas fa-times" style={{ color: 'red' }}> Not Approved</i>&nbsp;
                <CheckApprove sgnlClsTrckrItm={sgnlClsTrckrItm} patchCloseoutTrackerItem={patchCloseoutTrackerItem} />
            </>}
        </Col>
    </Row>)
};

const CloseoutTeamTrackerCmpnnt = ({ closeoutTeamTracker, setCloseoutTeamTracker }) => {
    const patchComment = async (event) => {
        const modifiedComment = event.target.value
        const projectID = event.target.id.split('-')[0]
        const closeoutTrackerID = event.target.id.split('-')[1]
        /* modify the closeout tracker in backend */
        await http.toastPatch(
            `${updateCloseoutTrackerAPI(projectID, closeoutTrackerID)}`,
            { "comment": modifiedComment }
        );
        /* modify the closeout tracker in frontend */
        let currentCloseoutTracker = { ...closeoutTeamTracker };
        currentCloseoutTracker.comment = modifiedComment;
        setCloseoutTeamTracker(currentCloseoutTracker);
    };
    return (<Container className='mt-5'>
        <h3 style={{ marginBottom: '20px' }}>{`${closeoutTeamTracker.team_title} Closeout Tasks`}</h3>
        {closeoutTeamTracker.closeout_tracker_item.map(clstTrckrItem => <CloseoutTrackerItem sgnlClsTrckrItm={clstTrckrItem} key={clstTrckrItem.closeout_tracker_item_id} projectID={closeoutTeamTracker.project} closeoutTrackerID={closeoutTeamTracker.closeout_tracker_id} closeoutTeamTracker={closeoutTeamTracker} setCloseoutTeamTracker={setCloseoutTeamTracker} />)}

        <Form.Label htmlFor="comment" className='mt-3'>{`Comment`}</Form.Label>
        <Form.Control
            as="textarea"
            id={`${closeoutTeamTracker.project}-${closeoutTeamTracker.closeout_tracker_id}-comment`}
            onBlur={patchComment}
            defaultValue={closeoutTeamTracker.comment}
        />
    </Container>)
};

export default CloseoutTeamTrackerCmpnnt;