const DUMMY_ID = 9999999;
// ********************************* LKU *********************************
export const LKUsAPI = "/global_manage/lkus/";
export const allMangersAPI = "/core/users/current_office_all_managers/";
export const allAdminsAPI = "/core/users/current_office_all_admins/";
// ********************************* auth ********************************
export const loginAPI = "/auth/jwt/create/";
export const usersAPI = "/auth/users/";
export const currentUserAPI = "/auth/users/me/";
export function checkUserExistInfoCompleteAPI(azureEmail) {
  return `/core/check_user_info/${azureEmail}`;
}
// **************************** project master ***************************
export const listProjectMastersAPI = "/global_manage/project_masters/";
export const PMlistProjectMastersAPI =
  "/global_manage/project_masters/pm_projects/";
export function updateProjectMasterAPI(projectID) {
  return `/global_manage/project_masters/${projectID}/`;
}
export const adminListProjectMastersAPI =
  "/global_manage/project_masters/admin_projects/";
export const prospectProjectsMastersAPI =
  "/global_manage/project_masters/prospect_projects/";
// **************************** project header ***************************
export function listProjectHeaderAPI() {
  return `global_manage/project_masters/${DUMMY_ID}/project_header/`;
}
export function updateProjectHeaderAPI(projectID) {
  return `global_manage/project_masters/${projectID}/project_header/${projectID}/`;
}
export const prospectProjectsHeadersAPI =
  "/global_manage/project_masters/9999999/project_header/prospect_projects/";
// **************************** project detail ***************************
export function listProjectDetailAPI() {
  return `global_manage/project_masters/${DUMMY_ID}/project_detail/`;
}
export function patchTemporaryProjectDetailAPI(projectID) {
  return `/global_manage/project_masters/${projectID}/project_detail/${projectID}/temporary_patch_project_detail/`;
}
// ************************************* project initialize *****************************************
// project detail
export function updateProjectDetailAPI(projectID) {
  return `/global_manage/project_masters/${projectID}/project_detail/${projectID}/`;
}
export function patchCommercialDetailAPI(projectID) {
  return `/global_manage/project_masters/${projectID}/project_detail/${projectID}/patch_commercial/`;
}
// project rate
export function listProjectRateAPI(projectID) {
  return `/global_manage/project_masters/${projectID}/project_rates/`;
}
export function updateProjectRateAPI(projectID, projectRateID) {
  return `/global_manage/project_masters/${projectID}/project_rates/${projectRateID}/`;
}
// generate_invoice_structure
export function generateInvoiceStructureAPI(projectID) {
  return `/global_manage/project_masters/${projectID}/invoices/generate_invoice_structure/`;
}
// ************************************** project management *****************************************
export function updateProjectSummaryAPI(projectID) {
  return `global_manage/project_masters/${projectID}/project_summary/${projectID}/`;
}
export function feeSummaryAPI(projectID) {
  return `global_manage/fee_summary/${projectID}`;
}
export function hoursReportAPI(projectID, startDate, endDate) {
  return `global_manage/hourly_report/${projectID}/${startDate}/${endDate}`;
}
export function latestHoursReport(projectID) {
  return `global_manage/latest_hours_report/${projectID}`;
}
// **************************************** pm closeout **********************************************
export function listCloseoutTrackerAPI(projectID) {
  return `global_manage/project_masters/${projectID}/closeout_tracker/`;
}
export function updateCloseoutTrackerAPI(projectID, closeoutTrackerID) {
  return `global_manage/project_masters/${projectID}/closeout_tracker/${closeoutTrackerID}/`;
}
export function updateCloseoutTrackerItemAPI(
  projectID,
  closeoutTrackerID,
  closeoutTrackerItemID
) {
  return `global_manage/project_masters/${projectID}/closeout_tracker/${closeoutTrackerID}/closeout_tracker_item/${closeoutTrackerItemID}/`;
}
export function closeoutInfoAPI(projectID) {
  return `global_manage/closeout_info/${projectID}`;
}
// ********************************************** COH ************************************************
export const listWeekSummariesAPI = "/global_manage/week_summaries/";
export const listCOHActiveWeekIDsAPI = "/global_manage/week_summaries/editable_week_ids/";
export function updateWeekSummariesAPI(weekSummaryID) {
  return `global_manage/week_summaries/${weekSummaryID}/`;
}
export function listDaySummariesAPI(weekID) {
  return `global_manage/weeks/${weekID}/day_summaries/`;
}
export function updateDaySummariesAPI(weekID, daySummaryID) {
  return `global_manage/weeks/${weekID}/day_summaries/${daySummaryID}/`;
}
export function listProjectHoursAPI(weekID) {
  return `global_manage/weeks/${weekID}/day_summaries/null/project_hours/`;
}
export function postProjectHoursAPI(weekID, daySummaryID) {
  return `global_manage/weeks/${weekID}/day_summaries/${daySummaryID}/project_hours/`;
}
export function updateProjectHoursAPI(weekID, daySummaryID, projectHourID) {
  return `global_manage/weeks/${weekID}/day_summaries/${daySummaryID}/project_hours/${projectHourID}/`;
}
export const listProjectRolesAPI = "/global_manage/project_roles/";
export function updateProjectRoleAPI(projectRoleID) {
  return `global_manage/project_roles/${projectRoleID}/`;
}
export const listSpainBlockedWeeksAPI = "/global_manage/spain_blocked_weeks/";
export const updateSpainBlockedWeeksAPI = "/global_manage/spain_blocked_weeks/block_weeks/";
export function updateSpainUnBlockedWeeksAPI(weekID) {
  return `/global_manage/spain_blocked_weeks/${weekID}/unblock_weeks/`;
}
export const listCOHProjectsAPI = "/global_manage/project_masters/coh_project_options/";
// ********************************************** Staffing ************************************************
export const currentWeekAPI = "/global_manage/weeks/current_week/";
export const next52WeeksListAPI = "/global_manage/weeks/next_52_weeks_list/";
export function listWorkingDaysAPI(weekID) {
  return `global_manage/weeks/${weekID}/working_days/`;
}
export function listAvailableDaysAPI(weekID) {
  return `global_manage/weeks/${weekID}/available_days/`;
}
export function updateAvailableDaysAPI(weekID, availableDaysID) {
  return `global_manage/weeks/${weekID}/available_days/${availableDaysID}/`;
}
export function listStaffingAPI(weekId) {
  return `global_manage/weeks/${weekId}/staffings/`;
}
export function updateStaffingAPI(weekId, staffingID) {
  return `global_manage/weeks/${weekId}/staffings/${staffingID}/`;
}
export function listUtilizationsAPI(weekID) {
  return `global_manage/weeks/${weekID}/utilization_summaries/`;
}
export function updateUtilizationAPI(weekID, utilizationID) {
  return `global_manage/weeks/${weekID}/utilization_summaries/${utilizationID}/`;
}
export const getPrepareAssignProjectManagerAPI =
  "global_manage/project_masters/999999/project_header/prepare_assign_manager/";
export const listStaffingProjectsAPI = "/global_manage/project_masters/staffing_project_options/";
// *********************************************** admin ***************************************************
export const getPrepareNewProjectAPI =
  "/global_manage/project_masters/prepare_new_project/";
export const adminInvoicesAPI =
  "/global_manage/project_masters/999999/invoices/admin_invoices/";
// *************************************** closeout team closeout *******************************************
export function projectsForCloseoutTeamAPI(teamID) {
  return `global_manage/project_masters/999999/closeout_tracker/projects_pending_approval/${teamID}/`;
}
export function teamCloseoutTrackerAPI(projectID, teamID) {
  return `global_manage/project_masters/${projectID}/closeout_tracker/team_tracker/${teamID}`;
}
export const closeoutTeamsByUserAPI = "global_manage/project_masters/999999/closeout_tracker/closeout_teams_by_user/";
// ************************************************ inovice *************************************************
export function listInvoicesAPI(projectID) {
  return `global_manage/project_masters/${projectID}/invoices/`;
}
export function updateInvoiceAPI(projectID, invoiceID) {
  return `global_manage/project_masters/${projectID}/invoices/${invoiceID}/`;
}
export function PMInvoiceSummaryAPI(projectID, invoiceID) {
  return `global_manage/project_masters/${projectID}/invoices/invoice_summary/`;
}
// invoice file
export function listInvoiceFilesAPI(projectID, invoiceID) {
  return `global_manage/project_masters/${projectID}/invoices/${invoiceID}/invoice_files/`;
}
export function downloadInvoiceFileAPI(projectID, invoiceID, invoiceFileID) {
  return `global_manage/project_masters/${projectID}/invoices/${invoiceID}/invoice_files/${invoiceFileID}/download_invoice_file/`;
}
export function deleteInvoiceFileAPI(projectID, invoiceID, invoiceFileID) {
  return `global_manage/project_masters/${projectID}/invoices/${invoiceID}/invoice_files/${invoiceFileID}/`;
}
// invoice line item
export function listInvoiceLineItemsAPI(projectID, invoiceID) {
  return `global_manage/project_masters/${projectID}/invoices/${invoiceID}/invoice_line_item/`;
}
export function updateInvoiceLineItemAPI(projectID, invoiceID, lineItemID) {
  return `global_manage/project_masters/${projectID}/invoices/${invoiceID}/invoice_line_item/${lineItemID}/`;
}
export function fillWithLatestHoursReportAPI(projectID, invoiceID) {
  return `global_manage/project_masters/${projectID}/invoices/${invoiceID}/invoice_line_item/fill_with_latest_hours_report/`;
}
// ********************************************** subcontract ***********************************************
export function listSubcontractAPI(projectId) {
  return `global_manage/project_masters/${projectId}/subcontracts/`;
}
export function PMSubcontractSummaryAPI(projectId) {
  return `global_manage/project_masters/${projectId}/subcontracts/subcontract_summary/`;
}
export const adminSubcontractAPI =
  "global_manage/project_masters/99999/subcontracts/admin_subcontracts/";
export function updateSubcontractAPI(projectID, contractID) {
  return `global_manage/project_masters/${projectID}/subcontracts/${contractID}/`;
}
export function adminSubcontractConnectAPI(projectId) {
  return `global_manage/project_masters/${projectId}/subcontracts/invoices_to_connect/`;
}
export function listSubcontractFilesAPI(projectID, subcontractID) {
  return `global_manage/project_masters/${projectID}/subcontracts/${subcontractID}/subcontract_files/`;
}
export function downloadSubcontractFileAPI(
  projectID,
  subcontractID,
  subcontractFileID
) {
  return `global_manage/project_masters/${projectID}/subcontracts/${subcontractID}/subcontract_files/${subcontractFileID}/download_subcontract_file/`;
}
export function deleteSubcontractFileAPI(
  projectID,
  subcontractID,
  subcontractFileID
) {
  return `global_manage/project_masters/${projectID}/subcontracts/${subcontractID}/subcontract_files/${subcontractFileID}/`;
}
// ********************************************** client ***********************************************
export function listClientAPI(officeID) {
  return `/global_manage/office/${officeID}/client/`;
}
export function updateClientAPI(officeID, clientID) {
  return `/global_manage/office/${officeID}/client/${clientID}/`;
}
// ********************************************** subcontract company ***********************************************
export function listSubcontractorAPI(officeID) {
  return `/global_manage/office/${officeID}/subcontractor/`;
}
export function updateSubcontractorAPI(officeID, SubcontractorID) {
  return `/global_manage/office/${officeID}/subcontractor/${SubcontractorID}/`;
}
// ********************************************** office ***********************************************
export const listAllOfficesAPI = `/global_manage/office/`;
// ********************************************** ICC ***********************************************
export const allICCUsersAPI = `/core/users/all_icc_users/`;
export const allICCProjectAPI = "/icc_manage/projects/";
export const listICCPMProjectsAPI = "/icc_manage/projects/pmo_projects/";
export const allICCOfficesAPI = "/icc_manage/iccoffices/";
export function listICCOfficesAPI(projectID) {
  return `/icc_manage/projects/${projectID}/iccoffices/`;
}
export function updateICCOfficesAPI(projectID, iccofficeID) {
  return `/icc_manage/projects/${projectID}/iccoffices/${iccofficeID}/`;
}
export function updatePillarSplitAPI(projectID) {
  return `/icc_manage/projects/${projectID}/pillar_split/`;
}
export function getAllLocalICCProjects(projectID) {
  return `/icc_manage/local_icc_projects/${projectID}`
}
export function iccfeeSummaryAPI(projectID) {
  return `/icc_manage/fee_summary/${projectID}`;
}
export function icchoursReportAPI(projectID, startDate, endDate) {
  return `/icc_manage/hourly_report/${projectID}/${startDate}/${endDate}`;
}
export function icclatestHoursReport(projectID) {
  return `/icc_manage/latest_hours_report/${projectID}`;
}
// **************************************** pm closeout **********************************************
export function listICCCloseoutTrackerAPI(projectID) {
  return `icc_manage/projects/${projectID}/closeout_tracker/`;
}
export function updateICCCloseoutTrackerAPI(projectID, closeoutTrackerID) {
  return `icc_manage/projects/${projectID}/closeout_tracker/${closeoutTrackerID}/`;
}
export function updateICCCloseoutTrackerItemAPI(
  projectID,
  closeoutTrackerID,
  closeoutTrackerItemID
) {
  return `icc_manage/projects/${projectID}/closeout_tracker/${closeoutTrackerID}/closeout_tracker_item/${closeoutTrackerItemID}/`;
}
export function ICCCloseoutInfoAPI(projectID) {
  return `icc_manage/closeout_info/${projectID}`;
}