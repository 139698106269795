import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import _ from 'lodash'
import { toast } from 'react-toastify';
import FrameCard from './common/FrameCard';
import PMProjectsDropdownList from '../screens/pmo/PMProjectsDropdownList';

function NavPage({ titleMessage, cardNavGroup, showProjectMasterDropdown = false }) {
    const accessPermissions = useSelector((state) => state.generic.accessPermissions);
    const selectedProject = useSelector((state) => state.PM.selectedProject);

    const history = useHistory();
    const location = useLocation();

    const handleRedirect = (path) => {
        if (showProjectMasterDropdown && _.isEmpty(selectedProject)) {
            toast.warning("Please select a project firstly!")
        } else history.push(`${path}`, { from: `${location.pathname}` })

        // history.push(location.pathname.replace(/\/[^\/]*$/, `${path}`));
        // Find a slash \/ which is followed by any number * of non slash characters [^\/] which is followed by end of string $
    };

    // const accessable = accessPermissions[cardNav.accessLimitaion]

    const getAccess = (accessLimitaion) => {
        let accessable = false;
        for (const access of accessLimitaion) {
            if (accessPermissions[access]) {
                accessable = true;
                break;
            }
        }
        return accessable
    };

    return (
        <>
            <Row>
                <Col><h3 className="mb-5 navTitle">{titleMessage}</h3></Col>
                {showProjectMasterDropdown && (
                    <Col>
                        <div style={{ marginLeft: '800px' }}>
                            <PMProjectsDropdownList />
                        </div>
                    </Col>
                )}
            </Row>
            <Row md={4} xs={1} className="mb-3">
                {cardNavGroup.map((cardNav) => (
                    getAccess(cardNav.accessLimitaion) ? <Col key={cardNav.path} className="mb-3">
                        <FrameCard className="">
                            <Button
                                variant="outline-primary"
                                className="mt-2 navCard"
                                onClick={() => handleRedirect(cardNav.path)}
                            >
                                {cardNav.label}
                            </Button>
                        </FrameCard>
                    </Col> : []
                ))}
            </Row>
        </>
    );
}

export default NavPage;