import React, { useState } from 'react';
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { Container, Row, Col } from 'react-bootstrap';


import http from "../../../services/httpService";
import { hoursReportAPI } from "../../../services/backendURLService";
import CustomForm from '../../../components/common/CustomForm';
import CustomTable from '../../../components/common/CustomTable';
import Spinner from '../../../components/Spinner'

const HourlyReport = ({ projectID }) => {
    const [hourlyReport, setHourlyReport] = useState(null);
    const [showLoader, setShowLoading] = useState(false)

    const formInfo = [
        {
            label: "Start Date",
            id: "start_date",
            type: "date"
        },
        {
            label: "End Date",
            id: "end_date",
            type: "date"
        }
    ];

    const formStructure = { submit_button: { label: 'Generate Hourly Report' } };

    const schema = {
        [formInfo[0].id]: Joi.date().required().label(formInfo[0].label),
        [formInfo[1].id]: Joi.date().required().label(formInfo[1].label)
    };

    const doSubmit = async (data, errors, setData, setErrors) => {
        console.log('HourlyReport - doSubmit - data', data)
        setShowLoading(true)
        try {
            const { data: hourly_report } = await http.get(hoursReportAPI(projectID, data.start_date, data.end_date));
            setHourlyReport(hourly_report);
            setShowLoading(false)
        } catch (error) {
            console.error("Error fetching hours report:", error);
            toast.warning("Project detail info is not complete."); // Toast for 405 status
        }
    };

    const columns = [
        { path: "resource", label: "Resource Name" },
        { path: "position", label: "Project Positon" },
        { path: "rate", label: "Rate" },
        { path: "hours", label: "Actual Recorded Hours" },
        { path: "total_spend", label: "Total Spend" }
    ];

    const initializedData = { start_date: '', end_date: '' }

    return (

        <>
            {/* Hourly Report */}
            <Container className='hourly-report-container'>
                <Row>
                    <div className="hoursReportIntro">
                        <p>This allows you to generate an hours report between certain dates.</p>
                        <p>The report is inclusive, e.g. if you select 1/1/2021 as start and 2/1/2021 as end, you will also get
                            hours from 1/1/2021 and 2/1/2021.</p>
                        <p>Only actual hours recorded are displayed in this report. If using for invoicing purposes, ensure that
                            all team members have recorded their hours for the period.</p>
                        <p>The most recent hours report for this project will display below until a new report is run.</p>
                    </div>
                </Row>
                <Row>
                    <Col md={8}>
                        <CustomForm
                            formInfo={formInfo}
                            formStructure={formStructure}
                            doSubmit={doSubmit}
                            schema={schema}
                            initializedData={initializedData}
                        />
                    </Col>
                </Row>
                <Row className='mt-5'>
                    {showLoader && <div style={{ marginTop: '-250px', marginLeft: '-250px', padding: '20px' }}><Spinner /></div>}
                    {hourlyReport && hourlyReport.length !== 0 && !showLoader && <CustomTable headerData={columns} bodyData={hourlyReport} />}
                    {hourlyReport && hourlyReport.length == 0 && !showLoader && <h3>There is no data during this period</h3>}
                </Row>
            </Container></>


    );

};

export default HourlyReport;