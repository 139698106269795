import React, { useState, useEffect } from 'react';
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import StaffingTableFormCell from './StaffingTableFormCell';
import { postUtilization, patchUtilization, deleteUtilization } from '../../../../store/staffing-actions'
import { conditionalRenderColor } from '../../../../utils/staffingUtil'
import { addSumLine } from '../../../../utils/tableUtil';

/*
    sum line
    utilization line
    user click cell
*/
const StaffingTableBody = ({ itemsDisplayed, headerData }) => {
    const dispatch = useDispatch();
    const [completeRows, setCompleteRows] = useState()
    const utilizations = useSelector((state) => state.Staffing.utilizations);

    // console.log("StaffingTableBody - - utilizations", utilizations)
    // console.log("StaffingTableBody - - completeRows", completeRows)

    {/********************************************* handle unit cell change *********************************************/ }
    const [selectedInputIndex, setSelectedInputIndex] = useState({ "selectedRow": {}, "selectedColumnPath": '' });

    const handleClick = (clickedRow, columnPath) => {
        // console.log('CustomTable - handleDoubleClick - clickedRow', clickedRow)
        // console.log('CustomTable - handleDoubleClick - columnPath', columnPath)

        setSelectedInputIndex({
            'selectedRow': clickedRow,
            "selectedColumnPath": columnPath
        })

        /* from redux, find the this clicked row resource's utilization */
        let utilization = utilizations.find(utilization => utilization.hasOwnProperty('resource') && utilization.resource.id === clickedRow.resource.id)
        let temp_rows = [...completeRows]

        /* 
         Remove the previous utilization line if it exists 
         1. when use click a cell which is already active, without the following code, there will be multiple utilization lines. so the following three lines of code are necessary
         2. use 'pillar_item' to identify utilization is not a good way, MODIFY it!
     */
        const utilizationRowIndex = temp_rows.findIndex(element => 'pillar_item' in element.resource);
        if (utilizationRowIndex !== -1) {
            temp_rows.splice(utilizationRowIndex, 1);
        }

        /*  inserts the utilization element into the temp_rows array at the position specified by index + 1 */
        const index = temp_rows.findIndex(temp_row => temp_row.id == clickedRow.id)
        temp_rows.splice(index + 1, 0, utilization);
        setCompleteRows(temp_rows)
    };

    const doDispatchUtilization = (weekID, modifiedUtilizationData) => {
        const utilizationID = modifiedUtilizationData[weekID].utilization_summary_id
        const utilization = modifiedUtilizationData[weekID].utilization.toFixed(2)
        const resourceID = modifiedUtilizationData.resource.id

        const data = {
            "resource": resourceID,
            "utilization": utilization
        }

        // console.log('StaffingTableBody - doDispatchUtilization - weekID', weekID)
        // console.log('StaffingTableBody - doDispatchUtilization - utilizationID', utilizationID)
        // console.log('StaffingTableBody - doDispatchUtilization - utilization', utilization)

        // if (utilization == 0 && utilizationID !== null) dispatch(deleteUtilization(weekID, utilizationID, data));
        // else if (utilization !== 0) {
        //     if (utilizationID == null) dispatch(postUtilization(weekID, data));
        //     else dispatch(patchUtilization(weekID, utilizationID, data));
        // }
        if (utilizationID == null) dispatch(postUtilization(weekID, data));
        else dispatch(patchUtilization(weekID, utilizationID, data));
    };

    const handleSubmit = (data, projectRoleID, doSumbitInOuterCmpnnt, staffingID) => {
        // console.log('StaffingTableBody - submitInTableLevel - data', data)
        // console.log('CustomTable - submitInTableLevel - Object.values(data)[0]', Object.values(data)[0])

        const updatedValue = Object.values(data)[0]
        const { selectedColumnPath: columnPath, selectedRow } = selectedInputIndex;
        const complet_row = Array.from(completeRows);
        const rowIndex = complet_row.findIndex(element => element.id == selectedRow.id);


        /* task 0: update complete row, this task has already been done in StaffingTableFormCell */

        /* task 1: set selected row = empty */
        setSelectedInputIndex({ "selectedRow": {}, "selectedColumnPath": '' })

        /* task 2: update utilization in redux */
        const utilizationRowIndex = completeRows.findIndex(element => 'pillar_item' in element.resource);
        doDispatchUtilization(columnPath, completeRows[utilizationRowIndex])

        /* 
            task 3: remove utilization line in complete rows
            these codes are not necessary, because in useEffect, when itemDisplayed change, selectedInputIndex is empty, utilization line is removed automatically
        */
        let temp_rows = [...complet_row]
        temp_rows.splice(rowIndex - 1, 1);
        setCompleteRows(temp_rows)

        /* task 4: update staffing in redux */
        doSumbitInOuterCmpnnt({
            "project_role": projectRoleID,
            "week_id": Object.keys(data)[0],
            "forecasted_days": updatedValue,
            "forecasted_days_id": staffingID
        })

        /* task 5: update StaffingTable completeBodyData, no need to do this because staffing in redux is the source of the completeBodyData, when staffing in redux updated, completeBodayData will update automatically */
    };
    {/************************************************ render body ******************************************************/ }
    const renderCell = (row, column) => {
        /* normal rows: contains resource(object) project(object) id(project role id) */
        if (!row.resource.hasOwnProperty('pillar_item') && !row.resource.hasOwnProperty('sum')) {
            const customContent = column.customContent
            const contentString = _.get(row, column.path).value
            const staffingID = _.get(row, column.path).id

            if (customContent) {
                if (customContent.type === 'inputbox') {
                    const cellKey = `${row.id}-${column.path}`
                    return <td key={cellKey} onClick={() => handleClick(row, column.path)}>
                        {selectedInputIndex.selectedRow.id + '-' + selectedInputIndex.selectedColumnPath === cellKey ?
                            <StaffingTableFormCell
                                formInfo={customContent.formInfo}
                                initializedData={{ [column.path]: contentString }}
                                doSubmit={(data) => handleSubmit(data, row.id, customContent.doSubmit, staffingID)}
                                formSchema={customContent.schema}
                                cellLocatedRow={row}
                                rows={completeRows}
                                setRows={setCompleteRows} /> : contentString

                        }
                    </td>
                }
            }
            if (!customContent)
                return <td key={`${row.id}-${column.path}`}>
                    {contentString}
                </td>;
        } else if (row.resource.hasOwnProperty('pillar_item')) {
            /* utilization rows： contains resource({pillar_item: ..., }) */
            if (column.path == 'project') return <td key={`${row.id}-${column.path}`}></td>;
            if (column.path == 'manager') return <td key={`${row.id}-${column.path}`}></td>;
            if (column.path == 'resource') return <td key={`${row.id}-${column.path}`}>Resource Utilization</td>;
            else {
                const utilizationValue = _.get(row, column.path).utilization
                const cellColor = conditionalRenderColor(utilizationValue)

                return <td key={`${row.id}-${column.path}`} style={{ backgroundColor: cellColor }}>
                    {(utilizationValue * 100).toFixed(0) + '%'}
                </td >;
            }
        } else if (row.resource.hasOwnProperty('sum')) {
            if (column.path == 'project') return <td key={`${row.id}-${column.path}`} style={{ backgroundColor: 'rgb(58, 187, 184)' }}></td >;
            if (column.path == 'manager') return <td key={`${row.id}-${column.path}`} style={{ backgroundColor: 'rgb(58, 187, 184)' }}></td >;
            if (column.path == 'resource') return <td key={`${row.id}-${column.path}`} style={{ backgroundColor: 'rgb(58, 187, 184)' }}>Sum</td>;
            else {
                const sumValue = _.get(row, column.path).toFixed(2)
                return <td key={`${row.id}-${column.path}`} style={{ backgroundColor: 'rgb(58, 187, 184)' }}>
                    {sumValue}
                </td >;
            }
        }
    };

    /* 
        1. add sum line
        2. if there is selected cell, add utilization line
        3. cannot explain why we need this code
        4. this part is an example of the infinite loop
    */
    useEffect(() => {
        const items_displayed = addSumLine(itemsDisplayed)
        if (Object.keys(selectedInputIndex.selectedRow).length !== 0) {
            /* if selected row is not empty, find the utilization from redux for the selected resource */
            let utilization = utilizations.find(utilization => utilization.hasOwnProperty('resource') && utilization.resource.id === selectedInputIndex.selectedRow.resource.id)
            let temp_rows = [...items_displayed]

            /* add the utilization line for the selected row */
            const index = temp_rows.findIndex(temp_row => temp_row.id == selectedInputIndex.selectedRow.id)
            temp_rows.splice(index + 1, 0, utilization);

            setCompleteRows(temp_rows)
        } else setCompleteRows(items_displayed)

    }, [itemsDisplayed]);

    // console.log('StaffingTableBody -  - completeRows', completeRows)
    // console.log('StaffingTableBody -  - itemsDisplayed', itemsDisplayed)

    return (
        <>{completeRows &&
            <tbody>
                {completeRows.map((row) => (
                    <tr key={row.id || 'utilization'} >
                        {headerData.map((column) => (
                            <React.Fragment key={`${column.path}-${row.id}`}>
                                {renderCell(row, column)}
                            </React.Fragment>
                        ))}
                    </tr>
                ))}
            </tbody>
        }</>
    );
};

export default StaffingTableBody;