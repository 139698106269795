import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { updatePMSubcontract } from "../../../store/PM-actions";
import { processUpdateSubcontract } from "../../../utils/publicUtil";
import { subcontractStatus } from "../../../services/constService";
import { generateOptions } from "../../../utils/publicUtil";
import SubcontractFileModal from '../../../components/pages/SubcontractFileModal';
import CustomTable from "../../../components/custom/Table/CustomTable";

const PMConfirmSubcontracts = ({ confirmSubcontracts, projectID }) => {
  const dispatch = useDispatch();
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedSubcontractID, setSelectedSubcontractID] = useState();

  const columns = [
    { path: "project.project_number", label: "Project Number" },
    { path: "project.name", label: "Project Name" },
    { path: "project.project_header.manager.value", label: "Project Manager" },
    { path: "project.project_header.status.value", label: "Project Status" },
    { path: "invoicing_company.value", label: "Subcontractor",sortPath:"invoicing_company.value", },
    { path: "invoice_number", label: "Subcontractor Invoice Number" },
    { path: "invoice_date", label: "Subcontractor Invoice Date" },
    { path: "concept", label: "Concept" },
    { path: "amount", label: "Amount", displayType: 'currency_amount', },
    {
      path: "due_date", label: "Due date", customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "due_date",
          id: "due_date",
          type: "date",
        },
        schema: {
          'due_date': Joi.date().required().label("due_date"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "payment_term.value", label: "Payment terms" },
    {
      path: "comment", label: "Comment",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "comment",
          id: "comment",
          type: "text",
        },
        schema: {
          comment: Joi.string().required().label("comment"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract(processUpdateSubcontract(selectedRow)))
        },
      },
    },
    { path: "status.value", label: "Status" },
    { path: "invoice.invoice_number", label: "Corresponding customer invoice number" },
    { path: "invoice.sent_date", label: "Customer date sent" },
    { path: "invoice.paid_date", label: "Customer invoice paid date" },
  ];
  /**************************************** button group ***************************************************/
  const buttonGroup = {
    upper_left: [
      {
        type: "primary",
        buttonText: (
          <Fragment>
            <FaArrowUp style={{ marginRight: "5px" }} />
            Approve and Move it to Paid Subcontract
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract({
            originalRowInfo: {
              subcontractID: selectedRow.id,
              projectID: selectedRow.project.id,
            },
            updatedInfo: {
              id: selectedRow.id,
              status: subcontractStatus.PENDING_PAY,
            },
          }))
        },
        id: 0,
      },
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FaArrowDown style={{ marginRight: "5px" }} />
            Reject and Move it Back to Connect to Client Invoice
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMSubcontract({
            originalRowInfo: {
              subcontractID: selectedRow.id,
              projectID: selectedRow.project.id,
            },
            updatedInfo: {
              id: selectedRow.id,
              status: subcontractStatus.CONNECT,
            },
          }))
        },
        id: 1,
      },
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Attach/View PDF files
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select subcontract!");
          } else {
            setSelectedSubcontractID(selectedRow.id)
            setShowFileModal(true)
          }
        },
        id: 2,
      },
    ],
  };

  const projectNameOptions = generateOptions(
    confirmSubcontracts,
    'project.id',
    'project.name',
    'project.project_number'
  );
  const projectNumberOptions = generateOptions(
    confirmSubcontracts,
    'project.id',
    'project.project_number',
    'project.project_number'
  );
  const projectManagerOptions = generateOptions(
    confirmSubcontracts,
    'project.project_header.manager.id',
    'project.project_header.manager.value',
    'project.project_number'
  );
  const projectStatusOptions = generateOptions(
    confirmSubcontracts,
    'project.project_header.status.id',
    'project.project_header.status.value',
    'project.project_number'
  );
  const subcontractorCompanyOptions = generateOptions(
    confirmSubcontracts,
    'id',
    'invoicing_company.value',
    'invoicing_company.value'
  );

  ////prepare filter options data end
  const filterGroup = [
    {
      'id': 'Project Name',
      'path': 'project',
      "options": projectNameOptions,
      "label": "Project Name"
    },
    {
      'id': 'Project Number',
      'path': 'project',
      "options": projectNumberOptions,
      "label": "Project Number"

    },
    {
      'id': 'Project Manager',
      'path': 'project.project_header.manager',
      "options": projectManagerOptions,
      "label": "Project Manager"
    },
    {
      'id': 'Project Status',
      'path': 'project.project_header.status',
      "options": projectStatusOptions,
      "label": "Project Status"
    },
    {
      'id': 'Subcontractor Company',
      'path': 'invoicing_company.value',
      "options": subcontractorCompanyOptions,
      "label": "Subcontractor"
    },
  ]


  return (
    <div className="table-container">
      <h2>
      PM Responsiblility - Review that the correct client invoice was connected, approve to pay subcontractor, contact Admin if you want to hold payment
      </h2>
      <CustomTable
        headerData={columns}
        bodyData={confirmSubcontracts}
        buttonGroup={buttonGroup}
        filterGroup={filterGroup}
      />
      <br />
      <br />
      <SubcontractFileModal show={showFileModal} setShow={setShowFileModal} projectID={projectID} subcontractID={selectedSubcontractID} />
    </div>
  );
};

export default PMConfirmSubcontracts;
