import { createSlice } from "@reduxjs/toolkit";

/* 
  basicWeekHeader: For the staffing part, the table header is variable, not fixed. basicWeekHeader mainly contains the week ids of the next 15 weeks
*/
const staffingSlice = createSlice({
  name: "Staffing",
  initialState: {
    weekLists: [],
    selectedWeek: {},
    workingDays: null,
    availableDays: null,
    forecastedDays: null,
    utilizations: null,
    projectProspectMasters: [],
    projectProspectHeaders: [],
    staffingSelectedGenre: [],
    // availableSelectedGenre: [],
    utilizationSelectedGenre: [],
    projectOptions: null,
  },
  reducers: {
    setProspectProjectsMasters(state, action) {
      state.projectProspectMasters = action.payload.projectProspectMasters;
    },
    setProspectProjectsHeaders(state, action) {
      state.projectProspectHeaders = action.payload.projectProspectHeaders;
    },
    setWeekLists(state, action) {
      state.weekLists = action.payload.weekLists;
    },
    setSelectedWeek(state, action) {
      state.selectedWeek = action.payload.selectedWeek;
    },
    setWorkingDays(state, action) {
      state.workingDays = action.payload.workingDays;
    },
    setAvailableDays(state, action) {
      state.availableDays = action.payload.availableDays;
    },
    modifyAvailableDays(state, action) {
      const {
        available_days,
        available_day_id,
        resource: resource_id,
        week: week_id,
        workingDays: working_days,
      } = action.payload.newAvailableDay;

      const modified_available_days = Array.from(state.availableDays);
      const changedDataIndex = modified_available_days.findIndex(
        (element) => element.id && element.id == resource_id
      );

      // console.log(
      //   "staffing-slice - modifyAvailableDays - available_day_id",
      //   available_day_id
      // );

      modified_available_days[changedDataIndex] = {
        ...modified_available_days[changedDataIndex],
        [week_id]: {
          id: available_day_id,
          value: available_days,
          working_days: working_days,
        },
      };

      state.availableDays = modified_available_days;
    },
    setForecastedDays(state, action) {
      // const { forecasted_days } = action.payload;
      // if (forecasted_days.length == 0)
      //   forecasted_days.push({ empty_message: "empty_forecasted_days" });
      state.forecastedDays = action.payload.forecasted_days;
    },
    modifyForecastedDays(state, action) {
      const {
        forecasted_days,
        staffing_id: forecasted_day_id,
        project_role: project_role_id,
        week: week_id,
      } = action.payload.newForecastedDay;

      const modified_forecasted_days = Array.from(state.forecastedDays);
      const changedDataIndex = modified_forecasted_days.findIndex(
        (element) => element.id && element.id == project_role_id
      );

      // console.log(
      //   "staffing-slice - modifyForecastedDays - forecasted_day_id",
      //   forecasted_day_id
      // );

      modified_forecasted_days[changedDataIndex] = {
        ...modified_forecasted_days[changedDataIndex],
        [week_id]: {
          id: forecasted_day_id,
          value: forecasted_days,
        },
      };

      state.forecastedDays = modified_forecasted_days;
    },
    setUtilizations(state, action) {
      state.utilizations = action.payload.utilizations;
    },

    modifyUtilizations(state, action) {
      const {
        utilization,
        utilization_summary_id,
        resource: resource_id,
        week: week_id,
      } = action.payload.newUtilization;

      const modified_utilizations = Array.from(state.utilizations);
      const changedDataIndex = modified_utilizations.findIndex(
        (element) =>
          element.hasOwnProperty("resource") &&
          element.resource.id == resource_id
      );

      // console.log(
      //   "staffing-slice - modifyUtilizations - utilization_summary_id",
      //   utilization_summary_id
      // );

      modified_utilizations[changedDataIndex] = {
        ...modified_utilizations[changedDataIndex],
        [week_id]: {
          utilization_summary_id: utilization_summary_id,
          utilization: utilization,
        },
      };

      state.utilizations = modified_utilizations;
    },
    setStaffingSelectedGenre(state, action) {
      state.staffingSelectedGenre = action.payload.selectedGenre;
    },
    // setAvailableSelectedGenre(state, action) {
    //   state.availableSelectedGenre = action.payload.selectedGenre;
    // },
    setUtilizationSelectedGenre(state, action) {
      state.utilizationSelectedGenre = action.payload.selectedGenre;
    },
    setProjectOptions(state, action) {
      state.projectOptions = action.payload.projectOptions;
    },
  },
});

export const staffingActions = staffingSlice.actions;

export default staffingSlice;
