import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import http from "../../../services/httpService";
import { closeoutTeamsByUserAPI } from "../../../services/backendURLService";
import Spinner from '../../../components/Spinner'
import CloseoutTeamView from './CloseoutTeamView';

const CloseoutView = () => {
    const [teams, setTeams] = useState()

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await http.get(`${closeoutTeamsByUserAPI}`);
            setTeams(data)
        }
        fetchData();
    }, []);

    return (
        <>
            {teams ?
                <Tabs>
                    <TabList>
                        {teams.map(team => <Tab>{team.title}</Tab>)}
                    </TabList>
                    {teams.map(team => <TabPanel>
                        <CloseoutTeamView team={team} />
                    </TabPanel>)}
                </Tabs>
                : <Spinner />}
        </>
    );
};

export default CloseoutView;