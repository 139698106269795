import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from "react-redux";

import { invoiceStatus } from "../../../services/constService";
import Spinner from "../../../components/Spinner";
import HourlyReport from "../ProjectManagement/HourlyReport";
import PMForecastedInvoices from './PMForecastedInvoices';
import PMRequestInvoices from "./PMRequestInvoices";
import PMCreateInvoices from "./PMCreateInvoices";
import PMApproveInvoices from "./PMApproveInvoices";
import PMPendingSendInvoices from "./PMPendingSendInvoices";
import PMSentInvoices from "./PMSentInvoices";
import PMPaidInvoices from "./PMPaidInvoices";
import PMAllInvoices from "./PMAllInvoices";
import { office as lkuOffice } from "../../../services/constService";

const NavPMInvoicing = () => {
  const selectedProject = useSelector((state) => state.PM.selectedProject);
  const history = useHistory();
  const invoices = useSelector((state) => state.PM.PMInvoices);
  const [forecastedInvoices, setForecastedInvoices] = useState([]);
  const [requestInvoices, setRequestInvoices] = useState([]);
  const [createInvoices, setCreateInvoices] = useState([]);
  const [approveInvoices, setApproveInvoices] = useState([]);
  const [pendingSendInvoices, setPendingSendInvoices] = useState([]);
  const [sendInvoices, setSentInvoices] = useState([]);
  const [paidInvoices, setPaidInvoices] = useState([]);
  const user = useSelector((state) => state.generic.currentUser);
  const office = user.office_id;

  useEffect(() => {
    if (!selectedProject.hasOwnProperty('id')) {
      console.log('redirect to another page')
      history.push('/pmo');
    }
  }, [selectedProject]);

  useEffect(() => {
    if (invoices && !('empty_message' in invoices[0]) && invoices[0].project.id == selectedProject.id) {
      setForecastedInvoices(invoices.filter(e => e.status.id == invoiceStatus.FORECASTED))
      setRequestInvoices(invoices.filter(e => e.status.id == invoiceStatus.REQUEST))
      setCreateInvoices(invoices.filter(e => e.status.id == invoiceStatus.CREATE))
      setApproveInvoices(invoices.filter(e => e.status.id == invoiceStatus.APPROVE))
      setPendingSendInvoices(invoices.filter(e => e.status.id == invoiceStatus.PENDING_SEND))
      setSentInvoices(invoices.filter(e => e.status.id == invoiceStatus.SENT))
      setPaidInvoices(invoices.filter(e => e.status.id == invoiceStatus.PAID))
    }
  }, [invoices]);

  // console.log('NavPMInvoicing - - invoices', invoices)

  /* 
      !invoices || invoices[0].project.id !== selectedProject.id 
      1. not null: at least contain empty_message with project ID
      2. is the right project
  */
  return (<>
    {!invoices || invoices[0].project.id !== selectedProject.id ? <Spinner /> :
      <>
        <Row>
          <Col><h3 className="mb-5">Invoicing</h3></Col>
          <Col><h3 className="mb-5">{selectedProject.value}</h3></Col>
        </Row>

        <Tabs>
          <TabList>
            <Tab>Invoice Management</Tab>
            <Tab>Invoice Summary</Tab>
          </TabList>

          <TabPanel>
          {(office == lkuOffice.BARCELONA || office == lkuOffice.MADRID) ?
            <Row>
            <Col xs={8}>
              <Tabs>
                <TabList>
                  <Tab>Request</Tab>
                  <Tab>Create</Tab>
                  <Tab>Paid Invoices</Tab>
                </TabList>

                <TabPanel>
                  <PMRequestInvoices requestInvoices={requestInvoices} projectID={selectedProject.id} />
                </TabPanel>
                <TabPanel>
                  <PMCreateInvoices createInvoices={createInvoices} projectID={selectedProject.id} />
                </TabPanel>
                <TabPanel>
                  <PMPaidInvoices paidInvoices={paidInvoices} projectID={selectedProject.id} />
                </TabPanel>
              </Tabs>
            </Col>
            <Col>
              <PMForecastedInvoices forecastedInvoices={forecastedInvoices} projectID={selectedProject.id} />
              <div className='invoiceManage-hoursReport-border'>
                <HourlyReport projectID={selectedProject.id} />
              </div>
            </Col>
          </Row>
            :<Row>
              <Col xs={8}>
                <Tabs>
                  <TabList>
                    <Tab>Request</Tab>
                    <Tab>Create</Tab>
                    <Tab>Approve</Tab>
                    <Tab>Pending Send</Tab>
                    <Tab>Sent Invoices</Tab>
                    <Tab>Paid Invoices</Tab>
                  </TabList>

                  <TabPanel>
                    <PMRequestInvoices requestInvoices={requestInvoices} projectID={selectedProject.id} />
                  </TabPanel>
                  <TabPanel>
                    <PMCreateInvoices createInvoices={createInvoices} projectID={selectedProject.id} />
                  </TabPanel>
                  <TabPanel>
                    <PMApproveInvoices approveInvoices={approveInvoices} projectID={selectedProject.id} />
                  </TabPanel>
                  <TabPanel>
                    <PMPendingSendInvoices pendingSendInvoices={pendingSendInvoices} projectID={selectedProject.id} />
                  </TabPanel>
                  <TabPanel>
                    <PMSentInvoices sendInvoices={sendInvoices} projectID={selectedProject.id} />
                  </TabPanel>
                  <TabPanel>
                    <PMPaidInvoices paidInvoices={paidInvoices} projectID={selectedProject.id} />
                  </TabPanel>
                </Tabs>
              </Col>
              <Col>
                <PMForecastedInvoices forecastedInvoices={forecastedInvoices} projectID={selectedProject.id} />
                <div className='invoiceManage-hoursReport-border'>
                  <HourlyReport projectID={selectedProject.id} />
                </div>
              </Col>
            </Row>}
          </TabPanel>
          <TabPanel>
            <PMAllInvoices invoices={invoices} projectID={selectedProject.id} />
          </TabPanel>
        </Tabs>
      </>
    }
  </>);
};

export default NavPMInvoicing;