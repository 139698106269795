import React from 'react';
import { toast } from "react-toastify";
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";

import http from '../../../services/httpService'
import { generateInvoiceStructureAPI } from '../../../services/backendURLService'
import { getPMInvoices } from "../../../store/PM-actions";
import FormForDisplay from '../../../components/common/FormForDisplay';

/* Used in PMO - Project Initialization */
const ProjectMasterDisplayForm = ({ projectID }) => {
    const projectMasters = useSelector((state) => state.PM.projectsForPMDropdown);
    const projectMaster = useSelector((state) => state.PM.projectMaster);
    const projectHeader = useSelector((state) => state.PM.projectHeader);
    const dispatch = useDispatch();
    {/********************************* display project master info *********************************/ }
    const selectedProject = projectMasters.find(element => element.id === projectID);

    const formInfo = [
        {
            label: "Project Number",
            id: "project_number",
        },
        {
            label: "Project Name",
            id: "name",
        },
        {
            label: "Offer Number",
            id: "offer_number",
            formText: 'For data integrity purposes you cannot edit project info here. If any of this information is wrong, contact the appropriate parties.',
        },
        {
            label: "Description",
            id: "description",
        },
        {
            label: "Project Manager",
            id: "manager",
        }
    ]

    const initializedData = {
        [formInfo[0].id]: projectMaster[formInfo[0].id],
        [formInfo[1].id]: projectMaster[formInfo[1].id],
        [formInfo[2].id]: projectMaster[formInfo[2].id],
        [formInfo[3].id]: projectMaster[formInfo[3].id],
        [formInfo[4].id]: projectHeader.manager && projectHeader.manager.label || '', // manager_name
    }

    const handleGenerateInvoiceStructure = async () => {
        try {
            await http.toastGet(`${generateInvoiceStructureAPI(projectID)}`);
            dispatch(getPMInvoices(selectedProject.id));
        } catch (ex) {
            if (ex.response) {
                toast.warning(ex.response.data);
            } else {
                console.error(ex);
                toast.error('An error occurred');
            }
        }
    };

    return (
        <div>
            <FormForDisplay
                formInfo={formInfo}
                displayedData={initializedData}
            />
            <hr />
            <p>
                This button automatically generates a forecasted invoice footprint in the PMO for the project using the inputs of fees, invoice frequency, and count of invoices.

                In order for it to work, you need to fill out those inputs.
            </p>
            <Button variant="outline-primary" onClick={handleGenerateInvoiceStructure} className='pull-right' >
                Generate Invoice Structure
            </Button>
        </div>
    );
};

export default ProjectMasterDisplayForm;