import { toast } from "react-toastify";
import { staffingActions } from "./staffing-slice";
import http from "../services/httpService";
import {
  next52WeeksListAPI,
  listAvailableDaysAPI,
  listStaffingAPI,
  updateAvailableDaysAPI,
  updateStaffingAPI,
  listUtilizationsAPI,
  updateUtilizationAPI,
  listProjectRolesAPI,
  listWorkingDaysAPI,
  prospectProjectsMastersAPI,
  prospectProjectsHeadersAPI,
  listStaffingProjectsAPI,
} from "../services/backendURLService";
import {
  transformAvailableDaysBody,
  transformForecastedDaysBody,
} from "../utils/staffingUtil";
import { processAPIData } from "../utils/publicUtil";

export const getStaffingWeekList = () => {
  return async (dispatch) => {
    try {
      const { data: week_list } = await http.get(`${next52WeeksListAPI}`);
      dispatch(
        staffingActions.setWeekLists({
          weekLists: week_list,
        })
      );
    } catch (error) {
      toast.error("getStaffingWeekList wrong!");
    }
  };
};

export const getWorkingDays = (weekID) => {
  return async (dispatch) => {
    try {
      const { data: working_days } = await http.get(
        `${listWorkingDaysAPI(weekID)}`
      );

      dispatch(
        staffingActions.setWorkingDays({
          workingDays: working_days,
        })
      );
    } catch (error) {
      toast.error("getWorkingDays wrong!");
    }
  };
};

export const getAvailableDays = (weekID) => {
  return async (dispatch) => {
    try {
      const { data: availableDays } = await http.get(
        `${listAvailableDaysAPI(weekID)}`
      );

      const organized_available_days =
        transformAvailableDaysBody(availableDays);

      // console.log(
      //   "staffing-actions - getAvailableDays - organized_available_days",
      //   organized_available_days
      // );

      dispatch(
        staffingActions.setAvailableDays({
          availableDays: organized_available_days,
        })
      );
    } catch (error) {
      toast.error("getAvailableDays wrong!");
    }
  };
};

export const postAvailableDays = (weekID, data) => {
  return async (dispatch) => {
    try {
      const { data: new_available_days } = await http.post(
        `${listAvailableDaysAPI(weekID)}`,
        data
      );

      dispatch(
        staffingActions.modifyAvailableDays({
          newAvailableDay: {
            ...new_available_days,
            workingDays: data.working_days,
          },
        })
      );
    } catch (error) {
      toast.error("postAvailableDays error!");
    }
  };
};

export const patchAvailableDays = (weekID, availableDaysID, data) => {
  return async (dispatch) => {
    try {
      await http.patch(
        `${updateAvailableDaysAPI(weekID, availableDaysID)}`,
        data
      );

      dispatch(
        staffingActions.modifyAvailableDays({
          newAvailableDay: {
            available_days: data.available_days,
            available_day_id: availableDaysID,
            resource: data.resource,
            week: weekID,
            workingDays: data.working_days,
          },
        })
      );
    } catch (error) {
      toast.error("patchAvailableDays error!");
    }
  };
};

export const deleteAvailableDays = (weekID, availableDaysID, data) => {
  return async (dispatch) => {
    try {
      // console.log("staffing-actions - deleteAvailableDays - data", data);
      await http.delete(`${updateAvailableDaysAPI(weekID, availableDaysID)}`);

      dispatch(
        staffingActions.modifyAvailableDays({
          newAvailableDay: {
            available_days: data.available_days,
            available_day_id: null,
            resource: data.resource,
            week: weekID,
            workingDays: data.working_days,
          },
        })
      );
    } catch (error) {
      toast.error("deleteAvailableDays error!");
    }
  };
};

export const getForecastedDays = (weekID) => {
  return async (dispatch) => {
    try {
      const { data: forecasted_days } = await http.get(
        `${listStaffingAPI(weekID)}`
      );

      // console.log(
      //   "staffing-actions - getForecastedDays - forecasted_days",
      //   forecasted_days
      // );

      let organizedForecastedDays = [];
      if (forecasted_days.length != 0) {
        organizedForecastedDays = transformForecastedDaysBody(forecasted_days);
      }

      dispatch(
        staffingActions.setForecastedDays({
          forecasted_days: organizedForecastedDays,
        })
      );
    } catch (error) {
      toast.error("getForecastedDays wrong!");
    }
  };
};

export const postForecastedDays = (weekID, data) => {
  return async (dispatch) => {
    try {
      const { data: new_forecasted_days } = await http.post(
        `${listStaffingAPI(weekID)}`,
        data
      );

      // console.log(
      //   "staffing-actions - postForecastedDays - new_forecasted_days",
      //   new_forecasted_days
      // );

      dispatch(
        staffingActions.modifyForecastedDays({
          newForecastedDay: new_forecasted_days,
        })
      );
    } catch (error) {
      toast.error("postForecastedDays error!");
    }
  };
};

export const patchForecastedDays = (weekID, forecastedDaysID, data) => {
  return async (dispatch) => {
    try {
      await http.patch(`${updateStaffingAPI(weekID, forecastedDaysID)}`, data);

      dispatch(
        staffingActions.modifyForecastedDays({
          newForecastedDay: {
            forecasted_days: data.forecasted_days,
            staffing_id: forecastedDaysID,
            project_role: data.project_role,
            week: weekID,
          },
        })
      );
    } catch (error) {
      toast.error("patchForecastedDays error!");
    }
  };
};

export const deleteForecastedDays = (weekID, forecastedDaysID, data) => {
  return async (dispatch) => {
    try {
      // console.log("staffing-actions - deleteForecastedDays - data", data);
      await http.delete(`${updateStaffingAPI(weekID, forecastedDaysID)}`);

      dispatch(
        staffingActions.modifyForecastedDays({
          newForecastedDay: {
            forecasted_days: data.forecasted_days,
            staffing_id: null,
            project_role: data.project_role,
            week: weekID,
          },
        })
      );
    } catch (error) {
      toast.error("deleteForecastedDays error!");
    }
  };
};

export const getUtilizations = (weekID) => {
  return async (dispatch) => {
    try {
      const { data: utilizations } = await http.get(
        `${listUtilizationsAPI(weekID)}`
      );

      // console.log(
      //   "staffing-actions - getUtilizations - utilizations",
      //   utilizations
      // );

      dispatch(
        staffingActions.setUtilizations({
          utilizations: utilizations,
        })
      );
    } catch (error) {
      toast.error("getAvailableDays wrong!");
    }
  };
};

export const postUtilization = (weekID, data) => {
  return async (dispatch) => {
    try {
      const { data: new_utilization_data } = await http.post(
        `${listUtilizationsAPI(weekID)}`,
        data
      );

      // console.log(
      //   "staffing-actions - postUtilizations - new_utilization_data",
      //   new_utilization_data
      // );

      dispatch(
        staffingActions.modifyUtilizations({
          newUtilization: new_utilization_data,
        })
      );
    } catch (error) {
      toast.error("postUtilization error!");
    }
  };
};

export const patchUtilization = (weekID, utilizationID, data) => {
  return async (dispatch) => {
    try {
      await http.patch(`${updateUtilizationAPI(weekID, utilizationID)}`, data);

      dispatch(
        staffingActions.modifyUtilizations({
          newUtilization: {
            utilization: data.utilization,
            utilization_summary_id: utilizationID,
            resource: data.resource,
            week: weekID,
          },
        })
      );
    } catch (error) {
      toast.error("patchUtilization error!");
    }
  };
};

// export const deleteUtilization = (weekID, utilizationID, data) => {
//   return async (dispatch) => {
//     try {
//       await http.delete(`${updateUtilizationAPI(weekID, utilizationID)}`);

//       dispatch(
//         staffingActions.modifyUtilizations({
//           newUtilization: {
//             utilization: data.utilization,
//             utilization_summary_id: null,
//             resource: data.resource,
//             week: weekID,
//           },
//         })
//       );
//     } catch (error) {
//       toast.error("deleteUtilization error!");
//     }
//   };
// };

export const postProjectRole = (data) => {
  return async (dispatch) => {
    try {
      await http.toastPost(`${listProjectRolesAPI}`, data);
    } catch (error) {
      if (
        error.response.status === 400 &&
        Object.values(error.response.data)[0] ==
        "The fields resource, project must make a unique set."
      )
        toast.error(`The project role has already exists`);
      // console.log(
      //   "staffing-actions - postProjectRole - Object.values(error.response.data)[0]",
      //   Object.values(error.response.data)[0]
      // );
    }
  };
};
/**************************Prospect Rent Page****************************** */
export const getProspectProjectMasters = () => {
  return async (dispatch) => {
    try {
      const { data: projectProspectMasters } = await http.get(
        `${prospectProjectsMastersAPI}`
      );
      dispatch(
        staffingActions.setProspectProjectsMasters({
          projectProspectMasters: projectProspectMasters,
        })
      );
    } catch (error) {
      console.log(
        "Staffing-actions - getProspectProjectMasters - Error: " + error
      );
      toast.error(error);
    }
  };
};
export const getProspectProjectHeaders = () => {
  return async (dispatch) => {
    try {
      const { data: projectProspectHeaders } = await http.get(
        `${prospectProjectsHeadersAPI}`
      );
      dispatch(
        staffingActions.setProspectProjectsHeaders({
          projectProspectHeaders: projectProspectHeaders,
        })
      );
    } catch (error) {
      console.log(
        "Staffing-actions - getProspectProjectHeaders - Error: " + error
      );
      toast.error(error);
    }
  };
};
/************************** generic ****************************** */
export const getProjectOptions = () => {
  return async (dispatch) => {
    try {
      const { data: project_options } = await http.get(
        `${listStaffingProjectsAPI}`
      );

      const organizedProjects = project_options.map(project => ({
        id: project.id,
        label: project.project_number + " " + project.name,
        value: project.project_number + " " + project.name,
      }))

      dispatch(
        staffingActions.setProjectOptions({
          projectOptions: organizedProjects,
        })
      );
    } catch (error) {
      console.log(
        "Staffing-actions - getProjectOptions - Error: " + error
      );
      toast.error(error);
    }
  };
};