import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { DropdownButton, Dropdown, ButtonGroup, Row, Col, Button } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import _ from 'lodash'

import { getAvailableDays, getForecastedDays, getUtilizations, getWorkingDays } from "../../../store/staffing-actions";
import { staffingActions } from "../../../store/staffing-slice";
import AvailableDays from './AvailableDays';
// import Staffing from './Staffing/Staffing'
import Staffing from './reWriteStaffing/Staffing'
import UtilizationSummary from "./UtilizationSummary";

const StaffPlanning = () => {
    const dispatch = useDispatch();
    const [dropdownList, setDropdownList] = useState([]);
    const weekLists = useSelector((state) => state.Staffing.weekLists);
    const selectedWeek = useSelector((state) => state.Staffing.selectedWeek);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const week_dropdown_list = weekLists.map(element => ({ value: element.week_name, id: element.week_id }));
        setDropdownList(week_dropdown_list)
    }, [weekLists]);

    const handleSelectWeek = (selectedWeek) => {
        dispatch(staffingActions.setSelectedWeek({ selectedWeek: selectedWeek }));
    };

    useEffect(() => {
        if (selectedWeek && !_.isEmpty(selectedWeek)) {
            dispatch(getWorkingDays(selectedWeek.id))
            dispatch(getAvailableDays(selectedWeek.id))
            dispatch(getForecastedDays(selectedWeek.id))
            dispatch(getUtilizations(selectedWeek.id))
        }
        if (!selectedWeek || _.isEmpty(selectedWeek)) {
            history.push('/staffing');
        }
    }, [selectedWeek]);

    const dataIsReady = !_.isEmpty(selectedWeek)

    return (
        <>

            {weekLists.length !== 0 &&
                <DropdownButton
                    as={ButtonGroup}
                    variant="outline-primary"
                    title={selectedWeek && selectedWeek.value || "Please select a starting week"}
                    className='pull-right project-master-dropdown-button m-2'

                >
                    {dropdownList.map((item) => (
                        <Dropdown.Item key={item.id} onClick={() => handleSelectWeek(item)} navitem='true'>
                            {item.value}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>}

            {dataIsReady ?
                <div>
                    <Button className="m-2 pull-right" variant="outline-primary" onClick={() => history.push(`/staffing/staff-planning/new-project-role-staffing`)}>Add Project Role</Button>
                    <Row>
                        <Col> <h3 className="mb-5">Staffing Sheet</h3></Col>
                    </Row>
                    <Tabs>
                        <TabList>
                            <Tab>Staffing Planning Sheet</Tab>
                            <Tab>Availability</Tab>
                            <Tab>Utilization Summary</Tab>

                        </TabList>

                        <TabPanel>
                            <Staffing />
                        </TabPanel>
                        <TabPanel>
                            <AvailableDays />
                        </TabPanel>
                        <TabPanel>
                            <UtilizationSummary />
                        </TabPanel>
                    </Tabs>
                </div>
                : null}
        </>
    );
};

export default StaffPlanning;