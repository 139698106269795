import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaSignOutAlt } from 'react-icons/fa';
import logo from "../assets/logo.svg"
import ICCSwitch from "./ICCSwitch";



function NavBar() {
  const user = useSelector((state) => state.generic.currentUser);
  const accessPermissions = useSelector((state) => state.generic.accessPermissions);
  const location = useLocation();


  return (
    <div>
      <Navbar bg="light" expand="lg" className="mb-3">
        <Navbar.Brand as={Link} to="/home">
          <img src={logo} alt="logo" width={"110px"} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {user.username && (
              <>
                <Nav.Link as={Link} to={{
                  pathname: '/home',
                  state: { from: `${location.pathname}` } // Pass the current path as the 'from' property
                }}>
                  MATER Home
                </Nav.Link>
                {accessPermissions.generalStaff && (
                  <NavLink className="nav-item nav-link"
                    to={{
                      pathname: '/coh/week-summaries',
                      state: { from: `${location.pathname}` }
                    }}>
                    COH
                  </NavLink>
                )}
                {(accessPermissions.generalStaff || accessPermissions.staffingManager) && (
                  <NavLink className="nav-item nav-link" to={{
                    pathname: '/staffing',
                    state: { from: `${location.pathname}` }
                  }}>
                    Staffing
                  </NavLink>
                )}
                {accessPermissions.projectManager && (
                  <NavLink className="nav-item nav-link"
                    to={{
                      pathname: '/pmo',
                      state: { from: `${location.pathname}` }
                    }}
                  >
                    PMO
                  </NavLink>
                )}
                {accessPermissions.admin && (
                  <Nav.Link as={Link} to={{
                    pathname: '/admin',
                    state: { from: `${location.pathname}` }
                  }}>
                    Admin
                  </Nav.Link>
                )}
                {accessPermissions.closeoutTeam && (
                  <Nav.Link as={Link} to={{
                    pathname: '/approvals',
                    state: { from: `${location.pathname}` }
                  }}>
                    Approvals
                  </Nav.Link>
                )}
                {accessPermissions.generalStaff && (
                  <NavLink className="nav-item nav-link" to={{
                    pathname: '/reports',
                    state: { from: `${location.pathname}` }
                  }}>
                    Reports
                  </NavLink>
                )}
                {accessPermissions.generalStaff && (<Nav.Link as={Link} to={{
                  pathname: '/settings',
                  state: { from: `${location.pathname}` }
                }}>
                  Settings
                </Nav.Link>
                )}

                  {/* {(accessPermissions.ICC_USER || accessPermissions.ICC_ADMIN) &&(
                <ICCSwitch/>)} */}
                  
              </>
            )}
          </Nav>
        </Navbar.Collapse>

        {user.username ? (<>
          <span className="greeting">Hello {user.first_name}!</span>
          {/* <Nav.Link as={Link} to="/me">
            {user.first_name}
          </Nav.Link> */}
          <Nav.Link as={Link} to="/logout">
            <FaSignOutAlt />&nbsp;&nbsp;Logout&nbsp;&nbsp;&nbsp;
          </Nav.Link>
        </>) :
          <Nav.Link as={Link} to="/login">
            Login&nbsp;&nbsp;&nbsp;
          </Nav.Link>
        }
      </Navbar>
    </div>
  );
}

export default NavBar;
