import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import ProjectSummary from "./ProjectSummary";
import FeeSummary from "./FeeSummary";
import ProjectHeaderDisplayForm from './ProjectHeaderDisplayForm';
import HourlyReport from './HourlyReport'
import LatestHoursReport  from "./LatestHoursReport";

const NavProjectManagement = () => {
    const selectedProject = useSelector((state) => state.PM.selectedProject);
    const history = useHistory();

    useEffect(() => {
        if (Object.keys(selectedProject).length === 0) history.push('/pmo');
    }, [selectedProject]);
    
    return (
        <div>
            <Row>
                <Col> <h3 className="mb-5">Project Management</h3></Col>
                <Col><h3 className="mb-5">{selectedProject.value}</h3></Col>
            </Row>
            {selectedProject.length !== 0 &&
                <>
                    <Row>
                        <Col md={9}>
                            <Tabs>
                                <TabList>
                                    <Tab>Project Summary</Tab>
                                    <Tab>Fee Summary</Tab>
                                    <Tab>Hourly Report</Tab>
                                    <Tab>Latest Hours Report</Tab>
                                </TabList>


                                <TabPanel>
                                    <ProjectSummary projectID={selectedProject.id} />
                                </TabPanel>
                                <TabPanel>
                                    <FeeSummary projectID={selectedProject.id} />
                                </TabPanel>
                                <TabPanel>
                                    <HourlyReport projectID={selectedProject.id} />
                                </TabPanel>
                                <TabPanel>
                                    <LatestHoursReport projectID={selectedProject.id} />
                                </TabPanel>
                            </Tabs>
                        </Col>

                        <Col>
                            <ProjectHeaderDisplayForm projectID={selectedProject.id} />
                        </Col>
                    </Row>

                </>
            }
        </div>
    );
};

export default NavProjectManagement;