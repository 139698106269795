import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import _ from "lodash";
import { FaArrowDown, FaArrowUp, FaPlus } from "react-icons/fa";

import { updatePMInvoice } from "../../../store/PM-actions";
import { processUpdateInvoice } from '../../../utils/publicUtil';
import { invoiceStatus } from "../../../services/constService";
import CustomTable from "../../../components/custom/Table/CustomTable";
import AddInvoiceLineItemDetail from '../../../components/pages/AddInvoiceLineItemDetail';

const PMRequestInvoices = ({ requestInvoices }) => {
  const dispatch = useDispatch();
  const [showAddLineItemDetail, setShowAddLineItemDetail] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();

  const columns = [
    { path: "status.title", label: "Invoice status" },
    {
      path: "concept",
      label: "Concept",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "concept",
          id: "concept",
          type: "text",
        },
        schema: { concept: Joi.string().required().label("Concept") },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        }
      },
    },
    {
      path: "invoice_date",
      label: "Date on invoice (yyyy-mm-dd)",
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "invoice_date",
          id: "invoice_date",
          type: "date",
        },
        schema: { invoice_date: Joi.date().required().label("Estimated Date") },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        }
      },
    },
    { path: "amount_estimate", label: "Previous Estimate Amount", displayType: 'currency_amount', },
    {
      path: "amount_actual",
      label: "Amount",
      displayType: 'currency_amount',
      customContent: {
        type: "inputbox",
        formInfo:
        {
          label: "amount_actual",
          id: "amount_actual",
          type: "text",
        },
        schema: {
          amount_actual: Joi.number().required().label("Amount"),
        },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        },
      },
    },
    {
      path: "notes",
      label: "Communication Between PM and Admin",
      customContent: {
        type: "inputbox",
        formInfo: {
          label: "notes",
          id: "notes",
          type: "text",
        },
        schema: { notes: Joi.string().required().label("Notes") },
        doSubmit: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice(processUpdateInvoice(selectedRow)))
        }
      },
    },
  ];
  /**************************************** button group ***************************************************/
  const buttonGroup = {
    upper_left: [
      {
        type: "primary",
        buttonText: (
          <Fragment>
            <FaArrowUp style={{ marginRight: "5px" }} />
            Submit Invoice to Admin
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice({
            originalRowInfo: {
              invoiceID: selectedRow.id,
              projectID: selectedRow.project.id,
            },
            updatedInfo: {
              id: selectedRow.id,
              status: invoiceStatus.CREATE,
            }
          }))
        },
        id: 0,
      },
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FaArrowDown style={{ marginRight: "5px" }} />
            Move Invoice Back in Progress
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) toast.warning("Please select invoice!");
          else dispatch(updatePMInvoice({
            originalRowInfo: {
              invoiceID: selectedRow.id,
              projectID: selectedRow.project.id,
            },
            updatedInfo: {
              invoiceID: selectedRow.id,
              status: invoiceStatus.FORECASTED,
              status_title: "Forecast",
            }
          }))
        },
        id: 1,
      },
      {
        type: "outline-primary",
        buttonText: (
          <Fragment>
            <FaPlus style={{ marginRight: "5px" }} /> Add Line Item Detail
          </Fragment>
        ),
        handleClick: (selectedRow) => {
          if (!selectedRow) {
            toast.warning("Please select invoice!");
          } else {
            setSelectedInvoice(selectedRow)
            setShowAddLineItemDetail(true)
          }
        },
        id: 2,
      },
    ],
  };

  console.log('PMRequestInvoices - - requestInvoices', requestInvoices)

  return (
    <div className="table-container">
      <h2>PM Responsibility - finalize invoice information, send details to Admin, and push button to send to Admin invoice page</h2>
      <CustomTable
        headerData={columns}
        bodyData={requestInvoices}
        buttonGroup={buttonGroup}
      />
      <p>A down payment should be placed as a project fee invoice.</p>
      <AddInvoiceLineItemDetail show={showAddLineItemDetail} setShow={setShowAddLineItemDetail} invoice={selectedInvoice} />
    </div>
  );
};

export default PMRequestInvoices;
