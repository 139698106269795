import React, { useEffect, useState } from "react";
import FileSaver from "file-saver";
import { toast } from "react-toastify";

import http from "../services/httpService";

export function useHandleFile({
  projectID,
  invoiceOrSubconID,
  filesAPI,
  downloadFileAPI,
  deleteFileAPI
}) {
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const { data: invoice_or_subcon_files } = await http.get(
        `${filesAPI(projectID, invoiceOrSubconID)}`
      );
      setFiles(invoice_or_subcon_files);
      setLoading(false);
    };

    if (invoiceOrSubconID) fetchData();
  }, [invoiceOrSubconID]);

  const handlDownloadFile = async (fileID, fileName) => {
    const { data: file } = await http.get(
      `${downloadFileAPI(projectID, invoiceOrSubconID, fileID)}`,
      { responseType: "blob" }
    );
    FileSaver.saveAs(new Blob([file]), `${fileName}`);
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();
    const file = e.target.formFile.files[0];

    const formData = new FormData();
    formData.append("file", file);

    try {
      const { data: newFile } = await http.toastPost(
        `${filesAPI(projectID, invoiceOrSubconID)}`,
        formData
      );
      // console.log('InvoiceFile - handleUploadFile - newInvoiceFile', newInvoiceFile)
      const updatedFiles = [...files, newFile];
      setFiles(updatedFiles);
      e.target.formFile.value = "";
    } catch (ex) {
      toast.error(ex.response);
    }
  };

  const handleDeleteFile = async (fileID) => {
    await http.toastDelete(
      `${deleteFileAPI(projectID, invoiceOrSubconID, fileID)}`
    );
    const updatedFiless = files.filter((file) => file.id !== fileID);
    setFiles(updatedFiless);
  };

  return {
    files,
    loading,
    handlDownloadFile,
    handleUploadFile,
    handleDeleteFile,
  };
}
