import React from 'react';
import { useSelector } from "react-redux";

import FormForDisplay from '../../../components/common/FormForDisplay';
import Spinner from '../../../components/Spinner';

/* Used in PMO - Project Management */
const ProjectHeaderDisplayForm = ({ projectID }) => {
    const projectMaster = useSelector((state) => state.PM.projectsForPMDropdown).find(element => element.id === projectID);
    const projectDetail = useSelector((state) => state.PM.projectDetail);
    const projectHeader = useSelector((state) => state.PM.projectHeader);
    const currentUserOffice = useSelector((state) => state.generic.currentUser).office_title;

    const formInfo = [
        {
            label: "Project Number",
            id: "project_number",
        },
        {
            label: "Client Name",
            id: "client_name",
        },
        {
            label: "Project Name",
            id: "name",
        },
        {
            label: "Project Manager",
            id: "manager",
        },
        {
            label: "PO Number",
            id: "po_number",
        },
        {
            label: "Duration in Days",
            id: "duration_in_days",
        },
        {
            label: "Currency",
            id: "currency",
        },
        {
            label: "Project Total Fees",
            id: "total_fee",
            displayType: 'currency_amount',
        },
        {
            label: "Internal Fees",
            id: "internal_fees",
            displayType: 'currency_amount',
        },
        {
            label: "External Fees",
            id: "external_fees_subcontract",
            displayType: 'currency_amount',
        },
        {
            label: "Travel Fees",
            id: "travel_fees",
            displayType: 'currency_amount',
        },
        {
            label: "Contract Office",
            id: "office",
        },
        {
            label: "Payment Term",
            id: "payment_term",
        },
        {
            label: "Payment Structure",
            id: "invoice_structure",
        },
        {
            label: "Service Offering",
            id: "service_offering",
        },
        {
            label: "Invoice to Company",
            id: "invoice_to_company",
        },
        {
            label: "Invoice Instruction",
            id: "invoice_instruction",
        },
        {
            label: "Invoice Detail",
            id: "invoice_detail",
        }
    ]

    console.log('ProjectHeaderDisplayForm - - projectHeader', projectHeader)
    // console.log('ProjectHeaderDisplayForm - - projectDetail', projectDetail)
    // console.log('ProjectHeaderDisplayForm - - projectDetail[formInfo[14].id]', projectDetail[formInfo[14].id])

    const initializedData = {
        [formInfo[0].id]: projectMaster[formInfo[0].id] || '', // project_number
        [formInfo[1].id]: projectHeader.client && projectHeader.client.label || '', // client_name
        [formInfo[2].id]: projectMaster[formInfo[2].id] || '', // name (project name)
        [formInfo[3].id]: projectHeader.manager && projectHeader.manager.label || '', // manager_name
        [formInfo[4].id]: projectDetail[formInfo[4].id] || '', // po_number
        [formInfo[5].id]: projectDetail[formInfo[5].id] || 0, // duration_in_days
        [formInfo[6].id]: projectDetail[formInfo[6].id] && projectDetail[formInfo[6].id].value || '', // currency
        [formInfo[7].id]: (parseInt(projectDetail.internal_fees) + parseInt(projectDetail.external_fees_subcontract)) || 0, // total_fee
        [formInfo[8].id]: projectDetail[formInfo[8].id] || 0, // internal_fee
        [formInfo[9].id]: projectDetail[formInfo[9].id] || 0, // external_fee
        [formInfo[10].id]: projectDetail[formInfo[10].id] || 0, // travel_fee
        [formInfo[11].id]: currentUserOffice || '', // office
        [formInfo[12].id]: projectDetail[formInfo[12].id] && projectDetail[formInfo[12].id].value || '', // payment_term
        [formInfo[13].id]: projectDetail[formInfo[13].id] && projectDetail[formInfo[13].id].value || '', // payment_structure
        [formInfo[14].id]: projectDetail[formInfo[14].id] && projectDetail[formInfo[14].id].map(item => item.title).join(", ") || '', // servive_offering
        [formInfo[15].id]: projectDetail[formInfo[15].id] || '', // invoice_to_company
        [formInfo[16].id]: projectDetail[formInfo[16].id] && projectDetail[formInfo[16].id].value || '', //invoice_instruction
        [formInfo[17].id]: projectDetail[formInfo[17].id] || '' // invoice_detail
    }

    const isNotEmptyObject = (obj) => {
        return Object.keys(obj).length !== 0;
    };

    return (
        <>
            {
                isNotEmptyObject(projectMaster) && isNotEmptyObject(projectDetail) && isNotEmptyObject(projectHeader) ?
                    <FormForDisplay
                        formInfo={formInfo}
                        displayedData={initializedData}
                    /> : <Spinner />
            }
        </>
    );
};

export default ProjectHeaderDisplayForm;